import { Injectable, WritableSignal, signal } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, LoginResp } from '@app/services/auth.service';
import { BrandMeta, TaxPayer, UserAccount } from '@app/types/global.type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DefaultRoute,
  defaultRoutes,
  getDefaultRoutes,
  getModuleAccess,
  ModuleAccess,
  moduleAccess,
  SlugAccess,
} from '@app/constant/features';

@Injectable({
  providedIn: 'root',
})
export class Store {
  brandMeta: WritableSignal<BrandMeta | null> = signal<BrandMeta | null>(null);

  appLoader: WritableSignal<boolean> = signal<boolean>(false);
  authToken: WritableSignal<string | null> = signal<string | null>(null);
  taxpayer: WritableSignal<TaxPayer | null> = signal<TaxPayer | null>(null);

  activeAccount: WritableSignal<UserAccount | null> = signal<UserAccount | null>(null);
  userAccess: WritableSignal<SlugAccess> = signal<SlugAccess>({});
  moduleAccess: WritableSignal<ModuleAccess> = signal<ModuleAccess>(moduleAccess);
  defaultRoute: WritableSignal<DefaultRoute> = signal<DefaultRoute>(defaultRoutes);

  miniSidenav: WritableSignal<boolean> = signal<boolean>(false);
  sideNavHide: WritableSignal<boolean> = signal<boolean>(false);

  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    private authSvc: AuthService,
  ) {
    // whitelabelling config
    const brandMeta = JSON.parse(localStorage.getItem('brandMeta') ?? 'null');
    this.brandMeta.set(brandMeta);

    //sync user token
    const authToken = localStorage.getItem('authToken') ?? '';
    this.authToken.set(authToken);

    //sync user
    const taxpayer = JSON.parse(localStorage.getItem('taxpayer') ?? 'null');
    this.taxpayer.set(taxpayer);

    // active account
    const activeAccount = JSON.parse(localStorage.getItem('activeAccount') ?? 'null');
    this.activeAccount.set(activeAccount);

    //sync user access
    const access = JSON.parse(localStorage.getItem('userAccess') ?? 'null');
    this.userAccess.set(access);
    this.moduleAccess.set(getModuleAccess(access));
    this.defaultRoute.set(getDefaultRoutes(access));

    const miniSidenav = localStorage.getItem('miniSidebar');
    this.miniSidenav.set(miniSidenav === 'true');

    this.sideNavHide.set(false);
  }

  setBrandMeta(data: BrandMeta) {
    this.brandMeta.set(data);
    localStorage.setItem('brandMeta', JSON.stringify(data));
  }

  setTaxpayer(data: LoginResp) {
    const taxpayer = {
      userId: data.userId,
      email: data.userEmail,
      name: data.userName,
      accounts: data.orgDetails ?? [],
      isMfaEnable: data.isMfaEnable ?? false,
      isMfaRegistered: data.isMfaRegistered ?? false,
    };

    this.authToken.set(data.token);
    localStorage.setItem('authToken', data.token);

    this.taxpayer.set(taxpayer);
    localStorage.setItem('taxpayer', JSON.stringify(taxpayer));

    if (data.orgDetails?.length) {
      const activeAccount = data.orgDetails?.[0];
      this.activeAccount.set(activeAccount);
      localStorage.setItem('activeAccount', JSON.stringify(activeAccount));
    }
  }

  setAuthToken(token: string) {
    this.authToken.set(token);
    localStorage.setItem('authToken', token);
  }

  setIsMfaRegistered(isMfaRegistered: boolean) {
    const taxpayer = this.taxpayer();
    if (taxpayer) {
      taxpayer.isMfaRegistered = isMfaRegistered;
      this.taxpayer.set(taxpayer);
      localStorage.setItem('taxpayer', JSON.stringify(taxpayer));
    }
  }

  setAccessList(data: string[]) {
    const enabled = data.reduce((acc: SlugAccess, curr) => {
      acc[curr] = true;
      return acc;
    }, {});

    const accessList = Object.assign({}, enabled);
    this.userAccess.set(accessList);
    localStorage.setItem('userAccess', JSON.stringify(accessList));

    this.moduleAccess.set(getModuleAccess(enabled));
    this.defaultRoute.set(getDefaultRoutes(enabled));
  }

  switchAccount(orgId: number | string) {
    const account = this.taxpayer()?.accounts.find((acc) => acc.orgId === orgId);
    console.log(account);
    if (account) {
      localStorage.removeItem('userAccess');
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith('filters_')) {
          localStorage.removeItem(key);
        }
      });

      this.activeAccount.set(account);
      localStorage.setItem('activeAccount', JSON.stringify(account));

      // const routerQuery = { queryParams: { redirectUrl: this.router.url } };
      // this.router.navigate(['/redirect']);
      window.location.href = '/redirect';
    }
  }

  clearSession() {
    this.ngbModal.dismissAll();
    this.authToken.set(null);
    this.taxpayer.set(null);
    localStorage.clear();

    // sessionStorage.setItem('redirectUrl', this.router.url);
    this.router.navigate(['/login']);
  }

  logout() {
    this.authSvc.logout().subscribe((status) => {
      if (status) this.clearSession();
    });
  }
}
