interface MSIC {
  [code: string]: {
    Description: string;
    category?: string;
  };
}

interface UnitType {
  [code: string]: {
    Name: string;
    Description: string;
  };
}

interface MasterCode {
  [code: string]: string;
}

export const countries = [
  {
    Code: 'MYS',
    Country: 'MALAYSIA',
  },
  {
    Code: 'SGP',
    Country: 'SINGAPORE',
  },
  {
    Code: 'ABW',
    Country: 'ARUBA',
  },
  {
    Code: 'AFG',
    Country: 'AFGHANISTAN',
  },
  {
    Code: 'AGO',
    Country: 'ANGOLA',
  },
  {
    Code: 'AIA',
    Country: 'ANGUILLA',
  },
  {
    Code: 'ALA',
    Country: 'ALAND ISLANDS',
  },
  {
    Code: 'ALB',
    Country: 'ALBANIA',
  },
  {
    Code: 'AND',
    Country: 'ANDORA',
  },
  {
    Code: 'ARE',
    Country: 'UNITED ARAB EMIRATES',
  },
  {
    Code: 'ARG',
    Country: 'ARGENTINA',
  },
  {
    Code: 'ARM',
    Country: 'ARMENIA',
  },
  {
    Code: 'ASM',
    Country: 'AMERICAN SAMOA',
  },
  {
    Code: 'ATA',
    Country: 'ANTARCTICA',
  },
  {
    Code: 'ATF',
    Country: 'FRENCH SOUTHERN TERRITORIES',
  },
  {
    Code: 'ATG',
    Country: 'ANTIGUA AND BARBUDA',
  },
  {
    Code: 'AUS',
    Country: 'AUSTRALIA',
  },
  {
    Code: 'AUT',
    Country: 'AUSTRIA',
  },
  {
    Code: 'AZE',
    Country: 'AZERBAIDJAN',
  },
  {
    Code: 'BDI',
    Country: 'BURUNDI',
  },
  {
    Code: 'BEL',
    Country: 'BELGIUM',
  },
  {
    Code: 'BEN',
    Country: 'BENIN',
  },
  {
    Code: 'BES',
    Country: 'BONAIRE, SINT EUSTATIUS AND SABA',
  },
  {
    Code: 'BFA',
    Country: 'BURKINA FASO',
  },
  {
    Code: 'BGD',
    Country: 'BANGLADESH',
  },
  {
    Code: 'BGR',
    Country: 'BULGARIA',
  },
  {
    Code: 'BHR',
    Country: 'BAHRAIN',
  },
  {
    Code: 'BHS',
    Country: 'BAHAMAS',
  },
  {
    Code: 'BIH',
    Country: 'BOSNIA AND HERZEGOVINA',
  },
  {
    Code: 'BLM',
    Country: 'SAINT BARTHELEMY',
  },
  {
    Code: 'BLR',
    Country: 'BELARUS',
  },
  {
    Code: 'BLZ',
    Country: 'BELIZE',
  },
  {
    Code: 'BMU',
    Country: 'BERMUDA',
  },
  {
    Code: 'BOL',
    Country: 'BOLIVIA',
  },
  {
    Code: 'BRA',
    Country: 'BRAZIL',
  },
  {
    Code: 'BRB',
    Country: 'BARBADOS',
  },
  {
    Code: 'BRN',
    Country: 'BRUNEI DARUSSALAM',
  },
  {
    Code: 'BTN',
    Country: 'BHUTAN',
  },
  {
    Code: 'BVT',
    Country: 'BOUVET ISLAND',
  },
  {
    Code: 'BWA',
    Country: 'BOTSWANA',
  },
  {
    Code: 'CAF',
    Country: 'CENTRAL AFRICAN REPUBLIC',
  },
  {
    Code: 'CAN',
    Country: 'CANADA',
  },
  {
    Code: 'CCK',
    Country: 'COCOS ISLAND',
  },
  {
    Code: 'CHE',
    Country: 'SWITZERLAND',
  },
  {
    Code: 'CHL',
    Country: 'CHILE',
  },
  {
    Code: 'CHN',
    Country: 'CHINA',
  },
  {
    Code: 'CIV',
    Country: "COTE D'IVOIRE",
  },
  {
    Code: 'CMR',
    Country: 'CAMEROON',
  },
  {
    Code: 'COD',
    Country: 'CONGO, THE DEMOCRATIC REPUBLIC',
  },
  {
    Code: 'COG',
    Country: 'CONGO',
  },
  {
    Code: 'COK',
    Country: 'COOK ISLANDS',
  },
  {
    Code: 'COL',
    Country: 'COLOMBIA',
  },
  {
    Code: 'COM',
    Country: 'COMOROS',
  },
  {
    Code: 'CPV',
    Country: 'CAPE VERDE',
  },
  {
    Code: 'CRI',
    Country: 'COSTA RICA',
  },
  {
    Code: 'CUB',
    Country: 'CUBA',
  },
  {
    Code: 'CUW',
    Country: 'CURACAO',
  },
  {
    Code: 'CXR',
    Country: 'CHRISTMAS ISLANDS',
  },
  {
    Code: 'CYM',
    Country: 'CAYMAN ISLANDS',
  },
  {
    Code: 'CYP',
    Country: 'CYPRUS',
  },
  {
    Code: 'CZE',
    Country: 'CZECH REPUBLIC',
  },
  {
    Code: 'DEU',
    Country: 'GERMANY',
  },
  {
    Code: 'DJI',
    Country: 'DJIBOUTI',
  },
  {
    Code: 'DMA',
    Country: 'DOMINICA',
  },
  {
    Code: 'DNK',
    Country: 'DENMARK',
  },
  {
    Code: 'DOM',
    Country: 'DOMINICAN REPUBLIC',
  },
  {
    Code: 'DZA',
    Country: 'ALGERIA',
  },
  {
    Code: 'ECU',
    Country: 'ECUADOR',
  },
  {
    Code: 'EGY',
    Country: 'EGYPT',
  },
  {
    Code: 'ERI',
    Country: 'ERITREA',
  },
  {
    Code: 'ESH',
    Country: 'WESTERN SAHARA',
  },
  {
    Code: 'ESP',
    Country: 'SPAIN',
  },
  {
    Code: 'EST',
    Country: 'ESTONIA',
  },
  {
    Code: 'ETH',
    Country: 'ETHIOPIA',
  },
  {
    Code: 'FIN',
    Country: 'FINLAND',
  },
  {
    Code: 'FJI',
    Country: 'FIJI',
  },
  {
    Code: 'FLK',
    Country: 'FALKLAND ISLANDS (MALVINAS)',
  },
  {
    Code: 'FRA',
    Country: 'FRANCE',
  },
  {
    Code: 'FRO',
    Country: 'FAEROE ISLANDS',
  },
  {
    Code: 'FSM',
    Country: 'MICRONESIA, FEDERATED STATES OF',
  },
  {
    Code: 'GAB',
    Country: 'GABON',
  },
  {
    Code: 'GBR',
    Country: 'UNITED KINGDOM',
  },
  {
    Code: 'GEO',
    Country: 'GEORGIA',
  },
  {
    Code: 'GGY',
    Country: 'GUERNSEY',
  },
  {
    Code: 'GHA',
    Country: 'GHANA',
  },
  {
    Code: 'GIB',
    Country: 'GIBRALTAR',
  },
  {
    Code: 'GIN',
    Country: 'GUINEA',
  },
  {
    Code: 'GLP',
    Country: 'GUADELOUPE',
  },
  {
    Code: 'GMB',
    Country: 'GAMBIA',
  },
  {
    Code: 'GNB',
    Country: 'GUINEA-BISSAU',
  },
  {
    Code: 'GNQ',
    Country: 'EQUATORIAL GUINEA',
  },
  {
    Code: 'GRC',
    Country: 'GREECE',
  },
  {
    Code: 'GRD',
    Country: 'GRENADA',
  },
  {
    Code: 'GRL',
    Country: 'GREENLAND',
  },
  {
    Code: 'GTM',
    Country: 'GUATEMALA',
  },
  {
    Code: 'GUF',
    Country: 'FRENCH GUIANA',
  },
  {
    Code: 'GUM',
    Country: 'GUAM',
  },
  {
    Code: 'GUY',
    Country: 'GUYANA',
  },
  {
    Code: 'HKG',
    Country: 'HONG KONG',
  },
  {
    Code: 'HMD',
    Country: 'HEARD AND MCDONALD ISLANDS',
  },
  {
    Code: 'HND',
    Country: 'HONDURAS',
  },
  {
    Code: 'HRV',
    Country: 'CROATIA',
  },
  {
    Code: 'HTI',
    Country: 'HAITI',
  },
  {
    Code: 'HUN',
    Country: 'HUNGARY',
  },
  {
    Code: 'IDN',
    Country: 'INDONESIA',
  },
  {
    Code: 'IMN',
    Country: 'ISLE OF MAN',
  },
  {
    Code: 'IND',
    Country: 'INDIA',
  },
  {
    Code: 'IOT',
    Country: 'BRITISH INDIAN OCEAN TERRITORY',
  },
  {
    Code: 'IRL',
    Country: 'IRELAND',
  },
  {
    Code: 'IRN',
    Country: 'IRAN',
  },
  {
    Code: 'IRQ',
    Country: 'IRAQ',
  },
  {
    Code: 'ISL',
    Country: 'ICELAND',
  },
  {
    Code: 'ISR',
    Country: 'ISRAEL',
  },
  {
    Code: 'ITA',
    Country: 'ITALY',
  },
  {
    Code: 'JAM',
    Country: 'JAMAICA',
  },
  {
    Code: 'JEY',
    Country: 'JERSEY (CHANNEL ISLANDS)',
  },
  {
    Code: 'JOR',
    Country: 'JORDAN ',
  },
  {
    Code: 'JPN',
    Country: 'JAPAN',
  },
  {
    Code: 'KAZ',
    Country: 'KAZAKHSTAN',
  },
  {
    Code: 'KEN',
    Country: 'KENYA',
  },
  {
    Code: 'KGZ',
    Country: 'KYRGYZSTAN',
  },
  {
    Code: 'KHM',
    Country: 'CAMBODIA',
  },
  {
    Code: 'KIR',
    Country: 'KIRIBATI',
  },
  {
    Code: 'KNA',
    Country: 'ST.KITTS AND NEVIS',
  },
  {
    Code: 'KOR',
    Country: 'THE REPUBLIC OF KOREA',
  },
  {
    Code: 'KWT',
    Country: 'KUWAIT',
  },
  {
    Code: 'LAO',
    Country: 'LAOS',
  },
  {
    Code: 'LBN',
    Country: 'LEBANON',
  },
  {
    Code: 'LBR',
    Country: 'LIBERIA',
  },
  {
    Code: 'LBY',
    Country: 'LIBYAN ARAB JAMAHIRIYA',
  },
  {
    Code: 'LCA',
    Country: 'SAINT LUCIA',
  },
  {
    Code: 'LIE',
    Country: 'LIECHTENSTEIN',
  },
  {
    Code: 'LKA',
    Country: 'SRI LANKA',
  },
  {
    Code: 'LSO',
    Country: 'LESOTHO',
  },
  {
    Code: 'LTU',
    Country: 'LITHUANIA',
  },
  {
    Code: 'LUX',
    Country: 'LUXEMBOURG',
  },
  {
    Code: 'LVA',
    Country: 'LATVIA',
  },
  {
    Code: 'MAC',
    Country: 'MACAO',
  },
  {
    Code: 'MAF',
    Country: 'SAINT MARTIN (FRENCH PART)',
  },
  {
    Code: 'MAR',
    Country: 'MOROCCO',
  },
  {
    Code: 'MCO',
    Country: 'MONACO',
  },
  {
    Code: 'MDA',
    Country: 'MOLDOVA, REPUBLIC OF',
  },
  {
    Code: 'MDG',
    Country: 'MADAGASCAR',
  },
  {
    Code: 'MDV',
    Country: 'MALDIVES',
  },
  {
    Code: 'MEX',
    Country: 'MEXICO',
  },
  {
    Code: 'MHL',
    Country: 'MARSHALL ISLANDS',
  },
  {
    Code: 'MKD',
    Country: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
  },
  {
    Code: 'MLI',
    Country: 'MALI',
  },
  {
    Code: 'MLT',
    Country: 'MALTA',
  },
  {
    Code: 'MMR',
    Country: 'MYANMAR',
  },
  {
    Code: 'MNE',
    Country: 'MONTENEGRO',
  },
  {
    Code: 'MNG',
    Country: 'MONGOLIA',
  },
  {
    Code: 'MNP',
    Country: 'NORTHERN MARIANA ISLANDS',
  },
  {
    Code: 'MOZ',
    Country: 'MOZAMBIQUE',
  },
  {
    Code: 'MRT',
    Country: 'MAURITANIA',
  },
  {
    Code: 'MSR',
    Country: 'MONTSERRAT',
  },
  {
    Code: 'MTQ',
    Country: 'MARTINIQUE',
  },
  {
    Code: 'MUS',
    Country: 'MAURITIUS',
  },
  {
    Code: 'MWI',
    Country: 'MALAWI',
  },
  {
    Code: 'MYT',
    Country: 'MAYOTTE',
  },
  {
    Code: 'NAM',
    Country: 'NAMIBIA',
  },
  {
    Code: 'NCL',
    Country: 'NEW CALEDONIA',
  },
  {
    Code: 'NER',
    Country: 'NIGER',
  },
  {
    Code: 'NFK',
    Country: 'NORFOLK ISLAND',
  },
  {
    Code: 'NGA',
    Country: 'NIGERIA',
  },
  {
    Code: 'NIC',
    Country: 'NICARAGUA',
  },
  {
    Code: 'NIU',
    Country: 'NIUE',
  },
  {
    Code: 'NLD',
    Country: 'NETHERLANDS',
  },
  {
    Code: 'NOR',
    Country: 'NORWAY',
  },
  {
    Code: 'NPL',
    Country: 'NEPAL',
  },
  {
    Code: 'NRU',
    Country: 'NAURU',
  },
  {
    Code: 'NZL',
    Country: 'NEW ZEALAND',
  },
  {
    Code: 'OMN',
    Country: 'OMAN',
  },
  {
    Code: 'PAK',
    Country: 'PAKISTAN',
  },
  {
    Code: 'PAN',
    Country: 'PANAMA',
  },
  {
    Code: 'PCN',
    Country: 'PITCAIRN',
  },
  {
    Code: 'PER',
    Country: 'PERU',
  },
  {
    Code: 'PHL',
    Country: 'PHILIPPINES',
  },
  {
    Code: 'PLW',
    Country: 'PALAU',
  },
  {
    Code: 'PNG',
    Country: 'PAPUA NEW GUINEA',
  },
  {
    Code: 'POL',
    Country: 'POLAND',
  },
  {
    Code: 'PRI',
    Country: 'PUERTO RICO',
  },
  {
    Code: 'PRK',
    Country: 'DEMOC.PEOPLES REP.OF KOREA',
  },
  {
    Code: 'PRT',
    Country: 'PORTUGAL',
  },
  {
    Code: 'PRY',
    Country: 'PARAGUAY',
  },
  {
    Code: 'PSE',
    Country: 'PALESTINIAN TERRITORY, OCCUPIED',
  },
  {
    Code: 'PYF',
    Country: 'FRENCH POLYNESIA',
  },
  {
    Code: 'QAT',
    Country: 'QATAR',
  },
  {
    Code: 'REU',
    Country: 'REUNION',
  },
  {
    Code: 'ROU',
    Country: 'ROMANIA',
  },
  {
    Code: 'RUS',
    Country: 'RUSSIAN FEDERATION (USSR)',
  },
  {
    Code: 'RWA',
    Country: 'RWANDA',
  },
  {
    Code: 'SAU',
    Country: 'SAUDI ARABIA',
  },
  {
    Code: 'SDN',
    Country: 'SUDAN',
  },
  {
    Code: 'SEN',
    Country: 'SENEGAL',
  },
  {
    Code: 'SGS',
    Country: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLAND',
  },
  {
    Code: 'SHN',
    Country: 'ST. HELENA',
  },
  {
    Code: 'SJM',
    Country: 'SVALBARD AND JAN MAYEN ISLANDS',
  },
  {
    Code: 'SLB',
    Country: 'SOLOMON ISLANDS',
  },
  {
    Code: 'SLE',
    Country: 'SIERRA LEONE',
  },
  {
    Code: 'SLV',
    Country: 'EL SALVADOR',
  },
  {
    Code: 'SMR',
    Country: 'SAN MARINO',
  },
  {
    Code: 'SOM',
    Country: 'SOMALIA',
  },
  {
    Code: 'SPM',
    Country: 'ST. PIERRE AND MIQUELON',
  },
  {
    Code: 'SRB',
    Country: 'SERBIA & MONTENEGRO',
  },
  {
    Code: 'SSD',
    Country: 'SOUTH SUDAN',
  },
  {
    Code: 'STP',
    Country: 'SAO TOME AND PRINCIPE',
  },
  {
    Code: 'SUR',
    Country: 'SURINAME',
  },
  {
    Code: 'SVK',
    Country: 'SLOVAK REPUBLIC',
  },
  {
    Code: 'SVN',
    Country: 'SLOVENIA',
  },
  {
    Code: 'SWE',
    Country: 'SWEDEN',
  },
  {
    Code: 'SWZ',
    Country: 'ESWATINI, KINGDOM OF (SWAZILAND)',
  },
  {
    Code: 'SXM',
    Country: 'SINT MAARTEN (DUTCH PART)',
  },
  {
    Code: 'SYC',
    Country: 'SEYCHELLES',
  },
  {
    Code: 'SYR',
    Country: 'SYRIAN ARAB REPUBLIC',
  },
  {
    Code: 'TCA',
    Country: 'TURKS AND CAICOS ISLANDS',
  },
  {
    Code: 'TCD',
    Country: 'CHAD',
  },
  {
    Code: 'TGO',
    Country: 'TOGO',
  },
  {
    Code: 'THA',
    Country: 'THAILAND',
  },
  {
    Code: 'TJK',
    Country: 'TAJIKISTAN',
  },
  {
    Code: 'TKL',
    Country: 'TOKELAU',
  },
  {
    Code: 'TKM',
    Country: 'TURKMENISTAN',
  },
  {
    Code: 'TLS',
    Country: 'TIMOR-LESTE',
  },
  {
    Code: 'TON',
    Country: 'TONGA',
  },
  {
    Code: 'TTO',
    Country: 'TRINIDAD AND TOBAGO',
  },
  {
    Code: 'TUN',
    Country: 'TUNISIA',
  },
  {
    Code: 'TUR',
    Country: 'TURKIYE',
  },
  {
    Code: 'TUV',
    Country: 'TUVALU',
  },
  {
    Code: 'TWN',
    Country: 'TAIWAN',
  },
  {
    Code: 'TZA',
    Country: 'TANZANIA UNITED REPUBLIC',
  },
  {
    Code: 'UGA',
    Country: 'UGANDA',
  },
  {
    Code: 'UKR',
    Country: 'UKRAINE',
  },
  {
    Code: 'UMI',
    Country: 'UNITED STATES MINOR OUTLYING ISLANDS',
  },
  {
    Code: 'URY',
    Country: 'URUGUAY',
  },
  {
    Code: 'USA',
    Country: 'UNITED STATES OF AMERICA',
  },
  {
    Code: 'UZB',
    Country: 'UZBEKISTAN',
  },
  {
    Code: 'VAT',
    Country: 'VATICAN CITY STATE (HOLY SEE)',
  },
  {
    Code: 'VCT',
    Country: 'SAINT VINCENT AND GRENADINES',
  },
  {
    Code: 'VEN',
    Country: 'VENEZUELA',
  },
  {
    Code: 'VGB',
    Country: 'VIRGIN ISLANDS(BRITISH)',
  },
  {
    Code: 'VIR',
    Country: 'VIRGIN ISLANDS(US)',
  },
  {
    Code: 'VNM',
    Country: 'VIETNAM',
  },
  {
    Code: 'VUT',
    Country: 'VANUATU',
  },
  {
    Code: 'WLF',
    Country: 'WALLIS AND FUTUNA ISLANDS',
  },
  {
    Code: 'WSM',
    Country: 'SAMOA',
  },
  {
    Code: 'YEM',
    Country: 'YEMEN',
  },
  {
    Code: 'ZAF',
    Country: 'SOUTH AFRICA',
  },
  {
    Code: 'ZMB',
    Country: 'ZAMBIA',
  },
  {
    Code: 'ZWE',
    Country: 'ZIMBABWE',
  },
  {
    Code: 'ANT',
    Country: 'NETHERLANDS ANTILLES',
  },
  {
    Code: 'XKX',
    Country: 'KOSOVO',
  },
];

export const currencies = [
  {
    key: 'MYR',
    name: 'Malaysian Ringgit',
  },
  {
    key: 'USD',
    name: 'US Dollar',
  },
  {
    key: 'AED',
    name: 'UAE Dirham',
  },
  {
    key: 'AFN',
    name: 'Afghani',
  },
  {
    key: 'ALL',
    name: 'Lek',
  },
  {
    key: 'AMD',
    name: 'Armenian Dram',
  },
  {
    key: 'ANG',
    name: 'Netherlands Antillean Guilder',
  },
  {
    key: 'AOA',
    name: 'Kwanza',
  },
  {
    key: 'ARS',
    name: 'Argentine Peso',
  },
  {
    key: 'AUD',
    name: 'Australian Dollar',
  },
  {
    key: 'AWG',
    name: 'Aruban Florin',
  },
  {
    key: 'AZN',
    name: 'Azerbaijan Manat',
  },
  {
    key: 'BAM',
    name: 'Convertible Mark',
  },
  {
    key: 'BBD',
    name: 'Barbados Dollar',
  },
  {
    key: 'BDT',
    name: 'Taka',
  },
  {
    key: 'BGN',
    name: 'Bulgarian Lev',
  },
  {
    key: 'BHD',
    name: 'Bahraini Dinar',
  },
  {
    key: 'BIF',
    name: 'Burundi Franc',
  },
  {
    key: 'BMD',
    name: 'Bermudian Dollar',
  },
  {
    key: 'BND',
    name: 'Brunei Dollar',
  },
  {
    key: 'BOB',
    name: 'Boliviano',
  },
  {
    key: 'BOV',
    name: 'Mvdol',
  },
  {
    key: 'BRL',
    name: 'Brazilian Real',
  },
  {
    key: 'BSD',
    name: 'Bahamian Dollar',
  },
  {
    key: 'BTN',
    name: 'Ngultrum',
  },
  {
    key: 'BWP',
    name: 'Pula',
  },
  {
    key: 'BYN',
    name: 'Belarusian Ruble',
  },
  {
    key: 'BZD',
    name: 'Belize Dollar',
  },
  {
    key: 'CAD',
    name: 'Canadian Dollar',
  },
  {
    key: 'CDF',
    name: 'Congolese Franc',
  },
  {
    key: 'CHE',
    name: 'WIR Euro',
  },
  {
    key: 'CHF',
    name: 'Swiss Franc',
  },
  {
    key: 'CHW',
    name: 'WIR Franc',
  },
  {
    key: 'CLF',
    name: 'Unidad de Fomento',
  },
  {
    key: 'CLP',
    name: 'Chilean Peso',
  },
  {
    key: 'CNY',
    name: 'Yuan Renminbi',
  },
  {
    key: 'COP',
    name: 'Colombian Peso',
  },
  {
    key: 'COU',
    name: 'Unidad de Valor Real',
  },
  {
    key: 'CRC',
    name: 'Costa Rican Colon',
  },
  {
    key: 'CUC',
    name: 'Peso Convertible',
  },
  {
    key: 'CUP',
    name: 'Cuban Peso',
  },
  {
    key: 'CVE',
    name: 'Cabo Verde Escudo',
  },
  {
    key: 'CZK',
    name: 'Czech Koruna',
  },
  {
    key: 'DJF',
    name: 'Djibouti Franc',
  },
  {
    key: 'DKK',
    name: 'Danish Krone',
  },
  {
    key: 'DOP',
    name: 'Dominican Peso',
  },
  {
    key: 'DZD',
    name: 'Algerian Dinar',
  },
  {
    key: 'EGP',
    name: 'Egyptian Pound',
  },
  {
    key: 'ERN',
    name: 'Nakfa',
  },
  {
    key: 'ETB',
    name: 'Ethiopian Birr',
  },
  {
    key: 'EUR',
    name: 'Euro',
  },
  {
    key: 'FJD',
    name: 'Fiji Dollar',
  },
  {
    key: 'FKP',
    name: 'Falkland Islands Pound',
  },
  {
    key: 'GBP',
    name: 'Pound Sterling',
  },
  {
    key: 'GEL',
    name: 'Lari',
  },
  {
    key: 'GHS',
    name: 'Ghana Cedi',
  },
  {
    key: 'GIP',
    name: 'Gibraltar Pound',
  },
  {
    key: 'GMD',
    name: 'Dalasi',
  },
  {
    key: 'GNF',
    name: 'Guinean Franc',
  },
  {
    key: 'GTQ',
    name: 'Quetzal',
  },
  {
    key: 'GYD',
    name: 'Guyana Dollar',
  },
  {
    key: 'HKD',
    name: 'Hong Kong Dollar',
  },
  {
    key: 'HNL',
    name: 'Lempira',
  },
  {
    key: 'HRK',
    name: 'Kuna',
  },
  {
    key: 'HTG',
    name: 'Gourde',
  },
  {
    key: 'HUF',
    name: 'Forint',
  },
  {
    key: 'IDR',
    name: 'Rupiah',
  },
  {
    key: 'ILS',
    name: 'New Israeli Sheqel',
  },
  {
    key: 'INR',
    name: 'Indian Rupee',
  },
  {
    key: 'IQD',
    name: 'Iraqi Dinar',
  },
  {
    key: 'IRR',
    name: 'Iranian Rial',
  },
  {
    key: 'ISK',
    name: 'Iceland Krona',
  },
  {
    key: 'JMD',
    name: 'Jamaican Dollar',
  },
  {
    key: 'JOD',
    name: 'Jordanian Dinar',
  },
  {
    key: 'JPY',
    name: 'Yen',
  },
  {
    key: 'KES',
    name: 'Kenyan Shilling',
  },
  {
    key: 'KGS',
    name: 'Som',
  },
  {
    key: 'KHR',
    name: 'Riel',
  },
  {
    key: 'KMF',
    name: 'Comorian Franc',
  },
  {
    key: 'KPW',
    name: 'North Korean Won',
  },
  {
    key: 'KRW',
    name: 'Won',
  },
  {
    key: 'KWD',
    name: 'Kuwaiti Dinar',
  },
  {
    key: 'KYD',
    name: 'Cayman Islands Dollar',
  },
  {
    key: 'KZT',
    name: 'Tenge',
  },
  {
    key: 'LAK',
    name: 'Lao Kip',
  },
  {
    key: 'LBP',
    name: 'Lebanese Pound',
  },
  {
    key: 'LKR',
    name: 'Sri Lanka Rupee',
  },
  {
    key: 'LRD',
    name: 'Liberian Dollar',
  },
  {
    key: 'LSL',
    name: 'Loti',
  },
  {
    key: 'LYD',
    name: 'Libyan Dinar',
  },
  {
    key: 'MAD',
    name: 'Moroccan Dirham',
  },
  {
    key: 'MDL',
    name: 'Moldovan Leu',
  },
  {
    key: 'MGA',
    name: 'Malagasy Ariary',
  },
  {
    key: 'MKD',
    name: 'Denar',
  },
  {
    key: 'MMK',
    name: 'Kyat',
  },
  {
    key: 'MNT',
    name: 'Tugrik',
  },
  {
    key: 'MOP',
    name: 'Pataca',
  },
  {
    key: 'MRU',
    name: 'Ouguiya',
  },
  {
    key: 'MUR',
    name: 'Mauritius Rupee',
  },
  {
    key: 'MVR',
    name: 'Rufiyaa',
  },
  {
    key: 'MWK',
    name: 'Malawi Kwacha',
  },
  {
    key: 'MXN',
    name: 'Mexican Peso',
  },
  {
    key: 'MXV',
    name: 'Mexican Unidad de Inversion (UDI)',
  },
  {
    key: 'MZN',
    name: 'Mozambique Metical',
  },
  {
    key: 'NAD',
    name: 'Namibia Dollar',
  },
  {
    key: 'NGN',
    name: 'Naira',
  },
  {
    key: 'NIO',
    name: 'Cordoba Oro',
  },
  {
    key: 'NOK',
    name: 'Norwegian Krone',
  },
  {
    key: 'NPR',
    name: 'Nepalese Rupee',
  },
  {
    key: 'NZD',
    name: 'New Zealand Dollar',
  },
  {
    key: 'OMR',
    name: 'Rial Omani',
  },
  {
    key: 'PAB',
    name: 'Balboa',
  },
  {
    key: 'PEN',
    name: 'Sol',
  },
  {
    key: 'PGK',
    name: 'Kina',
  },
  {
    key: 'PHP',
    name: 'Philippine Peso',
  },
  {
    key: 'PKR',
    name: 'Pakistan Rupee',
  },
  {
    key: 'PLN',
    name: 'Zloty',
  },
  {
    key: 'PYG',
    name: 'Guarani',
  },
  {
    key: 'QAR',
    name: 'Qatari Rial',
  },
  {
    key: 'RON',
    name: 'Romanian Leu',
  },
  {
    key: 'RSD',
    name: 'Serbian Dinar',
  },
  {
    key: 'RUB',
    name: 'Russian Ruble',
  },
  {
    key: 'RWF',
    name: 'Rwanda Franc',
  },
  {
    key: 'SAR',
    name: 'Saudi Riyal',
  },
  {
    key: 'SBD',
    name: 'Solomon Islands Dollar',
  },
  {
    key: 'SCR',
    name: 'Seychelles Rupee',
  },
  {
    key: 'SDG',
    name: 'Sudanese Pound',
  },
  {
    key: 'SEK',
    name: 'Swedish Krona',
  },
  {
    key: 'SGD',
    name: 'Singapore Dollar',
  },
  {
    key: 'SHP',
    name: 'Saint Helena Pound',
  },
  {
    key: 'SLL',
    name: 'Leone',
  },
  {
    key: 'SOS',
    name: 'Somali Shilling',
  },
  {
    key: 'SRD',
    name: 'Surinam Dollar',
  },
  {
    key: 'SSP',
    name: 'South Sudanese Pound',
  },
  {
    key: 'STN',
    name: 'Dobra',
  },
  {
    key: 'SVC',
    name: 'El Salvador Colon',
  },
  {
    key: 'SYP',
    name: 'Syrian Pound',
  },
  {
    key: 'SZL',
    name: 'Lilangeni',
  },
  {
    key: 'THB',
    name: 'Baht',
  },
  {
    key: 'TJS',
    name: 'Somoni',
  },
  {
    key: 'TMT',
    name: 'Turkmenistan New Manat',
  },
  {
    key: 'TND',
    name: 'Tunisian Dinar',
  },
  {
    key: 'TOP',
    name: 'Pa’anga',
  },
  {
    key: 'TRY',
    name: 'Turkish Lira',
  },
  {
    key: 'TTD',
    name: 'Trinidad and Tobago Dollar',
  },
  {
    key: 'TWD',
    name: 'New Taiwan Dollar',
  },
  {
    key: 'TZS',
    name: 'Tanzanian Shilling',
  },
  {
    key: 'UAH',
    name: 'Hryvnia',
  },
  {
    key: 'UGX',
    name: 'Uganda Shilling',
  },
  {
    key: 'USN',
    name: 'US Dollar (Next day)',
  },
  {
    key: 'UYI',
    name: 'Uruguay Peso en Unidades Indexadas (UI)',
  },
  {
    key: 'UYU',
    name: 'Peso Uruguayo',
  },
  {
    key: 'UYW',
    name: 'Unidad Previsional',
  },
  {
    key: 'UZS',
    name: 'Uzbekistan Sum',
  },
  {
    key: 'VED',
    name: 'Bolívar Soberano',
  },
  {
    key: 'VES',
    name: 'Bolívar Soberano',
  },
  {
    key: 'VND',
    name: 'Dong',
  },
  {
    key: 'VUV',
    name: 'Vatu',
  },
  {
    key: 'WST',
    name: 'Tala',
  },
  {
    key: 'XAF',
    name: 'CFA Franc BEAC',
  },
  {
    key: 'XAG',
    name: 'Silver',
  },
  {
    key: 'XAU',
    name: 'Gold',
  },
  {
    key: 'XBA',
    name: 'Bond Markets Unit European Composite Unit (EURCO)',
  },
  {
    key: 'XBB',
    name: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
  },
  {
    key: 'XBC',
    name: 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
  },
  {
    key: 'XBD',
    name: 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
  },
  {
    key: 'XCD',
    name: 'East Caribbean Dollar',
  },
  {
    key: 'XDR',
    name: 'SDR (Special Drawing Right)',
  },
  {
    key: 'XOF',
    name: 'CFA Franc BCEAO',
  },
  {
    key: 'XPD',
    name: 'Palladium',
  },
  {
    key: 'XPF',
    name: 'CFP Franc',
  },
  {
    key: 'XPT',
    name: 'Platinum',
  },
  {
    key: 'XSU',
    name: 'Sucre',
  },
  {
    key: 'XTS',
    name: 'keys specifically reserved for testing purposes',
  },
  {
    key: 'XUA',
    name: 'ADB Unit of Account',
  },
  {
    key: 'XXX',
    name: 'The codes assigned for transactions where no currency is involved',
  },
  {
    key: 'YER',
    name: 'Yemeni Rial',
  },
  {
    key: 'ZAR',
    name: 'Rand',
  },
  {
    key: 'ZMW',
    name: 'Zambian Kwacha',
  },
  {
    key: 'ZWL',
    name: 'Zimbabwe Dollar',
  },
];

export const stateList = [
  {
    Code: '01',
    State: 'Johor',
  },
  {
    Code: '02',
    State: 'Kedah',
  },
  {
    Code: '03',
    State: 'Kelantan',
  },
  {
    Code: '04',
    State: 'Melaka',
  },
  {
    Code: '05',
    State: 'Negeri Sembilan',
  },
  {
    Code: '06',
    State: 'Pahang',
  },
  {
    Code: '07',
    State: 'Pulau Pinang',
  },
  {
    Code: '08',
    State: 'Perak',
  },
  {
    Code: '09',
    State: 'Perlis',
  },
  {
    Code: '10',
    State: 'Selangor',
  },
  {
    Code: '11',
    State: 'Terengganu',
  },
  {
    Code: '12',
    State: 'Sabah',
  },
  {
    Code: '13',
    State: 'Sarawak',
  },
  {
    Code: '14',
    State: 'Wilayah Persekutuan Kuala Lumpur',
  },
  {
    Code: '15',
    State: 'Wilayah Persekutuan Labuan',
  },
  {
    Code: '16',
    State: 'Wilayah Persekutuan Putrajaya',
  },
  {
    Code: '17',
    State: 'Not Applicable',
  },
];

export const msicList = [
  {
    Code: '00000',
    Description: 'NOT APPLICABLE',
    category: '',
  },
  {
    Code: '01111',
    Description: 'Growing of maize',
    category: 'A',
  },
  {
    Code: '01112',
    Description: 'Growing of leguminous crops',
    category: 'A',
  },
  {
    Code: '01113',
    Description: 'Growing of oil seeds',
    category: 'A',
  },
  {
    Code: '01119',
    Description: 'Growing of other cereals n.e.c.',
    category: 'A',
  },
  {
    Code: '01120',
    Description: 'Growing of paddy',
    category: 'A',
  },
  {
    Code: '01131',
    Description: 'Growing of leafy or stem vegetables',
    category: 'A',
  },
  {
    Code: '01132',
    Description: 'Growing of fruits bearing vegetables',
    category: 'A',
  },
  {
    Code: '01133',
    Description: 'Growing of melons',
    category: 'A',
  },
  {
    Code: '01134',
    Description: 'Growing of mushrooms and truffles',
    category: 'A',
  },
  {
    Code: '01135',
    Description: 'Growing of vegetables seeds, except beet seeds',
    category: 'A',
  },
  {
    Code: '01136',
    Description: 'Growing of other vegetables',
    category: 'A',
  },
  {
    Code: '01137',
    Description: 'Growing of sugar beet',
    category: 'A',
  },
  {
    Code: '01138',
    Description: 'Growing of roots, tubers, bulb or tuberous vegetables',
    category: 'A',
  },
  {
    Code: '01140',
    Description: 'Growing of sugar cane',
    category: 'A',
  },
  {
    Code: '01150',
    Description: 'Growing of tobacco',
    category: 'A',
  },
  {
    Code: '01160',
    Description: 'Growing of fibre crops',
    category: 'A',
  },
  {
    Code: '01191',
    Description: 'Growing of flowers',
    category: 'A',
  },
  {
    Code: '01192',
    Description: 'Growing of flower seeds',
    category: 'A',
  },
  {
    Code: '01193',
    Description: 'Growing of sago (rumbia)',
    category: 'A',
  },
  {
    Code: '01199',
    Description: 'Growing of other non-perennial crops n.e.c.',
    category: 'A',
  },
  {
    Code: '01210',
    Description: 'Growing of grapes',
    category: 'A',
  },
  {
    Code: '01221',
    Description: 'Growing of banana',
    category: 'A',
  },
  {
    Code: '01222',
    Description: 'Growing of mango',
    category: 'A',
  },
  {
    Code: '01223',
    Description: 'Growing of durian',
    category: 'A',
  },
  {
    Code: '01224',
    Description: 'Growing of rambutan',
    category: 'A',
  },
  {
    Code: '01225',
    Description: 'Growing of star fruit',
    category: 'A',
  },
  {
    Code: '01226',
    Description: 'Growing of papaya',
    category: 'A',
  },
  {
    Code: '01227',
    Description: 'Growing of pineapple',
    category: 'A',
  },
  {
    Code: '01228',
    Description: 'Growing of pitaya (dragon fruit)',
    category: 'A',
  },
  {
    Code: '01229',
    Description: 'Growing of other tropical and subtropical fruits n.e.c.',
    category: 'A',
  },
  {
    Code: '01231',
    Description: 'Growing of pomelo',
    category: 'A',
  },
  {
    Code: '01232',
    Description: 'Growing of lemon and limes',
    category: 'A',
  },
  {
    Code: '01233',
    Description: 'Growing of tangerines and mandarin',
    category: 'A',
  },
  {
    Code: '01239',
    Description: 'Growing of other citrus fruits n.e.c.',
    category: 'A',
  },
  {
    Code: '01241',
    Description: 'Growing of guava',
    category: 'A',
  },
  {
    Code: '01249',
    Description: 'Growing of other pome fruits and stones fruits n.e.c.',
    category: 'A',
  },
  {
    Code: '01251',
    Description: 'Growing of berries',
    category: 'A',
  },
  {
    Code: '01252',
    Description: 'Growing of fruit seeds',
    category: 'A',
  },
  {
    Code: '01253',
    Description: 'Growing of edible nuts',
    category: 'A',
  },
  {
    Code: '01259',
    Description: 'Growing of other tree and bush fruits',
    category: 'A',
  },
  {
    Code: '01261',
    Description: 'Growing of oil palm (estate)',
    category: 'A',
  },
  {
    Code: '01262',
    Description: 'Growing of oil palm (smallholdings)',
    category: 'A',
  },
  {
    Code: '01263',
    Description: 'Growing of coconut (estate and smallholdings)',
    category: 'A',
  },
  {
    Code: '01269',
    Description: 'Growing of other oleaginous fruits n.e.c.',
    category: 'A',
  },
  {
    Code: '01271',
    Description: 'Growing of coffee',
    category: 'A',
  },
  {
    Code: '01272',
    Description: 'Growing of tea',
    category: 'A',
  },
  {
    Code: '01273',
    Description: 'Growing of cocoa',
    category: 'A',
  },
  {
    Code: '01279',
    Description: 'Growing of other beverage crops n.e.c.',
    category: 'A',
  },
  {
    Code: '01281',
    Description: 'Growing of pepper (piper nigrum)',
    category: 'A',
  },
  {
    Code: '01282',
    Description: 'Growing of chilies and pepper (capsicum spp.)',
    category: 'A',
  },
  {
    Code: '01283',
    Description: 'Growing of nutmeg',
    category: 'A',
  },
  {
    Code: '01284',
    Description: 'Growing of ginger',
    category: 'A',
  },
  {
    Code: '01285',
    Description:
      'Growing of plants used primarily in perfumery, in pharmacy or for insecticidal, fungicidal or similar purposes',
    category: 'A',
  },
  {
    Code: '01289',
    Description: 'Growing of other spices and aromatic crops n.e.c.',
    category: 'A',
  },
  {
    Code: '01291',
    Description: 'Growing  of rubber trees (estate)',
    category: 'A',
  },
  {
    Code: '01292',
    Description: 'Growing of rubber trees (smallholdings)',
    category: 'A',
  },
  {
    Code: '01293',
    Description: 'Growing of trees for extraction of sap',
    category: 'A',
  },
  {
    Code: '01294',
    Description: 'Growing of nipa palm',
    category: 'A',
  },
  {
    Code: '01295',
    Description: 'Growing of areca',
    category: 'A',
  },
  {
    Code: '01296',
    Description: 'Growing of roselle',
    category: 'A',
  },
  {
    Code: '01299',
    Description: 'Growing of other perennial crops n.e.c.',
    category: 'A',
  },
  {
    Code: '01301',
    Description: 'Growing of plants for planting',
    category: 'A',
  },
  {
    Code: '01302',
    Description: 'Growing of plants for ornamental purposes',
    category: 'A',
  },
  {
    Code: '01303',
    Description:
      'Growing of live plants for bulbs, tubers and roots; cuttings and slips; mushroom spawn',
    category: 'A',
  },
  {
    Code: '01304',
    Description: 'Operation of tree nurseries',
    category: 'A',
  },
  {
    Code: '01411',
    Description: 'Raising, breeding and production of cattle or buffaloes',
    category: 'A',
  },
  {
    Code: '01412',
    Description: 'Production of raw milk from cows or buffaloes',
    category: 'A',
  },
  {
    Code: '01413',
    Description: 'Production of bovine semen',
    category: 'A',
  },
  {
    Code: '01420',
    Description: 'Raising and breeding of horses, asses, mules or hinnes',
    category: 'A',
  },
  {
    Code: '01430',
    Description: 'Raising and breeding of camels (dromedary) and camelids',
    category: 'A',
  },
  {
    Code: '01441',
    Description: 'Raising, breeding and production of sheep and goats',
    category: 'A',
  },
  {
    Code: '01442',
    Description: 'Production of raw sheep or goat’s milk',
    category: 'A',
  },
  {
    Code: '01443',
    Description: 'Production of raw wool',
    category: 'A',
  },
  {
    Code: '01450',
    Description: 'Raising, breeding and production of swine/pigs',
    category: 'A',
  },
  {
    Code: '01461',
    Description: 'Raising, breeding and production of chicken, broiler',
    category: 'A',
  },
  {
    Code: '01462',
    Description: 'Raising, breeding and production of ducks',
    category: 'A',
  },
  {
    Code: '01463',
    Description: 'Raising, breeding and production of geese',
    category: 'A',
  },
  {
    Code: '01464',
    Description: 'Raising, breeding and production of quails',
    category: 'A',
  },
  {
    Code: '01465',
    Description: 'Raising and breeding of other poultry n.e.c.',
    category: 'A',
  },
  {
    Code: '01466',
    Description: 'Production of chicken eggs',
    category: 'A',
  },
  {
    Code: '01467',
    Description: 'Production of duck eggs',
    category: 'A',
  },
  {
    Code: '01468',
    Description: 'Production of other poultry eggs n.e.c.',
    category: 'A',
  },
  {
    Code: '01469',
    Description: 'Operation of poultry hatcheries',
    category: 'A',
  },
  {
    Code: '01491',
    Description: 'Raising, breeding and production of semi-domesticated',
    category: 'A',
  },
  {
    Code: '01492',
    Description: 'Production of fur skins, reptile or bird’s skin from ranching operation',
    category: 'A',
  },
  {
    Code: '01493',
    Description: 'Operation of worm farms, land mollusc farms, snail farms',
    category: 'A',
  },
  {
    Code: '01494',
    Description: 'Raising of silk worms and production of silk worm cocoons',
    category: 'A',
  },
  {
    Code: '01495',
    Description: 'Bee keeping and production of honey and beeswax',
    category: 'A',
  },
  {
    Code: '01496',
    Description: 'Raising and breeding of pet animals',
    category: 'A',
  },
  {
    Code: '01497',
    Description: 'Raising and breeding of swiflet',
    category: 'A',
  },
  {
    Code: '01499',
    Description: 'Raising of diverse/other animals n.e.c.',
    category: 'A',
  },
  {
    Code: '01500',
    Description: 'Mixed Farming',
    category: 'A',
  },
  {
    Code: '01610',
    Description: 'Agricultural activities for crops production on a fee or contract basis',
    category: 'A',
  },
  {
    Code: '01620',
    Description: 'Agricultural activities for animal production on a fee or contract basis',
    category: 'A',
  },
  {
    Code: '01631',
    Description: 'Preparation of crops for primary markets',
    category: 'A',
  },
  {
    Code: '01632',
    Description: 'Preparation of tobacco leaves',
    category: 'A',
  },
  {
    Code: '01633',
    Description: 'Preparation of cocoa beans',
    category: 'A',
  },
  {
    Code: '01634',
    Description: 'Sun-drying of fruits and vegetables',
    category: 'A',
  },
  {
    Code: '01640',
    Description: 'Seed processing for propagation',
    category: 'A',
  },
  {
    Code: '01701',
    Description: 'Hunting and trapping on a commercial basis',
    category: 'A',
  },
  {
    Code: '01702',
    Description: 'Taking of animals (dead or alive)',
    category: 'A',
  },
  {
    Code: '02101',
    Description:
      'Planting, replanting, transplanting, thinning and conserving of forests and timber tracts',
    category: 'A',
  },
  {
    Code: '02102',
    Description: 'Growing of coppice, pulpwood and fire wood',
    category: 'A',
  },
  {
    Code: '02103',
    Description: 'Operation of forest tree nurseries',
    category: 'A',
  },
  {
    Code: '02104',
    Description: 'Collection and raising of wildings (peat swamp forest tree species)',
    category: 'A',
  },
  {
    Code: '02105',
    Description: 'Forest plantation',
    category: 'A',
  },
  {
    Code: '02201',
    Description: 'Production of round wood for forest-based manufacturing industries',
    category: 'A',
  },
  {
    Code: '02202',
    Description: 'Production of round wood used in an unprocessed form',
    category: 'A',
  },
  {
    Code: '02203',
    Description: 'Production of charcoal in the forest (using traditional methods)',
    category: 'A',
  },
  {
    Code: '02204',
    Description: 'Rubber wood logging',
    category: 'A',
  },
  {
    Code: '02301',
    Description: 'Collection of rattan, bamboo',
    category: 'A',
  },
  {
    Code: '02302',
    Description: 'Bird’s nest collection',
    category: 'A',
  },
  {
    Code: '02303',
    Description: 'Wild sago palm collection',
    category: 'A',
  },
  {
    Code: '02309',
    Description: 'Gathering of non-wood forest products n.e.c.',
    category: 'A',
  },
  {
    Code: '02401',
    Description:
      'Carrying out part of the forestry and forest plantation operation on a fee or contract basis for forestry service activities',
    category: 'A',
  },
  {
    Code: '02402',
    Description:
      'Carrying out part of the forestry operation on a fee or contract basis for logging service activities',
    category: 'A',
  },
  {
    Code: '03111',
    Description: 'Fishing on a commercial basis in ocean and coastal waters',
    category: 'A',
  },
  {
    Code: '03112',
    Description: 'Collection of marine crustaceans and molluscs',
    category: 'A',
  },
  {
    Code: '03113',
    Description: 'Taking of aquatic animals: sea squirts, tunicates, sea urchins',
    category: 'A',
  },
  {
    Code: '03114',
    Description:
      'Activities of vessels engaged both in fishing and in processing and preserving of fish',
    category: 'A',
  },
  {
    Code: '03115',
    Description:
      'Gathering of other marine organisms and materials (natural pearls, sponges, coral and algae)',
    category: 'A',
  },
  {
    Code: '03119',
    Description: 'Marine fishing n.e.c.',
    category: 'A',
  },
  {
    Code: '03121',
    Description: 'Fishing on a commercial basis in inland waters',
    category: 'A',
  },
  {
    Code: '03122',
    Description: 'Taking of freshwater crustaceans and molluscs',
    category: 'A',
  },
  {
    Code: '03123',
    Description: 'Taking of freshwater aquatic animals',
    category: 'A',
  },
  {
    Code: '03124',
    Description: 'Gathering of freshwater flora and fauna',
    category: 'A',
  },
  {
    Code: '03129',
    Description: 'Freshwater fishing n.e.c.',
    category: 'A',
  },
  {
    Code: '03211',
    Description: 'Fish farming in sea water',
    category: 'A',
  },
  {
    Code: '03212',
    Description:
      'Production of bivalve spat (oyster, mussel), lobster lings, shrimp post-larvae, fish fry and fingerlings',
    category: 'A',
  },
  {
    Code: '03213',
    Description: 'Growing of laver and other edible seaweeds',
    category: 'A',
  },
  {
    Code: '03214',
    Description:
      'Culture of crustaceans, bivalves, other molluscs and other aquatic animals in sea water',
    category: 'A',
  },
  {
    Code: '03215',
    Description: 'Aquaculture activities in brackish water',
    category: 'A',
  },
  {
    Code: '03216',
    Description: 'Aquaculture activities in salt water filled tanks or reservoirs',
    category: 'A',
  },
  {
    Code: '03217',
    Description: 'Operation of  hatcheries (marine)',
    category: 'A',
  },
  {
    Code: '03218',
    Description: 'Operation of marine worm farms for fish feed',
    category: 'A',
  },
  {
    Code: '03219',
    Description: 'Marine aquaculture n.e.c.',
    category: 'A',
  },
  {
    Code: '03221',
    Description: 'Fish farming in freshwater',
    category: 'A',
  },
  {
    Code: '03222',
    Description: 'Shrimp farming in freshwater',
    category: 'A',
  },
  {
    Code: '03223',
    Description:
      'Culture of freshwater crustaceans, bivalves, other molluscs and other aquatic animals',
    category: 'A',
  },
  {
    Code: '03224',
    Description: 'Operation of hatcheries (freshwater)',
    category: 'A',
  },
  {
    Code: '03225',
    Description: 'Farming of frogs',
    category: 'A',
  },
  {
    Code: '03229',
    Description: 'Freshwater aquaculture n.e.c.',
    category: 'A',
  },
  {
    Code: '05100',
    Description: 'Mining of hard coal',
    category: 'B',
  },
  {
    Code: '05200',
    Description: 'Mining of lignite (brown coal)',
    category: 'B',
  },
  {
    Code: '06101',
    Description: 'Extraction of crude petroleum oils',
    category: 'B',
  },
  {
    Code: '06102',
    Description: 'Extraction of bituminous or oil shale and tar sand',
    category: 'B',
  },
  {
    Code: '06103',
    Description: 'Production of crude petroleum from bituminous shale and sand',
    category: 'B',
  },
  {
    Code: '06104',
    Description: 'Processes to obtain crude oils',
    category: 'B',
  },
  {
    Code: '06201',
    Description: 'Production of crude gaseous hydrocarbon (natural gas)',
    category: 'B',
  },
  {
    Code: '06202',
    Description: 'Extraction of condensates',
    category: 'B',
  },
  {
    Code: '06203',
    Description: 'Draining and separation of liquid hydrocarbon fractions',
    category: 'B',
  },
  {
    Code: '06204',
    Description: 'Gas desulphurization',
    category: 'B',
  },
  {
    Code: '06205',
    Description: 'Mining of hydrocarbon liquids, obtain through liquefaction or pyrolysis',
    category: 'B',
  },
  {
    Code: '07101',
    Description: 'Mining of ores valued chiefly for iron content',
    category: 'B',
  },
  {
    Code: '07102',
    Description: 'Beneficiation and agglomeration of iron ores',
    category: 'B',
  },
  {
    Code: '07210',
    Description: 'Mining of uranium and thorium ores',
    category: 'B',
  },
  {
    Code: '07291',
    Description: 'Mining of tin ores',
    category: 'B',
  },
  {
    Code: '07292',
    Description: 'Mining of copper',
    category: 'B',
  },
  {
    Code: '07293',
    Description: 'Mining of bauxite (aluminium)',
    category: 'B',
  },
  {
    Code: '07294',
    Description: 'Mining of ilmenite',
    category: 'B',
  },
  {
    Code: '07295',
    Description: 'Mining of gold',
    category: 'B',
  },
  {
    Code: '07296',
    Description: 'Mining of silver',
    category: 'B',
  },
  {
    Code: '07297',
    Description: 'Mining of platinum',
    category: 'B',
  },
  {
    Code: '07298',
    Description: 'Amang retreatment',
    category: 'B',
  },
  {
    Code: '07299',
    Description: 'Mining of other non-ferrous metal ores n.e.c.',
    category: 'B',
  },
  {
    Code: '08101',
    Description:
      'Quarrying, rough trimming and sawing of monumental and building stone such as marble, granite (dimension stone), sandstone',
    category: 'B',
  },
  {
    Code: '08102',
    Description: 'Quarrying, crushing and breaking of limestone',
    category: 'B',
  },
  {
    Code: '08103',
    Description: 'Mining of gypsum and anhydrite',
    category: 'B',
  },
  {
    Code: '08104',
    Description: 'Mining of chalk and uncalcined dolomite',
    category: 'B',
  },
  {
    Code: '08105',
    Description: 'Extraction and dredging of industrial sand, sand for construction and gravel',
    category: 'B',
  },
  {
    Code: '08106',
    Description: 'Breaking and crushing of stone and gravel',
    category: 'B',
  },
  {
    Code: '08107',
    Description: 'Quarrying of sand',
    category: 'B',
  },
  {
    Code: '08108',
    Description: 'Mining of clays, refractory clays and kaolin',
    category: 'B',
  },
  {
    Code: '08109',
    Description: 'Quarrying, crushing and breaking of granite',
    category: 'B',
  },
  {
    Code: '08911',
    Description: 'Mining of natural phosphates',
    category: 'B',
  },
  {
    Code: '08912',
    Description: 'Mining of natural potassium salts',
    category: 'B',
  },
  {
    Code: '08913',
    Description: 'Mining of native sulphur',
    category: 'B',
  },
  {
    Code: '08914',
    Description: 'Extraction and preparation of pyrites and pyrrhotite, except roasting',
    category: 'B',
  },
  {
    Code: '08915',
    Description: 'Mining of natural barium sulphate and carbonate (barytes and witherite)',
    category: 'B',
  },
  {
    Code: '08916',
    Description: 'Mining of natural borates, natural magnesium sulphates (kieserite)',
    category: 'B',
  },
  {
    Code: '08917',
    Description:
      'Mining of earth colours, fluorspar and other minerals valued chiefly as a source of chemicals',
    category: 'B',
  },
  {
    Code: '08918',
    Description: 'Guano mining',
    category: 'B',
  },
  {
    Code: '08921',
    Description: 'Peat digging',
    category: 'B',
  },
  {
    Code: '08922',
    Description: 'Peat agglomeration',
    category: 'B',
  },
  {
    Code: '08923',
    Description: 'Preparation of peat to improve quality or facilitate transport or storage',
    category: 'B',
  },
  {
    Code: '08931',
    Description: 'Extraction of salt from underground',
    category: 'B',
  },
  {
    Code: '08932',
    Description: 'Salt production by evaporation of sea water or other saline waters',
    category: 'B',
  },
  {
    Code: '08933',
    Description: 'Crushing, purification and refining of salt by the producer',
    category: 'B',
  },
  {
    Code: '08991',
    Description: 'Mining and quarrying of abrasive materials',
    category: 'B',
  },
  {
    Code: '08992',
    Description: 'Mining and quarrying of asbestos',
    category: 'B',
  },
  {
    Code: '08993',
    Description: 'Mining and quarrying of siliceous fossil meals',
    category: 'B',
  },
  {
    Code: '08994',
    Description: 'Mining and quarrying of natural graphite',
    category: 'B',
  },
  {
    Code: '08995',
    Description: 'Mining and quarrying of steatite (talc)',
    category: 'B',
  },
  {
    Code: '08996',
    Description: 'Mining and quarrying of gemstones',
    category: 'B',
  },
  {
    Code: '08999',
    Description: 'Other mining and quarrying n.e.c.',
    category: 'B',
  },
  {
    Code: '09101',
    Description: 'Oil and gas extraction service activities provided on a fee or contract basis',
    category: 'B',
  },
  {
    Code: '09102',
    Description: 'Oil and gas field fire fighting services',
    category: 'B',
  },
  {
    Code: '09900',
    Description: 'Support activities for other mining and quarrying',
    category: 'B',
  },
  {
    Code: '10101',
    Description: 'Processing and preserving of meat and production of meat products',
    category: 'C',
  },
  {
    Code: '10102',
    Description: 'Processing and preserving of poultry and poultry products',
    category: 'C',
  },
  {
    Code: '10103',
    Description: 'Production of hides and skins originating from slaughterhouses',
    category: 'C',
  },
  {
    Code: '10104',
    Description: 'Operation of slaughterhouses engaged in killing, houses dressing or packing meat',
    category: 'C',
  },
  {
    Code: '10109',
    Description: 'Processing and preserving of meat n.e.c.',
    category: 'C',
  },
  {
    Code: '10201',
    Description: 'Canning of fish, crustaceans and mollusks',
    category: 'C',
  },
  {
    Code: '10202',
    Description: 'Processing, curing and preserving of fish, crustacean and molluscs',
    category: 'C',
  },
  {
    Code: '10203',
    Description: 'Production of fish meals for human consumption or animal feed',
    category: 'C',
  },
  {
    Code: '10204',
    Description: 'Production of keropok including keropok lekor',
    category: 'C',
  },
  {
    Code: '10205',
    Description: 'Processing of seaweed',
    category: 'C',
  },
  {
    Code: '10301',
    Description: 'Manufacture of fruits and vegetable food products',
    category: 'C',
  },
  {
    Code: '10302',
    Description: 'Manufacture of fruit and vegetable juices',
    category: 'C',
  },
  {
    Code: '10303',
    Description: 'Pineapple canning',
    category: 'C',
  },
  {
    Code: '10304',
    Description: 'Manufacture of jams, marmalades and table jellies',
    category: 'C',
  },
  {
    Code: '10305',
    Description: 'Manufacture of nuts and nut products',
    category: 'C',
  },
  {
    Code: '10306',
    Description: 'Manufacture of bean curd products',
    category: 'C',
  },
  {
    Code: '10401',
    Description: 'Manufacture of crude palm oil',
    category: 'C',
  },
  {
    Code: '10402',
    Description: 'Manufacture of refined palm oil',
    category: 'C',
  },
  {
    Code: '10403',
    Description: 'Manufacture of palm kernel oil',
    category: 'C',
  },
  {
    Code: '10404',
    Description: 'Manufacture of crude and refined vegetable oil',
    category: 'C',
  },
  {
    Code: '10405',
    Description: 'Manufacture of coconut oil',
    category: 'C',
  },
  {
    Code: '10406',
    Description: 'Manufacture of compound cooking fats',
    category: 'C',
  },
  {
    Code: '10407',
    Description: 'Manufacture of animal oils and fats',
    category: 'C',
  },
  {
    Code: '10501',
    Description: 'Manufacture of ice cream and other edible ice such as sorbet',
    category: 'C',
  },
  {
    Code: '10502',
    Description: 'Manufacture of condensed, powdered and evaporated milk',
    category: 'C',
  },
  {
    Code: '10509',
    Description: 'Manufacture of other dairy products n.e.c.',
    category: 'C',
  },
  {
    Code: '10611',
    Description: 'Rice milling',
    category: 'C',
  },
  {
    Code: '10612',
    Description: 'Provision of milling services',
    category: 'C',
  },
  {
    Code: '10613',
    Description: 'Flour milling',
    category: 'C',
  },
  {
    Code: '10619',
    Description: 'Manufacture of grain mill products n.e.c.',
    category: 'C',
  },
  {
    Code: '10621',
    Description: 'Manufacture of starches and starch products',
    category: 'C',
  },
  {
    Code: '10622',
    Description: 'Manufacture of glucose, glucose syrup, maltose, inulin',
    category: 'C',
  },
  {
    Code: '10623',
    Description: 'Manufacture of sago and tapioca flour/products',
    category: 'C',
  },
  {
    Code: '10711',
    Description: 'Manufacture of biscuits and cookies',
    category: 'C',
  },
  {
    Code: '10712',
    Description: 'Manufacture of bread, cakes and other bakery products',
    category: 'C',
  },
  {
    Code: '10713',
    Description: 'Manufacture of snack products',
    category: 'C',
  },
  {
    Code: '10714',
    Description: 'Manufacture of frozen bakery products',
    category: 'C',
  },
  {
    Code: '10721',
    Description: 'Manufacture of sugar',
    category: 'C',
  },
  {
    Code: '10722',
    Description: 'Manufacture of sugar products',
    category: 'C',
  },
  {
    Code: '10731',
    Description: 'Manufacture of cocoa products',
    category: 'C',
  },
  {
    Code: '10732',
    Description: 'Manufacture of chocolate and chocolate products',
    category: 'C',
  },
  {
    Code: '10733',
    Description: 'Manufacture of sugar confectionery',
    category: 'C',
  },
  {
    Code: '10741',
    Description: 'Manufacture of meehoon, noodles and other related products',
    category: 'C',
  },
  {
    Code: '10742',
    Description: 'Manufacture of pastas',
    category: 'C',
  },
  {
    Code: '10750',
    Description: 'Manufacture of prepared meals and dishes',
    category: 'C',
  },
  {
    Code: '10791',
    Description: 'Manufacture of coffee',
    category: 'C',
  },
  {
    Code: '10792',
    Description: 'Manufacture of tea',
    category: 'C',
  },
  {
    Code: '10793',
    Description: 'Manufacture of sauces and condiments',
    category: 'C',
  },
  {
    Code: '10794',
    Description: 'Manufacture of spices and curry powder',
    category: 'C',
  },
  {
    Code: '10795',
    Description: 'Manufacture of egg products',
    category: 'C',
  },
  {
    Code: '10799',
    Description: 'Manufacture of other food products n.e.c.',
    category: 'C',
  },
  {
    Code: '10800',
    Description: 'Manufacture of prepared animal feeds',
    category: 'C',
  },
  {
    Code: '11010',
    Description: 'Distilling, rectifying and blending of spirits',
    category: 'C',
  },
  {
    Code: '11020',
    Description: 'Manufacture of wines',
    category: 'C',
  },
  {
    Code: '11030',
    Description: 'Manufacture of malt liquors and malt',
    category: 'C',
  },
  {
    Code: '11041',
    Description: 'Manufacture of soft drinks',
    category: 'C',
  },
  {
    Code: '11042',
    Description: 'Production of natural mineral water and other bottled water',
    category: 'C',
  },
  {
    Code: '12000',
    Description: 'MANUFACTURE OF TOBACCO PRODUCTS',
    category: 'C',
  },
  {
    Code: '13110',
    Description: 'Preparation and spinning of textile fibres',
    category: 'C',
  },
  {
    Code: '13120',
    Description: 'Weaving of textiles',
    category: 'C',
  },
  {
    Code: '13131',
    Description: 'Batik making',
    category: 'C',
  },
  {
    Code: '13132',
    Description: 'Dyeing, bleaching, printing and finishing of yarns and fabrics',
    category: 'C',
  },
  {
    Code: '13139',
    Description: 'Other finishing textiles',
    category: 'C',
  },
  {
    Code: '13910',
    Description: 'Manufacture of knitted and crocheted fabrics',
    category: 'C',
  },
  {
    Code: '13921',
    Description:
      'Manufacture of made-up articles of any textile materials, including of knitted or crocheted fabrics',
    category: 'C',
  },
  {
    Code: '13922',
    Description: 'Manufacture of made-up furnishing articles',
    category: 'C',
  },
  {
    Code: '13930',
    Description: 'Manufacture of carpets and rugs',
    category: 'C',
  },
  {
    Code: '13940',
    Description: 'Manufacture of cordage, rope, twine and netting',
    category: 'C',
  },
  {
    Code: '13990',
    Description: 'Manufacture of other textiles n.e.c.',
    category: 'C',
  },
  {
    Code: '14101',
    Description: 'Manufacture of specific wearing apparel',
    category: 'C',
  },
  {
    Code: '14102',
    Description: 'Manufacture of clothings',
    category: 'C',
  },
  {
    Code: '14103',
    Description: 'Custom tailoring',
    category: 'C',
  },
  {
    Code: '14109',
    Description: 'Manufacture of other clothing accessories',
    category: 'C',
  },
  {
    Code: '14200',
    Description: 'Manufacture of articles made of fur skins',
    category: 'C',
  },
  {
    Code: '14300',
    Description: 'Manufacture of knitted and crocheted apparel',
    category: 'C',
  },
  {
    Code: '15110',
    Description: 'Tanning and dressing of leather; dressing and dyeing of fur',
    category: 'C',
  },
  {
    Code: '15120',
    Description: 'Manufacture of luggage, handbags and the like, saddlery and harness',
    category: 'C',
  },
  {
    Code: '15201',
    Description: 'Manufacture of leather footwear',
    category: 'C',
  },
  {
    Code: '15202',
    Description: 'Manufacture of plastic footwear',
    category: 'C',
  },
  {
    Code: '15203',
    Description: 'Manufacture of rubber footwear',
    category: 'C',
  },
  {
    Code: '15209',
    Description: 'Manufacture of other footwear n.e.c.',
    category: 'C',
  },
  {
    Code: '16211',
    Description: 'Manufacture of veneer sheets and plywood',
    category: 'C',
  },
  {
    Code: '16221',
    Description: "Manufacture of builders' carpentry",
    category: 'C',
  },
  {
    Code: '16222',
    Description: 'Manufacture of joinery wood products',
    category: 'C',
  },
  {
    Code: '16230',
    Description: 'Manufacture of wooden containers',
    category: 'C',
  },
  {
    Code: '16291',
    Description: 'Manufacture of wood charcoal',
    category: 'C',
  },
  {
    Code: '16292',
    Description:
      'Manufacture of other products of wood, cane, articles of cork, straw and plaiting materials',
    category: 'C',
  },
  {
    Code: '17010',
    Description: 'Manufacture of pulp, paper and paperboard',
    category: 'C',
  },
  {
    Code: '17020',
    Description:
      'Manufacture of corrugated paper and paperboard and of containers of paper and paperboard',
    category: 'C',
  },
  {
    Code: '17091',
    Description: 'Manufacture of envelopes and letter-card',
    category: 'C',
  },
  {
    Code: '17092',
    Description: 'Manufacture of household and personal hygiene paper',
    category: 'C',
  },
  {
    Code: '17093',
    Description:
      'Manufacture of gummed or adhesive paper in strips or rolls and labels and wall paper',
    category: 'C',
  },
  {
    Code: '17094',
    Description: 'Manufacture of effigies, funeral paper goods, joss paper',
    category: 'C',
  },
  {
    Code: '17099',
    Description: 'Manufacture of other articles of paper and paperboard n.e.c.',
    category: 'C',
  },
  {
    Code: '18110',
    Description: 'Printing',
    category: 'C',
  },
  {
    Code: '18120',
    Description: 'Service activities related to printing',
    category: 'C',
  },
  {
    Code: '18200',
    Description: 'Reproduction of recorded media',
    category: 'C',
  },
  {
    Code: '19100',
    Description: 'Manufacture of coke oven products',
    category: 'C',
  },
  {
    Code: '19201',
    Description: 'Manufacture of refined petroleum products',
    category: 'C',
  },
  {
    Code: '19202',
    Description: 'Manufacture of bio-diesel products',
    category: 'C',
  },
  {
    Code: '20111',
    Description: 'Manufacture of liquefied or compressed inorganic industrial or medical gases',
    category: 'C',
  },
  {
    Code: '20112',
    Description: 'Manufacture of basic organic chemicals',
    category: 'C',
  },
  {
    Code: '20113',
    Description: 'Manufacture of inorganic compounds',
    category: 'C',
  },
  {
    Code: '20119',
    Description: 'Manufacture of other basic chemicals n.e.c.',
    category: 'C',
  },
  {
    Code: '20121',
    Description: 'Manufacture of fertilizers',
    category: 'C',
  },
  {
    Code: '20129',
    Description: 'Manufacture of associated nitrogen products',
    category: 'C',
  },
  {
    Code: '20131',
    Description: 'Manufacture of plastic in primary forms',
    category: 'C',
  },
  {
    Code: '20132',
    Description: 'Manufacture of synthetic rubber in primary forms: synthetic rubber, factice',
    category: 'C',
  },
  {
    Code: '20133',
    Description:
      'Manufacture of mixtures of synthetic rubber and natural rubber or rubber - like gums',
    category: 'C',
  },
  {
    Code: '20210',
    Description: 'Manufacture of pesticides and other agrochemical products',
    category: 'C',
  },
  {
    Code: '20221',
    Description: 'Manufacture of paints, varnishes and similar coatings ink and mastics',
    category: 'C',
  },
  {
    Code: '20222',
    Description: 'Manufacture of printing ink',
    category: 'C',
  },
  {
    Code: '20231',
    Description: 'Manufacture of soap and detergents, cleaning and polishing preparations',
    category: 'C',
  },
  {
    Code: '20232',
    Description: 'Manufacture of perfumes and toilet preparations',
    category: 'C',
  },
  {
    Code: '20291',
    Description:
      'Manufacture of photographic plates, films, sensitized paper and other sensitized unexposed materials',
    category: 'C',
  },
  {
    Code: '20292',
    Description: 'Manufacture of writing and drawing ink',
    category: 'C',
  },
  {
    Code: '20299',
    Description: 'Manufacture of other chemical products n.e.c.',
    category: 'C',
  },
  {
    Code: '20300',
    Description: 'Manufacture of man-made fibres',
    category: 'C',
  },
  {
    Code: '21001',
    Description:
      'Manufacture of medicinal active substances to be used for their pharmacological properties in the manufacture of medicaments',
    category: 'C',
  },
  {
    Code: '21002',
    Description: 'Processing of blood',
    category: 'C',
  },
  {
    Code: '21003',
    Description: 'Manufacture of medicaments',
    category: 'C',
  },
  {
    Code: '21004',
    Description: 'Manufacture of chemical contraceptive products',
    category: 'C',
  },
  {
    Code: '21005',
    Description: 'Manufacture of medical diagnostic preparation',
    category: 'C',
  },
  {
    Code: '21006',
    Description: 'Manufacture of radioactive in-vivo diagnostic substances',
    category: 'C',
  },
  {
    Code: '21007',
    Description: 'Manufacture of biotech pharmaceuticals',
    category: 'C',
  },
  {
    Code: '21009',
    Description:
      'Manufacture of other pharmaceuticals, medicinal chemical and botanical products n.e.c.',
    category: 'C',
  },
  {
    Code: '22111',
    Description: 'Manufacture of rubber tyres for vehicles',
    category: 'C',
  },
  {
    Code: '22112',
    Description: 'Manufacture of interchangeable tyre treads and retreading rubber tyres',
    category: 'C',
  },
  {
    Code: '22191',
    Description:
      'Manufacture of other products of natural or synthetic rubber, unvulcanized, vulcanized or hardened',
    category: 'C',
  },
  {
    Code: '22192',
    Description: 'Manufacture of rubber gloves',
    category: 'C',
  },
  {
    Code: '22193',
    Description: 'Rubber remilling and latex processing',
    category: 'C',
  },
  {
    Code: '22199',
    Description: 'Manufacture of other rubber products n.e.c',
    category: 'C',
  },
  {
    Code: '22201',
    Description: 'Manufacture of semi-manufactures of plastic products',
    category: 'C',
  },
  {
    Code: '22202',
    Description: 'Manufacture of finished plastic products',
    category: 'C',
  },
  {
    Code: '22203',
    Description: 'Manufacture of plastic articles for the packing of goods',
    category: 'C',
  },
  {
    Code: '22204',
    Description: "Manufacture of builders' plastics ware",
    category: 'C',
  },
  {
    Code: '22205',
    Description: 'Manufacture of plastic tableware, kitchenware and toilet articles',
    category: 'C',
  },
  {
    Code: '22209',
    Description: 'Manufacture of diverse plastic products n.e.c.',
    category: 'C',
  },
  {
    Code: '23101',
    Description: 'Manufacture of flat glass, including wired, coloured or tinted flat glass',
    category: 'C',
  },
  {
    Code: '23102',
    Description: 'Manufacture of laboratory, hygienic or pharmaceutical glassware',
    category: 'C',
  },
  {
    Code: '23109',
    Description: 'Manufacture of other glass products n.e.c.',
    category: 'C',
  },
  // {
  //   Code: '',
  //   Description: 'Manufacture of non-metallic mineral products n.e.c.',
  //   category: 'C',
  // },
  {
    Code: '23911',
    Description: 'Manufacture of refractory mortars and concretes',
    category: 'C',
  },
  {
    Code: '23912',
    Description: 'Manufacture of refractory ceramic goods',
    category: 'C',
  },
  {
    Code: '23921',
    Description: 'Manufacture of non-refractory ceramic',
    category: 'C',
  },
  {
    Code: '23929',
    Description: 'Manufacture of other clay building materials',
    category: 'C',
  },
  {
    Code: '23930',
    Description: 'Manufacture of other porcelain and ceramic products',
    category: 'C',
  },
  {
    Code: '23941',
    Description: 'Manufacture of hydraulic cement',
    category: 'C',
  },
  {
    Code: '23942',
    Description: 'Manufacture of lime and plaster',
    category: 'C',
  },
  {
    Code: '23951',
    Description: 'Manufacture of ready-mix and dry-mix concrete and mortars',
    category: 'C',
  },
  {
    Code: '23952',
    Description:
      'Manufacture of precast concrete, cement or artificial stone articles for use in construction',
    category: 'C',
  },
  {
    Code: '23953',
    Description:
      'Manufacture of prefabricated structural components for building or civil engineering of cement, concrete or artificial stone',
    category: 'C',
  },
  {
    Code: '23959',
    Description: 'Manufacture of other articles of concrete, cement and plaster n.e.c.',
    category: 'C',
  },
  {
    Code: '23960',
    Description: 'Cutting, shaping and finishing of stone',
    category: 'C',
  },
  {
    Code: '23990',
    Description: 'Manufacture of other non-metallic mineral products n.e.c.',
    category: 'C',
  },
  {
    Code: '24101',
    Description: 'Production of pig iron and spiegeleisen in pigs, blocks or other primary forms',
    category: 'C',
  },
  {
    Code: '24102',
    Description: 'Production of bars and rods of stainless steel or other alloy steel',
    category: 'C',
  },
  {
    Code: '24103',
    Description:
      'Manufacture of seamless tubes, by hot rolling, hot extrusion or hot drawing, or by cold drawing or cold rolling',
    category: 'C',
  },
  {
    Code: '24104',
    Description: 'Manufacture of steel tube fittings',
    category: 'C',
  },
  {
    Code: '24109',
    Description: 'Manufacture of other basic iron and steel products n.e.c.',
    category: 'C',
  },
  {
    Code: '24201',
    Description: 'Tin smelting',
    category: 'C',
  },
  {
    Code: '24202',
    Description: 'Production of aluminium from alumina',
    category: 'C',
  },
  {
    Code: '24209',
    Description: 'Manufacture of other basic precious and other non-ferrous metals n.e.c.',
    category: 'C',
  },
  {
    Code: '24311',
    Description: 'Casting of iron',
    category: 'C',
  },
  {
    Code: '24312',
    Description: 'Casting of steel',
    category: 'C',
  },
  {
    Code: '24320',
    Description: 'Casting of non-ferrous metals',
    category: 'C',
  },
  {
    Code: '25111',
    Description: 'Manufacture of industrial frameworks in metal',
    category: 'C',
  },
  {
    Code: '25112',
    Description: 'Manufacture of prefabricated buildings mainly of metal',
    category: 'C',
  },
  {
    Code: '25113',
    Description: 'Manufacture of metal doors, windows and their frames, shutters and gates',
    category: 'C',
  },
  {
    Code: '25119',
    Description: 'Manufacture of other structural metal products',
    category: 'C',
  },
  {
    Code: '25120',
    Description: 'Manufacture of tanks, reservoirs and containers of metal',
    category: 'C',
  },
  {
    Code: '25130',
    Description: 'Manufacture of steam generators, except central heating hot water boilers',
    category: 'C',
  },
  {
    Code: '25200',
    Description: 'Manufacture of weapons and ammunition',
    category: 'C',
  },
  {
    Code: '25910',
    Description: 'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
    category: 'C',
  },
  {
    Code: '25920',
    Description: 'Treatment and coating of metals; machining',
    category: 'C',
  },
  {
    Code: '25930',
    Description: 'Manufacture of cutlery, hand tools and general hardware',
    category: 'C',
  },
  {
    Code: '25991',
    Description: 'Manufacture of tins and cans for food products, collapsible tubes and boxes',
    category: 'C',
  },
  {
    Code: '25992',
    Description: 'Manufacture of metal cable, plaited bands and similar articles',
    category: 'C',
  },
  {
    Code: '25993',
    Description: 'Manufacture of bolts, screws, nuts and similar threaded products',
    category: 'C',
  },
  {
    Code: '25994',
    Description: 'Manufacture of metal household articles',
    category: 'C',
  },
  {
    Code: '25999',
    Description: 'Manufacture of any other fabricated metal products n.e.c.',
    category: 'C',
  },
  {
    Code: '26101',
    Description: 'Manufacture of diodes, transistors and similar semiconductor devices',
    category: 'C',
  },
  {
    Code: '26102',
    Description: 'Manufacture electronic integrated circuits micro assemblies',
    category: 'C',
  },
  {
    Code: '26103',
    Description: 'Manufacture of electrical capacitors and resistors',
    category: 'C',
  },
  {
    Code: '26104',
    Description: 'Manufacture of printed circuit boards',
    category: 'C',
  },
  {
    Code: '26105',
    Description: 'Manufacture of display components',
    category: 'C',
  },
  {
    Code: '26109',
    Description: 'Manufacture of other components for electronic applications',
    category: 'C',
  },
  {
    Code: '26201',
    Description: 'Manufacture of computers',
    category: 'C',
  },
  {
    Code: '26202',
    Description: 'Manufacture of peripheral equipment',
    category: 'C',
  },
  {
    Code: '26300',
    Description: 'Manufacture of communication equipment',
    category: 'C',
  },
  {
    Code: '26400',
    Description: 'Manufacture of consumer electronics',
    category: 'C',
  },
  {
    Code: '26511',
    Description: 'Manufacture of measuring, testing, navigating and control equipment',
    category: 'C',
  },
  {
    Code: '26512',
    Description: 'Manufacture of industrial process control equipment',
    category: 'C',
  },
  {
    Code: '26520',
    Description: 'Manufacture of watches and clocks and parts',
    category: 'C',
  },
  {
    Code: '26600',
    Description: 'Manufacture of irradiation, electro medical and electrotherapeutic equipment',
    category: 'C',
  },
  {
    Code: '26701',
    Description: 'Manufacture of optical instruments and equipment',
    category: 'C',
  },
  {
    Code: '26702',
    Description: 'Manufacture of photographic equipment',
    category: 'C',
  },
  {
    Code: '26800',
    Description: 'Manufacture of magnetic and optical recording media',
    category: 'C',
  },
  {
    Code: '27101',
    Description: 'Manufacture of electric motors, generators and transformers',
    category: 'C',
  },
  {
    Code: '27102',
    Description: 'Manufacture of electricity distribution and control apparatus',
    category: 'C',
  },
  {
    Code: '27200',
    Description: 'Manufacture of batteries and accumulators',
    category: 'C',
  },
  {
    Code: '27310',
    Description: 'Manufacture of fibre optic cables',
    category: 'C',
  },
  {
    Code: '27320',
    Description: 'Manufacture of other electronic and electric wires and cables',
    category: 'C',
  },
  {
    Code: '27330',
    Description:
      'Manufacture of current-carrying and non current-carrying wiring devices for electrical circuits regardless of material',
    category: 'C',
  },
  {
    Code: '27400',
    Description: 'Manufacture of electric lighting equipment',
    category: 'C',
  },
  {
    Code: '27500',
    Description: 'Manufacture of domestic appliances',
    category: 'C',
  },
  {
    Code: '27900',
    Description:
      'Manufacture of miscellaneous electrical equipment other than motors, generators and transformers, batteries and accumulators, wires and wiring devices, lighting equipment or domestic appliances',
  },
  {
    Code: '28110',
    Description: 'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
    category: 'C',
  },
  {
    Code: '28120',
    Description: 'Manufacture of fluid power equipment',
    category: 'C',
  },
  {
    Code: '28130',
    Description: 'Manufacture of other pumps, compressors, taps and valves',
    category: 'C',
  },
  {
    Code: '28140',
    Description: 'Manufacture of bearings, gears, gearing and driving elements',
    category: 'C',
  },
  {
    Code: '28150',
    Description: 'Manufacture of ovens, furnaces and furnace burners',
    category: 'C',
  },
  {
    Code: '28160',
    Description: 'Manufacture of lifting and handling equipment',
    category: 'C',
  },
  {
    Code: '28170',
    Description:
      'Manufacture of office machinery and equipment (except computers and peripheral equipment)',
    category: 'C',
  },
  {
    Code: '28180',
    Description:
      'Manufacture of power-driven hand tools with self-contained electric or non-electric motor or pneumatic drives',
    category: 'C',
  },
  {
    Code: '28191',
    Description: 'Manufacture of refrigerating or freezing industrial equipment',
    category: 'C',
  },
  {
    Code: '28192',
    Description: 'Manufacture of air-conditioning machines, including for motor vehicles',
    category: 'C',
  },
  {
    Code: '28199',
    Description: 'Manufacture of other general-purpose machinery n.e.c.',
    category: 'C',
  },
  {
    Code: '28210',
    Description: 'Manufacture of agricultural and forestry machinery',
    category: 'C',
  },
  {
    Code: '28220',
    Description: 'Manufacture of metal-forming machinery and machine tools',
    category: 'C',
  },
  {
    Code: '28230',
    Description: 'Manufacture of machinery for metallurgy',
    category: 'C',
  },
  {
    Code: '28240',
    Description: 'Manufacture of machinery for mining, quarrying and construction',
    category: 'C',
  },
  {
    Code: '28250',
    Description: 'Manufacture of machinery for food, beverage and tobacco processing',
    category: 'C',
  },
  {
    Code: '28260',
    Description: 'Manufacture of machinery for textile, apparel and leather production',
    category: 'C',
  },
  {
    Code: '28290',
    Description: 'Manufacture of other special-purpose machinery n.e.c.',
    category: 'C',
  },
  {
    Code: '29101',
    Description: 'Manufacture of passenger cars',
    category: 'C',
  },
  {
    Code: '29102',
    Description: 'Manufacture of commercial vehicles',
    category: 'C',
  },
  {
    Code: '29200',
    Description:
      'Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi- trailers',
    category: 'C',
  },
  {
    Code: '29300',
    Description: 'Manufacture of parts and accessories for motor vehicles',
    category: 'C',
  },
  {
    Code: '30110',
    Description: 'Building of ships and floating structures',
    category: 'C',
  },
  {
    Code: '30120',
    Description: 'Building of pleasure and sporting boats',
    category: 'C',
  },
  {
    Code: '30200',
    Description: 'Manufacture of railway locomotives and rolling stock',
    category: 'C',
  },
  {
    Code: '30300',
    Description: 'Manufacture of air and spacecraft and related machinery',
    category: 'C',
  },
  {
    Code: '30400',
    Description: 'Manufacture of military fighting vehicles',
    category: 'C',
  },
  {
    Code: '30910',
    Description: 'Manufacture of motorcycles',
    category: 'C',
  },
  {
    Code: '30920',
    Description: 'Manufacture of bicycles and invalid carriages',
    category: 'C',
  },
  {
    Code: '30990',
    Description: 'Manufacture of other transport equipments n.e.c.',
    category: 'C',
  },
  {
    Code: '31001',
    Description: 'Manufacture of wooden and cane furniture',
    category: 'C',
  },
  {
    Code: '31002',
    Description: 'Manufacture of metal furniture',
    category: 'C',
  },
  {
    Code: '31003',
    Description: 'Manufacture of mattress',
    category: 'C',
  },
  {
    Code: '31009',
    Description: 'Manufacture of other furniture, except of stone, concrete or ceramic',
    category: 'C',
  },
  {
    Code: '32110',
    Description: 'Manufacture of jewellery and related articles',
    category: 'C',
  },
  {
    Code: '32120',
    Description: 'Manufacture of imitation jewellery and related articles',
    category: 'C',
  },
  {
    Code: '32200',
    Description: 'Manufacture of musical instruments',
    category: 'C',
  },
  {
    Code: '32300',
    Description: 'Manufacture of sports goods',
    category: 'C',
  },
  {
    Code: '32400',
    Description: 'Manufacture of games and toys',
    category: 'C',
  },
  {
    Code: '32500',
    Description: 'Manufacture of medical and dental instrument and supplies',
    category: 'C',
  },
  {
    Code: '32901',
    Description: 'Manufacture of stationery',
    category: 'C',
  },
  {
    Code: '32909',
    Description: 'Other manufacturing n.e.c.',
    category: 'C',
  },
  {
    Code: '33110',
    Description: 'Repair of fabricated metal products',
    category: 'C',
  },
  {
    Code: '33120',
    Description: 'Repair and maintenance of industrial machinery and equipment',
    category: 'C',
  },
  {
    Code: '33131',
    Description:
      'Repair and maintenance of the measuring, testing, navigating and control equipment',
    category: 'C',
  },
  {
    Code: '33132',
    Description:
      'Repair and maintenance of irradiation, electro medical and electrotherapeutic equipment',
    category: 'C',
  },
  {
    Code: '33133',
    Description: 'Repair of optical instruments and photographic equipment',
    category: 'C',
  },
  {
    Code: '33140',
    Description: 'Repair and maintenance of electrical equipment except domestic appliances',
    category: 'C',
  },
  {
    Code: '33150',
    Description: 'Repair and maintenance of transport equipment except motorcycles and bicycles',
    category: 'C',
  },
  {
    Code: '33190',
    Description: 'Repair and maintenance of other equipment n.e.c.',
    category: 'C',
  },
  {
    Code: '33200',
    Description: 'Installation of industrial machinery and equipment',
    category: 'C',
  },
  {
    Code: '35101',
    Description: 'Operation of generation facilities that produce electric energy',
    category: 'D',
  },
  {
    Code: '35102',
    Description: 'Operation of transmission, distribution and sales of electricity',
    category: 'D',
  },
  {
    Code: '35201',
    Description:
      'Manufacture of gaseous fuels with a specified calorific value, by purification, blending and other processes from gases of various types including natural gas',
    category: 'D',
  },
  {
    Code: '35202',
    Description:
      'Transportation, distribution and supply of gaseous fuels of all kinds through a system of mains',
    category: 'D',
  },
  {
    Code: '35203',
    Description: 'Sale of gas to the user through mains',
    category: 'D',
  },
  {
    Code: '35301',
    Description:
      'Production, collection and distribution of steam and hot water for heating, power and other purposes',
    category: 'D',
  },
  {
    Code: '35302',
    Description: 'Production and distribution of cooled air, chilled water for cooling purposes',
    category: 'D',
  },
  {
    Code: '35303',
    Description: 'Production of ice, including ice for food and non-food (e.g. cooling) purposes',
    category: 'D',
  },
  {
    Code: '36001',
    Description: 'Purification and distribution of water for water supply purposes',
    category: 'E',
  },
  {
    Code: '36002',
    Description:
      'Desalting of sea or ground water to produce water as the principal product of interest',
    category: 'E',
  },
  {
    Code: '37000',
    Description: 'Sewerage and similar activities',
    category: 'E',
  },
  {
    Code: '38111',
    Description: 'Collection of non-hazardous solid waste (i.e. garbage) within a local area',
    category: 'E',
  },
  {
    Code: '38112',
    Description: 'Collection of recyclable materials',
    category: 'E',
  },
  {
    Code: '38113',
    Description: 'Collection of refuse in litter-bins in public places',
    category: 'E',
  },
  {
    Code: '38114',
    Description: 'Collection of construction and demolition waste',
    category: 'E',
  },
  {
    Code: '38115',
    Description: 'Operation of waste transfer stations for non-hazardous waste',
    category: 'E',
  },
  {
    Code: '38121',
    Description: 'Collection of hazardous waste',
    category: 'E',
  },
  {
    Code: '38122',
    Description: 'Operation of waste transfer stations for hazardous waste',
    category: 'E',
  },
  {
    Code: '38210',
    Description: 'Treatment and disposal of non-hazardous waste',
    category: 'E',
  },
  {
    Code: '38220',
    Description: 'Treatment and disposal of hazardous waste',
    category: 'E',
  },
  {
    Code: '38301',
    Description: 'Mechanical crushing of metal waste',
    category: 'E',
  },
  {
    Code: '38302',
    Description:
      'Dismantling of automobiles, computers, televisions and other equipment for material recover',
    category: 'E',
  },
  {
    Code: '38303',
    Description: 'Reclaiming of rubber such as used tires to produce secondary raw material',
    category: 'E',
  },
  {
    Code: '38304',
    Description: 'Reuse of rubber products',
    category: 'E',
  },
  {
    Code: '38309',
    Description: 'Materials recovery n.e.c.',
    category: 'E',
  },
  {
    Code: '39000',
    Description: 'Remediation activities and other waste management services',
    category: 'E',
  },
  {
    Code: '41001',
    Description: 'Residential buildings',
    category: 'F',
  },
  {
    Code: '41002',
    Description: 'Non-residential buildings',
    category: 'F',
  },
  {
    Code: '41003',
    Description: 'Assembly and erection of prefabricated constructions on the site',
    category: 'F',
  },
  {
    Code: '41009',
    Description: 'Construction of buildings n.e.c.',
    category: 'F',
  },
  {
    Code: '42101',
    Description: 'Construction of motorways, streets, roads, other vehicular and pedestrian ways',
    category: 'F',
  },
  {
    Code: '42102',
    Description: 'Surface work on streets, roads, highways, bridges or tunnels',
    category: 'F',
  },
  {
    Code: '42103',
    Description: 'Construction of bridges, including those for elevated highways',
    category: 'F',
  },
  {
    Code: '42104',
    Description: 'Construction of tunnels',
    category: 'F',
  },
  {
    Code: '42105',
    Description: 'Construction of railways and subways',
    category: 'F',
  },
  {
    Code: '42106',
    Description: 'Construction of airfield/airports runways',
    category: 'F',
  },
  {
    Code: '42109',
    Description: 'Construction of roads and railways n.e.c.',
    category: 'F',
  },
  {
    Code: '42201',
    Description: 'Long-distance pipelines, communication and power lines',
    category: 'F',
  },
  {
    Code: '42202',
    Description: 'Urban pipelines, urban communication and power lines; ancillary urban works',
    category: 'F',
  },
  {
    Code: '42203',
    Description: 'Water main and line construction',
    category: 'F',
  },
  {
    Code: '42204',
    Description: 'Reservoirs',
    category: 'F',
  },
  {
    Code: '42205',
    Description: 'Construction of irrigation systems (canals)',
    category: 'F',
  },
  {
    Code: '42206',
    Description: 'Construction of sewer systems (including repair) and sewage disposal plants',
    category: 'F',
  },
  {
    Code: '42207',
    Description: 'Construction of power plants',
    category: 'F',
  },
  {
    Code: '42209',
    Description: 'Construction of utility projects n.e.c.',
    category: 'F',
  },
  {
    Code: '42901',
    Description: 'Construction of refineries',
    category: 'F',
  },
  {
    Code: '42902',
    Description:
      'Construction of waterways, harbour and river works, pleasure ports (marinas), locks',
    category: 'F',
  },
  {
    Code: '42903',
    Description: 'Construction of dams and dykes',
    category: 'F',
  },
  {
    Code: '42904',
    Description: 'Dredging of waterways',
    category: 'F',
  },
  {
    Code: '42905',
    Description: 'Outdoor sports facilities',
    category: 'F',
  },
  {
    Code: '42906',
    Description: 'Land subdivision with land improvement',
    category: 'F',
  },
  {
    Code: '42909',
    Description: 'Construction of other engineering projects n.e.c.',
    category: 'F',
  },
  {
    Code: '43110',
    Description: 'Demolition or wrecking of buildings and other structures',
    category: 'F',
  },
  {
    Code: '43121',
    Description: 'Clearing of building sites',
    category: 'F',
  },
  {
    Code: '43122',
    Description: 'Earth moving',
    category: 'F',
  },
  {
    Code: '43123',
    Description:
      'Drilling, boring and core sampling for construction, geophysical, geological or similar purposes',
    category: 'F',
  },
  {
    Code: '43124',
    Description: 'Site preparation for mining',
    category: 'F',
  },
  {
    Code: '43125',
    Description: 'Drainage of agricultural or forestry land',
    category: 'F',
  },
  {
    Code: '43126',
    Description: 'Land reclamation work',
    category: 'F',
  },
  {
    Code: '43129',
    Description: 'Other site preparation activities n.e.c.',
    category: 'F',
  },
  {
    Code: '43211',
    Description: 'Electrical wiring and fittings',
    category: 'F',
  },
  {
    Code: '43212',
    Description: 'Telecommunications wiring',
    category: 'F',
  },
  {
    Code: '43213',
    Description: 'Computer network and cable television wiring',
    category: 'F',
  },
  {
    Code: '43214',
    Description: 'Satellite dishes',
    category: 'F',
  },
  {
    Code: '43215',
    Description: 'Lighting systems',
    category: 'F',
  },
  {
    Code: '43216',
    Description: 'Security systems',
    category: 'F',
  },
  {
    Code: '43219',
    Description: 'Electrical installation n.e.c.',
    category: 'F',
  },
  {
    Code: '43221',
    Description: 'Installation of heating systems (electric, gas and oil)',
    category: 'F',
  },
  {
    Code: '43222',
    Description: 'Installation of furnaces, cooling towers',
    category: 'F',
  },
  {
    Code: '43223',
    Description: 'Installation of non-electric solar energy collectors',
    category: 'F',
  },
  {
    Code: '43224',
    Description: 'Installation of plumbing and sanitary equipment',
    category: 'F',
  },
  {
    Code: '43225',
    Description:
      'Installation of ventilation, refrigeration or air-conditioning equipment and ducts',
    category: 'F',
  },
  {
    Code: '43226',
    Description: 'Installation of gas fittings',
    category: 'F',
  },
  {
    Code: '43227',
    Description: 'Installation of fire and lawn sprinkler systems',
    category: 'F',
  },
  {
    Code: '43228',
    Description: 'Steam piping',
    category: 'F',
  },
  {
    Code: '43229',
    Description: 'Plumbing, heat and air-conditioning installation n.e.c.',
    category: 'F',
  },
  {
    Code: '43291',
    Description:
      'Installation of elevators, escalators in buildings or other construction projects',
    category: 'F',
  },
  {
    Code: '43292',
    Description:
      'Installation of automated and revolving doors in buildings or other construction projects',
    category: 'F',
  },
  {
    Code: '43293',
    Description: 'Installation of lighting conductors in buildings or other construction projects',
    category: 'F',
  },
  {
    Code: '43294',
    Description: 'Installation vacuum cleaning systems in buildings or other construction projects',
    category: 'F',
  },
  {
    Code: '43295',
    Description:
      'Installation thermal, sound or vibration insulation in buildings or other construction projects',
    category: 'F',
  },
  {
    Code: '43299',
    Description: 'Other construction installation n.e.c.',
    category: 'F',
  },
  {
    Code: '43301',
    Description:
      'Installation of doors, windows, door and window frames of wood or other materials, fitted kitchens, staircases, shop fittings and furniture',
    category: 'F',
  },
  {
    Code: '43302',
    Description:
      'Laying, tiling, hanging or fitting in buildings or other construction projects of various types of materials',
    category: 'F',
  },
  {
    Code: '43303',
    Description: 'Interior and exterior painting of buildings',
    category: 'F',
  },
  {
    Code: '43304',
    Description: 'Painting of civil engineering structures',
    category: 'F',
  },
  {
    Code: '43305',
    Description: 'Installation of glass, mirrors',
    category: 'F',
  },
  {
    Code: '43306',
    Description: 'Interior completion',
    category: 'F',
  },
  {
    Code: '43307',
    Description: 'Cleaning of new buildings after construction',
    category: 'F',
  },
  {
    Code: '43309',
    Description: 'Other building completion and finishing work n.e.c.',
    category: 'F',
  },
  {
    Code: '43901',
    Description: 'Construction of foundations, including pile driving',
    category: 'F',
  },
  {
    Code: '43902',
    Description: 'Erection of non-self-manufactured steel elements',
    category: 'F',
  },
  {
    Code: '43903',
    Description: 'Scaffolds and work platform erecting and dismantling',
    category: 'F',
  },
  {
    Code: '43904',
    Description: 'Bricklaying and stone setting',
    category: 'F',
  },
  {
    Code: '43905',
    Description: 'Construction of outdoor swimming pools',
    category: 'F',
  },
  {
    Code: '43906',
    Description: 'Steam cleaning, sand blasting and similar activities for building exteriors',
    category: 'F',
  },
  {
    Code: '43907',
    Description: 'Renting of construction machinery and equipment with operator (e.g. cranes)',
    category: 'F',
  },
  {
    Code: '43909',
    Description: 'Other specialized construction activities, n.e.c.',
    category: 'F',
  },
  {
    Code: '45101',
    Description: 'Wholesale and retail of new motor vehicles',
    category: 'G',
  },
  {
    Code: '45102',
    Description: 'Wholesale and retail of used motor vehicles',
    category: 'G',
  },
  {
    Code: '45103',
    Description: 'Sale of industrial, commercial and agriculture vehicles – new',
    category: 'G',
  },
  {
    Code: '45104',
    Description: 'Sale of industrial, commercial and agriculture vehicles – used',
    category: 'G',
  },
  {
    Code: '45105',
    Description: 'Sale by commission agents',
    category: 'G',
  },
  {
    Code: '45106',
    Description: 'Car auctions',
    category: 'G',
  },
  {
    Code: '45109',
    Description: 'Sale of other motor vehicles n.e.c.',
    category: 'G',
  },
  {
    Code: '45201',
    Description: 'Maintenance and repair of motor vehicles',
    category: 'G',
  },
  {
    Code: '45202',
    Description: 'Spraying and painting',
    category: 'G',
  },
  {
    Code: '45203',
    Description: 'Washing and polishing (car wash)',
    category: 'G',
  },
  {
    Code: '45204',
    Description: 'Repair of motor vehicle seats',
    category: 'G',
  },
  {
    Code: '45205',
    Description: 'Installation of parts and accessories not as part of the manufacturing process',
    category: 'G',
  },
  {
    Code: '45300',
    Description:
      'Wholesale and retail sale of all kinds of parts, components, supplies, tools and accessories for motor vehicles',
    category: 'G',
  },
  {
    Code: '45401',
    Description: 'Wholesale and retail sale of motorcycles',
    category: 'G',
  },
  {
    Code: '45402',
    Description: 'Wholesale and retail sale of parts and accessories for motorcycles',
    category: 'G',
  },
  {
    Code: '45403',
    Description: 'Repair and maintenance of motorcycles',
    category: 'G',
  },
  {
    Code: '46100',
    Description: 'Wholesale on a fee or contract basis',
    category: 'G',
  },
  {
    Code: '46201',
    Description: 'Wholesale of rubber',
    category: 'G',
  },
  {
    Code: '46202',
    Description: 'Wholesale of palm oil',
    category: 'G',
  },
  {
    Code: '46203',
    Description: 'Wholesale of lumber and timber',
    category: 'G',
  },
  {
    Code: '46204',
    Description: 'Wholesale of flowers and plants',
    category: 'G',
  },
  {
    Code: '46205',
    Description: 'Wholesale of livestock',
    category: 'G',
  },
  {
    Code: '46209',
    Description: 'Wholesale of agricultural raw material and live animal n.e.c.',
    category: 'G',
  },
  {
    Code: '46311',
    Description: 'Wholesale of meat, poultry and eggs',
    category: 'G',
  },
  {
    Code: '46312',
    Description: 'Wholesale of fish and other seafood',
    category: 'G',
  },
  {
    Code: '46313',
    Description: 'Wholesale of fruits',
    category: 'G',
  },
  {
    Code: '46314',
    Description: 'Wholesale of vegetables',
    category: 'G',
  },
  {
    Code: '46319',
    Description: 'Wholesale of meat, fish, fruits and vegetables n.e.c.',
    category: 'G',
  },
  {
    Code: '46321',
    Description: 'Wholesale of rice, other grains, flour and sugars',
    category: 'G',
  },
  {
    Code: '46322',
    Description: 'Wholesale of dairy products',
    category: 'G',
  },
  {
    Code: '46323',
    Description: 'Wholesale of confectionary',
    category: 'G',
  },
  {
    Code: '46324',
    Description: 'Wholesale of  biscuits, cakes, breads and other bakery products',
    category: 'G',
  },
  {
    Code: '46325',
    Description: 'Wholesale of coffee, tea, cocoa and other beverages',
    category: 'G',
  },
  {
    Code: '46326',
    Description: 'Wholesale of beer, wine and spirits',
    category: 'G',
  },
  {
    Code: '46327',
    Description: 'Wholesale of tobacco, cigar, cigarettes',
    category: 'G',
  },
  {
    Code: '46329',
    Description: 'Wholesale of other foodstuffs',
    category: 'G',
  },
  {
    Code: '46411',
    Description: 'Wholesale of yarn and fabrics',
    category: 'G',
  },
  {
    Code: '46412',
    Description: 'Wholesale of household linen, towels, blankets',
    category: 'G',
  },
  {
    Code: '46413',
    Description: 'Wholesale of clothing',
    category: 'G',
  },
  {
    Code: '46414',
    Description: 'Wholesale of clothing accessories',
    category: 'G',
  },
  {
    Code: '46415',
    Description: 'Wholesale of fur articles',
    category: 'G',
  },
  {
    Code: '46416',
    Description: 'Wholesale of footwear',
    category: 'G',
  },
  {
    Code: '46417',
    Description: 'Wholesale of haberdashery',
    category: 'G',
  },
  {
    Code: '46419',
    Description: 'Wholesale of textiles, clothing n.e.c.',
    category: 'G',
  },
  {
    Code: '46421',
    Description: 'Wholesale of pharmaceutical and medical goods',
    category: 'G',
  },
  {
    Code: '46422',
    Description: 'Wholesale of perfumeries, cosmetics, soap and toiletries',
    category: 'G',
  },
  {
    Code: '46431',
    Description: 'Wholesale of bicycles and their parts and accessories',
    category: 'G',
  },
  {
    Code: '46432',
    Description: 'Wholesale of photographic and optical goods',
    category: 'G',
  },
  {
    Code: '46433',
    Description: 'Wholesale of leather goods and travel accessories',
    category: 'G',
  },
  {
    Code: '46434',
    Description: 'Wholesale of musical instruments, games and toys, sports goods',
    category: 'G',
  },
  {
    Code: '46441',
    Description: 'Wholesale of handicrafts and artificial flowers',
    category: 'G',
  },
  {
    Code: '46442',
    Description: 'Wholesale of cut flowers and plants',
    category: 'G',
  },
  {
    Code: '46443',
    Description: 'Wholesale of watches and clocks',
    category: 'G',
  },
  {
    Code: '46444',
    Description: 'Wholesale of jewellery',
    category: 'G',
  },
  {
    Code: '46491',
    Description: 'Wholesale of household furniture',
    category: 'G',
  },
  {
    Code: '46492',
    Description: 'Wholesale of household appliances',
    category: 'G',
  },
  {
    Code: '46493',
    Description: 'Wholesale of lighting equipment',
    category: 'G',
  },
  {
    Code: '46494',
    Description:
      'Wholesale of household utensils and cutlery, crockery, glassware, chinaware and pottery',
    category: 'G',
  },
  {
    Code: '46495',
    Description: 'Wholesale of woodenware, wickerwork and corkware',
    category: 'G',
  },
  {
    Code: '46496',
    Description: 'Wholesale of electrical and electronic goods',
    category: 'G',
  },
  {
    Code: '46497',
    Description: 'Wholesale of stationery, books, magazines and newspapers',
    category: 'G',
  },
  {
    Code: '46499',
    Description: 'Wholesale of other household goods n.e.c.',
    category: 'G',
  },
  {
    Code: '46510',
    Description: 'Wholesale of computer hardware, software and peripherals',
    category: 'G',
  },
  {
    Code: '46521',
    Description: 'Wholesale of telephone and telecommunications equipment, cell phones, pagers',
    category: 'G',
  },
  {
    Code: '46522',
    Description: 'Wholesale of electronic components and wiring accessories',
    category: 'G',
  },
  {
    Code: '46531',
    Description: 'Wholesale of agricultural machinery, equipment and supplies',
    category: 'G',
  },
  {
    Code: '46532',
    Description: 'Wholesale of lawn mowers however operated',
    category: 'G',
  },
  {
    Code: '46591',
    Description:
      'Wholesale of office machinery and business equipment, except computers and computer peripheral equipment',
    category: 'G',
  },
  {
    Code: '46592',
    Description: 'Wholesale of office furniture',
    category: 'G',
  },
  {
    Code: '46593',
    Description: 'Wholesale of computer-controlled machines tools',
    category: 'G',
  },
  {
    Code: '46594',
    Description: 'Wholesale of industrial machinery, equipment and supplies',
    category: 'G',
  },
  {
    Code: '46595',
    Description: 'Wholesale of construction and civil engineering machinery and equipment',
    category: 'G',
  },
  {
    Code: '46596',
    Description:
      'Wholesale of lift escalators, air-conditioning, security and fire fighting equipment',
    category: 'G',
  },
  {
    Code: '46599',
    Description:
      'Wholesale of other machinery for use in industry, trade and navigation and other services n.e.c.',
    category: 'G',
  },
  {
    Code: '46611',
    Description: 'Wholesale of petrol, diesel, lubricants',
    category: 'G',
  },
  {
    Code: '46612',
    Description: 'Wholesale of liquefied petroleum gas',
    category: 'G',
  },
  {
    Code: '46619',
    Description: 'Wholesale of other solid, liquid and gaseous fuels and related products n.e.c.',
    category: 'G',
  },
  {
    Code: '46621',
    Description: 'Wholesale of ferrous and non-ferrous metal ores and metals',
    category: 'G',
  },
  {
    Code: '46622',
    Description:
      'Wholesale of ferrous and non-ferrous semi-finished metal ores and products n.e.c.',
    category: 'G',
  },
  {
    Code: '46631',
    Description: 'Wholesale of logs, sawn timber, plywood, veneer and related products',
    category: 'G',
  },
  {
    Code: '46632',
    Description: 'Wholesale of paints and varnish',
    category: 'G',
  },
  {
    Code: '46633',
    Description: 'Wholesale of construction materials',
    category: 'G',
  },
  {
    Code: '46634',
    Description: 'Wholesale of fittings and fixtures',
    category: 'G',
  },
  {
    Code: '46635',
    Description: 'Wholesale of hot water heaters',
    category: 'G',
  },
  {
    Code: '46636',
    Description: 'Wholesale of sanitary installation and equipment',
    category: 'G',
  },
  {
    Code: '46637',
    Description: 'Wholesale of tools',
    category: 'G',
  },
  {
    Code: '46639',
    Description:
      'Wholesale of other construction materials, hardware, plumbing and heating equipment and supplies n.e.c.',
    category: 'G',
  },
  {
    Code: '46691',
    Description: 'Wholesale of industrial chemicals',
    category: 'G',
  },
  {
    Code: '46692',
    Description: 'Wholesale of fertilizers and agrochemical products',
    category: 'G',
  },
  {
    Code: '46693',
    Description: 'Wholesale of plastic materials in primary forms',
    category: 'G',
  },
  {
    Code: '46694',
    Description: 'Wholesale of rubber scrap',
    category: 'G',
  },
  {
    Code: '46695',
    Description: 'Wholesale of textile fibres',
    category: 'G',
  },
  {
    Code: '46696',
    Description: 'Wholesale of paper in bulk, packaging materials',
    category: 'G',
  },
  {
    Code: '46697',
    Description: 'Wholesale of precious stones',
    category: 'G',
  },
  {
    Code: '46698',
    Description: 'Wholesale of metal and non-metal waste and scrap and materials for recycling',
    category: 'G',
  },
  {
    Code: '46699',
    Description:
      'Dismantling of automobiles, computer, televisions and other equipment to obtain and re-sell usable parts',
    category: 'G',
  },
  {
    Code: '46901',
    Description: 'Wholesale of aquarium fishes, pet birds and animals',
    category: 'G',
  },
  {
    Code: '46902',
    Description: 'Wholesale of animal/pet food',
    category: 'G',
  },
  {
    Code: '46909',
    Description: 'Wholesale of a variety of goods without any particular specialization n.e.c.',
    category: 'G',
  },
  {
    Code: '47111',
    Description: 'Provision stores',
    category: 'G',
  },
  {
    Code: '47112',
    Description: 'Supermarket',
    category: 'G',
  },
  {
    Code: '47113',
    Description: 'Mini market',
    category: 'G',
  },
  {
    Code: '47114',
    Description: 'Convenience stores',
    category: 'G',
  },
  {
    Code: '47191',
    Description: 'Department stores',
    category: 'G',
  },
  {
    Code: '47192',
    Description: 'Department stores and supermarket',
    category: 'G',
  },
  {
    Code: '47193',
    Description: 'Hypermarket',
    category: 'G',
  },
  {
    Code: '47194',
    Description: 'News agent and miscellaneous goods store',
    category: 'G',
  },
  {
    Code: '47199',
    Description: 'Other retail sale in non-specialized stores n.e.c.',
    category: 'G',
  },
  {
    Code: '47211',
    Description: 'Retail sale of rice, flour, other grains and sugars',
    category: 'G',
  },
  {
    Code: '47212',
    Description: 'Retail sale of fresh or preserved vegetables and fruits',
    category: 'G',
  },
  {
    Code: '47213',
    Description: 'Retail sale of dairy products and eggs',
    category: 'G',
  },
  {
    Code: '47214',
    Description: 'Retail sale of meat and meat products (including poultry)',
    category: 'G',
  },
  {
    Code: '47215',
    Description: 'Retail sale of fish, other seafood and products thereof',
    category: 'G',
  },
  {
    Code: '47216',
    Description: 'Retail sale of bakery products and sugar confectionery',
    category: 'G',
  },
  {
    Code: '47217',
    Description:
      'Retail sale of mee, kuey teow, mee hoon, wantan skins and other food products made from flour or soya',
  },
  {
    Code: '47219',
    Description: 'Retail sale of other food products n.e.c.',
    category: 'G',
  },
  {
    Code: '47221',
    Description: 'Retail sale of beer, wine and spirits',
    category: 'G',
  },
  {
    Code: '47222',
    Description: 'Retail sale of tea, coffee, soft drinks, mineral water and other beverages',
    category: 'G',
  },
  {
    Code: '47230',
    Description: 'Retail sale of tobacco products in specialized store',
    category: 'G',
  },
  {
    Code: '47300',
    Description: 'Retail sale of automotive fuel in specialized stores',
    category: 'G',
  },
  {
    Code: '47412',
    Description: 'Retail sale of video game consoles and non-customized software',
    category: 'G',
  },
  {
    Code: '47413',
    Description: 'Retail sale of telecommunication equipment',
    category: 'G',
  },
  {
    Code: '47420',
    Description: 'Retail sale of audio and video equipment in specialized store',
    category: 'G',
  },
  {
    Code: '47510',
    Description: 'Retail sale of textiles in specialized stores',
    category: 'G',
  },
  {
    Code: '47531',
    Description: 'Retail sale of carpets and rugs',
    category: 'G',
  },
  {
    Code: '47532',
    Description: 'Retail sale of curtains and net curtains',
    category: 'G',
  },
  {
    Code: '47533',
    Description: 'Retail sale of wallpaper and floor coverings',
    category: 'G',
  },
  {
    Code: '47591',
    Description: 'Retail sale of household furniture',
    category: 'G',
  },
  {
    Code: '47592',
    Description: 'Retail sale of articles for lighting',
    category: 'G',
  },
  {
    Code: '47593',
    Description:
      'Retail sale of household utensils and cutlery, crockery, glassware, chinaware and pottery',
    category: 'G',
  },
  {
    Code: '47594',
    Description: 'Retail sale of wood, cork goods and wickerwork goods',
    category: 'G',
  },
  {
    Code: '47595',
    Description: 'Retail sale of household appliances',
    category: 'G',
  },
  {
    Code: '47596',
    Description: 'Retail sale of musical instruments and scores',
    category: 'G',
  },
  {
    Code: '47597',
    Description: 'Retail sale of security systems',
    category: 'G',
  },
  {
    Code: '47598',
    Description: 'Retail sale of household articles and equipment n.e.c.',
    category: 'G',
  },
  {
    Code: '47611',
    Description: 'Retail sale of office supplies and equipment',
    category: 'G',
  },
  {
    Code: '47612',
    Description: 'Retail sale of books, newspapers and stationary',
    category: 'G',
  },
  {
    Code: '47631',
    Description: 'Retail sale of sports goods and equipments',
    category: 'G',
  },
  {
    Code: '47632',
    Description: 'Retail sale of fishing equipment',
    category: 'G',
  },
  {
    Code: '47633',
    Description: 'Retail sale of camping goods',
    category: 'G',
  },
  {
    Code: '47634',
    Description: 'Retail sale of boats and equipments',
    category: 'G',
  },
  {
    Code: '47635',
    Description: 'Retail sale of bicycles and related parts and accessories',
    category: 'G',
  },
  {
    Code: '47640',
    Description: 'Retail sale of games and toys, made of all materials',
    category: 'G',
  },
  {
    Code: '47711',
    Description: 'Retail sale of articles of clothing, articles of fur and clothing accessories',
    category: 'G',
  },
  {
    Code: '47712',
    Description: 'Retail sale of footwear',
    category: 'G',
  },
  {
    Code: '47713',
    Description: 'Retail sale of leather goods, accessories of leather and leather substitutes',
    category: 'G',
  },
  {
    Code: '47721',
    Description:
      'Stores specialized in retail sale of pharmaceuticals, medical and orthopaedic goods',
    category: 'G',
  },
  {
    Code: '47722',
    Description: 'Stores specialized in retail sale of perfumery, cosmetic and toilet articles',
    category: 'G',
  },
  {
    Code: '47731',
    Description: 'Retail sale of photographic and precision equipment',
    category: 'G',
  },
  {
    Code: '47732',
    Description: 'Retail sale of watches and clocks',
    category: 'G',
  },
  {
    Code: '47733',
    Description: 'Retail sale of jewellery',
    category: 'G',
  },
  {
    Code: '47734',
    Description: 'Retail sale of flowers, plants, seeds, fertilizers',
    category: 'G',
  },
  {
    Code: '47735',
    Description: 'Retail sale of souvenirs, craftwork and religious articles',
    category: 'G',
  },
  {
    Code: '47736',
    Description: 'Retail sale of household fuel oil, cooking gas, coal and fuel wood',
    category: 'G',
  },
  {
    Code: '47737',
    Description: 'Retail sale of spectacles and other optical goods',
    category: 'G',
  },
  {
    Code: '47738',
    Description: 'Retail sale of aquarium fishes, pet animals and pet food',
    category: 'G',
  },
  {
    Code: '47739',
    Description: 'Other retail sale of new goods in specialized stores n.e.c.',
    category: 'G',
  },
  {
    Code: '47741',
    Description: 'Retail sale of second-hand books',
    category: 'G',
  },
  {
    Code: '47742',
    Description: 'Retail sale of second-hand electrical and electronic goods',
    category: 'G',
  },
  {
    Code: '47743',
    Description: 'Retail sale of antiques',
    category: 'G',
  },
  {
    Code: '47744',
    Description: 'Activities of auctioning houses (retail)',
    category: 'G',
  },
  {
    Code: '47749',
    Description: 'Retail sale of second-hand goods n.e.c.',
    category: 'G',
  },
  {
    Code: '47810',
    Description: 'Retail sale of food, beverages and tobacco products via stalls or markets',
    category: 'G',
  },
  {
    Code: '47820',
    Description: 'Retail sale of textiles, clothing and footwear via stalls or markets',
    category: 'G',
  },
  {
    Code: '47891',
    Description: 'Retail sale of carpets and rugs via stalls or markets',
    category: 'G',
  },
  {
    Code: '47893',
    Description: 'Retail sale of games and toys via stalls or markets',
    category: 'G',
  },
  {
    Code: '47894',
    Description:
      'Retail sale of household appliances and consumer electronics via stall or markets',
    category: 'G',
  },
  {
    Code: '47895',
    Description: 'Retail sale of music and video recordings via stall or markets',
    category: 'G',
  },
  {
    Code: '47911',
    Description: 'Retail sale of any kind of product by mail order',
    category: 'G',
  },
  {
    Code: '47912',
    Description: 'Retail sale of any kind of product over the Internet',
    category: 'G',
  },
  {
    Code: '47913',
    Description: 'Direct sale via television, radio and telephone',
    category: 'G',
  },
  {
    Code: '47914',
    Description: 'Internet retail auctions',
    category: 'G',
  },
  {
    Code: '47992',
    Description: 'Retail sale of any kind of product through vending machines',
    category: 'G',
  },
  {
    Code: '47999',
    Description: 'Other retail sale not in stores, stalls or markets n.e.c.',
    category: 'G',
  },
  {
    Code: '49110',
    Description: 'Passenger transport by inter-urban railways',
    category: 'H',
  },
  {
    Code: '49120',
    Description: 'Freight transport by inter-urban, suburban and urban railways',
    category: 'H',
  },
  {
    Code: '49211',
    Description: 'City bus services',
    category: 'H',
  },
  {
    Code: '49212',
    Description: 'Urban and suburban railway passenger transport service',
    category: 'H',
  },
  {
    Code: '49221',
    Description: 'Express bus services',
    category: 'H',
  },
  {
    Code: '49222',
    Description: 'Employees bus services',
    category: 'H',
  },
  {
    Code: '49223',
    Description: 'School bus services',
    category: 'H',
  },
  {
    Code: '49224',
    Description: 'Taxi operation and limousine services',
    category: 'H',
  },
  {
    Code: '49225',
    Description: 'Rental of cars with driver',
    category: 'H',
  },
  {
    Code: '49229',
    Description: 'Other passenger land transport n.e.c.',
    category: 'H',
  },
  {
    Code: '49230',
    Description: 'Freight transport by road',
    category: 'H',
  },
  {
    Code: '49300',
    Description: 'Transport via pipeline',
    category: 'H',
  },
  {
    Code: '50111',
    Description: 'Operation of excursion, cruise or sightseeing boats',
    category: 'H',
  },
  {
    Code: '50112',
    Description: 'Operation of ferries, water taxis',
    category: 'H',
  },
  {
    Code: '50113',
    Description: 'Rental of pleasure boats with crew for sea and coastal water transport',
    category: 'H',
  },
  {
    Code: '50121',
    Description: 'Transport of freight overseas and coastal waters, whether scheduled or not',
    category: 'H',
  },
  {
    Code: '50122',
    Description: 'Transport by towing or pushing of barges, oil rigs',
    category: 'H',
  },
  {
    Code: '50211',
    Description: 'Transport of passenger via rivers, canals, lakes and other inland waterways',
    category: 'H',
  },
  {
    Code: '50212',
    Description: 'Rental of pleasure boats with crew for inland water transport',
    category: 'H',
  },
  {
    Code: '50220',
    Description: 'Transport of freight via rivers, canals, lakes and other inland waterways',
    category: 'H',
  },
  {
    Code: '51101',
    Description: 'Transport of passengers by air over regular routes and on regular schedules',
    category: 'H',
  },
  {
    Code: '51102',
    Description: 'Non-scheduled transport of passenger by air',
    category: 'H',
  },
  {
    Code: '51103',
    Description:
      'Renting of air-transport equipment with operator for the purpose of passenger transportation',
    category: 'H',
  },
  {
    Code: '51201',
    Description: 'Transport freight by air over regular routes and on regular schedules',
    category: 'H',
  },
  {
    Code: '51202',
    Description: 'Non-scheduled transport of freight by air',
    category: 'H',
  },
  {
    Code: '51203',
    Description:
      'Renting of air-transport equipment with operator for the purpose of freight transportation',
    category: 'H',
  },
  {
    Code: '52100',
    Description: 'Warehousing and storage services',
    category: 'H',
  },
  {
    Code: '52211',
    Description: 'Operation of terminal facilities',
    category: 'H',
  },
  {
    Code: '52212',
    Description: 'Towing and road side assistance',
    category: 'H',
  },
  {
    Code: '52213',
    Description: 'Operation of parking facilities for motor vehicles (parking lots)',
    category: 'H',
  },
  {
    Code: '52214',
    Description: 'Highway, bridge and tunnel operation services',
    category: 'H',
  },
  {
    Code: '52219',
    Description: 'Other service activities incidental to land transportation n.e.c.',
    category: 'H',
  },
  {
    Code: '52221',
    Description: 'Port, harbours and piers operation services',
    category: 'H',
  },
  {
    Code: '52222',
    Description: 'Vessel salvage and refloating services',
    category: 'H',
  },
  {
    Code: '52229',
    Description: 'Other service activities incidental to water transportation n.e.c.',
    category: 'H',
  },
  {
    Code: '52231',
    Description: 'Operation of terminal facilities',
    category: 'H',
  },
  {
    Code: '52232',
    Description: 'Airport and air-traffic-control activities',
    category: 'H',
  },
  {
    Code: '52233',
    Description: 'Ground service activities on airfields',
    category: 'H',
  },
  {
    Code: '52234',
    Description: 'Fire fighting and fire-prevention services at airports',
    category: 'H',
  },
  {
    Code: '52239',
    Description: 'Other service activities incidental to air transportation n.e.c.',
    category: 'H',
  },
  {
    Code: '52241',
    Description: 'Stevedoring services',
    category: 'H',
  },
  {
    Code: '52249',
    Description: 'Other cargo handling activities n.e.c.',
    category: 'H',
  },
  {
    Code: '52291',
    Description: 'Forwarding of freight',
    category: 'H',
  },
  {
    Code: '52292',
    Description: 'Brokerage for ship and aircraft space',
    category: 'H',
  },
  {
    Code: '52299',
    Description: 'Other transportation support activities n.e.c.',
    category: 'H',
  },
  {
    Code: '53100',
    Description: 'National postal services',
    category: 'H',
  },
  {
    Code: '53200',
    Description: 'Courier activities other than national post activities',
    category: 'H',
  },
  {
    Code: '55101',
    Description: 'Hotels and resort hotels',
    category: 'I',
  },
  {
    Code: '55102',
    Description: 'Motels',
    category: 'I',
  },
  {
    Code: '55103',
    Description: 'Apartment hotels',
    category: 'I',
  },
  {
    Code: '55104',
    Description: 'Chalets',
    category: 'I',
  },
  {
    Code: '55105',
    Description: 'Rest house/guest house',
    category: 'I',
  },
  {
    Code: '55106',
    Description: 'Bed and breakfast units',
    category: 'I',
  },
  {
    Code: '55107',
    Description: 'Hostels',
    category: 'I',
  },
  {
    Code: '55108',
    Description: 'Home stay',
    category: 'I',
  },
  {
    Code: '55109',
    Description: 'Other short term accommodation activities n.e.c.',
    category: 'I',
  },
  {
    Code: '55200',
    Description: 'Camping grounds, recreational vehicle parks and trailer parks',
    category: 'I',
  },
  {
    Code: '55900',
    Description: 'Other accommodation',
    category: 'I',
  },
  {
    Code: '56103',
    Description: 'Fast-food restaurants',
    category: 'I',
  },
  {
    Code: '56104',
    Description: 'Ice cream truck vendors and parlours',
    category: 'I',
  },
  {
    Code: '56105',
    Description: 'Mobile food carts',
    category: 'I',
  },
  {
    Code: '56106',
    Description: 'Food stalls/hawkers',
    category: 'I',
  },
  {
    Code: '56107',
    Description: 'Food or beverage, food and beverage preparation in market stalls/hawkers',
    category: 'I',
  },
  {
    Code: '56210',
    Description: 'Event/food caterers',
    category: 'I',
  },
  {
    Code: '56290',
    Description: 'Other food service activities',
    category: 'I',
  },
  {
    Code: '56301',
    Description: 'Pubs, bars, discotheques, coffee houses, cocktail lounges and karaoke',
    category: 'I',
  },
  {
    Code: '56302',
    Description: 'Coffee shops',
    category: 'I',
  },
  {
    Code: '56303',
    Description: 'Drink stalls/hawkers',
    category: 'I',
  },
  {
    Code: '56304',
    Description: 'Mobile beverage',
    category: 'I',
  },
  {
    Code: '56309',
    Description: 'Others drinking places n.e.c.',
    category: 'I',
  },
  {
    Code: '58110',
    Description: 'Publishing of books, brochures and other publications',
    category: 'J',
  },
  {
    Code: '58120',
    Description: 'Publishing of mailing lists, telephone book, other directories',
    category: 'J',
  },
  {
    Code: '58130',
    Description:
      'Publishing of newspapers, journals, magazines and periodicals in print or electronic form',
    category: 'J',
  },
  {
    Code: '58190',
    Description:
      'Publishing of catalogues, photos, engraving and postcards, greeting cards, forms, posters, reproduction of works of art, advertising material and other printed matter n.e.c.',
    category: 'J',
  },
  {
    Code: '58201',
    Description: 'Business and other applications',
    category: 'J',
  },
  {
    Code: '58202',
    Description: 'Computer games for all platforms',
    category: 'J',
  },
  {
    Code: '58203',
    Description: 'Operating systems',
    category: 'J',
  },
  {
    Code: '59110',
    Description: 'Motion picture, video and television programme production activities',
    category: 'J',
  },
  {
    Code: '59120',
    Description: 'Motion picture, video and television programme post-production activities',
    category: 'J',
  },
  {
    Code: '59130',
    Description: 'Motion picture, video and television programme distribution activities',
    category: 'J',
  },
  {
    Code: '59140',
    Description: 'Motion picture projection activities',
    category: 'J',
  },
  {
    Code: '59200',
    Description: 'Sound recording and music publishing activities',
    category: 'J',
  },
  {
    Code: '60100',
    Description: 'Radio broadcasting',
    category: 'J',
  },
  {
    Code: '60200',
    Description: 'Television programming and broadcasting activities',
    category: 'J',
  },
  {
    Code: '61101',
    Description: 'Wired telecommunications services',
    category: 'J',
  },
  {
    Code: '61102',
    Description: 'Internet access providers by the operator of the wired infrastructure',
    category: 'J',
  },
  {
    Code: '61201',
    Description: 'Wireless telecommunications services',
    category: 'J',
  },
  {
    Code: '61202',
    Description: 'Internet access providers by the operator of the wireless infrastructure',
    category: 'J',
  },
  {
    Code: '61300',
    Description: 'Satellite telecommunications services',
    category: 'J',
  },
  {
    Code: '61901',
    Description:
      'Provision of Internet access over networks between the client and the ISP not owned or controlled by the ISP',
    category: 'J',
  },
  {
    Code: '61902',
    Description: 'Provision of telecommunications services over existing telecom connection',
    category: 'J',
  },
  {
    Code: '61903',
    Description: 'Telecommunications resellers',
    category: 'J',
  },
  {
    Code: '61904',
    Description:
      'Provision of telecommunications services over existing telecom connections VOIP (Voice Over Internet Protocol) provision',
    category: 'J',
  },
  {
    Code: '61905',
    Description: 'Provision of specialized telecommunications applications',
    category: 'J',
  },
  {
    Code: '61909',
    Description: 'Other telecommunications activities n.e.c.',
    category: 'J',
  },
  {
    Code: '62010',
    Description: 'Computer programming activities',
    category: 'J',
  },
  {
    Code: '62021',
    Description: 'Computer consultancy',
    category: 'J',
  },
  {
    Code: '62022',
    Description: 'Computer facilities management activities',
    category: 'J',
  },
  {
    Code: '62091',
    Description: 'Information Communication Technology (ICT) system security',
    category: 'J',
  },
  {
    Code: '62099',
    Description: 'Other information technology service activities n.e.c.',
    category: 'J',
  },
  {
    Code: '63111',
    Description:
      'Activities of providing infrastructure for hosting, data processing services and related activities',
    category: 'J',
  },
  {
    Code: '63112',
    Description: 'Data processing activities',
    category: 'J',
  },
  {
    Code: '63120',
    Description: 'Web portals',
    category: 'J',
  },
  {
    Code: '63910',
    Description: 'News syndicate and news agency activities',
    category: 'J',
  },
  {
    Code: '63990',
    Description: 'Other information service activities n.e.c.',
    category: 'J',
  },
  {
    Code: '64110',
    Description: 'Central banking',
    category: 'K',
  },
  {
    Code: '64191',
    Description: 'Commercial Banks',
    category: 'K',
  },
  {
    Code: '64192',
    Description: 'Islamic Banks',
    category: 'K',
  },
  {
    Code: '64193',
    Description: 'Offshore Banks',
    category: 'K',
  },
  {
    Code: '64194',
    Description: 'Investment Banks',
    category: 'K',
  },
  {
    Code: '64195',
    Description: 'Development financial institutions (with deposit taking functions)',
    category: 'K',
  },
  {
    Code: '64199',
    Description: 'Other monetary intermediation (with deposit taking functions) n.e.c.',
    category: 'K',
  },
  {
    Code: '64200',
    Description: 'Activities of holding companies',
    category: 'K',
  },
  {
    Code: '64301',
    Description: 'Venture capital companies',
    category: 'K',
  },
  {
    Code: '64302',
    Description: 'Unit trust fund excludes REITs',
    category: 'K',
  },
  {
    Code: '64303',
    Description: 'Property unit trust (REITs)',
    category: 'K',
  },
  {
    Code: '64304',
    Description: 'Other administration of trusts accounts',
    category: 'K',
  },
  {
    Code: '64309',
    Description: 'Trusts, funds and similar financial entities n.e.c.',
    category: 'K',
  },
  {
    Code: '64910',
    Description: 'Financial leasing activities',
    category: 'K',
  },
  {
    Code: '64921',
    Description: 'Development financial institutions (without deposit taking functions)',
    category: 'K',
  },
  {
    Code: '64922',
    Description: 'Credit card services',
    category: 'K',
  },
  {
    Code: '64923',
    Description: 'Licensed money lending activities',
    category: 'K',
  },
  {
    Code: '64924',
    Description: 'Pawnshops and pawnbrokers includes Ar-Rahnu',
    category: 'K',
  },
  {
    Code: '64925',
    Description: 'Co-operative with credits functions',
    category: 'K',
  },
  {
    Code: '64929',
    Description: 'Other credit granting n.e.c.',
    category: 'K',
  },
  {
    Code: '64991',
    Description: 'Factoring companies',
    category: 'K',
  },
  {
    Code: '64992',
    Description: 'Representative office of foreign banks',
    category: 'K',
  },
  {
    Code: '64993',
    Description: 'Nominee companies',
    category: 'K',
  },
  {
    Code: '64999',
    Description:
      'Other financial service activities, except insurance/takaful and pension funding n.e.c.',
    category: 'K',
  },
  {
    Code: '65111',
    Description: 'Life insurance',
    category: 'K',
  },
  {
    Code: '65112',
    Description: 'Family takaful',
    category: 'K',
  },
  {
    Code: '65121',
    Description: 'General insurance',
    category: 'K',
  },
  {
    Code: '65122',
    Description: 'General takaful',
    category: 'K',
  },
  {
    Code: '65123',
    Description: 'Composite insurance',
    category: 'K',
  },
  {
    Code: '65124',
    Description: 'Offshore insurance',
    category: 'K',
  },
  {
    Code: '65125',
    Description: 'Offshore takaful',
    category: 'K',
  },
  {
    Code: '65201',
    Description: 'Life reinsurance',
    category: 'K',
  },
  {
    Code: '65202',
    Description: 'Family retakaful',
    category: 'K',
  },
  {
    Code: '65203',
    Description: 'General reinsurance',
    category: 'K',
  },
  {
    Code: '65204',
    Description: 'General retakaful',
    category: 'K',
  },
  {
    Code: '65205',
    Description: 'Composite retakaful',
    category: 'K',
  },
  {
    Code: '65206',
    Description: 'Offshore reinsurance',
    category: 'K',
  },
  {
    Code: '65207',
    Description: 'Offshore retakaful',
    category: 'K',
  },
  {
    Code: '65301',
    Description: 'Pension funding',
    category: 'K',
  },
  {
    Code: '65302',
    Description: 'Provident funding',
    category: 'K',
  },
  {
    Code: '66111',
    Description: 'Stock exchanges',
    category: 'K',
  },
  {
    Code: '66112',
    Description: 'Exchanges for commodity contracts',
    category: 'K',
  },
  {
    Code: '66113',
    Description: 'Securities exchange',
    category: 'K',
  },
  {
    Code: '66114',
    Description: 'Exchanges for commodity futures contracts',
    category: 'K',
  },
  {
    Code: '66119',
    Description: 'Administration of financial markets n.e.c.',
    category: 'K',
  },
  {
    Code: '66121',
    Description: 'Stock, share and bond brokers',
    category: 'K',
  },
  {
    Code: '66122',
    Description: 'Commodity brokers and dealers',
    category: 'K',
  },
  {
    Code: '66123',
    Description: 'Gold bullion dealers',
    category: 'K',
  },
  {
    Code: '66124',
    Description: 'Foreign exchange broker and dealers (Bureaux de change)',
    category: 'K',
  },
  {
    Code: '66125',
    Description: 'Money-changing services',
    category: 'K',
  },
  {
    Code: '66129',
    Description: 'Other financial and commodity futures brokers and dealers',
    category: 'K',
  },
  {
    Code: '66191',
    Description: 'Investment advisory services',
    category: 'K',
  },
  {
    Code: '66192',
    Description: 'Financial consultancy services',
    category: 'K',
  },
  {
    Code: '66199',
    Description: 'Activities auxiliary to finance n.e.c.',
    category: 'K',
  },
  {
    Code: '66211',
    Description: 'Insurance adjusting service',
    category: 'K',
  },
  {
    Code: '66212',
    Description: 'Takaful adjusting service',
    category: 'K',
  },
  {
    Code: '66221',
    Description: 'Insurance agents',
    category: 'K',
  },
  {
    Code: '66222',
    Description: 'Takaful agents',
    category: 'K',
  },
  {
    Code: '66223',
    Description: 'Insurance brokers',
    category: 'K',
  },
  {
    Code: '66224',
    Description: 'Takaful brokers',
    category: 'K',
  },
  {
    Code: '66290',
    Description: 'Other activities auxiliary to insurance, takaful and pension funding',
    category: 'K',
  },
  {
    Code: '66301',
    Description: 'Management of pension funds',
    category: 'K',
  },
  {
    Code: '66302',
    Description: 'Assets/portfolio management',
    category: 'K',
  },
  {
    Code: '66303',
    Description: 'Unit trust management companies',
    category: 'K',
  },
  {
    Code: '68101',
    Description:
      'Buying, selling, renting and operating of self-owned or leased real estate – residential buildings',
    category: 'L',
  },
  {
    Code: '68102',
    Description:
      'Buying, selling, renting and operating of self-owned or leased real estate – non-residential buildings',
    category: 'L',
  },
  {
    Code: '68103',
    Description:
      'Buying, selling, renting and operating of self-owned or leased real estate – land',
    category: 'L',
  },
  {
    Code: '68104',
    Description:
      'Development of building projects for own operation, i.e. for renting of space in these buildings',
    category: 'L',
  },
  {
    Code: '68109',
    Description: 'Real estate activities with own or leased property n.e.c.',
    category: 'L',
  },
  {
    Code: '68201',
    Description:
      'Activities of real estate agents and brokers for buying, selling and renting of real estate',
    category: 'L',
  },
  {
    Code: '68202',
    Description: 'Management of real estate on a fee or contract basis',
    category: 'L',
  },
  {
    Code: '68203',
    Description: 'Appraisal services for real estate',
    category: 'L',
  },
  {
    Code: '68209',
    Description: 'Real estate activities on a fee or contract basis n.e.c.',
    category: 'L',
  },
  {
    Code: '69100',
    Description: 'Legal activities',
    category: 'M',
  },
  {
    Code: '69200',
    Description: 'Accounting, bookkeeping and auditing activities; tax consultancy',
    category: 'M',
  },
  {
    Code: '70100',
    Description: 'Activities of head offices',
    category: 'M',
  },
  {
    Code: '70201',
    Description: 'Business management consultancy services',
    category: 'M',
  },
  {
    Code: '70202',
    Description: 'Human resource consultancy services',
    category: 'M',
  },
  {
    Code: '70203',
    Description: 'Consultancy services in public relation and communications',
    category: 'M',
  },
  {
    Code: '70209',
    Description: 'Other management consultancy activities n.e.c',
    category: 'M',
  },
  {
    Code: '71101',
    Description: 'Architectural services',
    category: 'M',
  },
  {
    Code: '71102',
    Description: 'Engineering services',
    category: 'M',
  },
  {
    Code: '71103',
    Description: 'Land surveying services',
    category: 'M',
  },
  {
    Code: '71109',
    Description:
      'Other architectural and engineering activities and related technical consultancy n.e.c.',
    category: 'M',
  },
  {
    Code: '71200',
    Description: 'Technical testing and analysis',
    category: 'M',
  },
  {
    Code: '72101',
    Description: 'Research and development on natural sciences',
    category: 'M',
  },
  {
    Code: '72102',
    Description: 'Research and development on engineering and technology',
    category: 'M',
  },
  {
    Code: '72103',
    Description: 'Research and development on medical sciences',
    category: 'M',
  },
  {
    Code: '72104',
    Description: 'Research and development on biotechnology',
    category: 'M',
  },
  {
    Code: '72105',
    Description: 'Research and development on agricultural sciences',
    category: 'M',
  },
  {
    Code: '72106',
    Description: 'Research and development on Information Communication Technology (ICT)',
    category: 'M',
  },
  {
    Code: '72109',
    Description: 'Research and development on other natural science and engineering n.e.c.',
    category: 'M',
  },
  {
    Code: '72201',
    Description: 'Research and development on social sciences',
    category: 'M',
  },
  {
    Code: '72202',
    Description: 'Research and development on humanities',
    category: 'M',
  },
  {
    Code: '72209',
    Description: 'Research and development of other social sciences and humanities n.e.c.',
    category: 'M',
  },
  {
    Code: '73100',
    Description: 'Advertising',
    category: 'M',
  },
  {
    Code: '73200',
    Description: 'Market research and public opinion polling',
    category: 'M',
  },
  {
    Code: '74101',
    Description: 'Activities of interior decorators',
    category: 'M',
  },
  {
    Code: '74102',
    Description: 'Services of graphic designers',
    category: 'M',
  },
  {
    Code: '74103',
    Description: 'Fashion design services',
    category: 'M',
  },
  {
    Code: '74109',
    Description: 'Specialized design activities n.e.c.',
    category: 'M',
  },
  {
    Code: '74200',
    Description: 'Photographic activities',
    category: 'M',
  },
  {
    Code: '74901',
    Description: 'Translation and interpretation activities',
    category: 'M',
  },
  {
    Code: '74902',
    Description: 'Business brokerage activities',
    category: 'M',
  },
  {
    Code: '74903',
    Description: 'Security consulting',
    category: 'M',
  },
  {
    Code: '74904',
    Description: 'Activities of quantity surveyors',
    category: 'M',
  },
  {
    Code: '74905',
    Description:
      'Activities of consultants other than architecture, engineering and management consultants',
    category: 'M',
  },
  {
    Code: '74909',
    Description: 'Any other professional, scientific and technical activities n.e.c.',
    category: 'M',
  },
  {
    Code: '75000',
    Description: 'VETERINARY ACTIVITIES',
    category: 'M',
  },
  {
    Code: '77101',
    Description: 'Renting and operational leasing of passenger cars (without driver)',
    category: 'N',
  },
  {
    Code: '77102',
    Description:
      'Renting and operational leasing of trucks, utility trailers and recreational vehicles',
    category: 'N',
  },
  {
    Code: '77211',
    Description: 'Renting and leasing of pleasure boats, canoes, sailboats',
    category: 'N',
  },
  {
    Code: '77212',
    Description: 'Renting and leasing of bicycles',
    category: 'N',
  },
  {
    Code: '77213',
    Description: 'Renting and leasing of beach chairs and umbrellas',
    category: 'N',
  },
  {
    Code: '77219',
    Description: 'Renting and leasing of other sports equipment n.e.c.',
    category: 'N',
  },
  {
    Code: '77220',
    Description: 'Renting of video tapes, records, CDs, DVDs',
    category: 'N',
  },
  {
    Code: '77291',
    Description: 'Renting and leasing of textiles, wearing apparel and footwear',
    category: 'N',
  },
  {
    Code: '77292',
    Description:
      'Renting and leasing of furniture, pottery and glass, kitchen and tableware, electrical appliances and house wares',
    category: 'N',
  },
  {
    Code: '77293',
    Description: 'Renting and leasing of jewellery, musical instruments, scenery and costumes',
    category: 'N',
  },
  {
    Code: '77294',
    Description: 'Renting and leasing of books, journals and magazines',
    category: 'N',
  },
  {
    Code: '77295',
    Description: 'Renting and leasing of machinery and equipment used by amateurs or as a hobby',
    category: 'N',
  },
  {
    Code: '77296',
    Description: 'Renting of flowers and plants',
    category: 'N',
  },
  {
    Code: '77297',
    Description: 'Renting and leasing of electronic equipment for household use',
    category: 'N',
  },
  {
    Code: '77299',
    Description: 'Renting and leasing of other personal and household goods n.e.c.',
    category: 'N',
  },
  {
    Code: '77301',
    Description:
      'Renting and operational leasing, without operator, of other machinery and equipment that are generally used as capital goods by industries',
    category: 'N',
  },
  {
    Code: '77302',
    Description:
      'Renting and operational leasing of land-transport equipment (other than motor vehicles) without drivers',
    category: 'N',
  },
  {
    Code: '77303',
    Description: 'Renting and operational leasing of water-transport equipment without operator',
    category: 'N',
  },
  {
    Code: '77304',
    Description: 'Renting and operational leasing of air transport equipment without operator',
    category: 'N',
  },
  {
    Code: '77305',
    Description:
      'Renting and operational leasing of agricultural and forestry machinery and equipment without operator',
    category: 'N',
  },
  {
    Code: '77306',
    Description:
      'Renting and operational leasing of construction and civil-engineering machinery and equipment without operator',
    category: 'N',
  },
  {
    Code: '77307',
    Description:
      'Rental and operational leasing of office machinery and equipment without operator',
    category: 'N',
  },
  {
    Code: '77309',
    Description: 'Renting and leasing of other machinery, equipment and tangible goods n.e.c.',
    category: 'N',
  },
  {
    Code: '77400',
    Description: 'Leasing of intellectual property and similar products, except copyrighted works',
    category: 'N',
  },
  {
    Code: '78100',
    Description: 'Activities of employment placement agencies',
    category: 'N',
  },
  {
    Code: '78200',
    Description: 'Temporary employment agency activities',
    category: 'N',
  },
  {
    Code: '78300',
    Description: 'Provision of human resources for client businesses',
    category: 'N',
  },
  {
    Code: '79110',
    Description: 'Travel agency activities',
    category: 'N',
  },
  {
    Code: '79120',
    Description: 'Tour operator activities',
    category: 'N',
  },
  {
    Code: '79900',
    Description: 'Other reservation service and related activities',
    category: 'N',
  },
  {
    Code: '80100',
    Description: 'Private security activities',
    category: 'N',
  },
  {
    Code: '80200',
    Description: 'Security systems service activities',
    category: 'N',
  },
  {
    Code: '80300',
    Description: 'Investigation and detective activities',
    category: 'N',
  },
  {
    Code: '81100',
    Description: 'Combined facilities support activities',
    category: 'N',
  },
  {
    Code: '81210',
    Description: 'General cleaning of buildings',
    category: 'N',
  },
  {
    Code: '81291',
    Description: 'Cleaning of buildings of all types',
    category: 'N',
  },
  {
    Code: '81292',
    Description: 'Swimming pool cleaning and maintenance services',
    category: 'N',
  },
  {
    Code: '81293',
    Description: 'Cleaning of industrial machinery',
    category: 'N',
  },
  {
    Code: '81294',
    Description: 'Cleaning of trains, buses, planes',
    category: 'N',
  },
  {
    Code: '81295',
    Description: 'Cleaning of pest control services not in connection with agriculture',
    category: 'N',
  },
  {
    Code: '81296',
    Description: 'Disinfecting and exterminating activities',
    category: 'N',
  },
  {
    Code: '81297',
    Description: 'Cleaning of sea tankers',
    category: 'N',
  },
  {
    Code: '81299',
    Description: 'Other building and industrial cleaning activities, n.e.c.',
    category: 'N',
  },
  {
    Code: '81300',
    Description: 'Landscape care and maintenance service activities',
    category: 'N',
  },
  {
    Code: '82110',
    Description: 'Combined office administrative service activities',
    category: 'N',
  },
  {
    Code: '82191',
    Description: 'Document preparation, editing and/or proofreading',
    category: 'N',
  },
  {
    Code: '82192',
    Description: 'Typing, word processing or desktop publishing',
    category: 'N',
  },
  {
    Code: '82193',
    Description: 'Secretarial support services',
    category: 'N',
  },
  {
    Code: '82194',
    Description: 'Transcription of documents and other secretarial services',
    category: 'N',
  },
  {
    Code: '82195',
    Description: 'Provision of mailbox rental and other postal and mailing services',
    category: 'N',
  },
  {
    Code: '82196',
    Description: 'Photocopying, duplicating, blueprinting',
    category: 'N',
  },
  {
    Code: '82199',
    Description:
      'Photocopying, document preparation and other specialized office support activities n.e.c.',
    category: 'N',
  },
  {
    Code: '82200',
    Description: 'Activities of call centres',
    category: 'N',
  },
  {
    Code: '82301',
    Description: 'Organization, promotions and/or management of event',
    category: 'N',
  },
  {
    Code: '82302',
    Description: 'Meeting, incentive, convention, exhibition (MICE)',
    category: 'N',
  },
  {
    Code: '82910',
    Description: 'Activities of collection agencies and credit bureaus',
    category: 'N',
  },
  {
    Code: '82920',
    Description:
      'Packaging activities on a fee or contract basis, whether or not these involve an automated process',
    category: 'N',
  },
  {
    Code: '82990',
    Description: 'Other business support service activities n.e.c.',
    category: 'N',
  },
  {
    Code: '84111',
    Description: 'General (overall) public administration activities',
    category: 'O',
  },
  {
    Code: '84112',
    Description: 'Ancillary service activities for the government as a whole',
    category: 'O',
  },
  {
    Code: '84121',
    Description: 'Administrative educational services',
    category: 'O',
  },
  {
    Code: '84122',
    Description: 'Administrative health care services',
    category: 'O',
  },
  {
    Code: '84123',
    Description: 'Administrative housing and local government services',
    category: 'O',
  },
  {
    Code: '84124',
    Description: 'Administrative recreational, cultural, arts and sports services',
    category: 'O',
  },
  {
    Code: '84125',
    Description: 'Administrative religious affairs services',
    category: 'O',
  },
  {
    Code: '84126',
    Description: 'Administrative welfare services',
    category: 'O',
  },
  {
    Code: '84129',
    Description: 'Other community and social affairs services',
    category: 'O',
  },
  {
    Code: '84131',
    Description: 'Domestic and international trade affairs',
    category: 'O',
  },
  {
    Code: '84132',
    Description: 'Agriculture and rural development affairs',
    category: 'O',
  },
  {
    Code: '84133',
    Description: 'Primary industries affairs',
    category: 'O',
  },
  {
    Code: '84134',
    Description: 'Public works affairs',
    category: 'O',
  },
  {
    Code: '84135',
    Description: 'Transport affairs',
    category: 'O',
  },
  {
    Code: '84136',
    Description: 'Energy, telecommunication and postal affairs',
    category: 'O',
  },
  {
    Code: '84137',
    Description: 'Tourism affairs',
    category: 'O',
  },
  {
    Code: '84138',
    Description: 'Human resource affairs',
    category: 'O',
  },
  {
    Code: '84139',
    Description:
      'Other regulation of and contribution to more efficient operation of businesses n.e.c.',
    category: 'O',
  },
  {
    Code: '84210',
    Description: 'Foreign affairs',
    category: 'O',
  },
  {
    Code: '84220',
    Description: 'Military and civil defence services',
    category: 'O',
  },
  {
    Code: '84231',
    Description: 'Police service',
    category: 'O',
  },
  {
    Code: '84232',
    Description: 'Prison service',
    category: 'O',
  },
  {
    Code: '84233',
    Description: 'Immigration service',
    category: 'O',
  },
  {
    Code: '84234',
    Description: 'National registration service',
    category: 'O',
  },
  {
    Code: '84235',
    Description: 'Judiciary and legal service',
    category: 'O',
  },
  {
    Code: '84236',
    Description: 'Firefighting and fire prevention',
    category: 'O',
  },
  {
    Code: '84239',
    Description: 'Other public order and safety affairs related services',
    category: 'O',
  },
  {
    Code: '84300',
    Description: 'Compulsory social security activities e.g. SOCSO',
    category: 'O',
  },
  {
    Code: '85101',
    Description: 'Pre-primary education (Public)',
    category: 'P',
  },
  {
    Code: '85102',
    Description: 'Pre-primary education (Private)',
    category: 'P',
  },
  {
    Code: '85103',
    Description: 'Primary education (Public)',
    category: 'P',
  },
  {
    Code: '85104',
    Description: 'Primary education (Private)',
    category: 'P',
  },
  {
    Code: '85211',
    Description: 'General school secondary education (Public)',
    category: 'P',
  },
  {
    Code: '85212',
    Description: 'General school secondary education (Private)',
    category: 'P',
  },
  {
    Code: '85221',
    Description: 'Technical and vocational education below the level of higher education (Public)',
    category: 'P',
  },
  {
    Code: '85222',
    Description: 'Technical and vocational education below the level of higher education (Private)',
    category: 'P',
  },
  {
    Code: '85301',
    Description: 'College and university education (Public)',
    category: 'P',
  },
  {
    Code: '85302',
    Description: 'College and university education (Private)',
    category: 'P',
  },
  {
    Code: '85411',
    Description: 'Sports instruction',
    category: 'P',
  },
  {
    Code: '85412',
    Description: 'Martial arts instruction',
    category: 'P',
  },
  {
    Code: '85419',
    Description: 'Any other sports and recreation education n.e.c',
    category: 'P',
  },
  {
    Code: '85421',
    Description: 'Music and dancing school',
    category: 'P',
  },
  {
    Code: '85429',
    Description: 'Any other cultural education n.e.c.',
    category: 'P',
  },
  {
    Code: '85491',
    Description: 'Tuition centre',
    category: 'P',
  },
  {
    Code: '85492',
    Description: 'Driving school',
    category: 'P',
  },
  {
    Code: '85493',
    Description: 'Religious instruction',
    category: 'P',
  },
  {
    Code: '85494',
    Description: 'Computer training',
    category: 'P',
  },
  {
    Code: '85499',
    Description: 'Others education n.e.c',
    category: 'P',
  },
  {
    Code: '85500',
    Description: 'Educational support services for provision of non-instructional services',
    category: 'P',
  },
  {
    Code: '86101',
    Description: 'Hospital activities',
    category: 'Q',
  },
  {
    Code: '86102',
    Description: 'Maternity home services (outside hospital)',
    category: 'Q',
  },
  {
    Code: '86201',
    Description: 'General medical services',
    category: 'Q',
  },
  {
    Code: '86202',
    Description: 'Specialized medical services',
    category: 'Q',
  },
  {
    Code: '86203',
    Description: 'Dental services',
    category: 'Q',
  },
  {
    Code: '86901',
    Description: 'Dialysis Centres',
    category: 'Q',
  },
  {
    Code: '86902',
    Description: 'Medical laboratories',
    category: 'Q',
  },
  {
    Code: '86903',
    Description: 'Physiotherapy and occupational therapy service',
    category: 'Q',
  },
  {
    Code: '86904',
    Description: 'Acupuncture services',
    category: 'Q',
  },
  {
    Code: '86905',
    Description: 'Herbalist and homeopathy services',
    category: 'Q',
  },
  {
    Code: '86906',
    Description: 'Ambulance services',
    category: 'Q',
  },
  {
    Code: '86909',
    Description: 'Other human health services n.e.c.',
    category: 'Q',
  },
  {
    Code: '87101',
    Description: 'Homes for the elderly with nursing care',
    category: 'Q',
  },
  {
    Code: '87102',
    Description: 'Nursing homes',
    category: 'Q',
  },
  {
    Code: '87103',
    Description: 'Palliative or hospices',
    category: 'Q',
  },
  {
    Code: '87201',
    Description: 'Drug rehabilitation centres',
    category: 'Q',
  },
  {
    Code: '87209',
    Description: 'Other residential care activities for mental retardation n.e.c.',
    category: 'Q',
  },
  {
    Code: '87300',
    Description: 'Residential care activities for the elderly and disabled',
    category: 'Q',
  },
  {
    Code: '87901',
    Description: 'Orphanages',
    category: 'Q',
  },
  {
    Code: '87902',
    Description: 'Welfare homes services',
    category: 'Q',
  },
  {
    Code: '87909',
    Description: 'Other residential care activities n.e.c.',
    category: 'Q',
  },
  {
    Code: '88101',
    Description: 'Day-care activities for the elderly or for handicapped adults',
    category: 'Q',
  },
  {
    Code: '88109',
    Description: 'Others social work activities without accommodation for the elderly and disabled',
    category: 'Q',
  },
  {
    Code: '88901',
    Description: 'Counselling service',
    category: 'Q',
  },
  {
    Code: '88902',
    Description: 'Child day-care activities',
    category: 'Q',
  },
  {
    Code: '88909',
    Description: 'Other social work activities without accommodation n.e.c.',
    category: 'Q',
  },
  {
    Code: '90001',
    Description: 'Theatrical producer, singer group band and orchestra entertainment services',
    category: 'R',
  },
  {
    Code: '90002',
    Description: 'Operation of concert and theatre halls and other arts facilities',
    category: 'R',
  },
  {
    Code: '90003',
    Description: 'Activities of sculptors, painters, cartoonists, engravers, etchers',
    category: 'R',
  },
  {
    Code: '90004',
    Description: 'Activities of individual writers, for all subjects',
    category: 'R',
  },
  {
    Code: '90005',
    Description: 'Activities of independent journalists',
    category: 'R',
  },
  {
    Code: '90006',
    Description: 'Restoring of works of art such as painting',
    category: 'R',
  },
  {
    Code: '90007',
    Description:
      'Activities of producers or entrepreneurs of arts live events, with or without facilities',
    category: 'R',
  },
  {
    Code: '90009',
    Description: 'Creative, arts and entertainment activities n.e.c.',
    category: 'R',
  },
  {
    Code: '91011',
    Description: 'Documentation and information activities of libraries of all kinds',
    category: 'R',
  },
  {
    Code: '91012',
    Description: 'Stock photo libraries and services',
    category: 'R',
  },
  {
    Code: '91021',
    Description: 'Operation of museums of all kinds',
    category: 'R',
  },
  {
    Code: '91022',
    Description: 'Operation of historical sites and buildings',
    category: 'R',
  },
  {
    Code: '91031',
    Description: 'Operation of botanical and zoological gardens',
    category: 'R',
  },
  {
    Code: '91032',
    Description: 'Operation of nature reserves, including wildlife preservation',
    category: 'R',
  },
  {
    Code: '92000',
    Description: 'GAMBLING AND BETTING ACTIVITIES',
    category: 'R',
  },
  {
    Code: '93111',
    Description: 'Football, hockey, cricket, baseball, badminton, futsal, paintball',
    category: 'R',
  },
  {
    Code: '93112',
    Description: 'Racetracks for auto',
    category: 'R',
  },
  {
    Code: '93113',
    Description: 'Equestrian clubs',
    category: 'R',
  },
  {
    Code: '93114',
    Description: 'Swimming pools and stadiums, ice-skating arenas',
    category: 'R',
  },
  {
    Code: '93115',
    Description: 'Track and field stadium',
    category: 'R',
  },
  {
    Code: '93116',
    Description: 'Golf courses',
    category: 'R',
  },
  {
    Code: '93117',
    Description: 'Bowling centre',
    category: 'R',
  },
  {
    Code: '93118',
    Description: 'Fitness centres',
    category: 'R',
  },
  {
    Code: '93119',
    Description:
      'Organization and operation of outdoor or indoor sports events for professionals or amateurs by organizations with own facilities',
    category: 'R',
  },
  {
    Code: '93120',
    Description: 'The operation of sports clubs such as football club, bowling club, swimming club',
    category: 'R',
  },
  {
    Code: '93191',
    Description:
      'Activities of producers or promoters of sports events, with or without facilities',
    category: 'R',
  },
  {
    Code: '93192',
    Description: 'Activities of sports leagues and regulating bodies',
    category: 'R',
  },
  {
    Code: '93193',
    Description: 'Activities of related to promotion of sporting events',
    category: 'R',
  },
  {
    Code: '93199',
    Description: 'Other sports activities n.e.c.',
    category: 'R',
  },
  {
    Code: '93210',
    Description: 'Activities of amusement parks and theme parks',
    category: 'R',
  },
  {
    Code: '93291',
    Description: 'Activities of recreation parks and beaches',
    category: 'R',
  },
  {
    Code: '93292',
    Description: 'Operation of recreational transport facilities',
    category: 'R',
  },
  {
    Code: '93293',
    Description:
      'Renting of leisure and pleasure equipment as an integral part of recreational facilities',
    category: 'R',
  },
  {
    Code: '93294',
    Description: 'Operation of fairs and shows of a recreational nature',
    category: 'R',
  },
  {
    Code: '93295',
    Description: 'Operation of discotheques and dance floors',
    category: 'R',
  },
  {
    Code: '93296',
    Description:
      'Activities of producers or entrepreneurs of live events other than arts or sports events, with or without facilities',
    category: 'R',
  },
  {
    Code: '93297',
    Description: 'Cyber Café/Internet Centre',
    category: 'R',
  },
  {
    Code: '93299',
    Description: 'Any other amusement and recreation activities n.e.c.',
    category: 'R',
  },
  {
    Code: '94110',
    Description: 'Activities of business and employers membership organizations',
    category: 'S',
  },
  {
    Code: '94120',
    Description: 'Activities of professional membership organizations',
    category: 'S',
  },
  {
    Code: '94200',
    Description: 'Activities of trade unions',
    category: 'S',
  },
  {
    Code: '94910',
    Description: 'Activities of religious organizations',
    category: 'S',
  },
  {
    Code: '94920',
    Description: 'Activities of political organizations',
    category: 'S',
  },
  {
    Code: '94990',
    Description: 'Activities of other membership organizations n.e.c.',
    category: 'S',
  },
  {
    Code: '95111',
    Description: 'Repair of electronic equipment',
    category: 'S',
  },
  {
    Code: '95112',
    Description: 'Repair and maintenance of computer terminals',
    category: 'S',
  },
  {
    Code: '95113',
    Description: "Repair and maintenance of hand-held computers (PDA's)",
    category: 'S',
  },
  {
    Code: '95121',
    Description: 'Repair and maintenance of cordless telephones',
    category: 'S',
  },
  {
    Code: '95122',
    Description: 'Repair and maintenance of cellular phones',
    category: 'S',
  },
  {
    Code: '95123',
    Description: 'Repair and maintenance of carrier equipment modems',
    category: 'S',
  },
  {
    Code: '95124',
    Description: 'Repair and maintenance of fax machines',
    category: 'S',
  },
  {
    Code: '95125',
    Description: 'Repair and maintenance of communications transmission equipment',
    category: 'S',
  },
  {
    Code: '95126',
    Description: 'Repair and maintenance of two-way radios',
    category: 'S',
  },
  {
    Code: '95127',
    Description: 'Repair and maintenance of commercial TV and video cameras',
    category: 'S',
  },
  {
    Code: '95211',
    Description: 'Repair and maintenance of television, radio receivers',
    category: 'S',
  },
  {
    Code: '95212',
    Description: 'Repair and maintenance of VCR/DVD/VCD',
    category: 'S',
  },
  {
    Code: '95213',
    Description: 'Repair and maintenance of CD players',
    category: 'S',
  },
  {
    Code: '95214',
    Description: 'Repair and maintenance of household-type video cameras',
    category: 'S',
  },
  {
    Code: '95221',
    Description: 'Repair and servicing of household appliances',
    category: 'S',
  },
  {
    Code: '95222',
    Description: 'Repair and servicing of home and garden equipment',
    category: 'S',
  },
  {
    Code: '95230',
    Description: 'Repair of footwear and leather goods',
    category: 'S',
  },
  {
    Code: '95240',
    Description: 'Repair of furniture and home furnishings',
    category: 'S',
  },
  {
    Code: '95291',
    Description: 'Repair of bicycles',
    category: 'S',
  },
  {
    Code: '95292',
    Description: 'Repair and alteration of clothing',
    category: 'S',
  },
  {
    Code: '95293',
    Description: 'Repair and alteration of jewellery',
    category: 'S',
  },
  {
    Code: '95294',
    Description: 'Repair of watches, clocks and their parts',
    category: 'S',
  },
  {
    Code: '95295',
    Description: 'Repair of sporting goods',
    category: 'S',
  },
  {
    Code: '95296',
    Description: 'Repair of musical instruments',
    category: 'S',
  },
  {
    Code: '95299',
    Description: 'Repair of other personal and household goods n.e.c.',
    category: 'S',
  },
  {
    Code: '96011',
    Description: 'Laundering and dry-cleaning, pressing',
    category: 'S',
  },
  {
    Code: '96012',
    Description:
      "Carpet and rug shampooing, and drapery and curtain cleaning, whether on clients' premises or not",
    category: 'S',
  },
  {
    Code: '96013',
    Description: 'Provision of linens, work uniforms and related items by laundries',
    category: 'S',
  },
  {
    Code: '96014',
    Description: 'Diaper supply services',
    category: 'S',
  },
  {
    Code: '96020',
    Description: 'Hairdressing and other beauty treatment',
    category: 'S',
  },
  {
    Code: '96031',
    Description:
      "Preparing the dead for burial or cremation and embalming and morticians' services",
    category: 'S',
  },
  {
    Code: '96032',
    Description: 'Providing burial or cremation services',
    category: 'S',
  },
  {
    Code: '96033',
    Description: 'Rental of equipped space in funeral parlours',
    category: 'S',
  },
  {
    Code: '96034',
    Description: 'Rental or sale of graves',
    category: 'S',
  },
  {
    Code: '96035',
    Description: 'Maintenance of graves and mausoleums',
    category: 'S',
  },
  {
    Code: '96091',
    Description: 'Activities of sauna, steam baths, massage salons',
    category: 'S',
  },
  {
    Code: '96092',
    Description: "Astrological and spiritualists' activities",
    category: 'S',
  },
  {
    Code: '96093',
    Description:
      'Social activities such as escort services, dating services, services of marriage bureaux',
    category: 'S',
  },
  {
    Code: '96094',
    Description: 'Pet care services',
    category: 'S',
  },
  {
    Code: '96095',
    Description: 'Genealogical organizations',
    category: 'S',
  },
  {
    Code: '96096',
    Description: 'Shoe shiners, porters, valet car parkers',
    category: 'S',
  },
  {
    Code: '96097',
    Description: 'Concession operation of coin-operated personal service machines',
    category: 'S',
  },
  {
    Code: '96099',
    Description: 'Other service activities n.e.c.',
    category: 'S',
  },
  {
    Code: '97000',
    Description: 'Activities of households as employers of domestic personnel',
    category: 'T',
  },
  {
    Code: '98100',
    Description: 'Undifferentiated goods-producing activities of private households for own use',
    category: 'T',
  },
  {
    Code: '98200',
    Description: 'Undifferentiated service-producing activities of private households for own use',
    category: 'T',
  },
  {
    Code: '99000',
    Description: 'Activities of extraterritorial organization and bodies',
    category: 'U',
  },
  {
    Code: '16100',
    Description: 'Sawmilling and planning of wood',
    category: 'C',
  },
  {
    Code: '16212',
    Description: 'Manufacture of particle board and fibreboard',
    category: 'C',
  },
  {
    Code: '47411',
    Description: 'Retail sale of computers, computer equipment and supplies',
    category: 'G',
  },
  {
    Code: '47520',
    Description: 'Retail sale of construction materials, hardware, paints and glass',
    category: 'G',
  },
  {
    Code: '47620',
    Description:
      'Retail sale of musical records, audio tapes, compact discs , cassettes, video tapes, VCDs and DVDs, blank tapes and discs',
    category: 'G',
  },
  {
    Code: '47892',
    Description: 'Retail sale of books via stalls or markets',
    category: 'G',
  },
  {
    Code: '47991',
    Description: 'Retail sale of any kind of product by direct sales or door-to-door sales persons',
    category: 'G',
  },
  {
    Code: '56101',
    Description: 'Restaurants and restaurant cum night clubs',
    category: 'I',
  },
  {
    Code: '56102',
    Description: 'Cafeterias/canteens',
    category: 'I',
  },
];

export const classificationList = [
  {
    Code: '001',
    Description: 'Breastfeeding equipment ',
  },
  {
    Code: '002',
    Description: 'Child care centres and kindergartens fees',
  },
  {
    Code: '003',
    Description: 'Computer, smartphone or tablet',
  },
  {
    Code: '004',
    Description: 'Consolidated e-Invoice ',
  },
  {
    Code: '005',
    Description:
      'Construction materials (as specified under Fourth Schedule of the Lembaga Pembangunan Industri Pembinaan Malaysia Act 1994)',
  },
  {
    Code: '006',
    Description: 'Disbursement ',
  },
  {
    Code: '007',
    Description: 'Donation',
  },
  {
    Code: '008',
    Description: 'e-Commerce - e-Invoice to buyer / purchaser',
  },
  {
    Code: '009',
    Description: 'e-Commerce - Self-billed e-Invoice to seller, logistics, etc. ',
  },
  {
    Code: '010',
    Description: 'Education fees',
  },
  {
    Code: '011',
    Description: 'Goods on consignment (Consignor)',
  },
  {
    Code: '012',
    Description: 'Goods on consignment (Consignee)',
  },
  {
    Code: '013',
    Description: 'Gym membership',
  },
  {
    Code: '014',
    Description: 'Insurance - Education and medical benefits',
  },
  {
    Code: '015',
    Description: 'Insurance - Takaful or life insurance',
  },
  {
    Code: '016',
    Description: 'Interest and financing expenses',
  },
  {
    Code: '017',
    Description: 'Internet subscription ',
  },
  {
    Code: '018',
    Description: 'Land and building',
  },
  {
    Code: '019',
    Description:
      'Medical examination for learning disabilities and early intervention or rehabilitation treatments of learning disabilities',
  },
  {
    Code: '020',
    Description: 'Medical examination or vaccination expenses',
  },
  {
    Code: '021',
    Description: 'Medical expenses for serious diseases',
  },
  {
    Code: '022',
    Description: 'Others',
  },
  {
    Code: '023',
    Description: 'Petroleum operations (as defined in Petroleum (Income Tax) Act 1967)',
  },
  {
    Code: '024',
    Description: 'Private retirement scheme or deferred annuity scheme ',
  },
  {
    Code: '025',
    Description: 'Motor vehicle',
  },
  {
    Code: '026',
    Description:
      'Subscription of books / journals / magazines / newspapers / other similar publications',
  },
  {
    Code: '027',
    Description: 'Reimbursement ',
  },
  {
    Code: '028',
    Description: 'Rental of motor vehicle',
  },
  {
    Code: '029',
    Description:
      'EV charging facilities (Installation, rental, sale / purchase or subscription fees) ',
  },
  {
    Code: '030',
    Description: 'Repair and maintenance',
  },
  {
    Code: '031',
    Description: 'Research and development ',
  },
  {
    Code: '032',
    Description: 'Foreign income ',
  },
  {
    Code: '033',
    Description: 'Self-billed - Betting and gaming ',
  },
  {
    Code: '034',
    Description: 'Self-billed - Importation of goods ',
  },
  {
    Code: '035',
    Description: 'Self-billed - Importation of services',
  },
  {
    Code: '036',
    Description: 'Self-billed - Others',
  },
  {
    Code: '037',
    Description: 'Self-billed - Monetary payment to agents, dealers or distributors ',
  },
  {
    Code: '038',
    Description:
      'Sports equipment, rental / entry fees for sports facilities, registration in sports competition or sports training fees imposed by associations / sports clubs / companies registered with the Sports Commissioner or Companies Commission of Malaysia and carrying out sports activities as listed under the Sports Development Act 1997',
  },
  {
    Code: '039',
    Description: 'Supporting equipment for disabled person',
  },
  {
    Code: '040',
    Description: 'Voluntary contribution to approved provident fund ',
  },
  {
    Code: '041',
    Description: 'Dental examination or treatment',
  },
  {
    Code: '042',
    Description: 'Fertility treatment',
  },
  {
    Code: '043',
    Description: 'Treatment and home care nursing, daycare centres and residential care centers',
  },
  {
    Code: '044',
    Description: 'Vouchers, gift cards, loyalty points, etc',
  },
  {
    Code: '045',
    Description: 'Self-billed - Non-monetary payment to agents, dealers or distributors',
  },
];

export const invoiceTypeList = [
  {
    Code: '01',
    Description: 'Invoice',
  },
  {
    Code: '02',
    Description: 'Credit Note',
  },
  {
    Code: '03',
    Description: 'Debit Note',
  },
  {
    Code: '04',
    Description: 'Refund Note',
  },
  {
    Code: '11',
    Description: 'Self-billed Invoice',
  },
  {
    Code: '12',
    Description: 'Self-billed Credit Note',
  },
  {
    Code: '13',
    Description: 'Self-billed Debit Note',
  },
  {
    Code: '14',
    Description: 'Self-billed Refund Note',
  },
];

export const paymentMethodList = [
  {
    'Code': '01',
    'Payment Method': 'Cash',
  },
  {
    'Code': '02',
    'Payment Method': 'Cheque',
  },
  {
    'Code': '03',
    'Payment Method': 'Bank Transfer',
  },
  {
    'Code': '04',
    'Payment Method': 'Credit Card',
  },
  {
    'Code': '05',
    'Payment Method': 'Debit Card',
  },
  {
    'Code': '06',
    'Payment Method': 'e-Wallet / Digital Wallet',
  },
  {
    'Code': '07',
    'Payment Method': 'Digital Bank',
  },
  {
    'Code': '08',
    'Payment Method': 'Others',
  },
];

export const taxTypeList = [
  {
    Code: '01',
    Description: 'Sales Tax',
  },
  {
    Code: '02',
    Description: 'Service Tax',
  },
  {
    Code: '03',
    Description: 'Tourism Tax',
  },
  {
    Code: '04',
    Description: 'High-Value Goods Tax',
  },
  {
    Code: '05',
    Description: 'Sales Tax on Low Value Goods',
  },
  {
    Code: '06',
    Description: 'Not Applicable',
  },
  {
    Code: 'E',
    Description: 'Tax Exemption',
  },
];

export const unitTypeList = [
  {
    Code: 'C81',
    Name: 'radian',
    Description: '',
  },
  {
    Code: 'C25',
    Name: 'milliradian',
    Description: '',
  },
  {
    Code: 'B97',
    Name: 'microradian',
    Description: '',
  },
  {
    Code: 'DD',
    Name: 'degree [unit of angle]',
    Description: '',
  },
  {
    Code: 'D61',
    Name: 'minute [unit of angle]',
    Description: '',
  },
  {
    Code: 'D62',
    Name: 'second [unit of angle]',
    Description: '',
  },

  {
    Code: 'A91',
    Name: 'gon',
    Description: 'Synonym: grade',
  },
  {
    Code: 'M43',
    Name: 'mil',
    Description:
      'Unit to indicate an angle at military zone, equal to the 6400th part of the full circle of the 360° or 2·p·rad.',
  },
  {
    Code: 'M44',
    Name: 'revolution',
    Description:
      'Unit to identify an angle of the full circle of 360° or 2·p·rad (Refer ISO/TC12 SI Guide).',
  },
  {
    Code: 'D27',
    Name: 'steradian',
    Description: '',
  },
  {
    Code: 'H57',
    Name: 'inch per two pi radiant',
    Description: '',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'E96',
    Name: 'degree per second',
    Description: '',
  },
  {
    Code: 'H27',
    Name: 'degree per metre',
    Description: '',
  },
  {
    Code: 'M55',
    Name: 'metre per radiant',
    Description:
      'Unit of the translation factor for implementation from rotation to linear movement.',
  },
  {
    Code: 'DMT',
    Name: 'decimetre',
    Description: '',
  },
  {
    Code: 'CMT',
    Name: 'centimetre',
    Description: '',
  },
  {
    Code: '4H',
    Name: 'micrometre (micron)',
    Description: '',
  },
  {
    Code: 'MMT',
    Name: 'millimetre',
    Description: '',
  },
  {
    Code: 'HMT',
    Name: 'hectometre',
    Description: '',
  },

  {
    Code: 'KMT',
    Name: 'kilometre',
    Description: '',
  },
  {
    Code: 'C45',
    Name: 'nanometre',
    Description: '',
  },
  {
    Code: 'C52',
    Name: 'picometre',
    Description: '',
  },
  {
    Code: 'A71',
    Name: 'femtometre',
    Description: '',
  },
  {
    Code: 'A45',
    Name: 'decametre',
    Description: '',
  },
  {
    Code: 'NMI',
    Name: 'nautical mile',
    Description: '',
  },
  {
    Code: 'A11',
    Name: 'angstrom',
    Description: '',
  },
  {
    Code: 'A12',
    Name: 'astronomical unit',
    Description: '',
  },
  {
    Code: 'C63',
    Name: 'parsec',
    Description: '',
  },
  {
    Code: 'F52',
    Name: 'metre per kelvin',
    Description: '',
  },
  {
    Code: 'F50',
    Name: 'micrometre per kelvin',
    Description: '',
  },
  {
    Code: 'F51',
    Name: 'centimetre per kelvin',
    Description: '',
  },
  {
    Code: 'G06',
    Name: 'millimetre per bar',
    Description: '',
  },
  {
    Code: 'H84',
    Name: 'gram millimetre',
    Description: '',
  },
  {
    Code: 'G04',
    Name: 'centimetre per bar',
    Description: '',
  },
  {
    Code: 'G05',
    Name: 'metre per bar',
    Description: '',
  },
  {
    Code: 'H79',
    Name: 'French gauge',
    Description:
      'A unit of distance used for measuring the diameter of small tubes such as urological instruments and catheters.',
  },
  {
    Code: '',
    Name: '',
    Description: 'Synonym: French, Charrière, Charrière gauge',
  },
  {
    Code: 'AK',
    Name: 'fathom',
    Description: '',
  },
  {
    Code: 'X1',
    Name: "Gunter's chain",
    Description: 'A unit of distance used or formerly used by British surveyors.',
  },
  {
    Code: 'INH',
    Name: 'inch',
    Description: '',
  },
  {
    Code: 'M7',
    Name: 'micro-inch',
    Description: '',
  },
  {
    Code: 'FOT',
    Name: 'foot',
    Description: '',
  },
  {
    Code: 'YRD',
    Name: 'yard',
    Description: '',
  },
  {
    Code: 'SMI',
    Name: 'mile (statute mile)',
    Description: '',
  },
  {
    Code: '77',
    Name: 'milli-inch',
    Description: '',
  },
  {
    Code: 'B57',
    Name: 'light year',
    Description:
      'A unit of length defining the distance that light travels in a vacuum in one year.',
  },
  {
    Code: 'F49',
    Name: 'rod [unit of distance]',
    Description: 'A unit of distance equal to 5.5 yards (16 feet 6 inches).',
  },
  {
    Code: 'MAM',
    Name: 'megametre',
    Description: '',
  },
  {
    Code: 'K13',
    Name: 'foot per degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'K17',
    Name: 'foot per psi',
    Description: '',
  },
  {
    Code: 'K45',
    Name: 'inch per degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'K46',
    Name: 'inch per psi',
    Description: '',
  },
  {
    Code: 'L98',
    Name: 'yard per degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'L99',
    Name: 'yard per psi',
    Description: '',
  },
  {
    Code: 'M49',
    Name: 'chain (based on U.S. survey foot)',
    Description: 'Unit of the length according the Anglo-American system of units.',
  },
  {
    Code: 'M50',
    Name: 'furlong',
    Description:
      'Unit commonly used in Great Britain at rural distances: 1 furlong = 40 rods = 10 chains (UK) = 1/8 mile = 1/10 furlong = 220 yards = 660 foot.',
  },
  {
    Code: 'M51',
    Name: 'foot (U.S. survey) ',
    Description: 'Unit commonly used in the United States for ordnance survey.',
  },
  {
    Code: 'M52',
    Name: 'mile (based on U.S. survey foot) ',
    Description: 'Unit commonly used in the United States for ordnance survey.',
  },
  {
    Code: 'M53',
    Name: 'metre per pascal',
    Description: 'SI base unit metre divided by the derived SI unit pascal.',
  },
  {
    Code: 'MTK',
    Name: 'square metre',
    Description: '',
  },
  {
    Code: 'KMK',
    Name: 'square kilometre',
    Description: '',
  },
  {
    Code: 'H30',
    Name: 'square micrometre (square micron)',
    Description: '',
  },
  {
    Code: 'H59',
    Name: 'square metre per newton',
    Description: '',
  },
  {
    Code: 'DAA',
    Name: 'decare',
    Description: '',
  },
  {
    Code: 'CMK',
    Name: 'square centimetre',
    Description: '',
  },
  {
    Code: 'DMK',
    Name: 'square decimetre',
    Description: '',
  },
  {
    Code: 'H16',
    Name: 'square decametre',
    Description: 'Synonym: are',
  },
  {
    Code: 'H18',
    Name: 'square hectometre',
    Description: 'Synonym: hectare',
  },
  {
    Code: 'MMK',
    Name: 'square millimetre',
    Description: '',
  },

  {
    Code: 'INK',
    Name: 'square inch',
    Description: '',
  },
  {
    Code: 'FTK',
    Name: 'square foot',
    Description: '',
  },
  {
    Code: 'YDK',
    Name: 'square yard',
    Description: '',
  },
  {
    Code: 'MIK',
    Name: 'square mile (statute mile)',
    Description: '',
  },
  {
    Code: 'M48',
    Name: 'square mile (based on U.S. survey foot) ',
    Description: 'Unit of the area, which is mainly common in the agriculture and forestry.',
  },
  {
    Code: 'ACR',
    Name: 'acre',
    Description: '',
  },
  {
    Code: 'M47',
    Name: 'circular mil ',
    Description:
      'Unit of an area, of which the size is given by a diameter of length of 1 mm (0,001 in) based on the formula: area = p·(diameter/2)².',
  },
  {
    Code: 'MTQ',
    Name: 'cubic metre',
    Description: 'Synonym: metre cubed',
  },
  {
    Code: 'MAL',
    Name: 'megalitre',
    Description: '',
  },
  {
    Code: 'LTR',
    Name: 'litre',
    Description: '',
  },
  {
    Code: 'MMQ',
    Name: 'cubic millimetre',
    Description: '',
  },
  {
    Code: 'CMQ',
    Name: 'cubic centimetre',
    Description: '',
  },
  {
    Code: 'DMQ',
    Name: 'cubic decimetre',
    Description: '',
  },
  {
    Code: 'MLT',
    Name: 'millilitre',
    Description: '',
  },
  {
    Code: 'HLT',
    Name: 'hectolitre',
    Description: '',
  },
  {
    Code: 'CLT',
    Name: 'centilitre',
    Description: '',
  },
  {
    Code: 'DMA',
    Name: 'cubic decametre',
    Description: '',
  },
  {
    Code: 'H19',
    Name: 'cubic hectometre',
    Description: '',
  },
  {
    Code: 'H20',
    Name: 'cubic kilometre',
    Description: '',
  },
  {
    Code: 'M71',
    Name: 'cubic metre per pascal',
    Description:
      'Power of the SI base unit meter by exponent 3 divided by the derived SI base unit pascal.',
  },
  {
    Code: 'DLT',
    Name: 'decilitre',
    Description: '',
  },
  {
    Code: '4G',
    Name: 'microlitre',
    Description: '',
  },
  {
    Code: 'K6',
    Name: 'kilolitre',
    Description: '',
  },
  {
    Code: 'A44',
    Name: 'decalitre',
    Description: '',
  },
  {
    Code: 'G94',
    Name: 'cubic centimetre per bar',
    Description: '',
  },
  {
    Code: 'G95',
    Name: 'litre per bar',
    Description: '',
  },
  {
    Code: 'G96',
    Name: 'cubic metre per bar',
    Description: '',
  },
  {
    Code: 'G97',
    Name: 'millilitre per bar',
    Description: '',
  },

  {
    Code: 'INQ',
    Name: 'cubic inch',
    Description: 'Synonym: inch cubed',
  },
  {
    Code: 'FTQ',
    Name: 'cubic foot',
    Description: '',
  },
  {
    Code: 'YDQ',
    Name: 'cubic yard',
    Description: '',
  },
  {
    Code: 'GLI',
    Name: 'gallon (UK)',
    Description: '',
  },
  {
    Code: 'GLL',
    Name: 'gallon (US)',
    Description: '',
  },

  {
    Code: 'PTI',
    Name: 'pint (UK)',
    Description: '',
  },
  {
    Code: 'QTI',
    Name: 'quart (UK)',
    Description: '',
  },
  {
    Code: 'PTL',
    Name: 'liquid pint (US)',
    Description: '',
  },
  {
    Code: 'QTL',
    Name: 'liquid quart (US)',
    Description: '',
  },
  {
    Code: 'PTD',
    Name: 'dry pint (US)',
    Description: '',
  },
  {
    Code: 'OZI',
    Name: 'fluid ounce (UK)',
    Description: '',
  },

  {
    Code: 'J57',
    Name: 'barrel (UK petroleum)',
    Description: '',
  },
  {
    Code: 'K21',
    Name: 'cubic foot per degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'K23',
    Name: 'cubic foot per psi',
    Description: '',
  },
  {
    Code: 'L43',
    Name: 'peck (UK)',
    Description: '',
  },

  {
    Code: 'L84',
    Name: 'ton (UK shipping)',
    Description: '',
  },
  {
    Code: 'L86',
    Name: 'ton (US shipping)',
    Description: '',
  },
  {
    Code: 'M11',
    Name: 'cubic yard per degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'M14',
    Name: 'cubic yard per psi',
    Description: '',
  },
  {
    Code: 'OZA',
    Name: 'fluid ounce (US)',
    Description: '',
  },
  {
    Code: 'BUI',
    Name: 'bushel (UK)',
    Description: '',
  },
  {
    Code: 'BUA',
    Name: 'bushel (US)',
    Description: '',
  },
  {
    Code: 'BLL',
    Name: 'barrel (US)',
    Description: '',
  },
  {
    Code: 'BLD',
    Name: 'dry barrel (US)',
    Description: '',
  },
  {
    Code: 'GLD',
    Name: 'dry gallon (US)',
    Description: '',
  },
  {
    Code: 'QTD',
    Name: 'dry quart (US)',
    Description: '',
  },
  {
    Code: 'G26',
    Name: 'stere',
    Description: '',
  },
  {
    Code: 'G21',
    Name: 'cup [unit of volume]',
    Description: '',
  },
  {
    Code: 'G24',
    Name: 'tablespoon (US)',
    Description: '',
  },
  {
    Code: 'G25',
    Name: 'teaspoon (US)',
    Description: '',
  },
  {
    Code: 'G23',
    Name: 'peck',
    Description: '',
  },
  {
    Code: 'M67',
    Name: 'acre-foot (based on U.S. survey foot)',
    Description:
      'Unit of the volume, which is used in the United States to measure/gauge the capacity of reservoirs.',
  },
  {
    Code: 'M68',
    Name: 'cord (128 ft3)',
    Description:
      'Traditional unit of the volume of stacked firewood which has been measured with a cord.',
  },
  {
    Code: 'M69',
    Name: 'cubic mile (UK statute)',
    Description: 'Unit of volume according to the Imperial system of units.',
  },
  {
    Code: 'M70',
    Name: 'ton, register ',
    Description: 'Traditional unit of the cargo capacity.',
  },
  {
    Code: 'G27',
    Name: 'cubic centimetre per kelvin',
    Description: '',
  },
  {
    Code: 'G29',
    Name: 'cubic metre per kelvin',
    Description: '',
  },
  {
    Code: 'G28',
    Name: 'litre per kelvin',
    Description: '',
  },
  {
    Code: 'G30',
    Name: 'millilitre per kelvin',
    Description: '',
  },
  {
    Code: 'J36',
    Name: 'microlitre per litre',
    Description: '',
  },
  {
    Code: 'J87',
    Name: 'cubic centimetre per cubic metre',
    Description: '',
  },
  {
    Code: 'J91',
    Name: 'cubic decimetre per cubic metre',
    Description: '',
  },
  {
    Code: 'K62',
    Name: 'litre per litre',
    Description: '',
  },
  {
    Code: 'L19',
    Name: 'millilitre per litre',
    Description: '',
  },
  {
    Code: 'L21',
    Name: 'cubic millimetre per cubic metre',
    Description: '',
  },
  {
    Code: 'SEC',
    Name: 'second [unit of time]',
    Description: '',
  },
  {
    Code: 'MIN',
    Name: 'minute [unit of time]',
    Description: '',
  },
  {
    Code: 'HUR',
    Name: 'hour',
    Description: '',
  },
  {
    Code: 'DAY',
    Name: 'day',
    Description: '',
  },
  {
    Code: 'B52',
    Name: 'kilosecond',
    Description: '',
  },
  {
    Code: 'C26',
    Name: 'millisecond',
    Description: '',
  },
  {
    Code: 'H70',
    Name: 'picosecond',
    Description: '',
  },
  {
    Code: 'B98',
    Name: 'microsecond',
    Description: '',
  },
  {
    Code: 'C47',
    Name: 'nanosecond',
    Description: '',
  },
  {
    Code: 'WEE',
    Name: 'week',
    Description: '',
  },
  {
    Code: 'MON',
    Name: 'month',
    Description: 'Unit of time equal to 1/12 of a year of 365,25 days.',
  },
  {
    Code: 'ANN',
    Name: 'year',
    Description: 'Unit of time equal to 365,25 days.',
  },
  {
    Code: '',
    Name: '',
    Description: 'Synonym: Julian year',
  },
  {
    Code: 'D42',
    Name: 'tropical year',
    Description: 'Unit of time equal to about 365.242 19 days.',
  },
  {
    Code: '',
    Name: '',
    Description: 'Synonym: solar year',
  },
  {
    Code: 'L95',
    Name: 'common year',
    Description: '',
  },
  {
    Code: 'L96',
    Name: 'sidereal year',
    Description: '',
  },
  {
    Code: 'M56',
    Name: 'shake',
    Description: 'Unit for a very short period.',
  },
  {
    Code: '2A',
    Name: 'radian per second',
    Description: 'Refer ISO/TC12 SI Guide',
  },
  {
    Code: 'M46',
    Name: 'revolution per minute ',
    Description: 'Unit of the angular velocity.',
  },
  {
    Code: '2B',
    Name: 'radian per second squared',
    Description: 'Refer ISO/TC12 SI Guide',
  },
  {
    Code: 'M45',
    Name: 'degree [unit of angle] per second squared',
    Description:
      '360 part of a full circle divided by the power of the SI base unit second and the exponent 2.',
  },
  {
    Code: 'MTS',
    Name: 'metre per second',
    Description: '',
  },
  {
    Code: 'KNT',
    Name: 'knot',
    Description: '',
  },
  {
    Code: 'KMH',
    Name: 'kilometre per hour',
    Description: '',
  },
  {
    Code: 'C16',
    Name: 'millimetre per second',
    Description: '',
  },
  {
    Code: '2M',
    Name: 'centimetre per second',
    Description: '',
  },
  {
    Code: 'H49',
    Name: 'centimetre per hour',
    Description: '',
  },
  {
    Code: 'H81',
    Name: 'millimetre per minute',
    Description: '',
  },
  {
    Code: '2X',
    Name: 'metre per minute',
    Description: '',
  },
  {
    Code: 'M59',
    Name: 'metre per second pascal',
    Description:
      'SI base unit meter divided by the product of SI base unit second and the derived SI unit pascal.',
  },
  {
    Code: 'H66',
    Name: 'millimetre per year',
    Description: '',
  },
  {
    Code: 'H67',
    Name: 'millimetre per hour',
    Description: '',
  },
  {
    Code: 'FR',
    Name: 'foot per minute',
    Description: '',
  },
  {
    Code: 'IU',
    Name: 'inch per second',
    Description: '',
  },
  {
    Code: 'IU',
    Name: 'inch per second',
    Description: '',
  },
  {
    Code: 'FS',
    Name: 'foot per second',
    Description: '',
  },
  {
    Code: 'HM',
    Name: 'mile per hour (statute mile)',
    Description: '',
  },
  {
    Code: 'J84',
    Name: 'centimetre per second kelvin',
    Description: '',
  },
  {
    Code: 'J85',
    Name: 'centimetre per second bar',
    Description: '',
  },
  {
    Code: 'K14',
    Name: 'foot per hour',
    Description: '',
  },
  {
    Code: 'K18',
    Name: 'foot per second degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'K19',
    Name: 'foot per second psi',
    Description: '',
  },
  {
    Code: 'K47',
    Name: 'inch per second degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'K48',
    Name: 'inch per second psi',
    Description: '',
  },
  {
    Code: 'L12',
    Name: 'metre per second kelvin',
    Description: '',
  },
  {
    Code: 'L13',
    Name: 'metre per second bar',
    Description: '',
  },
  {
    Code: 'M22',
    Name: 'millilitre per square centimetre minute',
    Description: '',
  },
  {
    Code: 'M57',
    Name: 'mile per minute ',
    Description: 'Unit of velocity from the Imperial system of units.',
  },
  {
    Code: 'M58',
    Name: 'mile per second ',
    Description: 'Unit of the velocity from the Imperial system of units.',
  },
  {
    Code: 'M60',
    Name: 'metre per hour',
    Description: 'SI base unit metre divided by the unit hour.',
  },
  {
    Code: 'M61',
    Name: 'inch per year',
    Description:
      'Unit of the length according to the Anglo-American and Imperial system of units divided by the unit common year with 365 days.',
  },
  {
    Code: 'M62',
    Name: 'kilometre per second ',
    Description: '1000-fold of the SI base unit metre divided by the SI base unit second.',
  },
  {
    Code: 'M63',
    Name: 'inch per minute',
    Description:
      'Unit inch according to the Anglo-American and Imperial system of units divided by the unit minute.',
  },
  {
    Code: 'M64',
    Name: 'yard per second',
    Description:
      'Unit yard according to the Anglo-American and Imperial system of units divided by the SI base unit second.',
  },
  {
    Code: 'M65',
    Name: 'yard per minute',
    Description:
      'Unit yard according to the Anglo-American and Imperial system of units divided by the unit minute.',
  },
  {
    Code: 'M66',
    Name: 'yard per hour',
    Description:
      'Unit yard according to the Anglo-American and Imperial system of units divided by the unit hour.',
  },
  {
    Code: 'MSK',
    Name: 'metre per second squared',
    Description: '',
  },
  {
    Code: 'A76',
    Name: 'gal',
    Description: '',
  },
  {
    Code: 'C11',
    Name: 'milligal',
    Description: '',
  },
  {
    Code: 'M38',
    Name: 'kilometre per second squared',
    Description:
      '1000-fold of the SI base unit metre divided by the power of the SI base unit second by exponent 2.',
  },
  {
    Code: 'M39',
    Name: 'centimetre per second squared',
    Description:
      '0,01-fold of the SI base unit metre divided by the power of the SI base unit second by exponent 2.',
  },
  {
    Code: 'M41',
    Name: 'millimetre per second squared',
    Description:
      '0,001-fold of the SI base unit metre divided by the power of the SI base unit second by exponent 2.',
  },
  {
    Code: 'A73',
    Name: 'foot per second squared',
    Description: '',
  },
  {
    Code: 'IV',
    Name: 'inch per second squared',
    Description: '',
  },
  {
    Code: 'K40',
    Name: 'standard acceleration of free fall',
    Description: '',
  },
  {
    Code: 'M40',
    Name: 'yard per second squared',
    Description:
      'Unit of the length according to the Anglo-American and Imperial system of units divided by the power of the SI base unit second by exponent 2.',
  },
  {
    Code: 'M42',
    Name: 'mile (statute mile) per second squared',
    Description:
      'Unit of the length according to the Imperial system of units divided by the power of the SI base unit second by exponent 2.',
  },
  {
    Code: 'C92',
    Name: 'reciprocal metre',
    Description: '',
  },
  {
    Code: 'Q32',
    Name: 'femtolitre',
    Description: '',
  },
  {
    Code: 'Q33',
    Name: 'picolitre',
    Description: '',
  },
  {
    Code: 'Q34',
    Name: 'nanolitre',
    Description: '',
  },
  {
    Code: 'AWG',
    Name: 'american wire gauge',
    Description:
      'A unit of distance used for measuring the diameter of small tubes or wires such as the outer diameter od hypodermic or suture needles.',
  },
  {
    Code: 'NM3',
    Name: 'Normalised cubic metre',
    Description: 'Normalised cubic metre (temperature 0°C and pressure 101325 millibars )',
  },
  {
    Code: 'SM3',
    Name: 'Standard cubic metre',
    Description: 'Standard cubic metre (temperature 15°C and pressure 101325 millibars )',
  },
  {
    Code: 'HTZ',
    Name: 'hertz',
    Description: '',
  },
  {
    Code: 'KHZ',
    Name: 'kilohertz',
    Description: '',
  },
  {
    Code: 'MHZ',
    Name: 'megahertz',
    Description: '',
  },
  {
    Code: 'D29',
    Name: 'terahertz',
    Description: '',
  },
  {
    Code: 'A86',
    Name: 'gigahertz',
    Description: '',
  },
  {
    Code: 'MTZ',
    Name: 'milihertz',
    Description: 'A unit of frequency equal to 0.001 cycle per second',
  },
  {
    Code: 'H10',
    Name: 'reciprocal hour',
    Description: '',
  },
  {
    Code: 'H11',
    Name: 'reciprocal month',
    Description: '',
  },
  {
    Code: 'H09',
    Name: 'reciprocal year',
    Description: '',
  },
  {
    Code: 'H85',
    Name: 'reciprocal week',
    Description: '',
  },
  {
    Code: 'C97',
    Name: 'reciprocal second',
    Description: '',
  },
  {
    Code: 'RPS',
    Name: 'revolutions per second',
    Description: 'Refer ISO/TC12 SI Guide',
  },
  {
    Code: 'RPM',
    Name: 'revolutions per minute',
    Description: 'Refer ISO/TC12 SI Guide',
  },
  {
    Code: 'C94',
    Name: 'reciprocal minute',
    Description: '',
  },
  {
    Code: '2A',
    Name: 'radian per second',
    Description: 'Refer ISO/TC12 SI Guide',
  },
  {
    Code: 'C97',
    Name: 'reciprocal second',
    Description: '',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'A11',
    Name: 'angstrom',
    Description: '',
  },
  {
    Code: 'C92',
    Name: 'reciprocal metre',
    Description: '',
  },
  {
    Code: 'C50',
    Name: 'neper',
    Description: '',
  },
  {
    Code: '2N',
    Name: 'decibel',
    Description: '',
  },
  {
    Code: 'M72',
    Name: 'bel',
    Description: 'Logarithmic relationship to base 10.',
  },
  {
    Code: 'C97',
    Name: 'reciprocal second',
    Description: '',
  },
  {
    Code: 'C51',
    Name: 'neper per second',
    Description: '',
  },
  {
    Code: 'C50',
    Name: 'neper',
    Description: '',
  },
  {
    Code: 'KGM',
    Name: 'kilogram',
    Description: 'A unit of mass equal to one thousand grams.',
  },
  {
    Code: 'MC',
    Name: 'microgram',
    Description: '',
  },
  {
    Code: 'DJ',
    Name: 'decagram',
    Description: '',
  },
  {
    Code: 'DG',
    Name: 'decigram',
    Description: '',
  },
  {
    Code: 'GRM',
    Name: 'gram',
    Description: '',
  },
  {
    Code: 'CGM',
    Name: 'centigram',
    Description: '',
  },
  {
    Code: 'TNE',
    Name: 'tonne (metric ton)',
    Description: 'Synonym: metric ton',
  },
  {
    Code: 'DTN',
    Name: 'decitonne',
    Description: 'Synonym: centner, metric 100 kg; quintal, metric 100 kg',
  },
  {
    Code: 'MGM',
    Name: 'milligram',
    Description: '',
  },
  {
    Code: 'HGM',
    Name: 'hectogram',
    Description: '',
  },
  {
    Code: 'KTN',
    Name: 'kilotonne',
    Description: '',
  },
  {
    Code: '2U',
    Name: 'megagram',
    Description: '',
  },
  {
    Code: 'LBR',
    Name: 'pound',
    Description: '',
  },
  {
    Code: 'GRN',
    Name: 'grain',
    Description: '',
  },
  {
    Code: 'ONZ',
    Name: 'ounce (avoirdupois)',
    Description: '',
  },
  {
    Code: 'CWI',
    Name: 'hundred weight (UK)',
    Description: '',
  },
  {
    Code: 'CWA',
    Name: 'hundred pound (cwt) / hundred weight (US)',
    Description: '',
  },
  {
    Code: 'LTN',
    Name: 'ton (UK) or long ton (US)',
    Description: 'Synonym: gross ton (2240 lb)',
  },
  {
    Code: 'STI',
    Name: 'stone (UK)',
    Description: '',
  },
  {
    Code: 'STN',
    Name: 'ton (US) or short ton (UK/US)',
    Description: 'Synonym: net ton (2000 lb)',
  },
  {
    Code: 'APZ',
    Name: 'troy ounce or apothecary ounce',
    Description: '',
  },
  {
    Code: 'F13',
    Name: 'slug',
    Description:
      'A unit of mass. One slug is the mass accelerated at 1 foot per second per second by a force of 1 pound.',
  },
  {
    Code: 'K64',
    Name: 'pound (avoirdupois) per degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'L69',
    Name: 'tonne per kelvin',
    Description: '',
  },
  {
    Code: 'L87',
    Name: 'ton short per degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'M85',
    Name: 'ton, assay',
    Description:
      'Non SI-conforming unit of the mass used in the mineralogy to determine the concentration of precious metals in ore according to the mass of the precious metal in milligrams in a sample of the mass of an assay sound (number of troy ounces in a short ton (1 000 lb)).',
  },
  {
    Code: 'M86',
    Name: 'pfund',
    Description: 'Outdated unit of the mass used in Germany.',
  },
  {
    Code: 'KMQ',
    Name: 'kilogram per cubic metre',
    Description:
      'A unit of weight expressed in kilograms of a substance that fills a volume of one cubic metre.',
  },
  {
    Code: '23',
    Name: 'gram per cubic centimetre',
    Description: '',
  },
  {
    Code: 'D41',
    Name: 'tonne per cubic metre',
    Description: '',
  },
  {
    Code: 'GJ',
    Name: 'gram per millilitre',
    Description: '',
  },
  {
    Code: 'B35',
    Name: 'kilogram per litre',
    Description: '',
  },
  {
    Code: 'GL',
    Name: 'gram per litre',
    Description: '',
  },
  {
    Code: 'A93',
    Name: 'gram per cubic metre',
    Description: '',
  },
  {
    Code: 'GP',
    Name: 'milligram per cubic metre',
    Description: '',
  },
  {
    Code: 'B72',
    Name: 'megagram per cubic metre',
    Description: '',
  },
  {
    Code: 'B34',
    Name: 'kilogram per cubic decimetre',
    Description: '',
  },
  {
    Code: 'H64',
    Name: 'milligram per gram',
    Description: '',
  },
  {
    Code: 'H29',
    Name: 'microgram per litre',
    Description: '',
  },
  {
    Code: 'M1',
    Name: 'milligram per litre',
    Description: '',
  },
  {
    Code: 'GQ',
    Name: 'microgram per cubic metre',
    Description: '',
  },
  {
    Code: 'G11',
    Name: 'gram per cubic centimetre bar',
    Description: '',
  },
  {
    Code: 'G33',
    Name: 'gram per cubic centimetre kelvin',
    Description: '',
  },
  {
    Code: 'F23',
    Name: 'gram per cubic decimetre',
    Description: '',
  },
  {
    Code: 'G12',
    Name: 'gram per cubic decimetre bar',
    Description: '',
  },
  {
    Code: 'G34',
    Name: 'gram per cubic decimetre kelvin',
    Description: '',
  },
  {
    Code: 'G14',
    Name: 'gram per cubic metre bar',
    Description: '',
  },
  {
    Code: 'G36',
    Name: 'gram per cubic metre kelvin',
    Description: '',
  },
  {
    Code: 'G13',
    Name: 'gram per litre bar',
    Description: '',
  },
  {
    Code: 'G35',
    Name: 'gram per litre kelvin',
    Description: '',
  },
  {
    Code: 'G15',
    Name: 'gram per millilitre bar',
    Description: '',
  },
  {
    Code: 'G37',
    Name: 'gram per millilitre kelvin',
    Description: '',
  },
  {
    Code: 'G31',
    Name: 'kilogram per cubic centimetre',
    Description: '',
  },
  {
    Code: 'G16',
    Name: 'kilogram per cubic centimetre bar',
    Description: '',
  },
  {
    Code: 'G38',
    Name: 'kilogram per cubic centimetre kelvin',
    Description: '',
  },
  {
    Code: 'G18',
    Name: 'kilogram per cubic metre bar',
    Description: '',
  },
  {
    Code: 'G40',
    Name: 'kilogram per cubic metre kelvin',
    Description: '',
  },
  {
    Code: 'H54',
    Name: 'kilogram per cubic decimetre kelvin',
    Description: '',
  },
  {
    Code: 'H55',
    Name: 'kilogram per cubic decimetre bar',
    Description: '',
  },
  {
    Code: 'F14',
    Name: 'gram per kelvin',
    Description: '',
  },
  {
    Code: 'F15',
    Name: 'kilogram per kelvin',
    Description: '',
  },
  {
    Code: 'F24',
    Name: 'kilogram per kilomol',
    Description: '',
  },
  {
    Code: 'G17',
    Name: 'kilogram per litre bar',
    Description: '',
  },
  {
    Code: 'G39',
    Name: 'kilogram per litre kelvin',
    Description: '',
  },
  {
    Code: 'H53',
    Name: 'kilogram per bar',
    Description: '',
  },
  {
    Code: 'F18',
    Name: 'kilogram square centimetre',
    Description: '',
  },
  {
    Code: 'F19',
    Name: 'kilogram square millimetre',
    Description: '',
  },
  {
    Code: 'F74',
    Name: 'gram per bar',
    Description: '',
  },
  {
    Code: 'F75',
    Name: 'milligram per bar',
    Description: '',
  },
  {
    Code: 'F16',
    Name: 'milligram per kelvin',
    Description: '',
  },
  {
    Code: 'M73',
    Name: 'kilogram per cubic metre pascal',
    Description:
      'SI base unit kilogram divided by the product of the power of the SI base unit metre with exponent 3 and the derived SI unit pascal.',
  },
  {
    Code: '87',
    Name: 'pound per cubic foot',
    Description: '',
  },
  {
    Code: 'GE',
    Name: 'pound per gallon (US)',
    Description: '',
  },
  {
    Code: 'LA',
    Name: 'pound per cubic inch',
    Description: '',
  },
  {
    Code: 'G32',
    Name: 'ounce (avoirdupois) per cubic yard',
    Description: '',
  },
  {
    Code: 'J34',
    Name: 'microgram per cubic metre kelvin',
    Description: '',
  },
  {
    Code: 'J35',
    Name: 'microgram per cubic metre bar',
    Description: '',
  },
  {
    Code: 'K41',
    Name: 'grain per gallon (US)',
    Description: '',
  },
  {
    Code: 'K69',
    Name: 'pound (avoirdupois) per cubic foot degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'K70',
    Name: 'pound (avoirdupois) per cubic foot psi',
    Description: '',
  },
  {
    Code: 'K71',
    Name: 'pound (avoirdupois) per gallon (UK)',
    Description: '',
  },
  {
    Code: 'K75',
    Name: 'pound (avoirdupois) per cubic inch degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'K76',
    Name: 'pound (avoirdupois) per cubic inch psi',
    Description: '',
  },
  {
    Code: 'K84',
    Name: 'pound per cubic yard',
    Description: '',
  },
  {
    Code: 'L17',
    Name: 'milligram per cubic metre kelvin',
    Description: '',
  },
  {
    Code: 'L18',
    Name: 'milligram per cubic metre bar',
    Description: '',
  },
  {
    Code: 'L37',
    Name: 'ounce (avoirdupois) per gallon (UK)',
    Description: '',
  },
  {
    Code: 'L38',
    Name: 'ounce (avoirdupois) per gallon (US)',
    Description: '',
  },
  {
    Code: 'L39',
    Name: 'ounce (avoirdupois) per cubic inch',
    Description: '',
  },
  {
    Code: 'L65',
    Name: 'slug per cubic foot',
    Description: '',
  },
  {
    Code: 'L76',
    Name: 'tonne per cubic metre kelvin',
    Description: '',
  },
  {
    Code: 'L77',
    Name: 'tonne per cubic metre bar',
    Description: '',
  },
  {
    Code: 'L92',
    Name: 'ton (UK long) per cubic yard',
    Description: '',
  },
  {
    Code: 'L93',
    Name: 'ton (US short) per cubic yard',
    Description: '',
  },
  {
    Code: 'K77',
    Name: 'pound (avoirdupois) per psi',
    Description: '',
  },
  {
    Code: 'L70',
    Name: 'tonne per bar',
    Description: '',
  },
  {
    Code: 'L91',
    Name: 'ton short per psi',
    Description: '',
  },
  {
    Code: 'M74',
    Name: 'kilogram per pascal',
    Description: 'SI base unit kilogram divided by the derived SI unit pascal.',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'A39',
    Name: 'cubic metre per kilogram',
    Description: '',
  },
  {
    Code: '22',
    Name: 'decilitre per gram',
    Description: '',
  },
  {
    Code: 'H65',
    Name: 'millilitre per cubic metre',
    Description: '',
  },
  {
    Code: 'H83',
    Name: 'litre per kilogram',
    Description: '',
  },
  {
    Code: 'KX',
    Name: 'millilitre per kilogram',
    Description: '',
  },
  {
    Code: 'H15',
    Name: 'square centimetre per gram',
    Description: '',
  },
  {
    Code: 'N28',
    Name: 'cubic decimetre per kilogram',
    Description:
      '0,001 fold of the power of the SI base unit meter by exponent 3 divided by the SI based unit kilogram.',
  },
  {
    Code: 'N29',
    Name: 'cubic foot per pound',
    Description:
      'Power of the unit foot according to the Anglo-American and Imperial system of units by exponent 3 divided by the unit avoirdupois pound according to the avoirdupois unit system.',
  },
  {
    Code: 'N30',
    Name: 'cubic inch per pound',
    Description:
      'Power of the unit inch according to the Anglo-American and Imperial system of units by exponent 3 divided by the avoirdupois pound according to the avoirdupois unit system .',
  },
  {
    Code: 'KL',
    Name: 'kilogram per metre',
    Description: '',
  },
  {
    Code: 'GF',
    Name: 'gram per metre (gram per 100 centimetres)',
    Description: '',
  },
  {
    Code: 'H76',
    Name: 'gram per millimetre',
    Description: '',
  },
  {
    Code: 'KW',
    Name: 'kilogram per millimetre',
    Description: '',
  },
  {
    Code: 'C12',
    Name: 'milligram per metre',
    Description: '',
  },
  {
    Code: 'M31',
    Name: 'kilogram per kilometre',
    Description: '',
  },
  {
    Code: 'P2',
    Name: 'pound per foot',
    Description: '',
  },
  {
    Code: 'PO',
    Name: 'pound per inch of length',
    Description: '',
  },
  {
    Code: 'M83',
    Name: 'denier ',
    Description:
      'Traditional unit for the indication of the linear mass of textile fibers and yarns.',
  },
  {
    Code: 'M84',
    Name: 'pound per yard ',
    Description: 'Unit for linear mass according to avoirdupois system of units.',
  },
  {
    Code: 'GO',
    Name: 'milligram per square metre',
    Description: '',
  },
  {
    Code: '25',
    Name: 'gram per square centimetre',
    Description: '',
  },
  {
    Code: 'H63',
    Name: 'milligram per square centimetre',
    Description: '',
  },
  {
    Code: 'GM',
    Name: 'gram per square metre',
    Description: '',
  },
  {
    Code: '28',
    Name: 'kilogram per square metre',
    Description: '',
  },
  {
    Code: 'D5',
    Name: 'kilogram per square centimetre',
    Description: '',
  },
  {
    Code: 'ON',
    Name: 'ounce per square yard',
    Description: '',
  },
  {
    Code: '37',
    Name: 'ounce per square foot',
    Description: '',
  },
  {
    Code: 'B31',
    Name: 'kilogram metre per second',
    Description: '',
  },
  {
    Code: 'M98',
    Name: 'kilogram centimetre per second',
    Description:
      'Product of the SI base unit kilogram and the 0,01-fold of the SI base unit metre divided by the SI base unit second.',
  },
  {
    Code: 'M99',
    Name: 'gram centimetre per second',
    Description:
      'Product of the 0,001-fold of the SI base unit kilogram and the 0,01-fold of the SI base unit metre divided by the SI base unit second.',
  },
  {
    Code: 'N10',
    Name: 'pound foot per second',
    Description:
      'Product of the avoirdupois pound according to the avoirdupois unit system and the unit foot according to the Anglo-American and Imperial system of units divided by the SI base unit second.',
  },
  {
    Code: 'N11',
    Name: 'pound inch per second',
    Description:
      'Product of the avoirdupois pound according to the avoirdupois unit system and the unit inch according to the Anglo-American and Imperial system of units divided by the SI base unit second.',
  },
  {
    Code: 'B33',
    Name: 'kilogram metre squared per second',
    Description: '',
  },
  {
    Code: 'B32',
    Name: 'kilogram metre squared',
    Description: '',
  },
  {
    Code: 'F20',
    Name: 'pound inch squared',
    Description: '',
  },
  {
    Code: 'K65',
    Name: 'pound (avoirdupois) square foot',
    Description: '',
  },
  {
    Code: 'NEW',
    Name: 'newton',
    Description: '',
  },
  {
    Code: 'B73',
    Name: 'meganewton',
    Description: '',
  },
  {
    Code: 'B47',
    Name: 'kilonewton',
    Description: '',
  },
  {
    Code: 'C20',
    Name: 'millinewton',
    Description: '',
  },
  {
    Code: 'B92',
    Name: 'micronewton',
    Description: '',
  },

  {
    Code: 'C78',
    Name: 'pound-force',
    Description: '',
  },

  {
    Code: 'L40',
    Name: 'ounce (avoirdupois)-force',
    Description: '',
  },
  {
    Code: 'L94',
    Name: 'ton-force (US short)',
    Description: '',
  },
  {
    Code: 'M75',
    Name: 'kilopound-force',
    Description:
      '1000-fold of the unit of the force pound-force (lbf) according to the Anglo-American system of units with the relationship.',
  },
  {
    Code: 'M76',
    Name: 'poundal',
    Description:
      'Non SI-conforming unit of the power, which corresponds to a mass of a pound multiplied with the acceleration of a foot per square second.',
  },
  {
    Code: 'M77',
    Name: 'kilogram metre per second squared',
    Description:
      'Product of the SI base unit kilogram and the SI base unit metre divided by the power of the SI base unit second by exponent 2.',
  },
  {
    Code: 'M78',
    Name: 'pond',
    Description:
      '0,001-fold of the unit of the weight, defined as a mass of 1 kg which finds out about a weight strength from 1 kp by the gravitational force at sea level which corresponds to a strength of 9,806 65 newton.',
  },
  {
    Code: 'F17',
    Name: 'pound-force per foot',
    Description: '',
  },
  {
    Code: 'F48',
    Name: 'pound-force per inch',
    Description: '',
  },
  {
    Code: 'C54',
    Name: 'newton metre squared per kilogram squared',
    Description: '',
  },
  {
    Code: 'NU',
    Name: 'newton metre',
    Description: '',
  },
  {
    Code: 'H40',
    Name: 'newton per ampere',
    Description: '',
  },
  {
    Code: 'B74',
    Name: 'meganewton metre',
    Description: '',
  },
  {
    Code: 'B48',
    Name: 'kilonewton metre',
    Description: '',
  },
  {
    Code: 'D83',
    Name: 'millinewton metre',
    Description: '',
  },
  {
    Code: 'B93',
    Name: 'micronewton metre',
    Description: '',
  },
  {
    Code: 'DN',
    Name: 'decinewton metre',
    Description: '',
  },
  {
    Code: 'J72',
    Name: 'centinewton metre',
    Description: '',
  },
  {
    Code: 'M94',
    Name: 'kilogram metre',
    Description:
      'Unit of imbalance as a product of the SI base unit kilogram and the SI base unit metre.',
  },
  {
    Code: 'F88',
    Name: 'newton centimetre',
    Description: '',
  },
  {
    Code: 'F90',
    Name: 'newton metre per ampere',
    Description: '',
  },
  {
    Code: 'F89',
    Name: 'newton metre per degree',
    Description: '',
  },
  {
    Code: 'G19',
    Name: 'newton metre per kilogram',
    Description: '',
  },
  {
    Code: 'F47',
    Name: 'newton per millimetre',
    Description: '',
  },
  {
    Code: 'M93',
    Name: 'newton metre per radian',
    Description:
      'Product of the derived SI unit newton and the SI base unit metre divided by the unit radian.',
  },
  {
    Code: 'H41',
    Name: 'newton metre watt to the power minus 0,5',
    Description: '',
  },

  {
    Code: 'IA',
    Name: 'inch pound (pound inch)',
    Description: '',
  },
  {
    Code: '4Q',
    Name: 'ounce inch',
    Description: '',
  },
  {
    Code: '4R',
    Name: 'ounce foot',
    Description: '',
  },
  {
    Code: 'F22',
    Name: 'pound-force foot per ampere',
    Description: '',
  },
  {
    Code: 'F21',
    Name: 'pound-force inch',
    Description: '',
  },
  {
    Code: 'G20',
    Name: 'pound-force foot per pound',
    Description: '',
  },

  {
    Code: 'L41',
    Name: 'ounce (avoirdupois)-force inch',
    Description: '',
  },
  {
    Code: 'M92',
    Name: 'pound-force foot',
    Description:
      'Product of the unit pound-force according to the Anglo-American system of units and the unit foot according to the Anglo-American and the Imperial system of units.',
  },
  {
    Code: 'M95',
    Name: 'poundal foot',
    Description:
      'Product of the non SI-conforming unit of the force poundal and the unit foot according to the Anglo-American and Imperial system of units .',
  },
  {
    Code: 'M96',
    Name: 'poundal inch',
    Description:
      'Product of the non SI-conforming unit of the force poundal and the unit inch according to the Anglo-American and Imperial system of units .',
  },
  {
    Code: 'M97',
    Name: 'dyne metre',
    Description: 'CGS (Centimetre-Gram-Second system) unit of the rotational moment.',
  },
  {
    Code: 'C57',
    Name: 'newton second',
    Description: '',
  },
  {
    Code: 'C53',
    Name: 'newton metre second',
    Description: '',
  },
  {
    Code: '74',
    Name: 'millipascal',
    Description: '',
  },
  {
    Code: 'MPA',
    Name: 'megapascal',
    Description: '',
  },
  {
    Code: 'PAL',
    Name: 'pascal',
    Description: '',
  },
  {
    Code: 'KPA',
    Name: 'kilopascal',
    Description: '',
  },
  {
    Code: 'BAR',
    Name: 'bar [unit of pressure]',
    Description: '',
  },
  {
    Code: 'HBA',
    Name: 'hectobar',
    Description: '',
  },
  {
    Code: 'MBR',
    Name: 'millibar',
    Description: '',
  },
  {
    Code: 'KBA',
    Name: 'kilobar',
    Description: '',
  },
  {
    Code: 'ATM',
    Name: 'standard atmosphere',
    Description: '',
  },
  {
    Code: 'A89',
    Name: 'gigapascal',
    Description: '',
  },
  {
    Code: 'B96',
    Name: 'micropascal',
    Description: '',
  },
  {
    Code: 'A97',
    Name: 'hectopascal',
    Description: '',
  },
  {
    Code: 'H75',
    Name: 'decapascal',
    Description: '',
  },
  {
    Code: 'B85',
    Name: 'microbar',
    Description: '',
  },
  {
    Code: 'C55',
    Name: 'newton per square metre',
    Description: '',
  },
  {
    Code: 'C56',
    Name: 'newton per square millimetre',
    Description: '',
  },
  {
    Code: 'H07',
    Name: 'pascal second per bar',
    Description: '',
  },
  {
    Code: 'F94',
    Name: 'hectopascal cubic metre per second',
    Description: '',
  },
  {
    Code: 'F93',
    Name: 'hectopascal litre per second',
    Description: '',
  },
  {
    Code: 'F82',
    Name: 'hectopascal per kelvin',
    Description: '',
  },
  {
    Code: 'F83',
    Name: 'kilopascal per kelvin',
    Description: '',
  },
  {
    Code: 'F98',
    Name: 'megapascal cubic metre per second',
    Description: '',
  },
  {
    Code: 'F97',
    Name: 'megapascal litre per second',
    Description: '',
  },
  {
    Code: 'F85',
    Name: 'megapascal per kelvin',
    Description: '',
  },
  {
    Code: 'H07',
    Name: 'pascal second per bar',
    Description: '',
  },
  {
    Code: 'F96',
    Name: 'millibar cubic metre per second',
    Description: '',
  },
  {
    Code: 'F95',
    Name: 'millibar litre per second',
    Description: '',
  },
  {
    Code: 'F84',
    Name: 'millibar per kelvin',
    Description: '',
  },
  {
    Code: 'G01',
    Name: 'pascal cubic metre per second',
    Description: '',
  },
  {
    Code: 'F99',
    Name: 'pascal litre per second',
    Description: '',
  },
  {
    Code: 'F77',
    Name: 'pascal second per kelvin',
    Description: '',
  },
  {
    Code: 'F85',
    Name: 'megapascal per kelvin',
    Description: '',
  },
  {
    Code: 'E01',
    Name: 'newton per square centimetre',
    Description: 'A measure of pressure expressed in newtons per square centimetre.',
  },
  {
    Code: 'FP',
    Name: 'pound per square foot',
    Description: '',
  },
  {
    Code: 'PS',
    Name: 'pound-force per square inch',
    Description: '',
  },

  {
    Code: '80',
    Name: 'pound per square inch absolute',
    Description: '',
  },

  {
    Code: 'F79',
    Name: 'inch of mercury',
    Description: '',
  },
  {
    Code: 'F78',
    Name: 'inch of water',
    Description: '',
  },

  {
    Code: 'K31',
    Name: 'gram-force per square centimetre',
    Description: '',
  },
  {
    Code: 'E42',
    Name: 'kilogram-force per square centimetre',
    Description: '',
  },
  {
    Code: 'E41',
    Name: 'kilogram-force per square millimetre',
    Description: '',
  },
  {
    Code: 'K85',
    Name: 'pound-force per square foot',
    Description: '',
  },
  {
    Code: 'K86',
    Name: 'pound-force per square inch degree Fahrenheit',
    Description: '',
  },

  {
    Code: '',
    Name: 'Use kip per square inch (common code N20).',
    Description: '',
  },
  {
    Code: 'N13',
    Name: 'centimetre of mercury (0 ºC)',
    Description:
      'Non SI-conforming unit of pressure, at which a value of 1 cmHg meets the static pressure, which is generated by a mercury at a temperature of 0 °C with a height of 1 centimetre .',
  },
  {
    Code: 'N14',
    Name: 'centimetre of water (4 ºC)',
    Description:
      'Non SI-conforming unit of pressure, at which a value of 1 cmH2O meets the static pressure, which is generated by a head of water at a temperature of 4 °C with a height of 1 centimetre .',
  },
  {
    Code: 'N15',
    Name: 'foot of water (39.2 ºF)',
    Description:
      'Non SI-conforming unit of pressure according to the Anglo-American and Imperial system for units, whereas the value of 1 ftH2O is equivalent to the static pressure, which is generated by a head of water at a temperature 39,2°F with a height of 1 foot .',
  },
  {
    Code: 'N16',
    Name: 'inch of mercury (32 ºF)',
    Description:
      'Non SI-conforming unit of pressure according to the Anglo-American and Imperial system for units, whereas the value of 1 inHg meets the static pressure, which is generated by a mercury at a temperature of 32°F with a height of 1 inch.',
  },
  {
    Code: 'N17',
    Name: 'inch of mercury (60 ºF)',
    Description:
      'Non SI-conforming unit of pressure according to the Anglo-American and Imperial system for units, whereas the value of 1 inHg meets the static pressure, which is generated by a mercury at a temperature of 60°F with a height of 1 inch.',
  },
  {
    Code: 'N18',
    Name: 'inch of water (39.2 ºF)',
    Description:
      'Non SI-conforming unit of pressure according to the Anglo-American and Imperial system for units, whereas the value of 1 inH2O meets the static pressure, which is generated by a head of water at a temperature of 39,2°F with a height of 1 inch .',
  },
  {
    Code: 'N19',
    Name: 'inch of water (60 ºF)',
    Description:
      'Non SI-conforming unit of pressure according to the Anglo-American and Imperial system for units, whereas the value of 1 inH2O meets the static pressure, which is generated by a head of water at a temperature of 60°F with a height of 1 inch .',
  },
  {
    Code: 'N20',
    Name: 'kip per square inch',
    Description:
      'Non SI-conforming unit of the pressure according to the Anglo-American system of units as the 1000-fold of the unit of the force pound-force divided by the power of the unit inch by exponent 2.',
  },
  {
    Code: 'N21',
    Name: 'poundal per square foot ',
    Description:
      'Non SI-conforming unit of pressure by the Imperial system of units according to NIST: 1 pdl/ft² = 1,488 164 Pa.',
  },
  {
    Code: 'N22',
    Name: 'ounce (avoirdupois) per square inch ',
    Description:
      'Unit of the surface specific mass (avoirdupois ounce according to the avoirdupois system of units according to the surface square inch according to the Anglo-American and Imperial system of units).',
  },
  {
    Code: 'N23',
    Name: 'conventional metre of water',
    Description:
      'Not SI-conforming unit of pressure, whereas a value of 1 mH2O is equivalent to the static pressure, which is produced by one metre high water column .',
  },
  {
    Code: 'N24',
    Name: 'gram per square millimetre',
    Description:
      '0,001-fold of the SI base unit kilogram divided by the 0.000 001-fold of the power of the SI base unit meter by exponent 2.',
  },
  {
    Code: 'N25',
    Name: 'pound per square yard',
    Description:
      'Unit for areal-related mass as a unit pound according to the avoirdupois unit system divided by the power of the unit yard according to the Anglo-American and Imperial system of units with exponent 2.',
  },
  {
    Code: 'N26',
    Name: 'poundal per square inch',
    Description:
      'Non SI-conforming unit of the pressure according to the Imperial system of units (poundal by square inch).',
  },
  {
    Code: 'E99',
    Name: 'hectopascal per bar',
    Description: '',
  },
  {
    Code: 'F05',
    Name: 'megapascal per bar',
    Description: '',
  },
  {
    Code: 'F04',
    Name: 'millibar per bar',
    Description: '',
  },
  {
    Code: 'F07',
    Name: 'pascal per bar',
    Description: '',
  },
  {
    Code: 'F03',
    Name: 'kilopascal per bar',
    Description: '',
  },
  {
    Code: 'L52',
    Name: 'psi per psi',
    Description: '',
  },
  {
    Code: 'J56',
    Name: 'bar per bar',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C96',
    Name: 'reciprocal pascal or pascal to the power minus one',
    Description: '',
  },
  {
    Code: 'F58',
    Name: 'reciprocal bar',
    Description: '',
  },
  {
    Code: 'B83',
    Name: 'metre to the fourth power',
    Description: '',
  },
  {
    Code: 'G77',
    Name: 'millimetre to the fourth power',
    Description: '',
  },
  {
    Code: 'D69',
    Name: 'inch to the fourth power',
    Description: '',
  },
  {
    Code: 'N27',
    Name: 'foot to the fourth power ',
    Description:
      'Power of the unit foot according to the Anglo-American and Imperial system of units by exponent 4 according to NIST: 1 ft4 = 8,630 975 m4.',
  },
  {
    Code: 'MTQ',
    Name: 'cubic metre',
    Description: 'Synonym: metre cubed',
  },
  {
    Code: 'INQ',
    Name: 'cubic inch',
    Description: 'Synonym: inch cubed',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C65',
    Name: 'pascal second',
    Description: '',
  },
  {
    Code: 'N37',
    Name: 'kilogram per metre second',
    Description:
      'Unit of the dynamic viscosity as a quotient SI base unit kilogram divided by the SI base unit metre and by the SI base unit second.',
  },
  {
    Code: 'N38',
    Name: 'kilogram per metre minute',
    Description:
      'Unit of the dynamic viscosity as a quotient SI base unit kilogram divided by the SI base unit metre and by the unit minute.',
  },
  {
    Code: 'C24',
    Name: 'millipascal second',
    Description: '',
  },
  {
    Code: 'N36',
    Name: 'newton second per square metre',
    Description:
      'Unit of the dynamic viscosity as a product of unit of the pressure (newton by square metre) multiplied with the SI base unit second.',
  },
  {
    Code: 'N39',
    Name: 'kilogram per metre day',
    Description:
      'Unit of the dynamic viscosity as a quotient SI base unit kilogram divided by the SI base unit metre and by the unit day.',
  },
  {
    Code: 'N40',
    Name: 'kilogram per metre hour',
    Description:
      'Unit of the dynamic viscosity as a quotient SI base unit kilogram divided by the SI base unit metre and by the unit hour.',
  },
  {
    Code: 'N41',
    Name: 'gram per centimetre second',
    Description:
      'Unit of the dynamic viscosity as a quotient of the 0,001-fold of the SI base unit kilogram divided by the 0,01-fold of the SI base unit metre and SI base unit second.',
  },
  {
    Code: '89',
    Name: 'poise',
    Description: '',
  },
  {
    Code: 'C7',
    Name: 'centipoise',
    Description: '',
  },
  {
    Code: 'F06',
    Name: 'poise per bar',
    Description: '',
  },
  {
    Code: 'F86',
    Name: 'poise per kelvin',
    Description: '',
  },
  {
    Code: 'J32',
    Name: 'micropoise',
    Description: '',
  },
  {
    Code: 'J73',
    Name: 'centipoise per kelvin',
    Description: '',
  },
  {
    Code: 'J74',
    Name: 'centipoise per bar',
    Description: '',
  },
  {
    Code: 'K67',
    Name: 'pound per foot hour',
    Description: '',
  },
  {
    Code: 'K68',
    Name: 'pound per foot second',
    Description: '',
  },
  {
    Code: 'K91',
    Name: 'pound-force second per square foot',
    Description: '',
  },
  {
    Code: 'K92',
    Name: 'pound-force second per square inch',
    Description: '',
  },
  {
    Code: 'L15',
    Name: 'millipascal second per kelvin',
    Description: '',
  },
  {
    Code: 'L16',
    Name: 'millipascal second per bar',
    Description: '',
  },
  {
    Code: 'L64',
    Name: 'slug per foot second',
    Description: '',
  },
  {
    Code: 'N34',
    Name: 'poundal second per square foot ',
    Description: 'Non SI-conforming unit of viscosity.',
  },
  {
    Code: 'N35',
    Name: 'poise per pascal',
    Description:
      'CGS (Centimetre-Gram-Second system) unit poise divided by the derived SI unit pascal.',
  },
  {
    Code: 'N42',
    Name: 'poundal second per square inch',
    Description:
      'Non SI-conforming unit of dynamic viscosity according to the Imperial system of units as product unit of the pressure (poundal by square inch) multiplied by the SI base unit second.',
  },
  {
    Code: 'N43',
    Name: 'pound per foot minute',
    Description: 'Unit of the dynamic viscosity according to the Anglo-American unit system.',
  },
  {
    Code: 'N44',
    Name: 'pound per foot day',
    Description: 'Unit of the dynamic viscosity according to the Anglo-American unit system.',
  },
  {
    Code: 'S4',
    Name: 'square metre per second',
    Description: 'Synonym: metre squared per second (square metres/second US)',
  },
  {
    Code: 'M82',
    Name: 'square metre per second pascal',
    Description:
      'Power of the SI base unit metre with the exponent 2 divided by the SI base unit second and the derived SI unit pascal.',
  },
  {
    Code: 'C17',
    Name: 'millimetre squared per second',
    Description: '',
  },
  {
    Code: 'G41',
    Name: 'square metre per second bar',
    Description: '',
  },
  {
    Code: 'G09',
    Name: 'square metre per second kelvin',
    Description: '',
  },
  {
    Code: '91',
    Name: 'stokes',
    Description: '',
  },
  {
    Code: '4C',
    Name: 'centistokes',
    Description: '',
  },
  {
    Code: 'G46',
    Name: 'stokes per bar',
    Description: '',
  },
  {
    Code: 'G10',
    Name: 'stokes per kelvin',
    Description: '',
  },
  {
    Code: 'S3',
    Name: 'square foot per second',
    Description: 'Synonym: foot squared per second',
  },
  {
    Code: 'G08',
    Name: 'square inch per second',
    Description: '',
  },
  {
    Code: 'M79',
    Name: 'square foot per hour ',
    Description:
      'Power of the unit foot according to the Anglo-American and Imperial system of units by exponent 2 divided by the unit of time hour.',
  },
  {
    Code: 'M80',
    Name: 'stokes per pascal',
    Description:
      'CGS (Centimetre-Gram-Second system) unit stokes divided by the derived SI unit pascal.',
  },
  {
    Code: 'M81',
    Name: 'square centimetre per second',
    Description:
      '0,000 1-fold of the power of the SI base unit metre by exponent 2 divided by the SI base unit second.',
  },
  {
    Code: '4P',
    Name: 'newton per metre',
    Description: '',
  },
  {
    Code: 'C22',
    Name: 'millinewton per metre',
    Description: '',
  },
  {
    Code: 'M23',
    Name: 'newton per centimetre',
    Description: '',
  },
  {
    Code: 'N31',
    Name: 'kilonewton per metre',
    Description: '1000-fold of the derived SI unit newton divided by the SI base unit metre.',
  },
  {
    Code: 'DX',
    Name: 'dyne per centimetre',
    Description: '',
  },
  {
    Code: 'N32',
    Name: 'poundal per inch',
    Description:
      'Non SI-conforming unit of the surface tension according to the Imperial unit system as quotient poundal by inch.',
  },
  {
    Code: 'N33',
    Name: 'pound-force per yard',
    Description: 'Unit of force per unit length based on the Anglo-American system of units.',
  },
  {
    Code: 'M34',
    Name: 'newton metre per square metre',
    Description: '',
  },
  {
    Code: 'JOU',
    Name: 'joule',
    Description: '',
  },
  {
    Code: 'KJO',
    Name: 'kilojoule',
    Description: '',
  },
  {
    Code: 'A68',
    Name: 'exajoule',
    Description: '',
  },
  {
    Code: 'C68',
    Name: 'petajoule',
    Description: '',
  },
  {
    Code: 'D30',
    Name: 'terajoule',
    Description: '',
  },
  {
    Code: 'GV',
    Name: 'gigajoule',
    Description: '',
  },
  {
    Code: '3B',
    Name: 'megajoule',
    Description: '',
  },
  {
    Code: 'C15',
    Name: 'millijoule',
    Description: '',
  },
  {
    Code: 'A70',
    Name: 'femtojoule',
    Description: '',
  },
  {
    Code: 'A13',
    Name: 'attojoule',
    Description: '',
  },
  {
    Code: 'WHR',
    Name: 'watt hour',
    Description: '',
  },
  {
    Code: 'MWH',
    Name: 'megawatt hour (1000 kW.h)',
    Description:
      'A unit of power defining the total amount of bulk energy transferred or consumed.',
  },
  {
    Code: 'KWH',
    Name: 'kilowatt hour',
    Description: '',
  },
  {
    Code: 'GWH',
    Name: 'gigawatt hour',
    Description: '',
  },
  {
    Code: 'D32',
    Name: 'terawatt hour',
    Description: '',
  },
  {
    Code: 'A53',
    Name: 'electronvolt',
    Description: '',
  },
  {
    Code: 'B71',
    Name: 'megaelectronvolt',
    Description: '',
  },
  {
    Code: 'A85',
    Name: 'gigaelectronvolt',
    Description: '',
  },
  {
    Code: 'B29',
    Name: 'kiloelectronvolt',
    Description: '',
  },
  {
    Code: 'A57',
    Name: 'erg',
    Description: '',
  },
  {
    Code: '85',
    Name: 'foot pound-force',
    Description: '',
  },
  {
    Code: 'B38',
    Name: 'kilogram-force metre',
    Description: '',
  },
  {
    Code: 'N46',
    Name: 'foot poundal',
    Description: 'Unit of the work (force-path).',
  },
  {
    Code: 'N47',
    Name: 'inch poundal',
    Description:
      'Unit of work (force multiplied by path) according to the Imperial system of units as a product unit inch multiplied by poundal.',
  },
  {
    Code: 'G20',
    Name: 'pound-force foot per pound',
    Description: '',
  },
  {
    Code: 'WTT',
    Name: 'watt',
    Description: '',
  },
  {
    Code: 'KWT',
    Name: 'kilowatt',
    Description: '',
  },
  {
    Code: 'MAW',
    Name: 'megawatt',
    Description:
      'A unit of power defining the rate of energy transferred or consumed when a current of 1000 amperes flows due to a potential of 1000 volts at unity power factor.',
  },
  {
    Code: 'A90',
    Name: 'gigawatt',
    Description: '',
  },
  {
    Code: 'C31',
    Name: 'milliwatt',
    Description: '',
  },
  {
    Code: 'D80',
    Name: 'microwatt',
    Description: '',
  },
  {
    Code: 'F80',
    Name: 'water horse power',
    Description:
      'A unit of power defining the amount of power required to move a given volume of water against acceleration of gravity to a specified elevation (pressure head).',
  },
  {
    Code: 'A63',
    Name: 'erg per second',
    Description: '',
  },
  {
    Code: 'A74',
    Name: 'foot pound-force per second',
    Description: '',
  },
  {
    Code: 'B39',
    Name: 'kilogram-force metre per second',
    Description: '',
  },
  {
    Code: 'HJ',
    Name: 'metric horse power',
    Description: '',
  },
  {
    Code: 'A25',
    Name: 'cheval vapeur',
    Description: 'Synonym: metric horse power',
  },
  {
    Code: 'BHP',
    Name: 'brake horse power',
    Description: '',
  },
  {
    Code: 'K15',
    Name: 'foot pound-force per hour',
    Description: '',
  },
  {
    Code: 'K16',
    Name: 'foot pound-force per minute',
    Description: '',
  },
  {
    Code: 'K42',
    Name: 'horsepower (boiler)',
    Description: '',
  },
  {
    Code: 'N12',
    Name: 'Pferdestaerke',
    Description: 'Obsolete unit of the power relating to DIN 1301-3:1979: 1 PS = 735,498 75 W.',
  },
  {
    Code: 'KGS',
    Name: 'kilogram per second',
    Description: '',
  },
  {
    Code: 'H56',
    Name: 'kilogram per square metre second',
    Description: '',
  },
  {
    Code: 'M87',
    Name: 'kilogram per second pascal',
    Description:
      'SI base unit kilogram divided by the product of the SI base unit second and the derived SI unit pascal.',
  },
  {
    Code: '4M',
    Name: 'milligram per hour',
    Description: '',
  },
  {
    Code: 'F26',
    Name: 'gram per day',
    Description: '',
  },
  {
    Code: 'F62',
    Name: 'gram per day bar',
    Description: '',
  },
  {
    Code: 'F35',
    Name: 'gram per day kelvin',
    Description: '',
  },
  {
    Code: 'F27',
    Name: 'gram per hour',
    Description: '',
  },
  {
    Code: 'F63',
    Name: 'gram per hour bar',
    Description: '',
  },
  {
    Code: 'F36',
    Name: 'gram per hour kelvin',
    Description: '',
  },
  {
    Code: 'F28',
    Name: 'gram per minute',
    Description: '',
  },
  {
    Code: 'F64',
    Name: 'gram per minute bar',
    Description: '',
  },
  {
    Code: 'F37',
    Name: 'gram per minute kelvin',
    Description: '',
  },
  {
    Code: 'F29',
    Name: 'gram per second',
    Description: '',
  },
  {
    Code: 'F65',
    Name: 'gram per second bar',
    Description: '',
  },
  {
    Code: 'F38',
    Name: 'gram per second kelvin',
    Description: '',
  },
  {
    Code: 'F30',
    Name: 'kilogram per day',
    Description: '',
  },
  {
    Code: 'F66',
    Name: 'kilogram per day bar',
    Description: '',
  },
  {
    Code: 'F39',
    Name: 'kilogram per day kelvin',
    Description: '',
  },
  {
    Code: 'E93',
    Name: 'kilogram per hour',
    Description: '',
  },
  {
    Code: 'F67',
    Name: 'kilogram per hour bar',
    Description: '',
  },
  {
    Code: 'F40',
    Name: 'kilogram per hour kelvin',
    Description: '',
  },
  {
    Code: 'F31',
    Name: 'kilogram per minute',
    Description: '',
  },
  {
    Code: 'F68',
    Name: 'kilogram per minute bar',
    Description: '',
  },
  {
    Code: 'F41',
    Name: 'kilogram per minute kelvin',
    Description: '',
  },
  {
    Code: 'F69',
    Name: 'kilogram per second bar',
    Description: '',
  },
  {
    Code: 'F42',
    Name: 'kilogram per second kelvin',
    Description: '',
  },
  {
    Code: 'F32',
    Name: 'milligram per day',
    Description: '',
  },
  {
    Code: 'F70',
    Name: 'milligram per day bar',
    Description: '',
  },
  {
    Code: 'F43',
    Name: 'milligram per day kelvin',
    Description: '',
  },
  {
    Code: 'F71',
    Name: 'milligram per hour bar',
    Description: '',
  },
  {
    Code: 'F44',
    Name: 'milligram per hour kelvin',
    Description: '',
  },
  {
    Code: 'F33',
    Name: 'milligram per minute',
    Description: '',
  },
  {
    Code: 'F72',
    Name: 'milligram per minute bar',
    Description: '',
  },
  {
    Code: 'F45',
    Name: 'milligram per minute kelvin',
    Description: '',
  },
  {
    Code: 'F34',
    Name: 'milligram per second',
    Description: '',
  },
  {
    Code: 'F73',
    Name: 'milligram per second bar',
    Description: '',
  },
  {
    Code: 'F46',
    Name: 'milligram per second kelvin',
    Description: '',
  },
  {
    Code: 'F25',
    Name: 'gram per hertz',
    Description: '',
  },
  {
    Code: '4W',
    Name: 'ton (US) per hour',
    Description: '',
  },
  {
    Code: '4U',
    Name: 'pound per hour',
    Description: '',
  },
  {
    Code: 'K66',
    Name: 'pound (avoirdupois) per day',
    Description: '',
  },
  {
    Code: 'K73',
    Name: 'pound (avoirdupois) per hour degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'K74',
    Name: 'pound (avoirdupois) per hour psi',
    Description: '',
  },
  {
    Code: 'K78',
    Name: 'pound (avoirdupois) per minute',
    Description: '',
  },
  {
    Code: 'K79',
    Name: 'pound (avoirdupois) per minute degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'K80',
    Name: 'pound (avoirdupois) per minute psi',
    Description: '',
  },
  {
    Code: 'K81',
    Name: 'pound (avoirdupois) per second',
    Description: '',
  },
  {
    Code: 'K82',
    Name: 'pound (avoirdupois) per second degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'K83',
    Name: 'pound (avoirdupois) per second psi',
    Description: '',
  },
  {
    Code: 'L33',
    Name: 'ounce (avoirdupois) per day',
    Description: '',
  },
  {
    Code: 'L34',
    Name: 'ounce (avoirdupois) per hour',
    Description: '',
  },
  {
    Code: 'L35',
    Name: 'ounce (avoirdupois) per minute',
    Description: '',
  },
  {
    Code: 'L36',
    Name: 'ounce (avoirdupois) per second',
    Description: '',
  },
  {
    Code: 'L63',
    Name: 'slug per day',
    Description: '',
  },
  {
    Code: 'L66',
    Name: 'slug per hour',
    Description: '',
  },
  {
    Code: 'L67',
    Name: 'slug per minute',
    Description: '',
  },
  {
    Code: 'L68',
    Name: 'slug per second',
    Description: '',
  },
  {
    Code: 'L71',
    Name: 'tonne per day',
    Description: '',
  },
  {
    Code: 'L72',
    Name: 'tonne per day kelvin',
    Description: '',
  },
  {
    Code: 'L73',
    Name: 'tonne per day bar',
    Description: '',
  },
  {
    Code: 'E18',
    Name: 'tonne per hour',
    Description: 'A unit of weight or mass equal to one tonne per hour.',
  },
  {
    Code: 'L74',
    Name: 'tonne per hour kelvin',
    Description: '',
  },
  {
    Code: 'L75',
    Name: 'tonne per hour bar',
    Description: '',
  },
  {
    Code: 'L78',
    Name: 'tonne per minute',
    Description: '',
  },
  {
    Code: 'L79',
    Name: 'tonne per minute kelvin',
    Description: '',
  },
  {
    Code: 'L80',
    Name: 'tonne per minute bar',
    Description: '',
  },
  {
    Code: 'L81',
    Name: 'tonne per second',
    Description: '',
  },
  {
    Code: 'L82',
    Name: 'tonne per second kelvin',
    Description: '',
  },
  {
    Code: 'L83',
    Name: 'tonne per second bar',
    Description: '',
  },
  {
    Code: 'L85',
    Name: 'ton long per day',
    Description: '',
  },
  {
    Code: 'L88',
    Name: 'ton short per day',
    Description: '',
  },
  {
    Code: 'L89',
    Name: 'ton short per hour degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'L90',
    Name: 'ton short per hour psi',
    Description: '',
  },
  {
    Code: 'M88',
    Name: 'tonne per month',
    Description: 'Unit tonne divided by the unit month.',
  },
  {
    Code: 'M89',
    Name: 'tonne per year',
    Description: 'Unit tonne divided by the unit year with 365 days.',
  },
  {
    Code: 'M90',
    Name: 'kilopound per hour',
    Description:
      '1000-fold of the unit of the mass avoirdupois pound according to the avoirdupois unit system divided by the unit hour.',
  },
  {
    Code: 'J33',
    Name: 'microgram per kilogram',
    Description: '',
  },
  {
    Code: 'L32',
    Name: 'nanogram per kilogram',
    Description: '',
  },
  {
    Code: 'NA',
    Name: 'milligram per kilogram',
    Description: '',
  },
  {
    Code: 'M29',
    Name: 'kilogram per kilogram',
    Description: '',
  },
  {
    Code: 'M91',
    Name: 'pound per pound',
    Description:
      'Proportion of the mass consisting of the avoirdupois pound according to the avoirdupois unit system divided by the avoirdupois pound according to the avoirdupois unit system.',
  },
  {
    Code: 'MQS',
    Name: 'cubic metre per second',
    Description: '',
  },
  {
    Code: 'MQH',
    Name: 'cubic metre per hour',
    Description: '',
  },
  {
    Code: '40',
    Name: 'millilitre per second',
    Description: '',
  },
  {
    Code: '41',
    Name: 'millilitre per minute',
    Description: '',
  },
  {
    Code: 'LD',
    Name: 'litre per day',
    Description: '',
  },
  {
    Code: '2J',
    Name: 'cubic centimetre per second',
    Description: '',
  },
  {
    Code: '4X',
    Name: 'kilolitre per hour',
    Description: '',
  },
  {
    Code: 'L2',
    Name: 'litre per minute',
    Description: '',
  },
  {
    Code: 'G47',
    Name: 'cubic centimetre per day',
    Description: '',
  },
  {
    Code: 'G78',
    Name: 'cubic centimetre per day bar',
    Description: '',
  },
  {
    Code: 'G61',
    Name: 'cubic centimetre per day kelvin',
    Description: '',
  },
  {
    Code: 'G48',
    Name: 'cubic centimetre per hour',
    Description: '',
  },
  {
    Code: 'G79',
    Name: 'cubic centimetre per hour bar',
    Description: '',
  },
  {
    Code: 'G62',
    Name: 'cubic centimetre per hour kelvin',
    Description: '',
  },
  {
    Code: 'G49',
    Name: 'cubic centimetre per minute',
    Description: '',
  },
  {
    Code: 'G80',
    Name: 'cubic centimetre per minute bar',
    Description: '',
  },
  {
    Code: 'G63',
    Name: 'cubic centimetre per minute kelvin',
    Description: '',
  },
  {
    Code: 'G81',
    Name: 'cubic centimetre per second bar',
    Description: '',
  },
  {
    Code: 'G64',
    Name: 'cubic centimetre per second kelvin',
    Description: '',
  },
  {
    Code: 'E92',
    Name: 'cubic decimetre per hour',
    Description: '',
  },
  {
    Code: 'G52',
    Name: 'cubic metre per day',
    Description: '',
  },
  {
    Code: 'G86',
    Name: 'cubic metre per day bar',
    Description: '',
  },
  {
    Code: 'G69',
    Name: 'cubic metre per day kelvin',
    Description: '',
  },
  {
    Code: 'G87',
    Name: 'cubic metre per hour bar',
    Description: '',
  },
  {
    Code: 'G70',
    Name: 'cubic metre per hour kelvin',
    Description: '',
  },
  {
    Code: 'G53',
    Name: 'cubic metre per minute',
    Description: '',
  },
  {
    Code: 'G88',
    Name: 'cubic metre per minute bar',
    Description: '',
  },
  {
    Code: 'G71',
    Name: 'cubic metre per minute kelvin',
    Description: '',
  },
  {
    Code: 'G89',
    Name: 'cubic metre per second bar',
    Description: '',
  },
  {
    Code: 'G72',
    Name: 'cubic metre per second kelvin',
    Description: '',
  },
  {
    Code: 'G82',
    Name: 'litre per day bar',
    Description: '',
  },
  {
    Code: 'G65',
    Name: 'litre per day kelvin',
    Description: '',
  },
  {
    Code: 'G83',
    Name: 'litre per hour bar',
    Description: '',
  },
  {
    Code: 'G66',
    Name: 'litre per hour kelvin',
    Description: '',
  },
  {
    Code: 'G84',
    Name: 'litre per minute bar',
    Description: '',
  },
  {
    Code: 'G67',
    Name: 'litre per minute kelvin',
    Description: '',
  },
  {
    Code: 'G51',
    Name: 'litre per second',
    Description: '',
  },
  {
    Code: 'G85',
    Name: 'litre per second bar',
    Description: '',
  },
  {
    Code: 'G68',
    Name: 'litre per second kelvin',
    Description: '',
  },
  {
    Code: 'G54',
    Name: 'millilitre per day',
    Description: '',
  },
  {
    Code: 'G90',
    Name: 'millilitre per day bar',
    Description: '',
  },
  {
    Code: 'G73',
    Name: 'millilitre per day kelvin',
    Description: '',
  },
  {
    Code: 'G55',
    Name: 'millilitre per hour',
    Description: '',
  },
  {
    Code: 'G91',
    Name: 'millilitre per hour bar',
    Description: '',
  },
  {
    Code: 'G74',
    Name: 'millilitre per hour kelvin',
    Description: '',
  },
  {
    Code: 'G92',
    Name: 'millilitre per minute bar',
    Description: '',
  },
  {
    Code: 'G75',
    Name: 'millilitre per minute kelvin',
    Description: '',
  },
  {
    Code: 'G93',
    Name: 'millilitre per second bar',
    Description: '',
  },
  {
    Code: 'G76',
    Name: 'millilitre per second kelvin',
    Description: '',
  },
  {
    Code: '2K',
    Name: 'cubic foot per hour',
    Description: '',
  },
  {
    Code: '2L',
    Name: 'cubic foot per minute',
    Description: '',
  },
  {
    Code: '5A',
    Name: 'barrel (US) per minute',
    Description: '',
  },
  {
    Code: 'G2',
    Name: 'US gallon per minute',
    Description: '',
  },
  {
    Code: 'G3',
    Name: 'Imperial gallon per minute',
    Description: '',
  },
  {
    Code: 'G56',
    Name: 'cubic inch per hour',
    Description: '',
  },
  {
    Code: 'G57',
    Name: 'cubic inch per minute',
    Description: '',
  },
  {
    Code: 'G58',
    Name: 'cubic inch per second',
    Description: '',
  },
  {
    Code: 'G50',
    Name: 'gallon (US) per hour',
    Description: '',
  },
  {
    Code: 'J58',
    Name: 'barrel (UK petroleum) per minute',
    Description: '',
  },
  {
    Code: 'J59',
    Name: 'barrel (UK petroleum) per day',
    Description: '',
  },
  {
    Code: 'J60',
    Name: 'barrel (UK petroleum) per hour',
    Description: '',
  },
  {
    Code: 'J61',
    Name: 'barrel (UK petroleum) per second',
    Description: '',
  },
  {
    Code: 'J62',
    Name: 'barrel (US petroleum) per hour',
    Description: '',
  },
  {
    Code: 'J63',
    Name: 'barrel (US petroleum) per second',
    Description: '',
  },
  {
    Code: 'J64',
    Name: 'bushel (UK) per day',
    Description: '',
  },
  {
    Code: 'J65',
    Name: 'bushel (UK) per hour',
    Description: '',
  },
  {
    Code: 'J66',
    Name: 'bushel (UK) per minute',
    Description: '',
  },
  {
    Code: 'J67',
    Name: 'bushel (UK) per second',
    Description: '',
  },
  {
    Code: 'J68',
    Name: 'bushel (US dry) per day',
    Description: '',
  },
  {
    Code: 'J69',
    Name: 'bushel (US dry) per hour',
    Description: '',
  },
  {
    Code: 'J70',
    Name: 'bushel (US dry) per minute',
    Description: '',
  },
  {
    Code: 'J71',
    Name: 'bushel (US dry) per second',
    Description: '',
  },
  {
    Code: 'J90',
    Name: 'cubic decimetre per day',
    Description: '',
  },
  {
    Code: 'J92',
    Name: 'cubic decimetre per minute',
    Description: '',
  },
  {
    Code: 'J93',
    Name: 'cubic decimetre per second',
    Description: '',
  },
  {
    Code: 'N45',
    Name: 'cubic metre per second pascal',
    Description:
      'Power of the SI base unit meter by exponent 3 divided by the product of the SI base unit second and the derived SI base unit pascal.',
  },
  {
    Code: 'J95',
    Name: 'ounce (UK fluid) per day',
    Description: '',
  },
  {
    Code: 'J96',
    Name: 'ounce (UK fluid) per hour',
    Description: '',
  },
  {
    Code: 'J97',
    Name: 'ounce (UK fluid) per minute',
    Description: '',
  },
  {
    Code: 'J98',
    Name: 'ounce (UK fluid) per second',
    Description: '',
  },
  {
    Code: 'J99',
    Name: 'ounce (US fluid) per day',
    Description: '',
  },
  {
    Code: 'K10',
    Name: 'ounce (US fluid) per hour',
    Description: '',
  },
  {
    Code: 'K11',
    Name: 'ounce (US fluid) per minute',
    Description: '',
  },
  {
    Code: 'K12',
    Name: 'ounce (US fluid) per second',
    Description: '',
  },
  {
    Code: 'K22',
    Name: 'cubic foot per day',
    Description: '',
  },
  {
    Code: 'K26',
    Name: 'gallon (UK) per day',
    Description: '',
  },
  {
    Code: 'K27',
    Name: 'gallon (UK) per hour',
    Description: '',
  },
  {
    Code: 'K28',
    Name: 'gallon (UK) per second',
    Description: '',
  },
  {
    Code: 'K30',
    Name: 'gallon (US liquid) per second',
    Description: '',
  },
  {
    Code: 'K32',
    Name: 'gill (UK) per day',
    Description: '',
  },
  {
    Code: 'K33',
    Name: 'gill (UK) per hour',
    Description: '',
  },
  {
    Code: 'K34',
    Name: 'gill (UK) per minute',
    Description: '',
  },
  {
    Code: 'K35',
    Name: 'gill (UK) per second',
    Description: '',
  },
  {
    Code: 'K36',
    Name: 'gill (US) per day',
    Description: '',
  },
  {
    Code: 'K37',
    Name: 'gill (US) per hour',
    Description: '',
  },
  {
    Code: 'K38',
    Name: 'gill (US) per minute',
    Description: '',
  },
  {
    Code: 'K39',
    Name: 'gill (US) per second',
    Description: '',
  },
  {
    Code: 'K94',
    Name: 'quart (UK liquid) per day',
    Description: '',
  },
  {
    Code: 'K95',
    Name: 'quart (UK liquid) per hour',
    Description: '',
  },
  {
    Code: 'K96',
    Name: 'quart (UK liquid) per minute',
    Description: '',
  },
  {
    Code: 'K97',
    Name: 'quart (UK liquid) per second',
    Description: '',
  },
  {
    Code: 'K98',
    Name: 'quart (US liquid) per day',
    Description: '',
  },
  {
    Code: 'K99',
    Name: 'quart (US liquid) per hour',
    Description: '',
  },
  {
    Code: 'L10',
    Name: 'quart (US liquid) per minute',
    Description: '',
  },
  {
    Code: 'L11',
    Name: 'quart (US liquid) per second',
    Description: '',
  },
  {
    Code: 'L44',
    Name: 'peck (UK) per day',
    Description: '',
  },
  {
    Code: 'L45',
    Name: 'peck (UK) per hour',
    Description: '',
  },
  {
    Code: 'L46',
    Name: 'peck (UK) per minute',
    Description: '',
  },
  {
    Code: 'L47',
    Name: 'peck (UK) per second',
    Description: '',
  },
  {
    Code: 'L48',
    Name: 'peck (US dry) per day',
    Description: '',
  },
  {
    Code: 'L49',
    Name: 'peck (US dry) per hour',
    Description: '',
  },
  {
    Code: 'L50',
    Name: 'peck (US dry) per minute',
    Description: '',
  },
  {
    Code: 'L51',
    Name: 'peck (US dry) per second',
    Description: '',
  },
  {
    Code: 'L53',
    Name: 'pint (UK) per day',
    Description: '',
  },
  {
    Code: 'L54',
    Name: 'pint (UK) per hour',
    Description: '',
  },
  {
    Code: 'L55',
    Name: 'pint (UK) per minute',
    Description: '',
  },
  {
    Code: 'L56',
    Name: 'pint (UK) per second',
    Description: '',
  },
  {
    Code: 'L57',
    Name: 'pint (US liquid) per day',
    Description: '',
  },
  {
    Code: 'L58',
    Name: 'pint (US liquid) per hour',
    Description: '',
  },
  {
    Code: 'L59',
    Name: 'pint (US liquid) per minute',
    Description: '',
  },
  {
    Code: 'L60',
    Name: 'pint (US liquid) per second',
    Description: '',
  },
  {
    Code: 'M12',
    Name: 'cubic yard per day',
    Description: '',
  },
  {
    Code: 'M13',
    Name: 'cubic yard per hour',
    Description: '',
  },
  {
    Code: 'M15',
    Name: 'cubic yard per minute',
    Description: '',
  },
  {
    Code: 'M16',
    Name: 'cubic yard per second',
    Description: '',
  },
  {
    Code: 'H60',
    Name: 'cubic metre per cubic metre',
    Description: '',
  },
  {
    Code: 'F92',
    Name: 'bar cubic metre per second',
    Description: '',
  },
  {
    Code: 'F91',
    Name: 'bar litre per second',
    Description: '',
  },
  {
    Code: 'K87',
    Name: 'psi cubic inch per second',
    Description: '',
  },
  {
    Code: 'K88',
    Name: 'psi litre per second',
    Description: '',
  },
  {
    Code: 'K89',
    Name: 'psi cubic metre per second',
    Description: '',
  },
  {
    Code: 'K90',
    Name: 'psi cubic yard per second',
    Description: '',
  },
  {
    Code: 'Q29',
    Name: 'microgram per hectogram',
    Description: 'Microgram per hectogram.',
  },
  {
    Code: 'Q37',
    Name: 'Standard cubic metre per day',
    Description: 'Standard cubic metre (temperature 15°C and pressure 101325 millibars ) per day',
  },
  {
    Code: 'Q38',
    Name: 'Standard cubic metre per hour',
    Description: 'Standard cubic metre (temperature 15°C and pressure 101325 millibars ) per hour',
  },
  {
    Code: 'Q39',
    Name: 'Normalized cubic metre per day',
    Description: 'Normalized cubic metre (temperature 0°C and pressure 101325 millibars ) per day',
  },
  {
    Code: 'Q40',
    Name: 'Normalized cubic metre per hour',
    Description: 'Normalized cubic metre (temperature 0°C and pressure 101325 millibars ) per hour',
  },
  {
    Code: 'KWN',
    Name: 'Kilowatt hour per normalized cubic metre',
    Description:
      'Kilowatt hour per normalized cubic metre (temperature 0°C and pressure 101325 millibars ).',
  },
  {
    Code: 'KWS',
    Name: 'Kilowatt hour per standard cubic metre',
    Description:
      'Kilowatt hour per standard cubic metre (temperature 15°C and pressure 101325 millibars).',
  },
  {
    Code: 'Q41',
    Name: 'Joule per normalised cubic metre',
    Description:
      'Joule per normalised cubic metre (temperature 0°C and pressure 101325 millibars).',
  },
  {
    Code: 'Q42',
    Name: 'Joule per standard cubic metre',
    Description: 'Joule per standard cubic metre (temperature 15°C and pressure 101325 millibars).',
  },
  {
    Code: 'MNJ',
    Name: 'Mega Joule per Normalised cubic Metre',
    Description:
      'Energy in Mega Joules per normalised cubic metre for gas (temperature 0°C and pressure 101325 millibars)',
  },
  {
    Code: 'KEL',
    Name: 'kelvin',
    Description: 'Refer ISO 80000-5 (Quantities and units — Part 5: Thermodynamics)',
  },
  {
    Code: 'CEL',
    Name: 'degree Celsius',
    Description: 'Refer ISO 80000-5 (Quantities and units — Part 5: Thermodynamics)',
  },
  {
    Code: 'H12',
    Name: 'degree Celsius per hour',
    Description: '',
  },
  {
    Code: 'F60',
    Name: 'degree Celsius per bar',
    Description: '',
  },
  {
    Code: 'E98',
    Name: 'degree Celsius per kelvin',
    Description: '',
  },
  {
    Code: 'H13',
    Name: 'degree Celsius per minute',
    Description: '',
  },
  {
    Code: 'H14',
    Name: 'degree Celsius per second',
    Description: '',
  },
  {
    Code: 'F61',
    Name: 'kelvin per bar',
    Description: '',
  },
  {
    Code: 'F10',
    Name: 'kelvin per hour',
    Description: '',
  },
  {
    Code: 'F02',
    Name: 'kelvin per kelvin',
    Description: '',
  },
  {
    Code: 'F11',
    Name: 'kelvin per minute',
    Description: '',
  },
  {
    Code: 'F12',
    Name: 'kelvin per second',
    Description: '',
  },
  {
    Code: 'N79',
    Name: 'kelvin per pascal',
    Description: 'SI base unit kelvin divided by the derived SI unit pascal.',
  },
  {
    Code: 'J20',
    Name: 'degree Fahrenheit per kelvin',
    Description: '',
  },
  {
    Code: 'J21',
    Name: 'degree Fahrenheit per bar',
    Description: '',
  },
  {
    Code: 'J26',
    Name: 'reciprocal degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'A48',
    Name: 'degree Rankine',
    Description: 'Refer ISO 80000-5 (Quantities and units — Part 5: Thermodynamics)',
  },
  {
    Code: 'FAH',
    Name: 'degree Fahrenheit',
    Description: 'Refer ISO 80000-5 (Quantities and units — Part 5: Thermodynamics)',
  },
  {
    Code: 'J23',
    Name: 'degree Fahrenheit per hour',
    Description: '',
  },
  {
    Code: 'J24',
    Name: 'degree Fahrenheit per minute',
    Description: '',
  },
  {
    Code: 'J25',
    Name: 'degree Fahrenheit per second',
    Description: '',
  },
  {
    Code: 'J28',
    Name: 'degree Rankine per hour',
    Description: '',
  },
  {
    Code: 'J29',
    Name: 'degree Rankine per minute',
    Description: '',
  },
  {
    Code: 'J30',
    Name: 'degree Rankine per second',
    Description: '',
  },
  {
    Code: 'C91',
    Name: 'reciprocal kelvin or kelvin to the power minus one',
    Description: '',
  },
  {
    Code: 'M20',
    Name: 'reciprocal megakelvin or megakelvin to the power minus one',
    Description: '',
  },
  {
    Code: 'C64',
    Name: 'pascal per kelvin',
    Description: '',
  },
  {
    Code: 'F81',
    Name: 'bar per kelvin',
    Description: '',
  },
  {
    Code: 'C96',
    Name: 'reciprocal pascal or pascal to the power minus one',
    Description: '',
  },
  {
    Code: 'JOU',
    Name: 'joule',
    Description: '',
  },
  {
    Code: 'J55',
    Name: 'watt second',
    Description: '',
  },
  {
    Code: 'BTU',
    Name: 'British thermal unit (international table)',
    Description: '',
  },
  {
    Code: 'A1',
    Name: '15 °C calorie',
    Description: '',
  },
  {
    Code: 'D70',
    Name: 'calorie (international table) ',
    Description: '',
  },
  {
    Code: 'J39',
    Name: 'British thermal unit (mean)',
    Description: '',
  },
  {
    Code: 'J75',
    Name: 'calorie (mean)',
    Description: '',
  },
  {
    Code: 'K51',
    Name: 'kilocalorie (mean)',
    Description: '',
  },
  {
    Code: 'E14',
    Name: 'kilocalorie (international table)',
    Description: '',
  },
  {
    Code: 'K53',
    Name: 'kilocalorie (thermochemical)',
    Description: '',
  },
  {
    Code: 'N66',
    Name: 'British thermal unit (39 ºF) ',
    Description:
      'Unit of heat energy according to the Imperial system of units in a reference temperature of 39 °F.',
  },
  {
    Code: 'N67',
    Name: 'British thermal unit (59 ºF)',
    Description:
      'Unit of heat energy according to the Imperial system of units in a reference temperature of 59 °F.',
  },
  {
    Code: 'N68',
    Name: 'British thermal unit (60 ºF) ',
    Description:
      'Unit of head energy according to the Imperial system of units at a reference temperature of 60 °F.',
  },
  {
    Code: 'N69',
    Name: 'calorie (20 ºC) ',
    Description:
      'Unit for quantity of heat, which is to be required for 1 g air free water at a constant pressure from 101,325 kPa, to warm up the pressure of standard atmosphere at sea level, from 19,5 °C on 20,5 °C.',
  },
  {
    Code: 'N70',
    Name: 'quad (1015 BtuIT)',
    Description: 'Unit of heat energy according to the imperial system of units.',
  },
  {
    Code: 'N71',
    Name: 'therm (EC)',
    Description:
      'Unit of heat energy in commercial use, within the EU defined: 1 thm (EC) = 100 000 BtuIT.',
  },
  {
    Code: 'N72',
    Name: 'therm (U.S.)',
    Description: 'Unit of heat energy in commercial use.',
  },
  {
    Code: 'D35',
    Name: 'calorie (thermochemical)',
    Description: '',
  },
  {
    Code: 'WTT',
    Name: 'watt',
    Description: '',
  },
  {
    Code: 'KWT',
    Name: 'kilowatt',
    Description: '',
  },
  {
    Code: '2I',
    Name: 'British thermal unit (international table) per hour',
    Description: '',
  },
  {
    Code: 'J44',
    Name: 'British thermal unit (international table) per minute',
    Description: '',
  },
  {
    Code: 'J45',
    Name: 'British thermal unit (international table) per second',
    Description: '',
  },
  {
    Code: 'J47',
    Name: 'British thermal unit (thermochemical) per hour',
    Description: '',
  },
  {
    Code: 'J51',
    Name: 'British thermal unit (thermochemical) per minute',
    Description: '',
  },
  {
    Code: 'J52',
    Name: 'British thermal unit (thermochemical) per second',
    Description: '',
  },
  {
    Code: 'J81',
    Name: 'calorie (thermochemical) per minute',
    Description: '',
  },
  {
    Code: 'J82',
    Name: 'calorie (thermochemical) per second',
    Description: '',
  },
  {
    Code: 'E15',
    Name: 'kilocalorie (thermochemical) per hour',
    Description: '',
  },
  {
    Code: 'K54',
    Name: 'kilocalorie (thermochemical) per minute',
    Description: '',
  },
  {
    Code: 'K55',
    Name: 'kilocalorie (thermochemical) per second',
    Description: '',
  },
  {
    Code: 'D54',
    Name: 'watt per square metre',
    Description: '',
  },
  {
    Code: 'N48',
    Name: 'watt per square centimetre ',
    Description:
      'Derived SI unit watt divided by the power of the 0,01-fold the SI base unit metre by exponent 2.',
  },
  {
    Code: 'N49',
    Name: 'watt per square inch ',
    Description:
      'Derived SI unit watt divided by the power of the unit inch according to the Anglo-American and Imperial system of units by exponent 2.',
  },
  {
    Code: 'N50',
    Name: 'British thermal unit (international table) per square foot hour',
    Description: 'Unit of the surface heat flux according to the Imperial system of units.',
  },
  {
    Code: 'N51',
    Name: 'British thermal unit (thermochemical) per square foot hour',
    Description: 'Unit of the surface heat flux according to the Imperial system of units.',
  },
  {
    Code: 'N52',
    Name: 'British thermal unit (thermochemical) per square foot minute',
    Description: 'Unit of the surface heat flux according to the Imperial system of units.',
  },
  {
    Code: 'N53',
    Name: 'British thermal unit (international table) per square foot second',
    Description: 'Unit of the surface heat flux according to the Imperial system of units.',
  },
  {
    Code: 'N54',
    Name: 'British thermal unit (thermochemical) per square foot second',
    Description: 'Unit of the surface heat flux according to the Imperial system of units.',
  },
  {
    Code: 'N55',
    Name: 'British thermal unit (international table) per square inch second',
    Description: 'Unit of the surface heat flux according to the Imperial system of units.',
  },
  {
    Code: 'N56',
    Name: 'calorie (thermochemical) per square centimetre minute',
    Description: 'Unit of the surface heat flux according to the Imperial system of units.',
  },
  {
    Code: 'N57',
    Name: 'calorie (thermochemical) per square centimetre second',
    Description: 'Unit of the surface heat flux according to the Imperial system of units.',
  },
  {
    Code: 'D53',
    Name: 'watt per metre kelvin',
    Description: '',
  },
  {
    Code: 'N80',
    Name: 'watt per metre degree Celsius',
    Description:
      'Derived SI unit watt divided by the product of the SI base unit metre and the unit for temperature degree Celsius.',
  },
  {
    Code: 'N81',
    Name: 'kilowatt per metre kelvin',
    Description:
      '1000-fold of the derived SI unit watt divided by the product of the SI base unit metre and the SI base unit kelvin.',
  },
  {
    Code: 'N82',
    Name: 'kilowatt per metre degree Celsius',
    Description:
      '1000-fold of the derived SI unit watt divided by the product of the SI base unit metre and the unit for temperature degree Celsius.',
  },
  {
    Code: 'A22',
    Name: 'British thermal unit (international table) per second foot degree Rankine',
    Description: '',
  },
  {
    Code: 'D71',
    Name: 'calorie (international table) per second centimetre kelvin',
    Description: '',
  },
  {
    Code: 'D38',
    Name: 'calorie (thermochemical) per second centimetre kelvin',
    Description: '',
  },
  {
    Code: 'J40',
    Name: 'British thermal unit (international table) foot per hour square foot degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'J41',
    Name: 'British thermal unit (international table) inch per hour square foot degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'J42',
    Name: 'British thermal unit (international table) inch per second square foot degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'J46',
    Name: 'British thermal unit (thermochemical) foot per hour square foot degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'J48',
    Name: 'British thermal unit (thermochemical) inch per hour square foot degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'J49',
    Name: 'British thermal unit (thermochemical) inch per second square foot degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'J78',
    Name: 'calorie (thermochemical) per centimetre second degree Celsius',
    Description: '',
  },
  {
    Code: 'K52',
    Name: 'kilocalorie (international table) per hour metre degree Celsius',
    Description: '',
  },
  {
    Code: 'D55',
    Name: 'watt per square metre kelvin',
    Description: '',
  },
  {
    Code: 'N78',
    Name: 'kilowatt per square metre kelvin',
    Description:
      '1000-fold of the derived SI unit watt divided by the product of the power of the SI base unit metre by exponent 2 and the SI base unit kelvin.',
  },
  {
    Code: 'D72',
    Name: 'calorie (international table) per second square centimetre kelvin',
    Description: '',
  },
  {
    Code: 'D39',
    Name: 'calorie (thermochemical) per second square centimetre kelvin',
    Description: '',
  },
  {
    Code: 'A20',
    Name: 'British thermal unit (international table) per second square foot degree Rankine',
    Description: '',
  },
  {
    Code: 'A23',
    Name: 'British thermal unit (international table) per hour square foot degree Rankine',
    Description: '',
  },
  {
    Code: 'N74',
    Name: 'British thermal unit (international table) per hour square foot degree Fahrenheit',
    Description:
      'Unit of the heat transition coefficient according to the Imperial system of units.',
  },
  {
    Code: 'N75',
    Name: 'British thermal unit (thermochemical) per hour square foot degree Fahrenheit',
    Description:
      'Unit of the heat transition coefficient according to the imperial system of units.',
  },
  {
    Code: 'N76',
    Name: 'British thermal unit (international table) per second square foot degree Fahrenheit',
    Description:
      'Unit of the heat transition coefficient according to the imperial system of units.',
  },
  {
    Code: 'N77',
    Name: 'British thermal unit (thermochemical) per second square foot degree Fahrenheit',
    Description:
      'Unit of the heat transition coefficient according to the imperial system of units.',
  },
  {
    Code: 'D19',
    Name: 'square metre kelvin per watt',
    Description: '',
  },
  {
    Code: 'J19',
    Name: 'degree Fahrenheit hour square foot per British thermal unit (thermochemical)',
    Description: '',
  },
  {
    Code: 'J22',
    Name: 'degree Fahrenheit hour square foot per British thermal unit (international table)',
    Description: '',
  },
  {
    Code: 'J83',
    Name: 'clo',
    Description: '',
  },
  {
    Code: 'L14',
    Name: 'square metre hour degree Celsius per kilocalorie (international table)',
    Description: '',
  },
  {
    Code: 'B21',
    Name: 'kelvin per watt',
    Description: '',
  },
  {
    Code: 'H35',
    Name: 'kelvin metre per watt',
    Description: '',
  },
  {
    Code: 'N84',
    Name: 'degree Fahrenheit hour per British thermal unit (international table)',
    Description:
      'Non SI-conforming unit of the thermal resistance according to the Imperial system of units.',
  },
  {
    Code: 'N85',
    Name: 'degree Fahrenheit hour per British thermal unit (thermochemical)',
    Description:
      'Non SI-conforming unit of the thermal resistance according to the Imperial system of units.',
  },
  {
    Code: 'N86',
    Name: 'degree Fahrenheit second per British thermal unit (international table)',
    Description:
      'Non SI-conforming unit of the thermal resistance according to the Imperial system of units.',
  },
  {
    Code: 'N87',
    Name: 'degree Fahrenheit second per British thermal unit (thermochemical)',
    Description:
      'Non SI-conforming unit of the thermal resistance according to the Imperial system of units.',
  },
  {
    Code: 'N88',
    Name: 'degree Fahrenheit hour square foot per British thermal unit (international table) inch',
    Description: 'Unit of specific thermal resistance according to the Imperial system of units.',
  },
  {
    Code: 'N89',
    Name: 'degree Fahrenheit hour square foot per British thermal unit (thermochemical) inch',
    Description: 'Unit of specific thermal resistance according to the Imperial system of units.',
  },
  {
    Code: 'D52',
    Name: 'watt per kelvin',
    Description: '',
  },
  {
    Code: 'S4',
    Name: 'square metre per second',
    Description: 'Synonym: metre squared per second (square metres/second US)',
  },
  {
    Code: 'S3',
    Name: 'square foot per second',
    Description: 'Synonym: foot squared per second',
  },
  {
    Code: 'E97',
    Name: 'millimetre per degree Celcius metre',
    Description: '',
  },
  {
    Code: 'F53',
    Name: 'millimetre per kelvin',
    Description: '',
  },
  {
    Code: 'N83',
    Name: 'metre per degree Celcius metre',
    Description:
      'SI base unit metre divided by the product of the unit degree Celsius and the SI base unit metre.',
  },
  {
    Code: 'JE',
    Name: 'joule per kelvin',
    Description: '',
  },
  {
    Code: 'B41',
    Name: 'kilojoule per kelvin',
    Description: '',
  },
  {
    Code: 'J43',
    Name: 'British thermal unit (international table) per pound degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'J50',
    Name: 'British thermal unit (thermochemical) per pound degree Fahrenheit',
    Description: '',
  },
  {
    Code: 'J76',
    Name: 'calorie (international table) per gram degree Celsius',
    Description: '',
  },
  {
    Code: 'J79',
    Name: 'calorie (thermochemical) per gram degree Celsius',
    Description: '',
  },
  {
    Code: 'N60',
    Name: 'British thermal unit (international table) per degree Fahrenheit',
    Description: 'Unit of the heat capacity according to the Imperial system of units.',
  },
  {
    Code: 'N61',
    Name: 'British thermal unit (thermochemical) per degree Fahrenheit',
    Description: 'Unit of the heat capacity according to the Imperial system of units.',
  },
  {
    Code: 'N62',
    Name: 'British thermal unit (international table) per degree Rankine',
    Description: 'Unit of the heat capacity according to the Imperial system of units.',
  },
  {
    Code: 'N63',
    Name: 'British thermal unit (thermochemical) per degree Rankine',
    Description: 'Unit of the heat capacity according to the Imperial system of units.',
  },
  {
    Code: 'N64',
    Name: 'British thermal unit (thermochemical) per pound degree Rankine',
    Description:
      'Unit of the heat capacity (British thermal unit according to the international table according to the Rankine degree) according to the Imperial system of units divided by the unit avoirdupois pound according to the avoirdupois system of units.',
  },
  {
    Code: 'N65',
    Name: 'kilocalorie (international table) per gram kelvin',
    Description:
      'Unit of the mass-related heat capacity as quotient 1000-fold of the calorie (international table) divided by the product of the 0,001-fold of the SI base units kilogram and kelvin.',
  },
  {
    Code: 'B11',
    Name: 'joule per kilogram kelvin',
    Description: '',
  },
  {
    Code: 'B43',
    Name: 'kilojoule per kilogram kelvin',
    Description: '',
  },
  {
    Code: 'A21',
    Name: 'British thermal unit (international table) per pound degree Rankine',
    Description: '',
  },
  {
    Code: 'D76',
    Name: 'calorie (international table) per gram kelvin',
    Description: '',
  },
  {
    Code: 'D37',
    Name: 'calorie (thermochemical) per gram kelvin',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'JE',
    Name: 'joule per kelvin',
    Description: '',
  },
  {
    Code: 'J2',
    Name: 'joule per kilogram',
    Description: '',
  },
  {
    Code: 'D95',
    Name: 'joule per gram',
    Description: '',
  },
  {
    Code: 'JK',
    Name: 'megajoule per kilogram',
    Description: '',
  },
  {
    Code: 'B42',
    Name: 'kilojoule per kilogram',
    Description: '',
  },
  {
    Code: 'AZ',
    Name: 'British thermal unit (international table) per pound',
    Description: '',
  },
  {
    Code: 'D75',
    Name: 'calorie (international table) per gram',
    Description: '',
  },
  {
    Code: 'N73',
    Name: 'British thermal unit (thermochemical) per pound',
    Description:
      'Unit of the heat energy according to the Imperial system of units divided the unit avoirdupois pound according to the avoirdupois system of units.',
  },
  {
    Code: 'B36',
    Name: 'calorie (thermochemical) per gram',
    Description: '',
  },
  {
    Code: 'N58',
    Name: 'British thermal unit (international table) per cubic foot ',
    Description: 'Unit of the energy density according to the Imperial system of units.',
  },
  {
    Code: 'N59',
    Name: 'British thermal unit (thermochemical) per cubic foot',
    Description: 'Unit of the energy density according to the Imperial system of units.',
  },
  {
    Code: 'Q31',
    Name: 'kilojoule per gram',
    Description: '',
  },
  {
    Code: 'AMP',
    Name: 'ampere',
    Description: '',
  },
  {
    Code: 'B22',
    Name: 'kiloampere',
    Description: '',
  },
  {
    Code: 'H38',
    Name: 'megaampere',
    Description: '',
  },
  {
    Code: '4K',
    Name: 'milliampere',
    Description: '',
  },
  {
    Code: 'B84',
    Name: 'microampere',
    Description: '',
  },
  {
    Code: 'C39',
    Name: 'nanoampere',
    Description: '',
  },
  {
    Code: 'C70',
    Name: 'picoampere',
    Description: '',
  },
  {
    Code: 'N96',
    Name: 'biot',
    Description:
      'CGS (Centimetre-Gram-Second system) unit of the electric power which is defined by a force of 2 dyn per cm between two parallel conductors of infinite length with negligible cross-section in the distance of 1 cm.',
  },
  {
    Code: 'N97',
    Name: 'gilbert',
    Description:
      'CGS (Centimetre-Gram-Second system) unit of the magnetomotive force, which is defined by the work to increase the magnetic potential of a positive common pol with 1 erg.',
  },
  {
    Code: 'COU',
    Name: 'coulomb',
    Description: '',
  },
  {
    Code: 'A8',
    Name: 'ampere second',
    Description: '',
  },
  {
    Code: 'H32',
    Name: 'ampere squared second',
    Description: '',
  },
  {
    Code: 'AMH',
    Name: 'ampere hour',
    Description:
      'A unit of electric charge defining the amount of charge accumulated by a steady flow of one ampere for one hour.',
  },
  {
    Code: 'TAH',
    Name: 'kiloampere hour (thousand ampere hour)',
    Description: '',
  },
  {
    Code: 'D77',
    Name: 'megacoulomb',
    Description: '',
  },
  {
    Code: 'D86',
    Name: 'millicoulomb',
    Description: '',
  },
  {
    Code: 'B26',
    Name: 'kilocoulomb',
    Description: '',
  },
  {
    Code: 'B86',
    Name: 'microcoulomb',
    Description: '',
  },
  {
    Code: 'C40',
    Name: 'nanocoulomb',
    Description: '',
  },
  {
    Code: 'C71',
    Name: 'picocoulomb',
    Description: '',
  },
  {
    Code: 'E09',
    Name: 'milliampere hour',
    Description:
      'A unit of power load delivered at the rate of one thousandth of an ampere over a period of one hour.',
  },
  {
    Code: 'N95',
    Name: 'ampere minute',
    Description:
      'A unit of electric charge defining the amount of charge accumulated by a steady flow of one ampere for one minute..',
  },
  {
    Code: 'N94',
    Name: 'franklin',
    Description:
      'CGS (Centimetre-Gram-Second system) unit of the electrical charge, where the charge amounts to exactly 1 Fr where the force of 1 dyn on an equal load is performed at a distance of 1 cm.',
  },
  {
    Code: 'A29',
    Name: 'coulomb per cubic metre',
    Description: '',
  },
  {
    Code: 'A84',
    Name: 'gigacoulomb per cubic metre',
    Description: '',
  },
  {
    Code: 'A30',
    Name: 'coulomb per cubic millimetre',
    Description: '',
  },
  {
    Code: 'B69',
    Name: 'megacoulomb per cubic metre',
    Description: '',
  },
  {
    Code: 'A28',
    Name: 'coulomb per cubic centimetre',
    Description: '',
  },
  {
    Code: 'B27',
    Name: 'kilocoulomb per cubic metre',
    Description: '',
  },
  {
    Code: 'D88',
    Name: 'millicoulomb per cubic metre',
    Description: '',
  },
  {
    Code: 'B87',
    Name: 'microcoulomb per cubic metre',
    Description: '',
  },
  {
    Code: 'A34',
    Name: 'coulomb per square metre',
    Description: '',
  },
  {
    Code: 'B70',
    Name: 'megacoulomb per square metre',
    Description: '',
  },
  {
    Code: 'A35',
    Name: 'coulomb per square millimetre',
    Description: '',
  },
  {
    Code: 'A33',
    Name: 'coulomb per square centimetre',
    Description: '',
  },
  {
    Code: 'B28',
    Name: 'kilocoulomb per square metre',
    Description: '',
  },
  {
    Code: 'D89',
    Name: 'millicoulomb per square metre',
    Description: '',
  },
  {
    Code: 'B88',
    Name: 'microcoulomb per square metre',
    Description: '',
  },
  {
    Code: 'D50',
    Name: 'volt per metre',
    Description: '',
  },
  {
    Code: 'H45',
    Name: 'volt second per metre',
    Description: '',
  },
  {
    Code: 'D45',
    Name: 'volt squared per kelvin squared',
    Description: '',
  },
  {
    Code: 'D51',
    Name: 'volt per millimetre',
    Description: '',
  },
  {
    Code: 'H24',
    Name: 'volt per microsecond',
    Description: '',
  },
  {
    Code: 'H62',
    Name: 'millivolt per minute',
    Description: '',
  },
  {
    Code: 'H46',
    Name: 'volt per second',
    Description: '',
  },
  {
    Code: 'B79',
    Name: 'megavolt per metre',
    Description: '',
  },
  {
    Code: 'B55',
    Name: 'kilovolt per metre',
    Description: '',
  },
  {
    Code: 'D47',
    Name: 'volt per centimetre',
    Description: '',
  },
  {
    Code: 'C30',
    Name: 'millivolt per metre',
    Description: '',
  },
  {
    Code: 'C3',
    Name: 'microvolt per metre',
    Description: '',
  },
  {
    Code: 'G60',
    Name: 'volt per bar',
    Description: '',
  },
  {
    Code: 'N98',
    Name: 'volt per pascal',
    Description: 'Derived SI unit volt divided by the derived SI unit pascal.',
  },
  {
    Code: 'F87',
    Name: 'volt per litre minute',
    Description: '',
  },
  {
    Code: 'H22',
    Name: 'volt square inch per pound-force',
    Description: '',
  },
  {
    Code: 'H23',
    Name: 'volt per inch',
    Description: '',
  },
  {
    Code: 'VLT',
    Name: 'volt',
    Description: '',
  },
  {
    Code: 'B78',
    Name: 'megavolt',
    Description: '',
  },
  {
    Code: 'KVT',
    Name: 'kilovolt',
    Description: '',
  },
  {
    Code: '2Z',
    Name: 'millivolt',
    Description: '',
  },
  {
    Code: 'D82',
    Name: 'microvolt',
    Description: '',
  },
  {
    Code: 'N99',
    Name: 'picovolt',
    Description: '0,000 000 000 001-fold of the derived SI unit volt.',
  },
  {
    Code: 'FAR',
    Name: 'farad',
    Description: '',
  },
  {
    Code: 'H48',
    Name: 'attofarad',
    Description: '',
  },
  {
    Code: 'C10',
    Name: 'millifarad',
    Description: '',
  },
  {
    Code: '4O',
    Name: 'microfarad',
    Description: '',
  },
  {
    Code: 'C41',
    Name: 'nanofarad',
    Description: '',
  },
  {
    Code: '4T',
    Name: 'picofarad',
    Description: '',
  },
  {
    Code: 'N90',
    Name: 'kilofarad',
    Description: '1000-fold of the derived SI unit farad.',
  },
  {
    Code: 'A69',
    Name: 'farad per metre',
    Description: '',
  },
  {
    Code: 'H28',
    Name: 'microfarad per kilometre',
    Description: '',
  },
  {
    Code: 'H33',
    Name: 'farad per kilometre',
    Description: '',
  },
  {
    Code: 'B89',
    Name: 'microfarad per metre',
    Description: '',
  },
  {
    Code: 'C42',
    Name: 'nanofarad per metre',
    Description: '',
  },
  {
    Code: 'C72',
    Name: 'picofarad per metre',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'A26',
    Name: 'coulomb metre',
    Description: '',
  },
  {
    Code: 'A41',
    Name: 'ampere per square metre',
    Description: '',
  },
  {
    Code: 'H31',
    Name: 'ampere per kilogram',
    Description: '',
  },
  {
    Code: 'B66',
    Name: 'megaampere per square metre',
    Description: '',
  },
  {
    Code: 'A7',
    Name: 'ampere per square millimetre',
    Description: '',
  },
  {
    Code: 'A4',
    Name: 'ampere per square centimetre',
    Description: '',
  },
  {
    Code: 'B23',
    Name: 'kiloampere per square metre',
    Description: '',
  },
  {
    Code: 'G59',
    Name: 'milliampere per litre minute',
    Description: '',
  },
  {
    Code: 'N93',
    Name: 'ampere per pascal',
    Description: 'SI base unit ampere divided by the derived SI unit pascal.',
  },
  {
    Code: 'F57',
    Name: 'milliampere per pound-force per square inch',
    Description: '',
  },
  {
    Code: 'F59',
    Name: 'milliampere per bar',
    Description: '',
  },
  {
    Code: 'AE',
    Name: 'ampere per metre',
    Description: '',
  },
  {
    Code: 'B24',
    Name: 'kiloampere per metre',
    Description: '',
  },
  {
    Code: 'A3',
    Name: 'ampere per millimetre',
    Description: '',
  },
  {
    Code: 'A2',
    Name: 'ampere per centimetre',
    Description: '',
  },
  {
    Code: 'F76',
    Name: 'milliampere per millimetre',
    Description: '',
  },
  {
    Code: 'F08',
    Name: 'milliampere per inch',
    Description: '',
  },
  {
    Code: 'P10',
    Name: 'coulomb per metre',
    Description: 'Derived SI unit coulomb divided by the SI base unit metre.',
  },
  {
    Code: 'D33',
    Name: 'tesla',
    Description: '',
  },
  {
    Code: 'C29',
    Name: 'millitesla',
    Description: '',
  },
  {
    Code: 'D81',
    Name: 'microtesla',
    Description: '',
  },
  {
    Code: 'C48',
    Name: 'nanotesla',
    Description: '',
  },
  {
    Code: 'P13',
    Name: 'kilotesla',
    Description: '1000-fold of the derived SI unit tesla.',
  },
  {
    Code: 'P12',
    Name: 'gamma',
    Description: 'Unit of magnetic flow density.',
  },
  {
    Code: 'WEB',
    Name: 'weber',
    Description: '',
  },
  {
    Code: 'C33',
    Name: 'milliweber',
    Description: '',
  },
  {
    Code: 'P11',
    Name: 'kiloweber',
    Description: '1000 fold of the derived SI unit weber.',
  },
  {
    Code: 'D59',
    Name: 'weber per metre',
    Description: '',
  },
  {
    Code: 'B56',
    Name: 'kiloweber per metre',
    Description: '',
  },
  {
    Code: 'D60',
    Name: 'weber per millimetre',
    Description: '',
  },
  {
    Code: '81',
    Name: 'henry',
    Description: '',
  },
  {
    Code: 'C14',
    Name: 'millihenry',
    Description: '',
  },
  {
    Code: 'B90',
    Name: 'microhenry',
    Description: '',
  },
  {
    Code: 'C43',
    Name: 'nanohenry',
    Description: '',
  },
  {
    Code: 'C73',
    Name: 'picohenry',
    Description: '',
  },
  {
    Code: 'H03',
    Name: 'henry per kiloohm',
    Description: '',
  },
  {
    Code: 'H04',
    Name: 'henry per ohm',
    Description: '',
  },
  {
    Code: 'G98',
    Name: 'microhenry per kiloohm',
    Description: '',
  },
  {
    Code: 'G99',
    Name: 'microhenry per ohm',
    Description: '',
  },
  {
    Code: 'H05',
    Name: 'millihenry per kiloohm',
    Description: '',
  },
  {
    Code: 'H06',
    Name: 'millihenry per ohm',
    Description: '',
  },
  {
    Code: 'P24',
    Name: 'kilohenry',
    Description: '1000-fold of the derived SI unit henry.',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'A98',
    Name: 'henry per metre',
    Description: '',
  },
  {
    Code: 'B91',
    Name: 'microhenry per metre',
    Description: '',
  },
  {
    Code: 'C44',
    Name: 'nanohenry per metre',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'A5',
    Name: 'ampere square metre',
    Description: '',
  },
  {
    Code: 'AE',
    Name: 'ampere per metre',
    Description: '',
  },
  {
    Code: 'B8',
    Name: 'joule per cubic metre',
    Description: '',
  },
  {
    Code: 'D54',
    Name: 'watt per square metre',
    Description: '',
  },
  {
    Code: 'MTS',
    Name: 'metre per second',
    Description: '',
  },
  {
    Code: 'OHM',
    Name: 'ohm',
    Description: '',
  },
  {
    Code: 'A87',
    Name: 'gigaohm',
    Description: '',
  },
  {
    Code: 'B75',
    Name: 'megaohm',
    Description: '',
  },
  {
    Code: 'H44',
    Name: 'teraohm',
    Description: '',
  },
  {
    Code: 'B49',
    Name: 'kiloohm',
    Description: '',
  },
  {
    Code: 'E45',
    Name: 'milliohm',
    Description: '',
  },
  {
    Code: 'B94',
    Name: 'microohm',
    Description: '',
  },
  {
    Code: 'P22',
    Name: 'nanoohm',
    Description: '0,000 000 001-fold of the derived SI unit ohm.',
  },
  {
    Code: 'M26',
    Name: 'gigaohm per metre',
    Description: '',
  },
  {
    Code: 'SIE',
    Name: 'siemens',
    Description: '',
  },
  {
    Code: 'B53',
    Name: 'kilosiemens',
    Description: '',
  },
  {
    Code: 'C27',
    Name: 'millisiemens',
    Description: '',
  },
  {
    Code: 'B99',
    Name: 'microsiemens',
    Description: '',
  },
  {
    Code: 'G42',
    Name: 'microsiemens per centimetre',
    Description: '',
  },
  {
    Code: 'G43',
    Name: 'microsiemens per metre',
    Description: '',
  },
  {
    Code: 'N92',
    Name: 'picosiemens',
    Description: '0,000 000 000 001-fold of the derived SI unit siemens.',
  },
  {
    Code: 'NQ',
    Name: 'mho',
    Description: '',
  },
  {
    Code: 'NR',
    Name: 'micromho',
    Description: '',
  },
  {
    Code: 'C61',
    Name: 'ohm metre',
    Description: '',
  },
  {
    Code: 'A88',
    Name: 'gigaohm metre',
    Description: '',
  },
  {
    Code: 'B76',
    Name: 'megaohm metre',
    Description: '',
  },
  {
    Code: 'H88',
    Name: 'megaohm kilometre',
    Description: '',
  },
  {
    Code: 'B50',
    Name: 'kiloohm metre',
    Description: '',
  },
  {
    Code: 'C60',
    Name: 'ohm centimetre',
    Description: '',
  },
  {
    Code: 'C23',
    Name: 'milliohm metre',
    Description: '',
  },
  {
    Code: 'B95',
    Name: 'microohm metre',
    Description: '',
  },
  {
    Code: 'C46',
    Name: 'nanoohm metre',
    Description: '',
  },
  {
    Code: 'M24',
    Name: 'ohm kilometre',
    Description: '',
  },
  {
    Code: 'P23',
    Name: 'ohm circular-mil per foot ',
    Description: 'Unit of resistivity.',
  },
  {
    Code: 'F56',
    Name: 'ohm per kilometre',
    Description: '',
  },
  {
    Code: 'H26',
    Name: 'ohm per metre',
    Description: '',
  },
  {
    Code: 'H37',
    Name: 'megaohm per metre',
    Description: '',
  },
  {
    Code: 'F54',
    Name: 'milliohm per metre',
    Description: '',
  },
  {
    Code: 'H36',
    Name: 'megaohm per kilometre',
    Description: '',
  },
  {
    Code: 'F55',
    Name: 'ohm per mile (statute mile)',
    Description: '',
  },
  {
    Code: 'D10',
    Name: 'siemens per metre',
    Description: '',
  },
  {
    Code: 'H43',
    Name: 'siemens per centimetre',
    Description: '',
  },
  {
    Code: 'H61',
    Name: 'millisiemens per centimetre',
    Description: '',
  },
  {
    Code: 'B77',
    Name: 'megasiemens per metre',
    Description: '',
  },
  {
    Code: 'B54',
    Name: 'kilosiemens per metre',
    Description: '',
  },
  {
    Code: 'G45',
    Name: 'nanosiemens per metre',
    Description: '',
  },
  {
    Code: 'G44',
    Name: 'nanosiemens per centimetre',
    Description: '',
  },
  {
    Code: 'L42',
    Name: 'picosiemens per metre',
    Description: '',
  },
  {
    Code: 'C89',
    Name: 'reciprocal henry',
    Description: '',
  },
  {
    Code: 'C81',
    Name: 'radian',
    Description: '',
  },
  {
    Code: 'WTT',
    Name: 'watt',
    Description: '',
  },
  {
    Code: 'P14',
    Name: 'joule per second',
    Description: 'Quotient of the derived SI unit joule divided by the SI base unit second.',
  },
  {
    Code: 'KWT',
    Name: 'kilowatt',
    Description: '',
  },
  {
    Code: 'MAW',
    Name: 'megawatt',
    Description:
      'A unit of power defining the rate of energy transferred or consumed when a current of 1000 amperes flows due to a potential of 1000 volts at unity power factor.',
  },
  {
    Code: 'A90',
    Name: 'gigawatt',
    Description: '',
  },
  {
    Code: 'D31',
    Name: 'terawatt',
    Description: '',
  },
  {
    Code: 'C31',
    Name: 'milliwatt',
    Description: '',
  },
  {
    Code: 'P15',
    Name: 'joule per minute',
    Description: 'Quotient from the derived SI unit joule divided by the unit minute.',
  },
  {
    Code: 'P16',
    Name: 'joule per hour',
    Description: 'Quotient from the derived SI unit joule divided by the unit hour.',
  },
  {
    Code: 'P17',
    Name: 'joule per day',
    Description: 'Quotient from the derived SI unit joule divided by the unit day.',
  },
  {
    Code: 'P18',
    Name: 'kilojoule per second',
    Description:
      'Quotient from the 1000-fold of the derived SI unit joule divided by the SI base unit second.',
  },
  {
    Code: 'P19',
    Name: 'kilojoule per minute',
    Description:
      'Quotient from the 1000-fold of the derived SI unit joule divided by the unit minute.',
  },
  {
    Code: 'P20',
    Name: 'kilojoule per hour',
    Description:
      'Quotient from the 1000-fold of the derived SI unit joule divided by the unit hour.',
  },
  {
    Code: 'P21',
    Name: 'kilojoule per day',
    Description:
      'Quotient from the 1000-fold of the derived SI unit joule divided by the unit day.',
  },
  {
    Code: 'D80',
    Name: 'microwatt',
    Description: '',
  },
  {
    Code: 'K43',
    Name: 'horsepower (electric)',
    Description: '',
  },
  {
    Code: 'C49',
    Name: 'nanowatt',
    Description: '',
  },
  {
    Code: 'C75',
    Name: 'picowatt',
    Description: '',
  },
  {
    Code: 'D46',
    Name: 'volt - ampere',
    Description: '',
  },
  {
    Code: 'MVA',
    Name: 'megavolt - ampere',
    Description: '',
  },
  {
    Code: 'KVA',
    Name: 'kilovolt - ampere',
    Description: '',
  },
  {
    Code: 'M35',
    Name: 'millivolt - ampere',
    Description: '',
  },
  {
    Code: 'D44',
    Name: 'var',
    Description: 'The name of the unit is an acronym for volt-ampere-reactive.',
  },
  {
    Code: 'K5',
    Name: 'kilovolt ampere (reactive)',
    Description: 'Use kilovar (common code KVR)',
  },
  {
    Code: 'KVR',
    Name: 'kilovar',
    Description: '',
  },
  {
    Code: 'MAR',
    Name: 'megavar',
    Description:
      'A unit of electrical reactive power represented by a current of one thousand amperes flowing due a potential difference of one thousand volts where the sine of the phase angle between them is 1.',
  },
  {
    Code: 'JOU',
    Name: 'joule',
    Description: '',
  },
  {
    Code: 'WHR',
    Name: 'watt hour',
    Description: '',
  },
  {
    Code: 'N91',
    Name: 'reciprocal joule',
    Description: 'Reciprocal of the derived SI unit joule.',
  },
  {
    Code: 'M30',
    Name: 'reciprocal volt - ampere reciprocal second',
    Description: '',
  },
  {
    Code: 'M17',
    Name: 'kilohertz metre',
    Description: '',
  },
  {
    Code: 'M18',
    Name: 'gigahertz metre',
    Description: '',
  },
  {
    Code: 'M27',
    Name: 'megahertz metre',
    Description: '',
  },
  {
    Code: 'M21',
    Name: 'reciprocal kilovolt - ampere reciprocal hour',
    Description: '',
  },
  {
    Code: 'H34',
    Name: 'hertz metre',
    Description: '',
  },
  {
    Code: 'H39',
    Name: 'megahertz kilometre',
    Description: '',
  },
  {
    Code: 'HTZ',
    Name: 'hertz',
    Description: '',
  },
  {
    Code: 'C97',
    Name: 'reciprocal second',
    Description: '',
  },
  {
    Code: '2A',
    Name: 'radian per second',
    Description: 'Refer ISO/TC12 SI Guide',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'A11',
    Name: 'angstrom',
    Description: '',
  },
  {
    Code: 'C92',
    Name: 'reciprocal metre',
    Description: '',
  },
  {
    Code: 'C84',
    Name: 'radian per metre',
    Description: '',
  },
  {
    Code: 'MTS',
    Name: 'metre per second',
    Description: '',
  },
  {
    Code: 'JOU',
    Name: 'joule',
    Description: '',
  },
  {
    Code: 'B8',
    Name: 'joule per cubic metre',
    Description: '',
  },
  {
    Code: 'JM',
    Name: 'megajoule per cubic metre',
    Description: '',
  },
  {
    Code: 'B14',
    Name: 'joule per metre to the fourth power',
    Description: '',
  },
  {
    Code: 'WTT',
    Name: 'watt',
    Description: '',
  },
  {
    Code: 'B13',
    Name: 'joule per square metre',
    Description: 'Synonym: joule per metre squared',
  },
  {
    Code: 'C97',
    Name: 'reciprocal second',
    Description: '',
  },
  {
    Code: 'D1',
    Name: 'reciprocal second per steradian',
    Description: '',
  },
  {
    Code: 'D2',
    Name: 'reciprocal second per steradian metre squared',
    Description: '',
  },
  {
    Code: 'C99',
    Name: 'reciprocal second per metre squared',
    Description: '',
  },
  {
    Code: 'C93',
    Name: 'reciprocal square metre',
    Description: 'Synonym: reciprocal metre squared',
  },
  {
    Code: 'D54',
    Name: 'watt per square metre',
    Description: '',
  },
  {
    Code: 'H47',
    Name: 'watt per cubic metre',
    Description: '',
  },
  {
    Code: 'H74',
    Name: 'watt per metre',
    Description: '',
  },
  {
    Code: 'E43',
    Name: 'joule per square centimetre',
    Description: 'A unit of energy defining the number of joules per square centimetre.',
  },
  {
    Code: 'P37',
    Name: 'British thermal unit (international table) per square foot',
    Description:
      'Unit of the areal-related energy transmission according to the Imperial system of units.',
  },
  {
    Code: 'P38',
    Name: 'British thermal unit (thermochemical) per square foot',
    Description:
      'Unit of the areal-related energy transmission according to the Imperial system of units.',
  },
  {
    Code: 'P39',
    Name: 'calorie (thermochemical) per square centimetre ',
    Description:
      'Unit of the areal-related energy transmission according to the Imperial system of units.',
  },
  {
    Code: 'P40',
    Name: 'langley',
    Description:
      "CGS (Centimetre-Gram-Second system) unit of the areal-related energy transmission (as a measure of the incident quantity of heat of solar radiation on the earth's surface).",
  },
  {
    Code: 'D57',
    Name: 'watt per steradian',
    Description: '',
  },
  {
    Code: 'D58',
    Name: 'watt per steradian square metre',
    Description: '',
  },
  {
    Code: 'D56',
    Name: 'watt per square metre kelvin to the fourth power',
    Description: '',
  },
  {
    Code: 'D18',
    Name: 'metre kelvin',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'CDL',
    Name: 'candela',
    Description: '',
  },
  {
    Code: 'P33',
    Name: 'kilocandela',
    Description: '1000-fold of the SI base unit candela.',
  },
  {
    Code: 'P34',
    Name: 'millicandela',
    Description: '0,001-fold of the SI base unit candela.',
  },
  {
    Code: 'P35',
    Name: 'Hefner-Kerze',
    Description:
      'Obsolete, non-legal unit of the power in Germany relating to DIN 1301-3:1979: 1 HK = 0,903 cd.',
  },
  {
    Code: 'P36',
    Name: 'international candle ',
    Description:
      'Obsolete, non-legal unit of the power in Germany relating to DIN 1301-3:1979: 1 HK = 1,019 cd.',
  },
  {
    Code: 'LUM',
    Name: 'lumen',
    Description: '',
  },
  {
    Code: 'B62',
    Name: 'lumen second',
    Description: '',
  },
  {
    Code: 'B59',
    Name: 'lumen hour',
    Description: '',
  },
  {
    Code: 'A24',
    Name: 'candela per square metre',
    Description: '',
  },
  {
    Code: 'P28',
    Name: 'candela per square inch ',
    Description:
      'SI base unit candela divided by the power of unit inch according to the Anglo-American and Imperial system of units by exponent 2.',
  },
  {
    Code: 'P29',
    Name: 'footlambert',
    Description:
      'Unit of the luminance according to the Anglo-American system of units, defined as emitted or reflected luminance of a lm/ft².',
  },
  {
    Code: 'P30',
    Name: 'lambert',
    Description:
      'CGS (Centimetre-Gram-Second system) unit of luminance, defined as the emitted or reflected luminance by one lumen per square centimetre.',
  },
  {
    Code: 'P31',
    Name: 'stilb',
    Description:
      'CGS (Centimetre-Gram-Second system) unit of luminance, defined as emitted or reflected luminance by one lumen per square centimetre.',
  },
  {
    Code: 'P32',
    Name: 'candela per square foot',
    Description:
      'Base unit SI candela divided by the power of the unit foot according to the Anglo-American and Imperial system of units by exponent 2.',
  },
  {
    Code: 'B60',
    Name: 'lumen per square metre',
    Description: '',
  },
  {
    Code: 'LUX',
    Name: 'lux',
    Description: '',
  },
  {
    Code: 'KLX',
    Name: 'kilolux',
    Description: 'A unit of illuminance equal to one thousand lux.',
  },
  {
    Code: 'P25',
    Name: 'lumen per square foot ',
    Description:
      'Derived SI unit lumen divided by the power of the unit foot according to the Anglo-American and Imperial system of units by exponent 2.',
  },
  {
    Code: 'P26',
    Name: 'phot',
    Description:
      'CGS (Centimetre-Gram-Second system) unit of the illuminance, defined as lumen by square centimetre.',
  },
  {
    Code: 'P27',
    Name: 'footcandle',
    Description:
      'Non SI conform traditional unit, defined as density of light which impinges on a surface which has a distance of one foot from a light source, which shines with an intensity of an international candle.',
  },
  {
    Code: 'B64',
    Name: 'lux second',
    Description: '',
  },
  {
    Code: 'B63',
    Name: 'lux hour',
    Description: '',
  },
  {
    Code: 'B61',
    Name: 'lumen per watt',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C92',
    Name: 'reciprocal metre',
    Description: '',
  },
  {
    Code: 'D22',
    Name: 'square metre per mole',
    Description: '',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'C92',
    Name: 'reciprocal metre',
    Description: '',
  },
  {
    Code: 'SEC',
    Name: 'second [unit of time]',
    Description: '',
  },
  {
    Code: 'HTZ',
    Name: 'hertz',
    Description: '',
  },
  {
    Code: 'C59',
    Name: 'octave',
    Description: 'A unit used in music to describe the ratio in frequency between notes.',
  },
  {
    Code: 'C97',
    Name: 'reciprocal second',
    Description: '',
  },
  {
    Code: '2A',
    Name: 'radian per second',
    Description: 'Refer ISO/TC12 SI Guide',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'C92',
    Name: 'reciprocal metre',
    Description: '',
  },
  {
    Code: 'C84',
    Name: 'radian per metre',
    Description: '',
  },
  {
    Code: 'KMQ',
    Name: 'kilogram per cubic metre',
    Description:
      'A unit of weight expressed in kilograms of a substance that fills a volume of one cubic metre.',
  },
  {
    Code: 'PAL',
    Name: 'pascal',
    Description: '',
  },
  {
    Code: 'BAR',
    Name: 'bar [unit of pressure]',
    Description: '',
  },
  {
    Code: 'D9',
    Name: 'dyne per square centimetre',
    Description: '',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'MTS',
    Name: 'metre per second',
    Description: '',
  },
  {
    Code: 'MSK',
    Name: 'metre per second squared',
    Description: '',
  },
  {
    Code: 'MQS',
    Name: 'cubic metre per second',
    Description: '',
  },
  {
    Code: 'MTS',
    Name: 'metre per second',
    Description: '',
  },
  {
    Code: 'B8',
    Name: 'joule per cubic metre',
    Description: '',
  },
  {
    Code: 'A60',
    Name: 'erg per cubic centimetre',
    Description: '',
  },
  {
    Code: 'WTT',
    Name: 'watt',
    Description: '',
  },
  {
    Code: 'A63',
    Name: 'erg per second',
    Description: '',
  },
  {
    Code: 'D54',
    Name: 'watt per square metre',
    Description: '',
  },
  {
    Code: 'C32',
    Name: 'milliwatt per square metre',
    Description: '',
  },
  {
    Code: 'D85',
    Name: 'microwatt per square metre',
    Description: '',
  },
  {
    Code: 'C76',
    Name: 'picowatt per square metre',
    Description: '',
  },
  {
    Code: 'A64',
    Name: 'erg per second square centimetre',
    Description: '',
  },
  {
    Code: 'C67',
    Name: 'pascal second per metre',
    Description: '',
  },
  {
    Code: 'A50',
    Name: 'dyne second per cubic centimetre',
    Description: '',
  },
  {
    Code: 'C66',
    Name: 'pascal second per cubic metre',
    Description: '',
  },
  {
    Code: 'A52',
    Name: 'dyne second per centimetre to the fifth power',
    Description: '',
  },
  {
    Code: 'M32',
    Name: 'pascal second per litre',
    Description: '',
  },
  {
    Code: 'C58',
    Name: 'newton second per metre',
    Description: '',
  },
  {
    Code: 'A51',
    Name: 'dyne second per centimetre',
    Description: '',
  },
  {
    Code: '2N',
    Name: 'decibel',
    Description: '',
  },
  {
    Code: 'P43',
    Name: 'bel per metre',
    Description: 'Unit bel divided by the SI base unit metre.',
  },
  {
    Code: 'C97',
    Name: 'reciprocal second',
    Description: '',
  },
  {
    Code: 'H51',
    Name: 'decibel per kilometre',
    Description: '',
  },
  {
    Code: 'H52',
    Name: 'decibel per metre',
    Description: '',
  },
  {
    Code: 'C51',
    Name: 'neper per second',
    Description: '',
  },
  {
    Code: 'SEC',
    Name: 'second [unit of time]',
    Description: '',
  },
  {
    Code: 'C50',
    Name: 'neper',
    Description: '',
  },
  {
    Code: 'C92',
    Name: 'reciprocal metre',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: '2N',
    Name: 'decibel',
    Description: '',
  },
  {
    Code: 'MTK',
    Name: 'square metre',
    Description: '',
  },
  {
    Code: 'SEC',
    Name: 'second [unit of time]',
    Description: '',
  },
  {
    Code: 'C69',
    Name: 'phon',
    Description:
      'A unit of subjective sound loudness. A sound has loudness p phons if it seems to the listener to be equal in loudness to the sound of a pure tone of frequency 1 kilohertz and strength p decibels.',
  },
  {
    Code: 'D15',
    Name: 'sone',
    Description:
      'A unit of subjective sound loudness. One sone is the loudness of a pure tone of frequency one kilohertz and strength 40 decibels.',
  },
  {
    Code: 'P42',
    Name: 'pascal squared second',
    Description:
      'Unit of the set as a product of the power of derived SI unit pascal with exponent 2 and the SI base unit second.',
  },
  {
    Code: 'P41',
    Name: 'decade (logarithmic)',
    Description:
      '1 Dec := log2 10 ˜ 3,32 according to the logarithm for frequency range between f1 and f2, when f2/f1 = 10.',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C34',
    Name: 'mole',
    Description: '',
  },
  {
    Code: 'B45',
    Name: 'kilomole',
    Description: '',
  },
  {
    Code: 'C18',
    Name: 'millimole',
    Description: '',
  },
  {
    Code: 'FH',
    Name: 'micromole',
    Description: '',
  },
  {
    Code: 'Z9',
    Name: 'nanomole',
    Description: 'An SI unit of amount of substance equal to 10−9 moles',
  },
  {
    Code: 'P44',
    Name: 'pound mole',
    Description:
      'Non SI-conforming unit of quantity of a substance relating that one pound mole of a chemical composition corresponds to the same number of pounds as the molecular weight of one molecule of this composition in atomic mass units.',
  },
  {
    Code: 'C95',
    Name: 'reciprocal mole',
    Description: '',
  },
  {
    Code: 'D74',
    Name: 'kilogram per mole',
    Description: '',
  },
  {
    Code: 'A94',
    Name: 'gram per mole',
    Description: '',
  },
  {
    Code: 'A40',
    Name: 'cubic metre per mole',
    Description: '',
  },
  {
    Code: 'A37',
    Name: 'cubic decimetre per mole',
    Description: '',
  },
  {
    Code: 'A36',
    Name: 'cubic centimetre per mole',
    Description: '',
  },
  {
    Code: 'B58',
    Name: 'litre per mole',
    Description: '',
  },
  {
    Code: 'B15',
    Name: 'joule per mole',
    Description: '',
  },
  {
    Code: 'B44',
    Name: 'kilojoule per mole',
    Description: '',
  },
  {
    Code: 'B15',
    Name: 'joule per mole',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'B15',
    Name: 'joule per mole',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'B16',
    Name: 'joule per mole kelvin',
    Description: '',
  },
  {
    Code: 'C86',
    Name: 'reciprocal cubic metre',
    Description: '',
  },
  {
    Code: 'H50',
    Name: 'reciprocal cubic centimetre',
    Description: '',
  },
  {
    Code: 'L20',
    Name: 'reciprocal cubic millimetre',
    Description: '',
  },
  {
    Code: 'K20',
    Name: 'reciprocal cubic foot',
    Description: '',
  },
  {
    Code: 'K49',
    Name: 'reciprocal cubic inch',
    Description: '',
  },
  {
    Code: 'K63',
    Name: 'reciprocal litre',
    Description: '',
  },
  {
    Code: 'M10',
    Name: 'reciprocal cubic yard',
    Description: '',
  },
  {
    Code: 'KMQ',
    Name: 'kilogram per cubic metre',
    Description:
      'A unit of weight expressed in kilograms of a substance that fills a volume of one cubic metre.',
  },
  {
    Code: 'C36',
    Name: 'mole per cubic metre',
    Description: '',
  },
  {
    Code: 'C38',
    Name: 'mole per litre',
    Description: '',
  },
  {
    Code: 'B35',
    Name: 'kilogram per litre',
    Description: '',
  },
  {
    Code: 'C35',
    Name: 'mole per cubic decimetre',
    Description: '',
  },
  {
    Code: 'B46',
    Name: 'kilomole per cubic metre',
    Description: '',
  },
  {
    Code: 'E95',
    Name: 'mole per second',
    Description: '',
  },
  {
    Code: 'M33',
    Name: 'millimole per litre',
    Description: '',
  },
  {
    Code: 'P51',
    Name: 'mol per kilogram pascal',
    Description:
      'SI base unit mol divided by the product of the SI base unit kilogram and the derived SI unit pascal.',
  },
  {
    Code: 'P52',
    Name: 'mol per cubic metre pascal',
    Description:
      'SI base unit mol divided by the product of the power from the SI base unit metre with exponent 3 and the derived SI unit pascal.',
  },
  {
    Code: 'K59',
    Name: 'kilomole per cubic metre kelvin',
    Description: '',
  },
  {
    Code: 'K60',
    Name: 'kilomole per cubic metre bar',
    Description: '',
  },
  {
    Code: 'K93',
    Name: 'reciprocal psi',
    Description: '',
  },
  {
    Code: 'L24',
    Name: 'mole per kilogram kelvin',
    Description: '',
  },
  {
    Code: 'L25',
    Name: 'mole per kilogram bar',
    Description: '',
  },
  {
    Code: 'L26',
    Name: 'mole per litre kelvin',
    Description: '',
  },
  {
    Code: 'L27',
    Name: 'mole per litre bar',
    Description: '',
  },
  {
    Code: 'L28',
    Name: 'mole per cubic metre kelvin',
    Description: '',
  },
  {
    Code: 'L29',
    Name: 'mole per cubic metre bar',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C19',
    Name: 'mole per kilogram',
    Description: '',
  },
  {
    Code: 'D93',
    Name: 'second per cubic metre',
    Description: '',
  },
  {
    Code: 'D87',
    Name: 'millimole per kilogram',
    Description: '',
  },
  {
    Code: 'H68',
    Name: 'millimole per gram',
    Description: '',
  },
  {
    Code: 'P47',
    Name: 'kilomole per kilogram',
    Description: '1000-fold of the SI base unit mol divided by the SI base unit kilogram.',
  },
  {
    Code: 'P48',
    Name: 'pound mole per pound',
    Description:
      'Non SI-conforming unit of the material molar flux divided by the avoirdupois pound for mass according to the avoirdupois unit system.',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'PAL',
    Name: 'pascal',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'KAT',
    Name: 'katal',
    Description:
      'A unit of catalytic activity defining the catalytic activity of enzymes and other catalysts.',
  },
  {
    Code: 'E94',
    Name: 'kilomole per second',
    Description: '',
  },
  {
    Code: 'P45',
    Name: 'pound mole per second',
    Description:
      'Non SI-conforming unit of the power of the amount of substance non-SI compliant unit of the molar flux relating that a pound mole of a chemical composition the same number of pound corresponds like the molecular weight of a molecule of this composition in atomic mass units.',
  },
  {
    Code: 'P46',
    Name: 'pound mole per minute',
    Description:
      'Non SI-conforming unit of the power of the amount of substance non-SI compliant unit of the molar flux relating that a pound mole of a chemical composition the same number of pound corresponds like the molecular weight of a molecule of this composition in atomic mass units.',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'KGM',
    Name: 'kilogram',
    Description: 'A unit of mass equal to one thousand grams.',
  },
  {
    Code: 'D43',
    Name: 'unified atomic mass unit',
    Description: '',
  },
  {
    Code: 'A26',
    Name: 'coulomb metre',
    Description: '',
  },
  {
    Code: 'A27',
    Name: 'coulomb metre squared per volt',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'JE',
    Name: 'joule per kelvin',
    Description: '',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'S4',
    Name: 'square metre per second',
    Description: 'Synonym: metre squared per second (square metres/second US)',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'S4',
    Name: 'square metre per second',
    Description: 'Synonym: metre squared per second (square metres/second US)',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'COU',
    Name: 'coulomb',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'A32',
    Name: 'coulomb per mole',
    Description: '',
  },
  {
    Code: 'D10',
    Name: 'siemens per metre',
    Description: '',
  },
  {
    Code: 'D12',
    Name: 'siemens square metre per mole',
    Description: '',
  },
  {
    Code: 'K58',
    Name: 'kilomole per hour',
    Description: '',
  },
  {
    Code: 'K61',
    Name: 'kilomole per minute',
    Description: '',
  },
  {
    Code: 'L23',
    Name: 'mole per hour',
    Description: '',
  },
  {
    Code: 'L30',
    Name: 'mole per minute',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C81',
    Name: 'radian',
    Description: '',
  },
  {
    Code: 'C82',
    Name: 'radian square metre per mole',
    Description: '',
  },
  {
    Code: 'C83',
    Name: 'radian square metre per kilogram',
    Description: '',
  },
  {
    Code: 'P49',
    Name: 'newton square metre per ampere',
    Description:
      'Product of the derived SI unit newton and the power of SI base unit metre with exponent 2 divided by the SI base unit ampere.',
  },
  {
    Code: 'P50',
    Name: 'weber metre',
    Description: 'Product of the derived SI unit weber and SI base unit metre.',
  },
  {
    Code: 'Q30',
    Name: 'pH (potential of Hydrogen)',
    Description:
      'The activity of the (solvated) hydrogen ion (a logarithmic measure used to state the acidity or alkalinity of a chemical solution).',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'KGM',
    Name: 'kilogram',
    Description: 'A unit of mass equal to one thousand grams.',
  },
  {
    Code: 'D43',
    Name: 'unified atomic mass unit',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'KGM',
    Name: 'kilogram',
    Description: 'A unit of mass equal to one thousand grams.',
  },
  {
    Code: 'D43',
    Name: 'unified atomic mass unit',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'COU',
    Name: 'coulomb',
    Description: '',
  },
  {
    Code: 'B18',
    Name: 'joule second',
    Description: '',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'A11',
    Name: 'angstrom',
    Description: '',
  },
  {
    Code: 'C92',
    Name: 'reciprocal metre',
    Description: '',
  },
  {
    Code: 'JOU',
    Name: 'joule',
    Description: '',
  },
  {
    Code: 'A5',
    Name: 'ampere square metre',
    Description: '',
  },
  {
    Code: 'A10',
    Name: 'ampere square metre per joule second',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C97',
    Name: 'reciprocal second',
    Description: '',
  },
  {
    Code: '2A',
    Name: 'radian per second',
    Description: 'Refer ISO/TC12 SI Guide',
  },
  {
    Code: 'MTK',
    Name: 'square metre',
    Description: '',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'SEC',
    Name: 'second [unit of time]',
    Description: '',
  },
  {
    Code: 'JOU',
    Name: 'joule',
    Description: '',
  },
  {
    Code: 'A53',
    Name: 'electronvolt',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'CUR',
    Name: 'curie',
    Description: '',
  },
  {
    Code: 'MCU',
    Name: 'millicurie',
    Description: '',
  },
  {
    Code: 'M5',
    Name: 'microcurie',
    Description: '',
  },
  {
    Code: '2R',
    Name: 'kilocurie',
    Description: '',
  },
  {
    Code: 'BQL',
    Name: 'becquerel',
    Description: '',
  },
  {
    Code: 'GBQ',
    Name: 'gigabecquerel',
    Description: '',
  },
  {
    Code: '2Q',
    Name: 'kilobecquerel',
    Description: '',
  },
  {
    Code: '4N',
    Name: 'megabecquerel',
    Description: '',
  },
  {
    Code: 'H08',
    Name: 'microbecquerel',
    Description: '',
  },
  {
    Code: 'A42',
    Name: 'curie per kilogram',
    Description: '',
  },
  {
    Code: 'A18',
    Name: 'becquerel per kilogram',
    Description: '',
  },
  {
    Code: 'B67',
    Name: 'megabecquerel per kilogram',
    Description: '',
  },
  {
    Code: 'B25',
    Name: 'kilobecquerel per kilogram',
    Description: '',
  },
  {
    Code: 'A19',
    Name: 'becquerel per cubic metre',
    Description: '',
  },
  {
    Code: 'C97',
    Name: 'reciprocal second',
    Description: '',
  },
  {
    Code: 'JOU',
    Name: 'joule',
    Description: '',
  },
  {
    Code: 'A53',
    Name: 'electronvolt',
    Description: '',
  },
  {
    Code: 'A57',
    Name: 'erg',
    Description: '',
  },
  {
    Code: 'MTK',
    Name: 'square metre',
    Description: '',
  },
  {
    Code: 'A14',
    Name: 'barn',
    Description: '',
  },
  {
    Code: 'D24',
    Name: 'square metre per steradian',
    Description: '',
  },
  {
    Code: 'A17',
    Name: 'barn per steradian',
    Description: '',
  },
  {
    Code: 'D20',
    Name: 'square metre per joule',
    Description: '',
  },
  {
    Code: 'A15',
    Name: 'barn per electronvolt',
    Description: '',
  },
  {
    Code: 'D16',
    Name: 'square centimetre per erg',
    Description: '',
  },
  {
    Code: 'D25',
    Name: 'square metre per steradian joule',
    Description: '',
  },
  {
    Code: 'A16',
    Name: 'barn per steradian electronvolt',
    Description: '',
  },
  {
    Code: 'D17',
    Name: 'square centimetre per steradian erg',
    Description: '',
  },
  {
    Code: 'C92',
    Name: 'reciprocal metre',
    Description: '',
  },
  {
    Code: 'C93',
    Name: 'reciprocal square metre',
    Description: 'Synonym: reciprocal metre squared',
  },
  {
    Code: 'B81',
    Name: 'reciprocal metre squared reciprocal second',
    Description: '',
  },
  {
    Code: 'B13',
    Name: 'joule per square metre',
    Description: 'Synonym: joule per metre squared',
  },
  {
    Code: 'D54',
    Name: 'watt per square metre',
    Description: '',
  },
  {
    Code: 'A65',
    Name: 'erg per square centimetre second',
    Description: '',
  },
  {
    Code: 'C92',
    Name: 'reciprocal metre',
    Description: '',
  },
  {
    Code: 'D21',
    Name: 'square metre per kilogram',
    Description: '',
  },
  {
    Code: 'D22',
    Name: 'square metre per mole',
    Description: '',
  },
  {
    Code: 'MTK',
    Name: 'square metre',
    Description: '',
  },
  {
    Code: 'MTK',
    Name: 'square metre',
    Description: '',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'B12',
    Name: 'joule per metre',
    Description: '',
  },
  {
    Code: 'A54',
    Name: 'electronvolt per metre',
    Description: '',
  },
  {
    Code: 'A58',
    Name: 'erg per centimetre',
    Description: '',
  },
  {
    Code: 'D73',
    Name: 'joule square metre',
    Description: '',
  },
  {
    Code: 'A55',
    Name: 'electronvolt square metre',
    Description: '',
  },
  {
    Code: 'A66',
    Name: 'erg square centimetre',
    Description: '',
  },
  {
    Code: 'B20',
    Name: 'joule square metre per kilogram',
    Description: '',
  },
  {
    Code: 'A56',
    Name: 'electronvolt square metre per kilogram',
    Description: '',
  },
  {
    Code: 'A67',
    Name: 'erg square centimetre per gram',
    Description: '',
  },
  {
    Code: '28',
    Name: 'kilogram per square metre',
    Description: '',
  },
  {
    Code: 'C92',
    Name: 'reciprocal metre',
    Description: '',
  },
  {
    Code: 'D26',
    Name: 'square metre per volt second',
    Description: '',
  },
  {
    Code: 'H58',
    Name: 'metre per volt second',
    Description: '',
  },
  {
    Code: 'C86',
    Name: 'reciprocal cubic metre',
    Description: '',
  },
  {
    Code: 'MQS',
    Name: 'cubic metre per second',
    Description: '',
  },
  {
    Code: 'MTS',
    Name: 'metre per second',
    Description: '',
  },
  {
    Code: 'S4',
    Name: 'square metre per second',
    Description: 'Synonym: metre squared per second (square metres/second US)',
  },
  {
    Code: 'C98',
    Name: 'reciprocal second per cubic metre',
    Description: '',
  },
  {
    Code: 'C87',
    Name: 'reciprocal cubic metre per second',
    Description: 'Synonym: reciprocal second per cubic metre',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'SEC',
    Name: 'second [unit of time]',
    Description: '',
  },
  {
    Code: 'BQL',
    Name: 'becquerel',
    Description: '',
  },
  {
    Code: 'CUR',
    Name: 'curie',
    Description: '',
  },
  {
    Code: 'JOU',
    Name: 'joule',
    Description: '',
  },
  {
    Code: 'A95',
    Name: 'gray',
    Description: '',
  },
  {
    Code: 'C13',
    Name: 'milligray',
    Description: '',
  },
  {
    Code: 'C80',
    Name: 'rad',
    Description: '',
  },
  {
    Code: 'A61',
    Name: 'erg per gram',
    Description: '',
  },
  {
    Code: 'D13',
    Name: 'sievert',
    Description: '',
  },
  {
    Code: 'C28',
    Name: 'millisievert',
    Description: '',
  },
  {
    Code: 'D91',
    Name: 'rem',
    Description: '',
  },
  {
    Code: 'L31',
    Name: 'milliroentgen aequivalent men',
    Description: '',
  },
  {
    Code: 'A96',
    Name: 'gray per second',
    Description: '',
  },
  {
    Code: 'A62',
    Name: 'erg per gram second',
    Description: '',
  },
  {
    Code: 'B12',
    Name: 'joule per metre',
    Description: '',
  },
  {
    Code: 'A54',
    Name: 'electronvolt per metre',
    Description: '',
  },
  {
    Code: 'A58',
    Name: 'erg per centimetre',
    Description: '',
  },
  {
    Code: 'D21',
    Name: 'square metre per kilogram',
    Description: '',
  },
  {
    Code: 'CKG',
    Name: 'coulomb per kilogram',
    Description: '',
  },
  {
    Code: 'C8',
    Name: 'millicoulomb per kilogram',
    Description: '',
  },
  {
    Code: '2C',
    Name: 'roentgen',
    Description: '',
  },
  {
    Code: '2Y',
    Name: 'milliroentgen',
    Description: '',
  },
  {
    Code: 'J53',
    Name: 'coulomb square metre per kilogram',
    Description: '',
  },
  {
    Code: 'KR',
    Name: 'kiloroentgen',
    Description: '',
  },
  {
    Code: 'A31',
    Name: 'coulomb per kilogram second',
    Description: '',
  },
  {
    Code: 'D6',
    Name: 'roentgen per second',
    Description: '',
  },
  {
    Code: 'P54',
    Name: 'milligray per second',
    Description: '0,001-fold of the derived SI unit gray divided by the SI base unit second.',
  },
  {
    Code: 'P55',
    Name: 'microgray per second',
    Description: '0,000 001-fold of the derived SI unit gray divided by the SI base unit second.',
  },
  {
    Code: 'P56',
    Name: 'nanogray per second',
    Description:
      '0,000 000 001-fold of the derived SI unit gray divided by the SI base unit second.',
  },
  {
    Code: 'P57',
    Name: 'gray per minute',
    Description: 'SI derived unit gray divided by the unit minute.',
  },
  {
    Code: 'P58',
    Name: 'milligray per minute',
    Description: '0,001-fold of the derived SI unit gray divided by the unit minute.',
  },
  {
    Code: 'P59',
    Name: 'microgray per minute',
    Description: '0,000 001-fold of the derived SI unit gray divided by the unit minute.',
  },
  {
    Code: 'P60',
    Name: 'nanogray per minute',
    Description: '0,000 000 001-fold of the derived SI unit gray divided by the unit minute.',
  },
  {
    Code: 'P61',
    Name: 'gray per hour',
    Description: 'SI derived unit gray divided by the unit hour.',
  },
  {
    Code: 'P62',
    Name: 'milligray per hour',
    Description: '0,001-fold of the derived SI unit gray divided by the unit hour.',
  },
  {
    Code: 'P63',
    Name: 'microgray per hour',
    Description: '0,000 001-fold of the derived SI unit gray divided by the unit hour.',
  },
  {
    Code: 'P64',
    Name: 'nanogray per hour',
    Description: '0,000 000 001-fold of the derived SI unit gray divided by the unit hour.',
  },
  {
    Code: 'P65',
    Name: 'sievert per second',
    Description: 'Derived SI unit sievert divided by the SI base unit second.',
  },
  {
    Code: 'P66',
    Name: 'millisievert per second',
    Description: '0,001-fold of the derived SI unit sievert divided by the SI base unit second.',
  },
  {
    Code: 'P67',
    Name: 'microsievert per second',
    Description:
      '0,000 001-fold of the derived SI unit sievert divided by the SI base unit second.',
  },
  {
    Code: 'P68',
    Name: 'nanosievert per second',
    Description:
      '0,000 000 001-fold of the derived SI unit sievert divided by the SI base unit second.',
  },
  {
    Code: 'P69',
    Name: 'rem per second',
    Description:
      'Unit for the equivalent tin rate relating to DIN 1301-3:1979: 1 rem/s = 0,01 J/(kg·s) = 1 Sv/s.',
  },
  {
    Code: 'P70',
    Name: 'sievert per hour',
    Description: 'Derived SI unit sievert divided by the unit hour.',
  },
  {
    Code: 'P71',
    Name: 'millisievert per hour',
    Description: '0,001-fold of the derived SI unit sievert divided by the unit hour.',
  },
  {
    Code: 'P72',
    Name: 'microsievert per hour',
    Description: '0,000 001-fold of the derived SI unit sievert divided by the unit hour.',
  },
  {
    Code: 'P73',
    Name: 'nanosievert per hour',
    Description: '0,000 000 001-fold of the derived SI unit sievert divided by the unit hour.',
  },
  {
    Code: 'P74',
    Name: 'sievert per minute',
    Description: 'Derived SI unit sievert divided by the unit minute.',
  },
  {
    Code: 'P75',
    Name: 'millisievert per minute',
    Description: '0,001-fold of the derived SI unit sievert divided by the unit minute.',
  },
  {
    Code: 'P76',
    Name: 'microsievert per minute',
    Description: '0,000 001-fold of the derived SI unit sievert divided by the unit minute.',
  },
  {
    Code: 'P77',
    Name: 'nanosievert per minute',
    Description: '0,000 000 001-fold of the derived SI unit sievert divided by the unit minute.',
  },
  {
    Code: 'P78',
    Name: 'reciprocal square inch',
    Description:
      'Complement of the power of the unit inch according to the Anglo-American and Imperial system of units by exponent 2.',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'A11',
    Name: 'angstrom',
    Description: '',
  },
  {
    Code: 'C81',
    Name: 'radian',
    Description: '',
  },
  {
    Code: 'DD',
    Name: 'degree [unit of angle]',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'SEC',
    Name: 'second [unit of time]',
    Description: '',
  },
  {
    Code: 'WEB',
    Name: 'weber',
    Description: '',
  },
  {
    Code: 'P53',
    Name: 'unit pole ',
    Description:
      'CGS (Centimetre-Gram-Second system) unit for magnetic flux of a magnetic pole (according to the interaction of identical poles of 1 dyn at a distance of a cm).',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'MTR',
    Name: 'metre',
    Description: '',
  },
  {
    Code: 'C92',
    Name: 'reciprocal metre',
    Description: '',
  },
  {
    Code: 'C85',
    Name: 'reciprocal angstrom',
    Description: '',
  },
  {
    Code: 'C84',
    Name: 'radian per metre',
    Description: '',
  },
  {
    Code: 'C92',
    Name: 'reciprocal metre',
    Description: '',
  },
  {
    Code: '2A',
    Name: 'radian per second',
    Description: 'Refer ISO/TC12 SI Guide',
  },
  {
    Code: 'C97',
    Name: 'reciprocal second',
    Description: '',
  },
  {
    Code: 'KEL',
    Name: 'kelvin',
    Description: 'Refer ISO 80000-5 (Quantities and units — Part 5: Thermodynamics)',
  },
  {
    Code: 'D94',
    Name: 'second per cubic metre radian',
    Description: '',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C62',
    Name: 'one',
    Description: 'Synonym: unit',
  },
  {
    Code: 'C90',
    Name: 'reciprocal joule per cubic metre',
    Description: '',
  },
  {
    Code: 'C88',
    Name: 'reciprocal electron volt per cubic metre',
    Description: '',
  },
  {
    Code: 'C61',
    Name: 'ohm metre',
    Description: '',
  },
  {
    Code: 'A38',
    Name: 'cubic metre per coulomb',
    Description: '',
  },
  {
    Code: 'VLT',
    Name: 'volt',
    Description: '',
  },
  {
    Code: 'D33',
    Name: 'tesla',
    Description: '',
  },
  {
    Code: 'D48',
    Name: 'volt per kelvin',
    Description: '',
  },
  {
    Code: 'D49',
    Name: 'millivolt per kelvin',
    Description: '',
  },
  {
    Code: 'JOU',
    Name: 'joule',
    Description: '',
  },
  {
    Code: 'A53',
    Name: 'electronvolt',
    Description: '',
  },
  {
    Code: 'A70',
    Name: 'femtojoule',
    Description: '',
  },
  {
    Code: 'A13',
    Name: 'attojoule',
    Description: '',
  },
  {
    Code: 'A6',
    Name: 'ampere per square metre kelvin squared',
    Description: '',
  },
  {
    Code: 'C86',
    Name: 'reciprocal cubic metre',
    Description: '',
  },
  {
    Code: 'KGM',
    Name: 'kilogram',
    Description: 'A unit of mass equal to one thousand grams.',
  },
  {
    Code: '33',
    Name: 'kilopascal square metre per gram',
    Description: '',
  },
  {
    Code: 'P79',
    Name: 'pascal square metre per kilogram',
    Description:
      'Unit of the burst index as derived unit for pressure pascal related to the substance, represented as a quotient from the SI base unit kilogram divided by the power of the SI base unit metre by exponent 2.',
  },
  {
    Code: '34',
    Name: 'kilopascal per millimetre',
    Description: '',
  },
  {
    Code: 'H42',
    Name: 'pascal per metre',
    Description: '',
  },
  {
    Code: 'H69',
    Name: 'picopascal per kilometre',
    Description: '',
  },
  {
    Code: 'P80',
    Name: 'millipascal per metre',
    Description: '0,001-fold of the derived SI unit pascal divided by the SI base unit metre.',
  },
  {
    Code: 'P81',
    Name: 'kilopascal per metre',
    Description: '1000-fold of the derived SI unit pascal divided by the SI base unit metre.',
  },
  {
    Code: 'P82',
    Name: 'hectopascal per metre',
    Description: '100-fold of the derived SI unit pascal divided by the SI base unit metre.',
  },
  {
    Code: 'P83',
    Name: 'standard atmosphere per metre',
    Description: 'Outdated unit of the pressure divided by the SI base unit metre.',
  },
  {
    Code: 'P84',
    Name: 'technical atmosphere per metre',
    Description:
      'Obsolete and non-legal unit of the pressure which is generated by a 10 metre water column divided by the SI base unit metre.',
  },
  {
    Code: 'P85',
    Name: 'torr per metre',
    Description:
      'CGS (Centimetre-Gram-Second system) unit of the pressure divided by the SI base unit metre.',
  },
  {
    Code: 'P86',
    Name: 'psi per inch',
    Description:
      'Compound unit for pressure (pound-force according to the Anglo-American unit system divided by the power of the unit inch according to the Anglo-American and Imperial system of units with the exponent 2) divided by the unit inch according to the Anglo-American and Imperial system of units .',
  },
  {
    Code: '35',
    Name: 'millilitre per square centimetre second',
    Description: '',
  },
  {
    Code: '36',
    Name: 'cubic foot per minute per square foot',
    Description: 'Conversion factor required',
  },
  {
    Code: 'P87',
    Name: 'cubic metre per second square metre',
    Description:
      'Unit of volume flow cubic meters by second related to the transmission surface in square metres.',
  },
  {
    Code: 'OPM',
    Name: 'oscillations per minute',
    Description: 'The number of oscillation per minute',
  },
  {
    Code: 'KNM',
    Name: 'kilonewton per square metre',
    Description: '',
  },
  {
    Code: 'Q35',
    Name: 'megawatts per minute',
    Description:
      'A unit of power defining the total amount of bulk energy transferred or consumer per minute',
  },
  {
    Code: '10',
    Name: 'group',
    Description:
      'A unit of count defining the number of groups (group: set of items classified together).',
  },
  {
    Code: '11',
    Name: 'outfit',
    Description:
      'A unit of count defining the number of outfits (outfit: a complete set of equipment / materials / objects used for a specific purpose).',
  },
  {
    Code: '13',
    Name: 'ration',
    Description:
      'A unit of count defining the number of rations (ration: a single portion of provisions).',
  },
  {
    Code: '14',
    Name: 'shot',
    Description: 'A unit of liquid measure, especially related to spirits.',
  },
  {
    Code: '15',
    Name: 'stick, military',
    Description:
      'A unit of count defining the number of military sticks (military stick: bombs or paratroops released in rapid succession from an aircraft).',
  },
  {
    Code: '20',
    Name: 'twenty foot container',
    Description:
      'A unit of count defining the number of shipping containers that measure 20 foot in length.',
  },
  {
    Code: '21',
    Name: 'forty foot container',
    Description:
      'A unit of count defining the number of shipping containers that measure 40 foot in length.',
  },
  {
    Code: '24',
    Name: 'theoretical pound',
    Description:
      'A unit of mass defining the expected mass of material expressed as the number of pounds.',
  },
  {
    Code: '27',
    Name: 'theoretical ton',
    Description:
      'A unit of mass defining the expected mass of material, expressed as the number of tons.',
  },
  {
    Code: '38',
    Name: 'ounce per square foot per 0,01inch',
    Description: '',
  },
  {
    Code: '56',
    Name: 'sitas',
    Description: 'A unit of area for tin plate equal to a surface area of 100 square metres.',
  },
  {
    Code: '57',
    Name: 'mesh',
    Description:
      'A unit of count defining the number of strands per inch as a measure of the fineness of a woven product.',
  },
  {
    Code: '58',
    Name: 'net kilogram',
    Description: 'A unit of mass defining the total number of kilograms after deductions.',
  },
  {
    Code: '59',
    Name: 'part per million',
    Description: 'A unit of proportion equal to 10⁻⁶.',
  },
  {
    Code: '60',
    Name: 'percent weight',
    Description: 'A unit of proportion equal to 10⁻².',
  },
  {
    Code: '61',
    Name: 'part per billion (US)',
    Description: 'A unit of proportion equal to 10⁻⁹.',
  },
  {
    Code: '64',
    Name: 'pound per square inch, gauge',
    Description: '',
  },
  {
    Code: '66',
    Name: 'oersted',
    Description: '',
  },
  {
    Code: '76',
    Name: 'gauss',
    Description: '',
  },
  {
    Code: '78',
    Name: 'kilogauss',
    Description: '',
  },
  {
    Code: '1I',
    Name: 'fixed rate',
    Description:
      'A unit of quantity expressed as a predetermined or set rate for usage of a facility or service.',
  },
  {
    Code: '2G',
    Name: 'volt AC',
    Description: 'A unit of electric potential in relation to alternating current (AC).',
  },
  {
    Code: '2H',
    Name: 'volt DC',
    Description: 'A unit of electric potential in relation to direct current (DC).',
  },
  {
    Code: '2P',
    Name: 'kilobyte',
    Description: 'A unit of information equal to 10³ (1000) bytes.',
  },
  {
    Code: '3C',
    Name: 'manmonth',
    Description:
      'A unit of count defining the number of months for a person or persons to perform an undertaking.',
  },
  {
    Code: '4L',
    Name: 'megabyte',
    Description: 'A unit of information equal to 10⁶ (1000000) bytes.',
  },
  {
    Code: '5B',
    Name: 'batch',
    Description:
      'A unit of count defining the number of batches (batch: quantity of material produced in one operation or number of animals or persons coming at once).',
  },
  {
    Code: '5E',
    Name: 'MMSCF/day',
    Description: 'A unit of volume equal to one million (1000000) cubic feet of gas per day.',
  },
  {
    Code: '5J',
    Name: 'hydraulic horse power',
    Description:
      'A unit of power defining the hydraulic horse power delivered by a fluid pump depending on the viscosity of the fluid.',
  },
  {
    Code: 'A43',
    Name: 'deadweight tonnage',
    Description:
      'A unit of mass defining the difference between the weight of a ship when completely empty and its weight when completely loaded, expressed as the number of tons.',
  },
  {
    Code: 'A47',
    Name: 'decitex',
    Description:
      'A unit of yarn density. One decitex equals a mass of 1 gram per 10 kilometres of length.',
  },
  {
    Code: 'A49',
    Name: 'denier',
    Description:
      'A unit of yarn density. One denier equals a mass of 1 gram per 9 kilometres of length.',
  },
  {
    Code: 'A59',
    Name: '8-part cloud cover',
    Description:
      'A unit of count defining the number of eighth-parts as a measure of the celestial dome cloud coverage.',
  },
  {
    Code: '',
    Name: '',
    Description: 'Synonym: OKTA , OCTA',
  },
  {
    Code: 'A75',
    Name: 'freight ton',
    Description:
      'A unit of information typically used for billing purposes, defined as either the number of metric tons or the number of cubic metres, whichever is the larger.',
  },
  {
    Code: 'A77',
    Name: 'Gaussian CGS (Centimetre-Gram-Second system) unit of displacement',
    Description: '',
  },
  {
    Code: 'A78',
    Name: 'Gaussian CGS (Centimetre-Gram-Second system) unit of electric current',
    Description: '',
  },
  {
    Code: 'A79',
    Name: 'Gaussian CGS (Centimetre-Gram-Second system) unit of electric charge',
    Description: '',
  },
  {
    Code: 'A80',
    Name: 'Gaussian CGS (Centimetre-Gram-Second system) unit of electric field strength',
    Description: '',
  },
  {
    Code: 'A81',
    Name: 'Gaussian CGS (Centimetre-Gram-Second system) unit of electric polarization',
    Description: '',
  },
  {
    Code: 'A82',
    Name: 'Gaussian CGS (Centimetre-Gram-Second system) unit of electric potential',
    Description: '',
  },
  {
    Code: 'A83',
    Name: 'Gaussian CGS (Centimetre-Gram-Second system) unit of magnetization',
    Description: '',
  },
  {
    Code: 'A9',
    Name: 'rate',
    Description: 'A unit of quantity expressed as a rate for usage of a facility or service.',
  },
  {
    Code: 'A99',
    Name: 'bit',
    Description: 'A unit of information equal to one binary digit.',
  },
  {
    Code: 'AA',
    Name: 'ball',
    Description:
      'A unit of count defining the number of balls (ball: object formed in the shape of sphere).',
  },
  {
    Code: 'AB',
    Name: 'bulk pack',
    Description: 'A unit of count defining the number of items per bulk pack.',
  },
  {
    Code: 'ACT',
    Name: 'activity',
    Description:
      'A unit of count defining the number of activities (activity: a unit of work or action).',
  },
  {
    Code: 'AD',
    Name: 'byte',
    Description: 'A unit of information equal to 8 bits.',
  },
  {
    Code: 'AH',
    Name: 'additional minute',
    Description:
      'A unit of time defining the number of minutes in addition to the referenced minutes.',
  },
  {
    Code: 'AI',
    Name: 'average minute per call',
    Description:
      'A unit of count defining the number of minutes for the average interval of a call.',
  },
  {
    Code: 'AL',
    Name: 'access line',
    Description: 'A unit of count defining the number of telephone access lines.',
  },
  {
    Code: 'AQ',
    Name: 'anti-hemophilic factor (AHF) unit',
    Description: 'A unit of measure for blood potency (US).',
  },
  {
    Code: 'AS',
    Name: 'assortment',
    Description:
      'A unit of count defining the number of assortments (assortment: set of items grouped in a mixed collection).',
  },
  {
    Code: 'ASM',
    Name: 'alcoholic strength by mass',
    Description: 'A unit of mass defining the alcoholic strength of a liquid.',
  },
  {
    Code: 'ASU',
    Name: 'alcoholic strength by volume',
    Description:
      'A unit of volume defining the alcoholic strength of a liquid (e.g. spirit, wine, beer, etc), often at a specific temperature.',
  },
  {
    Code: 'AY',
    Name: 'assembly',
    Description:
      'A unit of count defining the number of assemblies (assembly: items that consist of component parts).',
  },
  {
    Code: 'B1',
    Name: 'barrel (US) per day',
    Description: '',
  },
  {
    Code: 'B10',
    Name: 'bit per second',
    Description: 'A unit of information equal to one binary digit per second.',
  },
  {
    Code: 'B17',
    Name: 'credit',
    Description:
      'A unit of count defining the number of entries made to the credit side of an account.',
  },
  {
    Code: 'B19',
    Name: 'digit',
    Description: 'A unit of information defining the quantity of numerals used to form a number.',
  },
  {
    Code: 'B3',
    Name: 'batting pound',
    Description: 'A unit of mass defining the number of pounds of wadded fibre.',
  },
  {
    Code: 'B30',
    Name: 'gibibit',
    Description: 'A unit of information equal to 2³⁰ bits (binary digits).',
  },
  {
    Code: 'B4',
    Name: 'barrel, imperial',
    Description:
      'A unit of volume used to measure beer.  One beer barrel equals 36 imperial gallons.',
  },
  {
    Code: 'B65',
    Name: 'maxwell',
    Description: '',
  },
  {
    Code: 'B68',
    Name: 'gigabit',
    Description: 'A unit of information equal to 10⁹ bits (binary digits).',
  },
  {
    Code: 'B7',
    Name: 'cycle',
    Description:
      'A unit of count defining the number of cycles (cycle: a recurrent period of definite duration).',
  },
  {
    Code: 'B80',
    Name: 'gigabit per second',
    Description: 'A unit of information equal to 10⁹ bits (binary digits) per second.',
  },
  {
    Code: 'B82',
    Name: 'inch per linear foot',
    Description: 'A unit of length defining the number of inches per linear foot.',
  },
  {
    Code: 'BB',
    Name: 'base box',
    Description:
      'A unit of area of 112 sheets of tin mil products (tin plate, tin free steel or black plate) 14 by 20 inches, or 31,360 square inches.',
  },
  {
    Code: 'BFT',
    Name: 'board foot',
    Description:
      'A unit of volume defining the number of cords (cord: a stack of firewood of 128 cubic feet).',
  },
  {
    Code: 'BIL',
    Name: 'billion (EUR)',
    Description: 'Synonym: trillion (US)',
  },
  {
    Code: 'BP',
    Name: 'hundred board foot',
    Description: 'A unit of volume equal to one hundred board foot.',
  },
  {
    Code: 'BPM',
    Name: 'beats per minute',
    Description: 'The number of beats per minute.',
  },
  {
    Code: 'C0',
    Name: 'call',
    Description:
      'A unit of count defining the number of calls (call: communication session or visitation).',
  },
  {
    Code: 'C21',
    Name: 'kibibit',
    Description: 'A unit of information equal to 2¹⁰ (1024) bits (binary digits).',
  },
  {
    Code: 'C37',
    Name: 'kilobit',
    Description: 'A unit of information equal to 10³ (1000) bits (binary digits).',
  },
  {
    Code: 'C74',
    Name: 'kilobit per second',
    Description: 'A unit of information equal to 10³ (1000) bits (binary digits) per second.',
  },
  {
    Code: 'C79',
    Name: 'kilovolt ampere hour',
    Description: 'A unit of accumulated energy of 1000 volt amperes over a period of one hour.',
  },
  {
    Code: 'C9',
    Name: 'coil group',
    Description:
      'A unit of count defining the number of coil groups (coil group: groups of items arranged by lengths of those items placed in a joined sequence of concentric circles).',
  },
  {
    Code: 'CCT',
    Name: 'carrying capacity in metric ton',
    Description:
      'A unit of mass defining the carrying capacity, expressed as the number of metric tons.',
  },
  {
    Code: 'CEN',
    Name: 'hundred',
    Description: 'A unit of count defining the number of units in multiples of 100.',
  },
  {
    Code: 'CG',
    Name: 'card',
    Description:
      'A unit of count defining the number of units of card (card: thick stiff paper or cardboard).',
  },
  {
    Code: 'CLF',
    Name: 'hundred leave',
    Description:
      'A unit of count defining the number of leaves, expressed in units of one hundred leaves.',
  },
  {
    Code: 'CNP',
    Name: 'hundred pack',
    Description:
      'A unit of count defining the number of hundred-packs (hundred-pack: set of one hundred items packaged together).',
  },
  {
    Code: 'CNT',
    Name: 'cental (UK)',
    Description: 'A unit of mass equal to one hundred weight (US).',
  },
  {
    Code: 'CTG',
    Name: 'content gram',
    Description: 'A unit of mass defining the number of grams of a named item in a product.',
  },
  {
    Code: 'CTM',
    Name: 'metric carat',
    Description: '',
  },
  {
    Code: 'CTN',
    Name: 'content ton (metric)',
    Description: 'A unit of mass defining the number of metric tons of a named item in a product.',
  },
  {
    Code: 'D03',
    Name: 'kilowatt hour per hour',
    Description: 'A unit of accumulated energy of a thousand watts over a period of one hour.',
  },
  {
    Code: 'D04',
    Name: 'lot [unit of weight]',
    Description: 'A unit of weight equal to about 1/2 ounce or 15 grams.',
  },
  {
    Code: 'D11',
    Name: 'mebibit',
    Description: 'A unit of information equal to 2²⁰ (1048576) bits (binary digits).',
  },
  {
    Code: 'D23',
    Name: 'pen gram (protein)',
    Description:
      'A unit of count defining the number of grams of amino acid prescribed for parenteral/enteral therapy.',
  },
  {
    Code: 'D34',
    Name: 'tex',
    Description:
      'A unit of yarn density. One decitex equals a mass of 1 gram per 1 kilometre of length.',
  },
  {
    Code: 'D36',
    Name: 'megabit',
    Description: 'A unit of information equal to 10⁶ (1000000) bits (binary digits).',
  },
  {
    Code: 'D63',
    Name: 'book',
    Description:
      'A unit of count defining the number of books (book: set of items bound together or written document of a material whole).',
  },
  {
    Code: 'D65',
    Name: 'round',
    Description:
      'A unit of count defining the number of rounds (round: A circular or cylindrical object).',
  },
  {
    Code: 'D68',
    Name: 'number of words',
    Description: 'A unit of count defining the number of words.',
  },
  {
    Code: 'D78',
    Name: 'megajoule per second',
    Description: 'A unit of accumulated energy equal to one million joules per second.',
  },
  {
    Code: 'DAD',
    Name: 'ten day',
    Description: 'A unit of time defining the number of days in multiples of 10.',
  },
  {
    Code: 'DB',
    Name: 'dry pound',
    Description:
      'A unit of mass defining the number of pounds of a product, disregarding the water content of the product.',
  },
  {
    Code: 'DEC',
    Name: 'decade',
    Description:
      'A unit of count defining the number of decades (decade: quantity equal to 10 or time equal to 10 years).',
  },
  {
    Code: 'DMO',
    Name: 'standard kilolitre',
    Description:
      'A unit of volume defining the number of kilolitres of a product at a temperature of 15 degrees Celsius, especially in relation to hydrocarbon oils.',
  },
  {
    Code: 'DPC',
    Name: 'dozen piece',
    Description:
      'A unit of count defining the number of pieces in multiples of 12 (piece: a single item, article or exemplar).',
  },
  {
    Code: 'DPR',
    Name: 'dozen pair',
    Description:
      "A unit of count defining the number of pairs in multiples of 12 (pair: item described by two's).",
  },
  {
    Code: 'DPT',
    Name: 'displacement tonnage',
    Description:
      'A unit of mass defining the volume of sea water a ship displaces, expressed as the number of tons.',
  },
  {
    Code: 'DRA',
    Name: 'dram (US)',
    Description: 'Synonym: drachm (UK), troy dram',
  },
  {
    Code: 'DRI',
    Name: 'dram (UK)',
    Description: 'Synonym: avoirdupois dram',
  },
  {
    Code: 'DRL',
    Name: 'dozen roll',
    Description: 'A unit of count defining the number of rolls, expressed in twelve roll units.',
  },
  {
    Code: 'DT',
    Name: 'dry ton',
    Description:
      'A unit of mass defining the number of tons of a product, disregarding the water content of the product.',
  },
  {
    Code: 'DWT',
    Name: 'pennyweight',
    Description: '',
  },
  {
    Code: 'DZN',
    Name: 'dozen',
    Description: 'A unit of count defining the number of units in multiples of 12.',
  },
  {
    Code: 'DZP',
    Name: 'dozen pack',
    Description:
      'A unit of count defining the number of packs in multiples of 12 (pack: standard packaging unit).',
  },
  {
    Code: 'E07',
    Name: 'megawatt hour per hour',
    Description: 'A unit of accumulated energy of a million watts over a period of one hour.',
  },
  {
    Code: 'E08',
    Name: 'megawatt per hertz',
    Description:
      'A unit of energy expressed as the load change in million watts that will cause a frequency shift of one hertz.',
  },
  {
    Code: 'E10',
    Name: 'degree day',
    Description:
      'A unit of measure used in meteorology and engineering to measure the demand for heating or cooling over a given period of days.',
  },
  {
    Code: 'E11',
    Name: 'gigacalorie',
    Description: 'A unit of heat energy equal to one thousand million calories.',
  },
  {
    Code: 'E12',
    Name: 'mille',
    Description: 'A unit of count defining the number of cigarettes in units of 1000.',
  },
  {
    Code: 'E16',
    Name: 'million Btu(IT) per hour',
    Description: 'A unit of power equal to one million British thermal units per hour.',
  },
  {
    Code: 'E17',
    Name: 'cubic foot per second',
    Description:
      'A unit of volume equal to one cubic foot passing a given point in a period of one second.',
  },
  {
    Code: 'E19',
    Name: 'ping',
    Description: 'A unit of area equal to 3.3 square metres.',
  },
  {
    Code: 'E20',
    Name: 'megabit per second',
    Description: 'A unit of information equal to 10⁶ (1000000) bits (binary digits) per second.',
  },
  {
    Code: 'E21',
    Name: 'shares',
    Description:
      'A unit of count defining the number of shares (share: a total or portion of the parts into which a business entity’s capital is divided).',
  },
  {
    Code: 'E22',
    Name: 'TEU',
    Description:
      'A unit of count defining the number of twenty-foot equivalent units (TEUs) as a measure of containerized cargo capacity.',
  },
  {
    Code: 'E23',
    Name: 'tyre',
    Description:
      'A unit of count defining the number of tyres (a solid or air-filled covering placed around a wheel rim to form a soft contact with the road, absorb shock and provide traction).',
  },
  {
    Code: 'E25',
    Name: 'active unit',
    Description: 'A unit of count defining the number of active units within a substance.',
  },
  {
    Code: 'E27',
    Name: 'dose',
    Description:
      'A unit of count defining the number of doses (dose: a definite quantity of a medicine or drug).',
  },
  {
    Code: 'E28',
    Name: 'air dry ton',
    Description:
      'A unit of mass defining the number of tons of a product, disregarding the water content of the product.',
  },
  {
    Code: 'E30',
    Name: 'strand',
    Description:
      'A unit of count defining the number of strands (strand: long, thin, flexible, single thread, strip of fibre, constituent filament or multiples of the same, twisted together).',
  },
  {
    Code: 'E31',
    Name: 'square metre per litre',
    Description: 'A unit of count defining the number of square metres per litre.',
  },
  {
    Code: 'E32',
    Name: 'litre per hour',
    Description: 'A unit of count defining the number of litres per hour.',
  },
  {
    Code: 'E33',
    Name: 'foot per thousand',
    Description: 'A unit of count defining the number of feet per thousand units.',
  },
  {
    Code: 'E34',
    Name: 'gigabyte',
    Description: 'A unit of information equal to 10⁹ bytes.',
  },
  {
    Code: 'E35',
    Name: 'terabyte',
    Description: 'A unit of information equal to 10¹² bytes.',
  },
  {
    Code: 'E36',
    Name: 'petabyte',
    Description: 'A unit of information equal to 10¹⁵ bytes.',
  },
  {
    Code: 'E37',
    Name: 'pixel',
    Description: 'A unit of count defining the number of pixels (pixel: picture element).',
  },
  {
    Code: 'E38',
    Name: 'megapixel',
    Description: 'A unit of count equal to 10⁶ (1000000) pixels (picture elements).',
  },
  {
    Code: 'E39',
    Name: 'dots per inch',
    Description:
      'A unit of information defining the number of dots per linear inch as a measure of the resolution or sharpness of a graphic image.',
  },
  {
    Code: 'E4',
    Name: 'gross kilogram',
    Description: 'A unit of mass defining the total number of kilograms before deductions.',
  },
  {
    Code: 'E40',
    Name: 'part per hundred thousand',
    Description: 'A unit of proportion equal to 10⁻⁵.',
  },
  {
    Code: 'E44',
    Name: 'kilogram-force metre per square centimetre',
    Description:
      'A unit of torsion defining the torque kilogram-force metre per square centimetre.',
  },
  {
    Code: 'E46',
    Name: 'kilowatt hour per cubic metre',
    Description: 'A unit of energy consumption expressed as kilowatt hour per cubic metre.',
  },
  {
    Code: 'E47',
    Name: 'kilowatt hour per kelvin',
    Description: 'A unit of energy consumption expressed as kilowatt hour per kelvin.',
  },
  {
    Code: 'E48',
    Name: 'service unit',
    Description:
      'A unit of count defining the number of service units (service unit: defined period / property / facility / utility of supply).',
  },
  {
    Code: 'E49',
    Name: 'working day',
    Description:
      'A unit of count defining the number of working days (working day: a day on which work is ordinarily performed).',
  },
  {
    Code: 'E50',
    Name: 'accounting unit',
    Description: 'A unit of count defining the number of accounting units.',
  },
  {
    Code: 'E51',
    Name: 'job',
    Description: 'A unit of count defining the number of jobs.',
  },
  {
    Code: 'E52',
    Name: 'run foot',
    Description: 'A unit of count defining the number feet per run.',
  },
  {
    Code: 'E53',
    Name: 'test',
    Description: 'A unit of count defining the number of tests.',
  },
  {
    Code: 'E54',
    Name: 'trip',
    Description: 'A unit of count defining the number of trips.',
  },
  {
    Code: 'E55',
    Name: 'use',
    Description: 'A unit of count defining the number of times an object is used.',
  },
  {
    Code: 'E56',
    Name: 'well',
    Description: 'A unit of count defining the number of wells.',
  },
  {
    Code: 'E57',
    Name: 'zone',
    Description: 'A unit of count defining the number of zones.',
  },
  {
    Code: 'E58',
    Name: 'exabit per second',
    Description: 'A unit of information equal to 10¹⁸ bits (binary digits) per second.',
  },
  {
    Code: 'E59',
    Name: 'exbibyte',
    Description: 'A unit of information equal to 2⁶⁰ bytes.',
  },
  {
    Code: 'E60',
    Name: 'pebibyte',
    Description: 'A unit of information equal to 2⁵⁰ bytes.',
  },
  {
    Code: 'E61',
    Name: 'tebibyte',
    Description: 'A unit of information equal to 2⁴⁰ bytes.',
  },
  {
    Code: 'E62',
    Name: 'gibibyte',
    Description: 'A unit of information equal to 2³⁰ bytes.',
  },
  {
    Code: 'E63',
    Name: 'mebibyte',
    Description: 'A unit of information equal to 2²⁰ bytes.',
  },
  {
    Code: 'E64',
    Name: 'kibibyte',
    Description: 'A unit of information equal to 2¹⁰ bytes.',
  },
  {
    Code: 'E65',
    Name: 'exbibit per metre',
    Description: 'A unit of information equal to 2⁶⁰ bits (binary digits) per metre.',
  },
  {
    Code: 'E66',
    Name: 'exbibit per square metre',
    Description: 'A unit of information equal to 2⁶⁰ bits (binary digits) per square metre.',
  },
  {
    Code: 'E67',
    Name: 'exbibit per cubic metre',
    Description: 'A unit of information equal to 2⁶⁰ bits (binary digits) per cubic metre.',
  },
  {
    Code: 'E68',
    Name: 'gigabyte per second',
    Description: 'A unit of information equal to 10⁹ bytes per second.',
  },
  {
    Code: 'E69',
    Name: 'gibibit per metre',
    Description: 'A unit of information equal to 2³⁰ bits (binary digits) per metre.',
  },
  {
    Code: 'E70',
    Name: 'gibibit per square metre',
    Description: 'A unit of information equal to 2³⁰ bits (binary digits) per square metre.',
  },
  {
    Code: 'E71',
    Name: 'gibibit per cubic metre',
    Description: 'A unit of information equal to 2³⁰ bits (binary digits) per cubic metre.',
  },
  {
    Code: 'E72',
    Name: 'kibibit per metre',
    Description: 'A unit of information equal to 2¹⁰ bits (binary digits) per metre.',
  },
  {
    Code: 'E73',
    Name: 'kibibit per square metre',
    Description: 'A unit of information equal to 2¹⁰ bits (binary digits) per square metre.',
  },
  {
    Code: 'E74',
    Name: 'kibibit per cubic metre',
    Description: 'A unit of information equal to 2¹⁰ bits (binary digits) per cubic metre.',
  },
  {
    Code: 'E75',
    Name: 'mebibit per metre',
    Description: 'A unit of information equal to 2²⁰ bits (binary digits) per metre.',
  },
  {
    Code: 'E76',
    Name: 'mebibit per square metre',
    Description: 'A unit of information equal to 2²⁰ bits (binary digits) per square metre.',
  },
  {
    Code: 'E77',
    Name: 'mebibit per cubic metre',
    Description: 'A unit of information equal to 2²⁰ bits (binary digits) per cubic metre.',
  },
  {
    Code: 'E78',
    Name: 'petabit',
    Description: 'A unit of information equal to 10¹⁵ bits (binary digits).',
  },
  {
    Code: 'E79',
    Name: 'petabit per second',
    Description: 'A unit of information equal to 10¹⁵ bits (binary digits) per second.',
  },
  {
    Code: 'E80',
    Name: 'pebibit per metre',
    Description: 'A unit of information equal to 2⁵⁰ bits (binary digits) per metre.',
  },
  {
    Code: 'E81',
    Name: 'pebibit per square metre',
    Description: 'A unit of information equal to 2⁵⁰ bits (binary digits) per square metre.',
  },
  {
    Code: 'E82',
    Name: 'pebibit per cubic metre',
    Description: 'A unit of information equal to 2⁵⁰ bits (binary digits) per cubic metre.',
  },
  {
    Code: 'E83',
    Name: 'terabit',
    Description: 'A unit of information equal to 10¹² bits (binary digits).',
  },
  {
    Code: 'E84',
    Name: 'terabit per second',
    Description: 'A unit of information equal to 10¹² bits (binary digits) per second.',
  },
  {
    Code: 'E85',
    Name: 'tebibit per metre',
    Description: 'A unit of information equal to 2⁴⁰ bits (binary digits) per metre.',
  },
  {
    Code: 'E86',
    Name: 'tebibit per cubic metre',
    Description: 'A unit of information equal to 2⁴⁰ bits (binary digits) per cubic metre.',
  },
  {
    Code: 'E87',
    Name: 'tebibit per square metre',
    Description: 'A unit of information equal to 2⁴⁰ bits (binary digits) per square metre.',
  },
  {
    Code: 'E88',
    Name: 'bit per metre',
    Description: 'A unit of information equal to 1 bit (binary digit) per metre.',
  },
  {
    Code: 'E89',
    Name: 'bit per square metre',
    Description: 'A unit of information equal to 1 bit (binary digit) per square metre.',
  },
  {
    Code: 'E90',
    Name: 'reciprocal centimetre',
    Description: '',
  },
  {
    Code: 'E91',
    Name: 'reciprocal day',
    Description: '',
  },
  {
    Code: 'EA',
    Name: 'each',
    Description: 'A unit of count defining the number of items regarded as separate units.',
  },
  {
    Code: 'EB',
    Name: 'electronic mail box',
    Description: 'A unit of count defining the number of electronic mail boxes.',
  },
  {
    Code: 'EQ',
    Name: 'equivalent gallon',
    Description:
      'A unit of volume defining the number of gallons of product produced from concentrate.',
  },
  {
    Code: 'F01',
    Name: 'bit per cubic metre',
    Description: 'A unit of information equal to 1 bit (binary digit) per cubic metre.',
  },
  {
    Code: 'FBM',
    Name: 'fibre metre',
    Description: 'A unit of length defining the number of metres of individual fibre.',
  },
  {
    Code: 'FC',
    Name: 'thousand cubic foot',
    Description: 'A unit of volume equal to one thousand cubic foot.',
  },
  {
    Code: 'FF',
    Name: 'hundred cubic metre',
    Description: 'A unit of volume equal to one hundred cubic metres.',
  },
  {
    Code: 'FIT',
    Name: 'failures in time',
    Description:
      'A unit of count defining the number of failures that can be expected over a specified time interval. Failure rates of semiconductor components are often specified as FIT (failures in time unit) where 1 FIT = 10⁻⁹ /h.',
  },
  {
    Code: 'FL',
    Name: 'flake ton',
    Description:
      'A unit of mass defining the number of tons of a flaked substance (flake: a small flattish fragment).',
  },
  {
    Code: 'GB',
    Name: 'gallon (US) per day',
    Description: '',
  },
  {
    Code: 'GDW',
    Name: 'gram, dry weight',
    Description:
      'A unit of mass defining the number of grams of a product, disregarding the water content of the product.',
  },
  {
    Code: 'GFI',
    Name: 'gram of fissile isotope',
    Description:
      'A unit of mass defining the number of grams of a fissile isotope (fissile isotope: an isotope whose nucleus is able to be split when irradiated with low energy neutrons).',
  },
  {
    Code: 'GGR',
    Name: 'great gross',
    Description:
      'A unit of count defining the number of units in multiples of 1728 (12 x 12 x 12).',
  },
  {
    Code: 'GIA',
    Name: 'gill (US)',
    Description: '',
  },
  {
    Code: 'GIC',
    Name: 'gram, including container',
    Description:
      'A unit of mass defining the number of grams of a product, including its container.',
  },
  {
    Code: 'GII',
    Name: 'gill (UK)',
    Description: '',
  },
  {
    Code: 'GIP',
    Name: 'gram, including inner packaging',
    Description:
      'A unit of mass defining the number of grams of a product, including its inner packaging materials.',
  },
  {
    Code: 'GRO',
    Name: 'gross',
    Description: 'A unit of count defining the number of units in multiples of 144 (12 x 12).',
  },
  {
    Code: 'GRT',
    Name: 'gross register ton',
    Description:
      'A unit of mass equal to the total cubic footage before deductions, where 1 register ton is equal to 100 cubic feet. Refer International Convention on tonnage measurement of ships.',
  },
  {
    Code: 'GT',
    Name: 'gross ton',
    Description:
      'A unit of mass equal to 2240 pounds. Refer International Convention on Tonnage measurement of Ships.',
  },
  {
    Code: '',
    Name: '',
    Description: 'Synonym: ton (UK) or long ton (US) (common code LTN)',
  },
  {
    Code: 'H21',
    Name: 'blank',
    Description: 'A unit of count defining the number of blanks.',
  },
  {
    Code: 'H25',
    Name: 'percent per kelvin',
    Description: 'A unit of proportion, equal to 0.01, in relation to the SI base unit Kelvin.',
  },
  {
    Code: 'H71',
    Name: 'percent per month',
    Description: 'A unit of proportion, equal to 0.01, in relation to a month.',
  },
  {
    Code: 'H72',
    Name: 'percent per hectobar',
    Description: 'A unit of proportion, equal to 0.01, in relation to 100-fold of the unit bar.',
  },
  {
    Code: 'H73',
    Name: 'percent per decakelvin',
    Description:
      'A unit of proportion, equal to 0.01, in relation to 10-fold of the SI base unit Kelvin.',
  },
  {
    Code: 'H77',
    Name: 'module width',
    Description:
      'A unit of measure used to describe the breadth of electronic assemblies as an installation standard or mounting dimension.',
  },
  {
    Code: 'H80',
    Name: 'rack unit',
    Description:
      'A unit of measure used to describe the height in rack units of equipment intended for mounting in a 19-inch rack or a 23-inch rack. One rack unit is 1.75 inches (44.45 mm) high.',
  },
  {
    Code: 'H82',
    Name: 'big point',
    Description:
      'A unit of length defining the number of big points (big point: Adobe software(US) defines the big point to be exactly 1/72 inch (0.013 888 9 inch or 0.352 777 8 millimeters))',
  },
  {
    Code: 'H87',
    Name: 'piece',
    Description:
      'A unit of count defining the number of pieces (piece: a single item, article or exemplar).',
  },
  {
    Code: 'H89',
    Name: 'percent per ohm',
    Description: 'A unit of proportion, equal to 0.01, in relation to the SI derived unit ohm.',
  },
  {
    Code: 'H90',
    Name: 'percent per degree',
    Description: 'A unit of proportion, equal to 0.01, in relation to an angle of one degree.',
  },
  {
    Code: 'H91',
    Name: 'percent per ten thousand',
    Description: 'A unit of proportion, equal to 0.01, in relation to multiples of ten thousand.',
  },
  {
    Code: 'H92',
    Name: 'percent per one hundred thousand',
    Description:
      'A unit of proportion, equal to 0.01, in relation to multiples of one hundred thousand.',
  },
  {
    Code: 'H93',
    Name: 'percent per hundred',
    Description: 'A unit of proportion, equal to 0.01, in relation to multiples of one hundred.',
  },
  {
    Code: 'H94',
    Name: 'percent per thousand',
    Description: 'A unit of proportion, equal to 0.01, in relation to multiples of one thousand.',
  },
  {
    Code: 'H95',
    Name: 'percent per volt',
    Description: 'A unit of proportion, equal to 0.01, in relation to the SI derived unit volt.',
  },
  {
    Code: 'H96',
    Name: 'percent per bar',
    Description:
      'A unit of proportion, equal to 0.01, in relation to an atmospheric pressure of one bar.',
  },
  {
    Code: 'H98',
    Name: 'percent per inch',
    Description: 'A unit of proportion, equal to 0.01, in relation to an inch.',
  },
  {
    Code: 'H99',
    Name: 'percent per metre',
    Description: 'A unit of proportion, equal to 0.01, in relation to a metre.',
  },
  {
    Code: 'HA',
    Name: 'hank',
    Description: 'A unit of length, typically for yarn.',
  },
  {
    Code: 'HBX',
    Name: 'hundred boxes',
    Description:
      'A unit of count defining the number of boxes in multiples of one hundred box units.',
  },
  {
    Code: 'HC',
    Name: 'hundred count',
    Description: 'A unit of count defining the number of units counted in multiples of 100.',
  },
  {
    Code: 'HDW',
    Name: 'hundred kilogram, dry weight',
    Description:
      'A unit of mass defining the number of hundred kilograms of a product, disregarding the water content of the product.',
  },
  {
    Code: 'HEA',
    Name: 'head',
    Description:
      'A unit of count defining the number of heads (head: a person or animal considered as one of a number).',
  },
  {
    Code: 'HH',
    Name: 'hundred cubic foot',
    Description: 'A unit of volume equal to one hundred cubic foot.',
  },
  {
    Code: 'HIU',
    Name: 'hundred international unit',
    Description: 'A unit of count defining the number of international units in multiples of 100.',
  },
  {
    Code: 'HKM',
    Name: 'hundred kilogram, net mass',
    Description:
      'A unit of mass defining the number of hundred kilograms of a product, after deductions.',
  },
  {
    Code: 'HMQ',
    Name: 'million cubic metre',
    Description: 'A unit of volume equal to one million cubic metres.',
  },
  {
    Code: 'HPA',
    Name: 'hectolitre of pure alcohol',
    Description: 'A unit of volume equal to one hundred litres of pure alcohol.',
  },
  {
    Code: 'IE',
    Name: 'person',
    Description: 'A unit of count defining the number of persons.',
  },
  {
    Code: 'ISD',
    Name: 'international sugar degree',
    Description:
      'A unit of measure defining the sugar content of a solution, expressed in degrees.',
  },
  {
    Code: 'IUG',
    Name: 'international unit per gram',
    Description: 'A unit of count defining the number of international units per gram.',
  },
  {
    Code: 'J10',
    Name: 'percent per millimetre',
    Description: 'A unit of proportion, equal to 0.01, in relation to a millimetre.',
  },
  {
    Code: 'J12',
    Name: 'per mille per psi',
    Description:
      'A unit of pressure equal to one thousandth of a psi (pound-force per square inch).',
  },
  {
    Code: 'J13',
    Name: 'degree API',
    Description:
      'A unit of relative density as a measure of how heavy or light a petroleum liquid is compared to water (API: American Petroleum Institute).',
  },
  {
    Code: 'J14',
    Name: 'degree Baume (origin scale)',
    Description: 'A traditional unit of relative density for liquids. Named after Antoine Baumé.',
  },
  {
    Code: 'J15',
    Name: 'degree Baume (US heavy)',
    Description: 'A unit of relative density for liquids heavier than water.',
  },
  {
    Code: 'J16',
    Name: 'degree Baume (US light)',
    Description: 'A unit of relative density for liquids lighter than water.',
  },
  {
    Code: 'J17',
    Name: 'degree Balling',
    Description:
      'A unit of density as a measure of sugar content, especially of beer wort. Named after Karl Balling.',
  },
  {
    Code: 'J18',
    Name: 'degree Brix',
    Description:
      'A unit of proportion used in measuring the dissolved sugar-to-water mass ratio of a liquid. Named after Adolf Brix.',
  },
  {
    Code: 'J27',
    Name: 'degree Oechsle',
    Description:
      'A unit of density as a measure of sugar content of must, the unfermented liqueur from which wine is made. Named after Ferdinand Oechsle.',
  },
  {
    Code: 'J31',
    Name: 'degree Twaddell',
    Description:
      'A unit of density for liquids that are heavier than water.  1 degree Twaddle represents a difference in specific gravity of 0.005.',
  },
  {
    Code: 'J38',
    Name: 'baud',
    Description: 'A unit of signal transmission speed equal to one signalling event per second.',
  },
  {
    Code: 'J54',
    Name: 'megabaud',
    Description:
      'A unit of signal transmission speed equal to 10⁶ (1000000) signaling events per second.',
  },
  {
    Code: 'JNT',
    Name: 'pipeline joint',
    Description: 'A count of the number of pipeline joints.',
  },
  {
    Code: 'JPS',
    Name: 'hundred metre',
    Description: 'A unit of count defining the number of 100 metre lengths.',
  },
  {
    Code: 'JWL',
    Name: 'number of jewels',
    Description: 'A unit of count defining the number of jewels (jewel: precious stone).',
  },
  {
    Code: 'K1',
    Name: 'kilowatt demand',
    Description: 'A unit of measure defining the power load measured at predetermined intervals.',
  },
  {
    Code: 'K2',
    Name: 'kilovolt ampere reactive demand',
    Description:
      'A unit of measure defining the reactive power demand equal to one kilovolt ampere of reactive power.',
  },
  {
    Code: 'K3',
    Name: 'kilovolt ampere reactive hour',
    Description:
      'A unit of measure defining the accumulated reactive energy equal to one kilovolt ampere of reactive power per hour.',
  },
  {
    Code: 'K50',
    Name: 'kilobaud',
    Description:
      'A unit of signal transmission speed equal to 10³ (1000) signaling events per second.',
  },
  {
    Code: 'KA',
    Name: 'cake',
    Description:
      'A unit of count defining the number of cakes (cake: object shaped into a flat, compact mass).',
  },
  {
    Code: 'KB',
    Name: 'kilocharacter',
    Description: 'A unit of information equal to 10³ (1000) characters.',
  },
  {
    Code: 'KCC',
    Name: 'kilogram of choline chloride',
    Description: 'A unit of mass equal to one thousand grams of choline chloride.',
  },
  {
    Code: 'KDW',
    Name: 'kilogram drained net weight',
    Description:
      'A unit of mass defining the net number of kilograms of a product, disregarding the liquid content of the product.',
  },
  {
    Code: 'KHY',
    Name: 'kilogram of hydrogen peroxide',
    Description: 'A unit of mass equal to one thousand grams of hydrogen peroxide.',
  },
  {
    Code: 'KI',
    Name: 'kilogram per millimetre width',
    Description: '',
  },
  {
    Code: 'KIC',
    Name: 'kilogram, including container',
    Description:
      'A unit of mass defining the number of kilograms of a product, including its container.',
  },
  {
    Code: 'KIP',
    Name: 'kilogram, including inner packaging',
    Description:
      'A unit of mass defining the number of kilograms of a product, including its inner packaging materials.',
  },
  {
    Code: 'KJ',
    Name: 'kilosegment',
    Description: 'A unit of information equal to 10³ (1000) segments.',
  },
  {
    Code: 'KLK',
    Name: 'lactic dry material percentage',
    Description:
      'A unit of proportion defining the percentage of dry lactic material in a product.',
  },
  {
    Code: 'KMA',
    Name: 'kilogram of methylamine',
    Description: 'A unit of mass equal to one thousand grams of methylamine.',
  },
  {
    Code: 'KNI',
    Name: 'kilogram of nitrogen',
    Description: 'A unit of mass equal to one thousand grams of nitrogen.',
  },
  {
    Code: 'KNS',
    Name: 'kilogram named substance',
    Description: 'A unit of mass equal to one kilogram of a named substance.',
  },
  {
    Code: 'KO',
    Name: 'milliequivalence caustic potash per gram of product',
    Description:
      'A unit of count defining the number of milligrams of potassium hydroxide per gram of product as a measure of the concentration of potassium hydroxide in the product.',
  },
  {
    Code: 'KPH',
    Name: 'kilogram of potassium hydroxide (caustic potash)',
    Description:
      'A unit of mass equal to one thousand grams of potassium hydroxide (caustic potash).',
  },
  {
    Code: 'KPO',
    Name: 'kilogram of potassium oxide',
    Description: 'A unit of mass equal to one thousand grams of potassium oxide.',
  },
  {
    Code: 'KPP',
    Name: 'kilogram of phosphorus pentoxide (phosphoric anhydride)',
    Description:
      'A unit of mass equal to one thousand grams of phosphorus pentoxide phosphoric anhydride.',
  },
  {
    Code: 'KSD',
    Name: 'kilogram of substance 90 % dry',
    Description: 'A unit of mass equal to one thousand grams of a named substance that is 90% dry.',
  },
  {
    Code: 'KSH',
    Name: 'kilogram of sodium hydroxide (caustic soda)',
    Description: 'A unit of mass equal to one thousand grams of sodium hydroxide (caustic soda).',
  },
  {
    Code: 'KT',
    Name: 'kit',
    Description: 'A unit of count defining the number of kits (kit: tub, barrel or pail).',
  },
  {
    Code: 'KUR',
    Name: 'kilogram of uranium',
    Description: 'A unit of mass equal to one thousand grams of uranium.',
  },
  {
    Code: 'KWY',
    Name: 'kilowatt year',
    Description: 'killowatt year',
  },
  {
    Code: 'KWO',
    Name: 'kilogram of tungsten trioxide',
    Description: 'A unit of mass equal to one thousand grams of tungsten trioxide.',
  },
  {
    Code: 'LAC',
    Name: 'lactose excess percentage',
    Description:
      'A unit of proportion defining the percentage of lactose in a product that exceeds a defined percentage level.',
  },
  {
    Code: 'LBT',
    Name: 'troy pound (US)',
    Description: '',
  },
  {
    Code: 'LEF',
    Name: 'leaf',
    Description: 'A unit of count defining the number of leaves.',
  },
  {
    Code: 'LF',
    Name: 'linear foot',
    Description:
      'A unit of count defining the number of feet (12-inch) in length of a uniform width object.',
  },
  {
    Code: 'LH',
    Name: 'labour hour',
    Description: 'A unit of time defining the number of labour hours.',
  },
  {
    Code: 'LK',
    Name: 'link',
    Description: 'A unit of distance equal to 0.01 chain.',
  },
  {
    Code: 'LM',
    Name: 'linear metre',
    Description:
      'A unit of count defining the number of metres in length of a uniform width object.',
  },
  {
    Code: 'LN',
    Name: 'length',
    Description:
      'A unit of distance defining the linear extent of an item measured from end to end.',
  },
  {
    Code: 'LO',
    Name: 'lot [unit of procurement]',
    Description:
      'A unit of count defining the number of lots (lot: a collection of associated items).',
  },
  {
    Code: 'LP',
    Name: 'liquid pound',
    Description: 'A unit of mass defining the number of pounds of a liquid substance.',
  },
  {
    Code: 'LPA',
    Name: 'litre of pure alcohol',
    Description: 'A unit of volume equal to one litre of pure alcohol.',
  },
  {
    Code: 'LR',
    Name: 'layer',
    Description: 'A unit of count defining the number of layers.',
  },
  {
    Code: 'LS',
    Name: 'lump sum',
    Description: 'A unit of count defining the number of whole or a complete monetary amounts.',
  },
  {
    Code: 'LUB',
    Name: 'metric ton, lubricating oil',
    Description: 'A unit of mass defining the number of metric tons of lubricating oil.',
  },
  {
    Code: 'LY',
    Name: 'linear yard',
    Description:
      'A unit of count defining the number of 36-inch units in length of a uniform width object.',
  },
  {
    Code: 'M19',
    Name: 'Beaufort',
    Description:
      'An empirical measure for describing wind speed based mainly on observed sea conditions. The Beaufort scale indicates the wind speed by numbers that typically range from 0 for calm, to 12 for hurricane.',
  },
  {
    Code: 'M25',
    Name: 'percent per degree Celsius',
    Description: 'A unit of proportion, equal to 0.01, in relation to a temperature of one degree.',
  },
  {
    Code: 'M36',
    Name: '30-day month',
    Description:
      'A unit of count defining the number of months expressed in multiples of 30 days, one day equals 24 hours.',
  },
  {
    Code: 'M37',
    Name: 'actual/360',
    Description:
      'A unit of count defining the number of years expressed in multiples of 360 days, one day equals 24 hours.',
  },
  {
    Code: 'M4',
    Name: 'monetary value',
    Description: 'A unit of measure expressed as a monetary amount.',
  },
  {
    Code: 'M9',
    Name: 'million Btu per 1000 cubic foot',
    Description: '',
  },
  {
    Code: 'MAH',
    Name: 'megavolt ampere reactive hour',
    Description:
      'A unit of electrical reactive power defining the total amount of reactive power across a power system.',
  },
  {
    Code: 'MBE',
    Name: 'thousand standard brick equivalent',
    Description: 'A unit of count defining the number of one thousand brick equivalent units.',
  },
  {
    Code: 'MBF',
    Name: 'thousand board foot',
    Description: 'A unit of volume equal to one thousand board foot.',
  },
  {
    Code: 'MD',
    Name: 'air dry metric ton',
    Description:
      'A unit of count defining the number of metric tons of a product, disregarding the water content of the product.',
  },
  {
    Code: 'MIL',
    Name: 'thousand',
    Description: '',
  },
  {
    Code: 'MIO',
    Name: 'million',
    Description: '',
  },
  {
    Code: 'MIU',
    Name: 'million international unit',
    Description: 'A unit of count defining the number of international units in multiples of 10⁶.',
  },
  {
    Code: 'MLD',
    Name: 'milliard',
    Description: 'Synonym: billion (US)',
  },
  {
    Code: 'MND',
    Name: 'kilogram, dry weight',
    Description:
      'A unit of mass defining the number of kilograms of a product, disregarding the water content of the product.',
  },
  {
    Code: 'N1',
    Name: 'pen calorie',
    Description:
      'A unit of count defining the number of calories prescribed daily for parenteral/enteral therapy.',
  },
  {
    Code: 'N3',
    Name: 'print point',
    Description: '',
  },
  {
    Code: 'NAR',
    Name: 'number of articles',
    Description: 'A unit of count defining the number of articles (article: item).',
  },
  {
    Code: 'NCL',
    Name: 'number of cells',
    Description:
      'A unit of count defining the number of cells (cell: an enclosed or circumscribed space, cavity, or volume).',
  },
  {
    Code: 'NF',
    Name: 'message',
    Description: 'A unit of count defining the number of messages.',
  },
  {
    Code: 'NIL',
    Name: 'nil',
    Description: 'A unit of count defining the number of instances of nothing.',
  },
  {
    Code: 'NIU',
    Name: 'number of international units',
    Description: 'A unit of count defining the number of international units.',
  },
  {
    Code: 'NL',
    Name: 'load',
    Description:
      'A unit of volume defining the number of loads (load: a quantity of items carried or processed at one time).',
  },
  {
    Code: 'NMP',
    Name: 'number of packs',
    Description:
      'A unit of count defining the number of packs (pack: a collection of objects packaged together).',
  },
  {
    Code: 'NPR',
    Name: 'number of pairs',
    Description: "A unit of count defining the number of pairs (pair: item described by two's).",
  },
  {
    Code: 'NPT',
    Name: 'number of parts',
    Description:
      'A unit of count defining the number of parts (part: component of a larger entity).',
  },
  {
    Code: 'NT',
    Name: 'net ton',
    Description:
      'A unit of mass equal to 2000 pounds, see ton (US).  Refer International Convention on tonnage measurement of Ships.',
  },
  {
    Code: 'NTT',
    Name: 'net register ton',
    Description:
      'A unit of mass equal to the total cubic footage after deductions, where 1 register ton is equal to 100 cubic feet. Refer International Convention on tonnage measurement of Ships.',
  },
  {
    Code: 'NX',
    Name: 'part per thousand',
    Description: 'A unit of proportion equal to 10⁻³.',
  },
  {
    Code: '',
    Name: '',
    Description: 'Synonym: per mille',
  },
  {
    Code: 'OA',
    Name: 'panel',
    Description:
      'A unit of count defining the number of panels (panel: a distinct, usually rectangular, section of a surface).',
  },
  {
    Code: 'ODE',
    Name: 'ozone depletion equivalent',
    Description:
      'A unit of mass defining the ozone depletion potential in kilograms of a product relative to the calculated depletion for the reference substance, Trichlorofluoromethane (CFC-11).',
  },
  {
    Code: 'ODG',
    Name: 'ODS Grams',
    Description:
      'A unit of measure calculated by multiplying the mass of the substance in grams and the ozone-depleting potential for the substance.',
  },
  {
    Code: 'ODK',
    Name: 'ODS Kilograms',
    Description:
      'A unit of measure calculated by multiplying the mass of the substance in kilograms and the ozone-depleting potential for the substance.',
  },
  {
    Code: 'ODM',
    Name: 'ODS Milligrams',
    Description:
      'A unit of measure calculated by multiplying the mass of the substance in milligrams and the ozone-depleting potential for the substance.',
  },
  {
    Code: 'OT',
    Name: 'overtime hour',
    Description: 'A unit of time defining the number of overtime hours.',
  },
  {
    Code: 'OZ',
    Name: 'ounce av',
    Description:
      'A unit of measure equal to 1/16 of a pound or about 28.3495 grams (av = avoirdupois). Use ounce (common code ONZ).',
  },
  {
    Code: 'P1',
    Name: 'percent',
    Description: 'A unit of proportion equal to 0.01.',
  },
  {
    Code: 'P5',
    Name: 'five pack',
    Description:
      'A unit of count defining the number of five-packs (five-pack: set of five items packaged together).',
  },
  {
    Code: 'P88',
    Name: 'rhe',
    Description: 'Non SI-conforming unit of fluidity of dynamic viscosity.',
  },
  {
    Code: 'P89',
    Name: 'pound-force foot per inch',
    Description:
      'Unit for length-related rotational moment according to the Anglo-American and Imperial system of units.',
  },
  {
    Code: 'P90',
    Name: 'pound-force inch per inch',
    Description:
      'Unit for length-related rotational moment according to the Anglo-American and Imperial system of units.',
  },
  {
    Code: 'P91',
    Name: 'perm (0 ºC)',
    Description:
      'Traditional unit for the ability of a material to allow the transition of the steam, defined at a temperature of 0 °C as steam transmittance, where the mass of one grain steam penetrates an area of one foot squared at a pressure from one inch mercury per hour.',
  },
  {
    Code: 'P92',
    Name: 'perm (23 ºC)',
    Description:
      'Traditional unit for the ability of a material to allow the transition of the steam, defined at a temperature of 23 °C as steam transmittance at which the mass of one grain of steam penetrates an area of one square foot at a pressure of one inch mercury per hour.',
  },
  {
    Code: 'P93',
    Name: 'byte per second',
    Description: 'Unit byte divided by the SI base unit second.',
  },
  {
    Code: 'P94',
    Name: 'kilobyte per second',
    Description: '1000-fold of the unit byte divided by the SI base unit second.',
  },
  {
    Code: 'P95',
    Name: 'megabyte per second',
    Description: '1 000 000-fold of the unit byte divided by the SI base unit second.',
  },
  {
    Code: 'P96',
    Name: 'reciprocal volt',
    Description: 'Reciprocal of the derived SI unit volt.',
  },
  {
    Code: 'P97',
    Name: 'reciprocal radian',
    Description: 'Reciprocal of the unit radian.',
  },
  {
    Code: 'P98',
    Name: 'pascal to the power sum of stoichiometric numbers',
    Description:
      'Unit of the equilibrium constant on the basis of the pressure(ISO 80000-9:2009, 9-35.a).',
  },
  {
    Code: 'P99',
    Name: 'mole per cubiv metre to the power sum of stoichiometric numbers',
    Description:
      'Unit of the equilibrium constant on the basis of the concentration (ISO 80000-9:2009, 9-36.a).',
  },
  {
    Code: 'PD',
    Name: 'pad',
    Description:
      'A unit of count defining the number of pads (pad: block of paper sheets fastened together at one end).',
  },
  {
    Code: 'PFL',
    Name: 'proof litre',
    Description:
      'A unit of volume equal to one litre of proof spirits, or the alcohol equivalent thereof. Used for measuring the strength of distilled alcoholic liquors, expressed as a percentage of the alcohol content of a standard mixture at a specific temperature.',
  },
  {
    Code: 'PGL',
    Name: 'proof gallon',
    Description:
      'A unit of volume equal to one gallon of proof spirits, or the alcohol equivalent thereof. Used for measuring the strength of distilled alcoholic liquors, expressed as a percentage of the alcohol content of a standard mixture at a specific temperature.',
  },
  {
    Code: 'PI',
    Name: 'pitch',
    Description: 'A unit of count defining the number of characters that fit in a horizontal inch.',
  },
  {
    Code: 'PLA',
    Name: 'degree Plato',
    Description:
      'A unit of proportion defining the sugar content of a product, especially in relation to beer.',
  },
  {
    Code: 'PQ',
    Name: 'page per inch',
    Description:
      'A unit of quantity defining the degree of thickness of a bound publication, expressed as the number of pages per inch of thickness.',
  },
  {
    Code: 'PR',
    Name: 'pair',
    Description: "A unit of count defining the number of pairs (pair: item described by two's).",
  },
  {
    Code: 'PTN',
    Name: 'portion',
    Description: 'A quantity of allowance of food allotted to, or enough for, one person.',
  },
  {
    Code: 'Q10',
    Name: 'joule per tesla',
    Description:
      'Unit of the magnetic dipole moment of the molecule as derived SI unit joule divided by the derived SI unit tesla.',
  },
  {
    Code: 'Q11',
    Name: 'erlang',
    Description:
      'Unit of the market value according to the feature of a single feature as a statistical measurement of the existing utilization.',
  },
  {
    Code: 'Q12',
    Name: 'octet',
    Description: 'Synonym for byte: 1 octet = 8 bit = 1 byte.',
  },
  {
    Code: 'Q13',
    Name: 'octet per second',
    Description: 'Unit octet divided by the SI base unit second.',
  },
  {
    Code: 'Q14',
    Name: 'shannon',
    Description:
      'Logarithmic unit for information equal to the content of decision of a sentence of two mutually exclusive events, expressed as a logarithm to base 2.',
  },
  {
    Code: 'Q15',
    Name: 'hartley',
    Description:
      'Logarithmic unit for information equal to the content of decision of a sentence of ten mutually exclusive events, expressed as a logarithm to base 10.',
  },
  {
    Code: 'Q16',
    Name: 'natural unit of information',
    Description:
      'Logarithmic unit for information equal to the content of decision of a sentence of ,718 281 828 459 mutually exclusive events, expressed as a logarithm to base Euler value e.',
  },
  {
    Code: 'Q17',
    Name: 'shannon per second',
    Description:
      'Time related logarithmic unit for information equal to the content of decision of a sentence of two mutually exclusive events, expressed as a logarithm to base 2.',
  },
  {
    Code: 'Q18',
    Name: 'hartley per second',
    Description:
      'Time related logarithmic unit for information equal to the content of decision of a sentence of ten mutually exclusive events, expressed as a logarithm to base 10.',
  },
  {
    Code: 'Q19',
    Name: 'natural unit of information per second',
    Description:
      'Time related logarithmic unit for information equal to the content of decision of a sentence of 2,718 281 828 459 mutually exclusive events, expressed as a logarithm to base of the Euler value e.',
  },
  {
    Code: 'Q20',
    Name: 'second per kilogramm',
    Description:
      'Unit of the Einstein transition probability for spontaneous or inducing emissions and absorption according to ISO 80000-7:2008, expressed as SI base unit second divided by the SI base unit kilogram.',
  },
  {
    Code: 'Q21',
    Name: 'watt square metre',
    Description:
      'Unit of the first radiation constants c1 = 2·p·h·c0², the value of which is 3,741 771 18·10?¹6-fold   that of the comparative value of the product of the derived SI unit watt multiplied with the power of the SI base unit metre with the exponent 2.',
  },
  {
    Code: 'Q22',
    Name: 'second per radian cubic metre',
    Description:
      'Unit of the density of states as an expression of angular frequency as complement of the product of hertz and radiant and the power of SI base unit metre by exponent 3 .',
  },
  {
    Code: 'Q23',
    Name: 'weber to the power minus one',
    Description:
      'Complement of the derived SI unit weber as unit of the Josephson constant, which value is equal to the 384 597,891-fold of the reference value gigahertz divided by volt.',
  },
  {
    Code: 'Q24',
    Name: 'reciprocal inch',
    Description:
      'Complement of the unit inch according to the Anglo-American and Imperial system of units.',
  },
  {
    Code: 'Q25',
    Name: 'dioptre',
    Description:
      'Unit used at the statement of relative refractive indexes of optical systems as complement of the focal length with correspondence to: 1 dpt = 1/m.',
  },
  {
    Code: 'Q26',
    Name: 'one per one',
    Description:
      'Value of the quotient from two physical units of the same kind as a numerator and denominator whereas the units are shortened mutually.',
  },
  {
    Code: 'Q27',
    Name: 'newton metre per metre',
    Description:
      'Unit for length-related rotational moment as product of the derived SI unit newton and the SI base unit metre divided by the SI base unit metre.',
  },
  {
    Code: 'Q28',
    Name: 'kilogram per square metre pascal second',
    Description: 'Unit for the ability of a material to allow the transition of steam.',
  },
  {
    Code: 'Q36',
    Name: 'square metre per cubic metre',
    Description:
      'A unit of the amount of surface area per unit volume of an object or collection of objects.',
  },
  {
    Code: 'Q3',
    Name: 'meal',
    Description:
      'A unit of count defining the number of meals (meal: an amount of food to be eaten on a single occasion).',
  },
  {
    Code: 'QA',
    Name: 'page - facsimile',
    Description: 'A unit of count defining the number of facsimile pages.',
  },
  {
    Code: 'QAN',
    Name: 'quarter (of a year)',
    Description: 'A unit of time defining the number of quarters (3 months).',
  },
  {
    Code: 'QB',
    Name: 'page - hardcopy',
    Description:
      'A unit of count defining the number of hardcopy pages (hardcopy page: a page rendered as printed or written output on paper, film, or other permanent medium).',
  },
  {
    Code: 'QR',
    Name: 'quire',
    Description:
      'A unit of count for paper, expressed as the number of quires (quire: a number of paper sheets, typically 25).',
  },
  {
    Code: 'QTR',
    Name: 'quarter (UK)',
    Description:
      'A traditional unit of weight equal to 1/4 hundredweight. In the United Kingdom, one quarter equals 28 pounds.',
  },
  {
    Code: 'R1',
    Name: 'pica',
    Description:
      'A unit of count defining the number of picas. (pica: typographical length equal to 12 points or 4.22 mm (approx.)).',
  },
  {
    Code: 'R9',
    Name: 'thousand cubic metre',
    Description: 'A unit of volume equal to one thousand cubic metres.',
  },
  {
    Code: 'RH',
    Name: 'running or operating hour',
    Description: 'A unit of time defining the number of hours of operation.',
  },
  {
    Code: 'RM',
    Name: 'ream',
    Description:
      'A unit of count for paper, expressed as the number of reams (ream: a large quantity of paper sheets, typically 500).',
  },
  {
    Code: 'ROM',
    Name: 'room',
    Description: 'A unit of count defining the number of rooms.',
  },
  {
    Code: 'RP',
    Name: 'pound per ream',
    Description:
      'A unit of mass for paper, expressed as pounds per ream. (ream: a large quantity of paper, typically 500 sheets).',
  },
  {
    Code: 'RT',
    Name: 'revenue ton mile',
    Description:
      'A unit of information typically used for billing purposes, expressed as the number of revenue tons (revenue ton: either a metric ton or a cubic metres, whichever is the larger), moved over a distance of one mile.',
  },
  {
    Code: 'SAN',
    Name: 'half year (6 months)',
    Description: 'A unit of time defining the number of half years (6 months).',
  },
  {
    Code: 'SCO',
    Name: 'score',
    Description: 'A unit of count defining the number of units in multiples of 20.',
  },
  {
    Code: 'SCR',
    Name: 'scruple',
    Description: '',
  },
  {
    Code: 'SET',
    Name: 'set',
    Description:
      'A unit of count defining the number of sets (set: a number of objects grouped together).',
  },
  {
    Code: 'SG',
    Name: 'segment',
    Description: 'A unit of information equal to 64000 bytes.',
  },
  {
    Code: 'SHT',
    Name: 'shipping ton',
    Description: 'A unit of mass defining the number of tons for shipping.',
  },
  {
    Code: 'SQ',
    Name: 'square',
    Description: 'A unit of count defining the number of squares (square: rectangular shape).',
  },
  {
    Code: 'SQR',
    Name: 'square, roofing',
    Description:
      'A unit of count defining the number of squares of roofing materials, measured in multiples of 100 square feet.',
  },
  {
    Code: 'SR',
    Name: 'strip',
    Description:
      'A unit of count defining the number of strips (strip: long narrow piece of an object).',
  },
  {
    Code: 'STC',
    Name: 'stick',
    Description:
      'A unit of count defining the number of sticks (stick: slender and often cylindrical piece of a substance).',
  },
  {
    Code: 'STK',
    Name: 'stick, cigarette',
    Description:
      'A unit of count defining the number of cigarettes in the smallest unit for stock-taking and/or duty computation.',
  },
  {
    Code: 'STL',
    Name: 'standard litre',
    Description:
      'A unit of volume defining the number of litres of a product at a temperature of 15 degrees Celsius, especially in relation to hydrocarbon oils.',
  },
  {
    Code: 'STW',
    Name: 'straw',
    Description:
      'A unit of count defining the number of straws (straw: a slender tube used for sucking up liquids).',
  },
  {
    Code: 'SW',
    Name: 'skein',
    Description:
      'A unit of count defining the number of skeins (skein: a loosely-coiled bundle of yarn or thread).',
  },
  {
    Code: 'SX',
    Name: 'shipment',
    Description:
      'A unit of count defining the number of shipments (shipment: an amount of goods shipped or transported).',
  },
  {
    Code: 'SYR',
    Name: 'syringe',
    Description:
      'A unit of count defining the number of syringes (syringe: a small device for pumping, spraying and/or injecting liquids through a small aperture).',
  },
  {
    Code: 'T0',
    Name: 'telecommunication line in service',
    Description: 'A unit of count defining the number of lines in service.',
  },
  {
    Code: 'T3',
    Name: 'thousand piece',
    Description:
      'A unit of count defining the number of pieces in multiples of 1000 (piece: a single item, article or exemplar).',
  },
  {
    Code: 'TAN',
    Name: 'total acid number',
    Description:
      'A unit of chemistry defining the amount of potassium hydroxide (KOH) in milligrams that is needed to neutralize the acids in one gram of oil. It is an important quality measurement of crude oil.',
  },
  {
    Code: 'TI',
    Name: 'thousand square inch',
    Description: '',
  },
  {
    Code: 'TIC',
    Name: 'metric ton, including container',
    Description:
      'A unit of mass defining the number of metric tons of a product, including its container.',
  },
  {
    Code: 'TIP',
    Name: 'metric ton, including inner packaging',
    Description:
      'A unit of mass defining the number of metric tons of a product, including its inner packaging materials.',
  },
  {
    Code: 'TKM',
    Name: 'tonne kilometre',
    Description:
      'A unit of information typically used for billing purposes, expressed as the number of tonnes (metric tons) moved over a distance of one kilometre. ',
  },
  {
    Code: 'TMS',
    Name: 'kilogram of imported meat, less offal',
    Description:
      'A unit of mass equal to one thousand grams of imported meat, disregarding less valuable by-products such as the entrails.',
  },
  {
    Code: 'TP',
    Name: 'ten pack',
    Description: 'A unit of count defining the number of items in multiples of 10.',
  },
  {
    Code: 'TPI',
    Name: 'teeth per inch',
    Description: 'The number of teeth per inch.',
  },
  {
    Code: 'TPR',
    Name: 'ten pair',
    Description:
      "A unit of count defining the number of pairs in multiples of 10 (pair: item described by two's).",
  },
  {
    Code: 'TQD',
    Name: 'thousand cubic metre per day',
    Description: 'A unit of volume equal to one thousand cubic metres per day.',
  },
  {
    Code: 'TRL',
    Name: 'trillion (EUR)',
    Description: '',
  },
  {
    Code: 'TST',
    Name: 'ten set',
    Description:
      'A unit of count defining the number of sets in multiples of 10 (set: a number of objects grouped together).',
  },
  {
    Code: 'TTS',
    Name: 'ten thousand sticks',
    Description:
      'A unit of count defining the number of sticks in multiples of 10000 (stick: slender and often cylindrical piece of a substance).',
  },
  {
    Code: 'U1',
    Name: 'treatment',
    Description:
      'A unit of count defining the number of treatments (treatment: subjection to the action of a chemical, physical or biological agent).',
  },
  {
    Code: 'U2',
    Name: 'tablet',
    Description:
      'A unit of count defining the number of tablets (tablet: a small flat or compressed solid object).',
  },
  {
    Code: 'UB',
    Name: 'telecommunication line in service average',
    Description: 'A unit of count defining the average number of lines in service.',
  },
  {
    Code: 'UC',
    Name: 'telecommunication port',
    Description: 'A unit of count defining the number of network access ports.',
  },
  {
    Code: 'VA',
    Name: 'volt - ampere per kilogram',
    Description: '',
  },
  {
    Code: 'VP',
    Name: 'percent volume',
    Description:
      'A measure of concentration, typically expressed as the percentage volume of a solute in a solution.',
  },
  {
    Code: 'W2',
    Name: 'wet kilo',
    Description:
      'A unit of mass defining the number of kilograms of a product, including the water content of the product.',
  },
  {
    Code: 'WA',
    Name: 'watt per kilogram',
    Description: '',
  },
  {
    Code: 'WB',
    Name: 'wet pound',
    Description:
      'A unit of mass defining the number of pounds of a material, including the water content of the material.',
  },
  {
    Code: 'WCD',
    Name: 'cord',
    Description:
      'A unit of volume used for measuring lumber. One board foot equals 1/12 of a cubic foot.',
  },
  {
    Code: 'WE',
    Name: 'wet ton',
    Description:
      'A unit of mass defining the number of tons of a material, including the water content of the material.',
  },
  {
    Code: 'WG',
    Name: 'wine gallon',
    Description: 'A unit of volume equal to 231 cubic inches.',
  },
  {
    Code: 'WM',
    Name: 'working month',
    Description: 'A unit of time defining the number of working months.',
  },
  {
    Code: 'WSD',
    Name: 'standard',
    Description: 'A unit of volume of finished lumber equal to 165 cubic feet.',
  },
  {
    Code: '',
    Name: '',
    Description: 'Synonym: standard cubic foot',
  },
  {
    Code: 'WW',
    Name: 'millilitre of water',
    Description: 'A unit of volume equal to the number of millilitres of water.',
  },
  {
    Code: 'Z11',
    Name: 'hanging container',
    Description: 'A unit of count defining the number of hanging containers.',
  },
  {
    Code: 'ZP',
    Name: 'page',
    Description: 'A unit of count defining the number of pages.',
  },
  {
    Code: 'ZZ',
    Name: 'mutually defined',
    Description: 'A unit of measure as agreed in common between two or more parties.',
  },
  {
    Code: 'MRW',
    Name: 'Metre Week',
    Description: 'Unit for measuring physical dimensions and time, as required by DIN 18451',
  },
  {
    Code: 'MKW',
    Name: 'Square Metre Week',
    Description: 'Unit for measuring physical dimensions and time as required by DIN 18451',
  },
  {
    Code: 'MQW',
    Name: 'Cubic Metre Week',
    Description: 'Unit for measuring physical dimensions and time as required by DIN 18451',
  },
  {
    Code: 'HWE',
    Name: 'Piece Week',
    Description: 'Unit for measuring the item amount and time as required by DIN 18451',
  },
  {
    Code: 'MRD',
    Name: 'Metre Day',
    Description: 'Unit for measuring physical dimensions and time as required by DIN 18451',
  },
  {
    Code: 'MKD',
    Name: 'Square Metre Day',
    Description: 'Unit for measuring physical dimensions and time as required by DIN 18451',
  },
  {
    Code: 'MQD',
    Name: 'Cubic Metre Day',
    Description: 'Unit for measuring physical dimensions and time as required by DIN 18451',
  },
  {
    Code: 'HAD',
    Name: 'Piece Day',
    Description: 'Unit for measuring the item amount and time as required by DIN 18451',
  },
  {
    Code: 'MRM',
    Name: 'Metre Month',
    Description: 'Unit for measuring physical dimensions and time as required by DIN 18451',
  },
  {
    Code: 'MKM',
    Name: 'Square Metre Month',
    Description: 'Unit for measuring physical dimensions and time as required by DIN 18451',
  },
  {
    Code: 'MQM',
    Name: 'Cubic Metre Month',
    Description: 'Unit for measuring physical dimensions and time as required by DIN 18451',
  },
  {
    Code: 'HMO',
    Name: 'Piece Month',
    Description: 'Unit for measuring the item amount and time as required by DIN 18451',
  },
  {
    Code: 'DBW',
    Name: 'Decibel watt',
    Description:
      'The decibel watt or dBW is a unit for the measurement of the strength of a signal expressed in decibels relative to one watt.',
  },
  {
    Code: 'DBM',
    Name: 'Decibel-milliwatts',
    Description:
      'dBm (sometimes dBmW or decibel-milliwatts) is unit of level used to indicate that a power ratio is expressed in decibels (dB) with reference to one milliwatt (mW).',
  },
  {
    Code: 'FNU',
    Name: 'Formazin nephelometric unit',
    Description: 'Formazin nephelometric unit (FNU) is used for water turbidity level evaluation',
  },
  {
    Code: 'NTU',
    Name: 'Nephelometric turbidity unit',
    Description: 'Nephelometric turbidity unit (NTU) is used for water turbidity level evaluation',
  },
  { Code: 'X1A', Name: 'Drum, steel', Description: '' },
  { Code: 'X1B', Name: 'Drum, aluminium', Description: '' },
  { Code: 'X1D', Name: 'Drum, plywood', Description: '' },
  { Code: 'X1F', Name: 'Container, flexible', Description: '' },
  { Code: 'X1G', Name: 'Drum, fibre', Description: '' },
  { Code: 'X1W', Name: 'Drum, wooden', Description: '' },
  { Code: 'X2C', Name: 'Barrel, wooden', Description: '' },
  { Code: 'X3A', Name: 'Jerrican, steel', Description: '' },
  { Code: 'X3H', Name: 'Jerrican, plastic', Description: '' },
  { Code: 'X43', Name: 'Bag, super bulk', Description: '' },
  { Code: 'X44', Name: 'Bag, polybag', Description: '' },
  { Code: 'X4A', Name: 'Box, steel', Description: '' },
  { Code: 'X4B', Name: 'Box, aluminium', Description: '' },
  { Code: 'X4C', Name: 'Box, natural wood', Description: '' },
  { Code: 'X4D', Name: 'Box, plywood', Description: '' },
  { Code: 'X4F', Name: 'Box, reconstituted wood', Description: '' },
  { Code: 'X4G', Name: 'Box, fibreboard', Description: '' },
  { Code: 'X4H', Name: 'Box, plastic', Description: '' },
  { Code: 'X5H', Name: 'Bag, woven plastic', Description: '' },
  { Code: 'X5L', Name: 'Bag, textile', Description: '' },
  { Code: 'X5M', Name: 'Bag, paper', Description: '' },
  { Code: 'X6H', Name: 'Composite packaging, plastic receptacle', Description: '' },
  { Code: 'X6P', Name: 'Composite packaging, glass receptacle', Description: '' },
  { Code: 'X7A', Name: 'Case, car', Description: '' },
  { Code: 'X7B', Name: 'Case, wooden', Description: '' },
  { Code: 'X8A', Name: 'Pallet, wooden', Description: '' },
  { Code: 'X8B', Name: 'Crate, wooden', Description: '' },
  { Code: 'X8C', Name: 'Bundle, wooden', Description: '' },
  { Code: 'XAA', Name: 'Intermediate bulk container, rigid plastic', Description: '' },
  { Code: 'XAB', Name: 'Receptacle, fibre', Description: '' },
  { Code: 'XAC', Name: 'Receptacle, paper', Description: '' },
  { Code: 'XAD', Name: 'Receptacle, wooden', Description: '' },
  { Code: 'XAE', Name: 'Aerosol', Description: '' },
  { Code: 'XAF', Name: 'Pallet, modular, collars 80cms * 60cms', Description: '' },
  { Code: 'XAG', Name: 'Pallet, shrinkwrapped', Description: '' },
  { Code: 'XAH', Name: 'Pallet, 100cms * 110cms', Description: '' },
  { Code: 'XAI', Name: 'Clamshell', Description: '' },
  { Code: 'XAJ', Name: 'Cone', Description: '' },
  { Code: 'XAL', Name: 'Ball', Description: '' },
  { Code: 'XAM', Name: 'Ampoule, non-protected', Description: '' },
  { Code: 'XAP', Name: 'Ampoule, protected', Description: '' },
  { Code: 'XAT', Name: 'Atomizer', Description: '' },
  { Code: 'XAV', Name: 'Capsule', Description: '' },
  { Code: 'XB4', Name: 'Belt', Description: '' },
  { Code: 'XBA', Name: 'Barrel', Description: '' },
  { Code: 'XBB', Name: 'Bobbin', Description: '' },
  { Code: 'XBC', Name: 'Bottlecrate / bottlerack', Description: '' },
  { Code: 'XBD', Name: 'Board', Description: '' },
  { Code: 'XBE', Name: 'Bundle', Description: '' },
  { Code: 'XBF', Name: 'Balloon, non-protected', Description: '' },
  { Code: 'XBG', Name: 'Bag', Description: '' },
  { Code: 'XBH', Name: 'Bunch', Description: '' },
  { Code: 'XBI', Name: 'Bin', Description: '' },
  { Code: 'XBJ', Name: 'Bucket', Description: '' },
  { Code: 'XBK', Name: 'Basket', Description: '' },
  { Code: 'XBL', Name: 'Bale, compressed', Description: '' },
  { Code: 'XBM', Name: 'Basin', Description: '' },
  { Code: 'XBN', Name: 'Bale, non-compressed', Description: '' },
  { Code: 'XBO', Name: 'Bottle, non-protected, cylindrical', Description: '' },
  { Code: 'XBP', Name: 'Balloon, protected', Description: '' },
  { Code: 'XBQ', Name: 'Bottle, protected cylindrical', Description: '' },
  { Code: 'XBR', Name: 'Bar', Description: '' },
  { Code: 'XBS', Name: 'Bottle, non-protected, bulbous', Description: '' },
  { Code: 'XBT', Name: 'Bolt', Description: '' },
  { Code: 'XBU', Name: 'Butt', Description: '' },
  { Code: 'XBV', Name: 'Bottle, protected bulbous', Description: '' },
  { Code: 'XBW', Name: 'Box, for liquids', Description: '' },
  { Code: 'XBX', Name: 'Box', Description: '' },
  { Code: 'XBY', Name: 'Board, in bundle/bunch/truss', Description: '' },
  { Code: 'XBZ', Name: 'Bars, in bundle/bunch/truss', Description: '' },
  { Code: 'XCA', Name: 'Can, rectangular', Description: '' },
  { Code: 'XCB', Name: 'Crate, beer', Description: '' },
  { Code: 'XCC', Name: 'Churn', Description: '' },
  { Code: 'XCD', Name: 'Can, with handle and spout', Description: '' },
  { Code: 'XCE', Name: 'Creel', Description: '' },
  { Code: 'XCF', Name: 'Coffer', Description: '' },
  { Code: 'XCG', Name: 'Cage', Description: '' },
  { Code: 'XCH', Name: 'Chest', Description: '' },
  { Code: 'XCI', Name: 'Canister', Description: '' },
  { Code: 'XCJ', Name: 'Coffin', Description: '' },
  { Code: 'XCK', Name: 'Cask', Description: '' },
  { Code: 'XCL', Name: 'Coil', Description: '' },
  { Code: 'XCM', Name: 'Card', Description: '' },
  {
    Code: 'XCN',
    Name: 'Container, not otherwise specified as transport equipment',
    Description: '',
  },
  { Code: 'XCO', Name: 'Carboy, non-protected', Description: '' },
  { Code: 'XCP', Name: 'Carboy, protected', Description: '' },
  { Code: 'XCQ', Name: 'Cartridge', Description: '' },
  { Code: 'XCR', Name: 'Crate', Description: '' },
  { Code: 'XCS', Name: 'Case', Description: '' },
  { Code: 'XCT', Name: 'Carton', Description: '' },
  { Code: 'XCU', Name: 'Cup', Description: '' },
  { Code: 'XCV', Name: 'Cover', Description: '' },
  { Code: 'XCW', Name: 'Cage, roll', Description: '' },
  { Code: 'XCX', Name: 'Can, cylindrical', Description: '' },
  { Code: 'XCY', Name: 'Cylinder', Description: '' },
  { Code: 'XCZ', Name: 'Canvas', Description: '' },
  { Code: 'XDA', Name: 'Crate, multiple layer, plastic', Description: '' },
  { Code: 'XDB', Name: 'Crate, multiple layer, wooden', Description: '' },
  { Code: 'XDC', Name: 'Crate, multiple layer, cardboard', Description: '' },
  { Code: 'XDG', Name: 'Cage, Commonwealth Handling Equipment Pool (CHEP)', Description: '' },
  {
    Code: 'XDH',
    Name: 'Box, Commonwealth Handling Equipment Pool (CHEP), Eurobox',
    Description: '',
  },
  { Code: 'XDI', Name: 'Drum, iron', Description: '' },
  { Code: 'XDJ', Name: 'Demijohn, non-protected', Description: '' },
  { Code: 'XDK', Name: 'Crate, bulk, cardboard', Description: '' },
  { Code: 'XDL', Name: 'Crate, bulk, plastic', Description: '' },
  { Code: 'XDM', Name: 'Crate, bulk, wooden', Description: '' },
  { Code: 'XDN', Name: 'Dispenser', Description: '' },
  { Code: 'XDP', Name: 'Demijohn, protected', Description: '' },
  { Code: 'XDR', Name: 'Drum', Description: '' },
  { Code: 'XDS', Name: 'Tray, one layer no cover, plastic', Description: '' },
  { Code: 'XDT', Name: 'Tray, one layer no cover, wooden', Description: '' },
  { Code: 'XDU', Name: 'Tray, one layer no cover, polystyrene', Description: '' },
  { Code: 'XDV', Name: 'Tray, one layer no cover, cardboard', Description: '' },
  { Code: 'XDW', Name: 'Tray, two layers no cover, plastic tray', Description: '' },
  { Code: 'XDX', Name: 'Tray, two layers no cover, wooden', Description: '' },
  { Code: 'XDY', Name: 'Tray, two layers no cover, cardboard', Description: '' },
  { Code: 'XEC', Name: 'Bag, plastic', Description: '' },
  { Code: 'XED', Name: 'Case, with pallet base', Description: '' },
  { Code: 'XEE', Name: 'Case, with pallet base, wooden', Description: '' },
  { Code: 'XEF', Name: 'Case, with pallet base, cardboard', Description: '' },
  { Code: 'XEG', Name: 'Case, with pallet base, plastic', Description: '' },
  { Code: 'XEH', Name: 'Case, with pallet base, metal', Description: '' },
  { Code: 'XEI', Name: 'Case, isothermic', Description: '' },
  { Code: 'XEN', Name: 'Envelope', Description: '' },
  { Code: 'XFB', Name: 'Flexibag', Description: '' },
  { Code: 'XFC', Name: 'Crate, fruit', Description: '' },
  { Code: 'XFD', Name: 'Crate, framed', Description: '' },
  { Code: 'XFE', Name: 'Flexitank', Description: '' },
  { Code: 'XFI', Name: 'Firkin', Description: '' },
  { Code: 'XFL', Name: 'Flask', Description: '' },
  { Code: 'XFO', Name: 'Footlocker', Description: '' },
  { Code: 'XFP', Name: 'Filmpack', Description: '' },
  { Code: 'XFR', Name: 'Frame', Description: '' },
  { Code: 'XFT', Name: 'Foodtainer', Description: '' },
  { Code: 'XFW', Name: 'Cart, flatbed', Description: '' },
  { Code: 'XFX', Name: 'Bag, flexible container', Description: '' },
  { Code: 'XGB', Name: 'Bottle, gas', Description: '' },
  { Code: 'XGI', Name: 'Girder', Description: '' },
  { Code: 'XGL', Name: 'Container, gallon', Description: '' },
  { Code: 'XGR', Name: 'Receptacle, glass', Description: '' },
  { Code: 'XGU', Name: 'Tray, containing horizontally stacked flat items', Description: '' },
  { Code: 'XGY', Name: 'Bag, gunny', Description: '' },
  { Code: 'XGZ', Name: 'Girders, in bundle/bunch/truss', Description: '' },
  { Code: 'XHA', Name: 'Basket, with handle, plastic', Description: '' },
  { Code: 'XHB', Name: 'Basket, with handle, wooden', Description: '' },
  { Code: 'XHC', Name: 'Basket, with handle, cardboard', Description: '' },
  { Code: 'XHG', Name: 'Hogshead', Description: '' },
  { Code: 'XHN', Name: 'Hanger', Description: '' },
  { Code: 'XHR', Name: 'Hamper', Description: '' },
  { Code: 'XIA', Name: 'Package, display, wooden', Description: '' },
  { Code: 'XIB', Name: 'Package, display, cardboard', Description: '' },
  { Code: 'XIC', Name: 'Package, display, plastic', Description: '' },
  { Code: 'XID', Name: 'Package, display, metal', Description: '' },
  { Code: 'XIE', Name: 'Package, show', Description: '' },
  { Code: 'XIF', Name: 'Package, flow', Description: '' },
  { Code: 'XIG', Name: 'Package, paper wrapped', Description: '' },
  { Code: 'XIH', Name: 'Drum, plastic', Description: '' },
  { Code: 'XIK', Name: 'Package, cardboard, with bottle grip-holes', Description: '' },
  { Code: 'XIL', Name: 'Tray, rigid, lidded stackable (CEN TS 14482:2002)', Description: '' },
  { Code: 'XIN', Name: 'Ingot', Description: '' },
  { Code: 'XIZ', Name: 'Ingots, in bundle/bunch/truss', Description: '' },
  { Code: 'XJB', Name: 'Bag, jumbo', Description: '' },
  { Code: 'XJC', Name: 'Jerrican, rectangular', Description: '' },
  { Code: 'XJG', Name: 'Jug', Description: '' },
  { Code: 'XJR', Name: 'Jar', Description: '' },
  { Code: 'XJT', Name: 'Jutebag', Description: '' },
  { Code: 'XJY', Name: 'Jerrican, cylindrical', Description: '' },
  { Code: 'XKG', Name: 'Keg', Description: '' },
  { Code: 'XKI', Name: 'Kit', Description: '' },
  { Code: 'XLE', Name: 'Luggage', Description: '' },
  { Code: 'XLG', Name: 'Log', Description: '' },
  { Code: 'XLT', Name: 'Lot', Description: '' },
  { Code: 'XLU', Name: 'Lug', Description: '' },
  { Code: 'XLV', Name: 'Liftvan', Description: '' },
  { Code: 'XLZ', Name: 'Logs, in bundle/bunch/truss', Description: '' },
  { Code: 'XMA', Name: 'Crate, metal', Description: '' },
  { Code: 'XMB', Name: 'Bag, multiply', Description: '' },
  { Code: 'XMC', Name: 'Crate, milk', Description: '' },
  { Code: 'XME', Name: 'Container, metal', Description: '' },
  { Code: 'XMR', Name: 'Receptacle, metal', Description: '' },
  { Code: 'XMS', Name: 'Sack, multi-wall', Description: '' },
  { Code: 'XMT', Name: 'Mat', Description: '' },
  { Code: 'XMW', Name: 'Receptacle, plastic wrapped', Description: '' },
  { Code: 'XMX', Name: 'Matchbox', Description: '' },
  { Code: 'XNA', Name: 'Not available', Description: '' },
  { Code: 'XNE', Name: 'Unpacked or unpackaged', Description: '' },
  { Code: 'XNF', Name: 'Unpacked or unpackaged, single unit', Description: '' },
  { Code: 'XNG', Name: 'Unpacked or unpackaged, multiple units', Description: '' },
  { Code: 'XNS', Name: 'Nest', Description: '' },
  { Code: 'XNT', Name: 'Net', Description: '' },
  { Code: 'XNU', Name: 'Net, tube, plastic', Description: '' },
  { Code: 'XNV', Name: 'Net, tube, textile', Description: '' },
  { Code: 'XO1', Name: 'Two sided cage on wheels with fixing strap', Description: '' },
  { Code: 'XO2', Name: 'Trolley', Description: '' },
  { Code: 'XO3', Name: 'Oneway pallet ISO 0 - 1/2 EURO Pallet', Description: '' },
  { Code: 'XO4', Name: 'Oneway pallet ISO 1 - 1/1 EURO Pallet', Description: '' },
  { Code: 'XO5', Name: 'Oneway pallet ISO 2 - 2/1 EURO Pallet', Description: '' },
  { Code: 'XO6', Name: 'Pallet with exceptional dimensions', Description: '' },
  { Code: 'XO7', Name: 'Wooden pallet 40 cm x 80 cm', Description: '' },
  { Code: 'XO8', Name: 'Plastic pallet SRS 60 cm x 80 cm', Description: '' },
  { Code: 'XO9', Name: 'Plastic pallet SRS 80 cm x 120 cm', Description: '' },
  { Code: 'XOA', Name: 'Pallet, CHEP 40 cm x 60 cm', Description: '' },
  { Code: 'XOB', Name: 'Pallet, CHEP 80 cm x 120 cm', Description: '' },
  { Code: 'XOC', Name: 'Pallet, CHEP 100 cm x 120 cm', Description: '' },
  { Code: 'XOD', Name: 'Pallet, AS 4068-1993', Description: '' },
  { Code: 'XOE', Name: 'Pallet, ISO T11', Description: '' },
  { Code: 'XOF', Name: 'Platform, unspecified weight or dimension', Description: '' },
  { Code: 'XOG', Name: 'Pallet ISO 0 - 1/2 EURO Pallet', Description: '' },
  { Code: 'XOH', Name: 'Pallet ISO 1 - 1/1 EURO Pallet', Description: '' },
  { Code: 'XOI', Name: 'Pallet ISO 2 – 2/1 EURO Pallet', Description: '' },
  { Code: 'XOJ', Name: '1/4 EURO Pallet', Description: '' },
  { Code: 'XOK', Name: 'Block', Description: '' },
  { Code: 'XOL', Name: '1/8 EURO Pallet', Description: '' },
  { Code: 'XOM', Name: 'Synthetic pallet ISO 1', Description: '' },
  { Code: 'XON', Name: 'Synthetic pallet ISO 2', Description: '' },
  { Code: 'XOP', Name: 'Wholesaler pallet', Description: '' },
  { Code: 'XOQ', Name: 'Pallet 80 X 100 cm', Description: '' },
  { Code: 'XOR', Name: 'Pallet 60 X 100 cm', Description: '' },
  { Code: 'XOS', Name: 'Oneway pallet', Description: '' },
  { Code: 'XOT', Name: 'Octabin', Description: '' },
  { Code: 'XOU', Name: 'Container, outer', Description: '' },
  { Code: 'XOV', Name: 'Returnable pallet', Description: '' },
  { Code: 'XOW', Name: 'Large bag, pallet sized', Description: '' },
  { Code: 'XOX', Name: 'A wheeled pallet with raised rim (81 x 67 x 135)', Description: '' },
  { Code: 'XOY', Name: 'A Wheeled pallet with raised rim (81 x 72 x 135)', Description: '' },
  { Code: 'XOZ', Name: 'Wheeled pallet with raised rim (81 x 60 x 16)', Description: '' },
  { Code: 'XP1', Name: 'CHEP pallet 60 cm x 80 cm', Description: '' },
  { Code: 'XP2', Name: 'Pan', Description: '' },
  { Code: 'XP3', Name: 'LPR pallet 60 cm x 80 cm', Description: '' },
  { Code: 'XP4', Name: 'LPR pallet 80 cm x 120 cm', Description: '' },
  { Code: 'XPA', Name: 'Packet', Description: '' },
  { Code: 'XPB', Name: 'Pallet, box Combined open-ended box and pallet', Description: '' },
  { Code: 'XPC', Name: 'Parcel', Description: '' },
  { Code: 'XPD', Name: 'Pallet, modular, collars 80cms * 100cms', Description: '' },
  { Code: 'XPE', Name: 'Pallet, modular, collars 80cms * 120cms', Description: '' },
  { Code: 'XPF', Name: 'Pen', Description: '' },
  { Code: 'XPG', Name: 'Plate', Description: '' },
  { Code: 'XPH', Name: 'Pitcher', Description: '' },
  { Code: 'XPI', Name: 'Pipe', Description: '' },
  { Code: 'XPJ', Name: 'Punnet', Description: '' },
  { Code: 'XPK', Name: 'Package', Description: '' },
  { Code: 'XPL', Name: 'Pail', Description: '' },
  { Code: 'XPN', Name: 'Plank', Description: '' },
  { Code: 'XPO', Name: 'Pouch', Description: '' },
  { Code: 'XPP', Name: 'Piece', Description: '' },
  { Code: 'XPR', Name: 'Receptacle, plastic', Description: '' },
  { Code: 'XPT', Name: 'Pot', Description: '' },
  { Code: 'XPU', Name: 'Tray', Description: '' },
  { Code: 'XPV', Name: 'Pipes, in bundle/bunch/truss', Description: '' },
  { Code: 'XPX', Name: 'Pallet', Description: '' },
  { Code: 'XPY', Name: 'Plates, in bundle/bunch/truss', Description: '' },
  { Code: 'XPZ', Name: 'Planks, in bundle/bunch/truss', Description: '' },
  { Code: 'XQA', Name: 'Drum, steel, non-removable head', Description: '' },
  { Code: 'XQB', Name: 'Drum, steel, removable head', Description: '' },
  { Code: 'XQC', Name: 'Drum, aluminium, non-removable head', Description: '' },
  { Code: 'XQD', Name: 'Drum, aluminium, removable head', Description: '' },
  { Code: 'XQF', Name: 'Drum, plastic, non-removable head', Description: '' },
  { Code: 'XQG', Name: 'Drum, plastic, removable head', Description: '' },
  { Code: 'XQH', Name: 'Barrel, wooden, bung type', Description: '' },
  { Code: 'XQJ', Name: 'Barrel, wooden, removable head', Description: '' },
  { Code: 'XQK', Name: 'Jerrican, steel, non-removable head', Description: '' },
  { Code: 'XQL', Name: 'Jerrican, steel, removable head', Description: '' },
  { Code: 'XQM', Name: 'Jerrican, plastic, non-removable head', Description: '' },
  { Code: 'XQN', Name: 'Jerrican, plastic, removable head', Description: '' },
  { Code: 'XQP', Name: 'Box, wooden, natural wood, ordinary', Description: '' },
  { Code: 'XQQ', Name: 'Box, wooden, natural wood, with sift proof walls', Description: '' },
  { Code: 'XQR', Name: 'Box, plastic, expanded', Description: '' },
  { Code: 'XQS', Name: 'Box, plastic, solid', Description: '' },
  { Code: 'XRD', Name: 'Rod', Description: '' },
  { Code: 'XRG', Name: 'Ring', Description: '' },
  { Code: 'XRJ', Name: 'Rack, clothing hanger', Description: '' },
  { Code: 'XRK', Name: 'Rack', Description: '' },
  { Code: 'XRL', Name: 'Reel', Description: '' },
  { Code: 'XRO', Name: 'Roll', Description: '' },
  { Code: 'XRT', Name: 'Rednet', Description: '' },
  { Code: 'XRZ', Name: 'Rods, in bundle/bunch/truss', Description: '' },
  { Code: 'XSA', Name: 'Sack', Description: '' },
  { Code: 'XSB', Name: 'Slab', Description: '' },
  { Code: 'XSC', Name: 'Crate, shallow', Description: '' },
  { Code: 'XSD', Name: 'Spindle', Description: '' },
  { Code: 'XSE', Name: 'Sea-chest', Description: '' },
  { Code: 'XSH', Name: 'Sachet', Description: '' },
  { Code: 'XSI', Name: 'Skid', Description: '' },
  { Code: 'XSK', Name: 'Case, skeleton', Description: '' },
  { Code: 'XSL', Name: 'Slipsheet', Description: '' },
  { Code: 'XSM', Name: 'Sheetmetal', Description: '' },
  { Code: 'XSO', Name: 'Spool', Description: '' },
  { Code: 'XSP', Name: 'Sheet, plastic wrapping', Description: '' },
  { Code: 'XSS', Name: 'Case, steel', Description: '' },
  { Code: 'XST', Name: 'Sheet', Description: '' },
  { Code: 'XSU', Name: 'Suitcase', Description: '' },
  { Code: 'XSV', Name: 'Envelope, steel', Description: '' },
  { Code: 'XSW', Name: 'Shrinkwrapped', Description: '' },
  { Code: 'XSX', Name: 'Set', Description: '' },
  { Code: 'XSY', Name: 'Sleeve', Description: '' },
  { Code: 'XSZ', Name: 'Sheets, in bundle/bunch/truss', Description: '' },
  { Code: 'XT1', Name: 'Tablet', Description: '' },
  { Code: 'XTB', Name: 'Tub', Description: '' },
  { Code: 'XTC', Name: 'Tea-chest', Description: '' },
  { Code: 'XTD', Name: 'Tube, collapsible', Description: '' },
  { Code: 'XTE', Name: 'Tyre', Description: '' },
  { Code: 'XTG', Name: 'Tank container, generic', Description: '' },
  { Code: 'XTI', Name: 'Tierce', Description: '' },
  { Code: 'XTK', Name: 'Tank, rectangular', Description: '' },
  { Code: 'XTL', Name: 'Tub, with lid', Description: '' },
  { Code: 'XTN', Name: 'Tin', Description: '' },
  { Code: 'XTO', Name: 'Tun', Description: '' },
  { Code: 'XTR', Name: 'Trunk', Description: '' },
  { Code: 'XTS', Name: 'Truss', Description: '' },
  { Code: 'XTT', Name: 'Bag, tote', Description: '' },
  { Code: 'XTU', Name: 'Tube', Description: '' },
  { Code: 'XTV', Name: 'Tube, with nozzle', Description: '' },
  { Code: 'XTW', Name: 'Pallet, triwall', Description: '' },
  { Code: 'XTY', Name: 'Tank, cylindrical', Description: '' },
  { Code: 'XTZ', Name: 'Tubes, in bundle/bunch/truss', Description: '' },
  { Code: 'XUC', Name: 'Uncaged', Description: '' },
  { Code: 'XUN', Name: 'Unit', Description: '' },
  { Code: 'XVA', Name: 'Vat', Description: '' },
  { Code: 'XVG', Name: 'Bulk, gas (at 1031 mbar and 15°C)', Description: '' },
  { Code: 'XVI', Name: 'Vial', Description: '' },
  { Code: 'XVK', Name: 'Vanpack', Description: '' },
  { Code: 'XVL', Name: 'Bulk, liquid', Description: '' },
  { Code: 'XVN', Name: 'Vehicle', Description: '' },
  { Code: 'XVO', Name: 'Bulk, solid, large particles ("nodules")', Description: '' },
  { Code: 'XVP', Name: 'Vacuum-packed', Description: '' },
  { Code: 'XVQ', Name: 'Bulk, liquefied gas (at abnormal temperature/pressure)', Description: '' },
  { Code: 'XVR', Name: 'Bulk, solid, granular particles ("grains")', Description: '' },
  { Code: 'XVS', Name: 'Bulk, scrap metal', Description: '' },
  { Code: 'XVY', Name: 'Bulk, solid, fine particles ("powders")', Description: '' },
  { Code: 'XWA', Name: 'Intermediate bulk container', Description: '' },
  { Code: 'XWB', Name: 'Wickerbottle', Description: '' },
  { Code: 'XWC', Name: 'Intermediate bulk container, steel', Description: '' },
  { Code: 'XWD', Name: 'Intermediate bulk container, aluminium', Description: '' },
  { Code: 'XWF', Name: 'Intermediate bulk container, metal', Description: '' },
  {
    Code: 'XWG',
    Name: 'Intermediate bulk container, steel, pressurised > 10 kpa',
    Description: '',
  },
  {
    Code: 'XWH',
    Name: 'Intermediate bulk container, aluminium, pressurised > 10 kpa',
    Description: '',
  },
  { Code: 'XWJ', Name: 'Intermediate bulk container, metal, pressure 10 kpa', Description: '' },
  { Code: 'XWK', Name: 'Intermediate bulk container, steel, liquid', Description: '' },
  { Code: 'XWL', Name: 'Intermediate bulk container, aluminium, liquid', Description: '' },
  { Code: 'XWM', Name: 'Intermediate bulk container, metal, liquid', Description: '' },
  {
    Code: 'XWN',
    Name: 'Intermediate bulk container, woven plastic, without coat/liner',
    Description: '',
  },
  { Code: 'XWP', Name: 'Intermediate bulk container, woven plastic, coated', Description: '' },
  { Code: 'XWQ', Name: 'Intermediate bulk container, woven plastic, with liner', Description: '' },
  {
    Code: 'XWR',
    Name: 'Intermediate bulk container, woven plastic, coated and liner',
    Description: '',
  },
  { Code: 'XWS', Name: 'Intermediate bulk container, plastic film', Description: '' },
  { Code: 'XWT', Name: 'Intermediate bulk container, textile without coat/liner', Description: '' },
  {
    Code: 'XWU',
    Name: 'Intermediate bulk container, natural wood, with inner liner',
    Description: '',
  },
  { Code: 'XWV', Name: 'Intermediate bulk container, textile, coated', Description: '' },
  { Code: 'XWW', Name: 'Intermediate bulk container, textile, with liner', Description: '' },
  { Code: 'XWX', Name: 'Intermediate bulk container, textile, coated and liner', Description: '' },
  { Code: 'XWY', Name: 'Intermediate bulk container, plywood, with inner liner', Description: '' },
  {
    Code: 'XWZ',
    Name: 'Intermediate bulk container, reconstituted wood, with inner liner',
    Description: '',
  },
  { Code: 'XXA', Name: 'Bag, woven plastic, without inner coat/liner', Description: '' },
  { Code: 'XXB', Name: 'Bag, woven plastic, sift proof', Description: '' },
  { Code: 'XXC', Name: 'Bag, woven plastic, water resistant', Description: '' },
  { Code: 'XXD', Name: 'Bag, plastics film', Description: '' },
  { Code: 'XXF', Name: 'Bag, textile, without inner coat/liner', Description: '' },
  { Code: 'XXG', Name: 'Bag, textile, sift proof', Description: '' },
  { Code: 'XXH', Name: 'Bag, textile, water resistant', Description: '' },
  { Code: 'XXJ', Name: 'Bag, paper, multi-wall', Description: '' },
  { Code: 'XXK', Name: 'Bag, paper, multi-wall, water resistant', Description: '' },
  { Code: 'XYA', Name: 'Composite packaging, plastic receptacle in steel drum', Description: '' },
  {
    Code: 'XYB',
    Name: 'Composite packaging, plastic receptacle in steel crate box',
    Description: '',
  },
  {
    Code: 'XYC',
    Name: 'Composite packaging, plastic receptacle in aluminium drum',
    Description: '',
  },
  {
    Code: 'XYD',
    Name: 'Composite packaging, plastic receptacle in aluminium crate',
    Description: '',
  },
  { Code: 'XYF', Name: 'Composite packaging, plastic receptacle in wooden box', Description: '' },
  { Code: 'XYG', Name: 'Composite packaging, plastic receptacle in plywood drum', Description: '' },
  { Code: 'XYH', Name: 'Composite packaging, plastic receptacle in plywood box', Description: '' },
  { Code: 'XYJ', Name: 'Composite packaging, plastic receptacle in fibre drum', Description: '' },
  {
    Code: 'XYK',
    Name: 'Composite packaging, plastic receptacle in fibreboard box',
    Description: '',
  },
  { Code: 'XYL', Name: 'Composite packaging, plastic receptacle in plastic drum', Description: '' },
  {
    Code: 'XYM',
    Name: 'Composite packaging, plastic receptacle in solid plastic box',
    Description: '',
  },
  { Code: 'XYN', Name: 'Composite packaging, glass receptacle in steel drum', Description: '' },
  {
    Code: 'XYP',
    Name: 'Composite packaging, glass receptacle in steel crate box',
    Description: '',
  },
  { Code: 'XYQ', Name: 'Composite packaging, glass receptacle in aluminium drum', Description: '' },
  {
    Code: 'XYR',
    Name: 'Composite packaging, glass receptacle in aluminium crate',
    Description: '',
  },
  { Code: 'XYS', Name: 'Composite packaging, glass receptacle in wooden box', Description: '' },
  { Code: 'XYT', Name: 'Composite packaging, glass receptacle in plywood drum', Description: '' },
  {
    Code: 'XYV',
    Name: 'Composite packaging, glass receptacle in wickerwork hamper',
    Description: '',
  },
  { Code: 'XYW', Name: 'Composite packaging, glass receptacle in fibre drum', Description: '' },
  { Code: 'XYX', Name: 'Composite packaging, glass receptacle in fibreboard box', Description: '' },
  {
    Code: 'XYY',
    Name: 'Composite packaging, glass receptacle in expandable plastic pack',
    Description: '',
  },
  {
    Code: 'XYZ',
    Name: 'Composite packaging, glass receptacle in solid plastic pack',
    Description: '',
  },
  { Code: 'XZA', Name: 'Intermediate bulk container, paper, multi-wall', Description: '' },
  { Code: 'XZB', Name: 'Bag, large', Description: '' },
  {
    Code: 'XZC',
    Name: 'Intermediate bulk container, paper, multi-wall, water resistant',
    Description: '',
  },
  {
    Code: 'XZD',
    Name: 'Intermediate bulk container, rigid plastic, with structural equipment, solids',
    Description: '',
  },
  {
    Code: 'XZF',
    Name: 'Intermediate bulk container, rigid plastic, freestanding, solids',
    Description: '',
  },
  {
    Code: 'XZG',
    Name: 'Intermediate bulk container, rigid plastic, with structural equipment, pressurised',
    Description: '',
  },
  {
    Code: 'XZH',
    Name: 'Intermediate bulk container, rigid plastic, freestanding, pressurised',
    Description: '',
  },
  {
    Code: 'XZJ',
    Name: 'Intermediate bulk container, rigid plastic, with structural equipment, liquids',
    Description: '',
  },
  {
    Code: 'XZK',
    Name: 'Intermediate bulk container, rigid plastic, freestanding, liquids',
    Description: '',
  },
  {
    Code: 'XZL',
    Name: 'Intermediate bulk container, composite, rigid plastic, solids',
    Description: '',
  },
  {
    Code: 'XZM',
    Name: 'Intermediate bulk container, composite, flexible plastic, solids',
    Description: '',
  },
  {
    Code: 'XZN',
    Name: 'Intermediate bulk container, composite, rigid plastic, pressurised',
    Description: '',
  },
  {
    Code: 'XZP',
    Name: 'Intermediate bulk container, composite, flexible plastic, pressurised',
    Description: '',
  },
  {
    Code: 'XZQ',
    Name: 'Intermediate bulk container, composite, rigid plastic, liquids',
    Description: '',
  },
  {
    Code: 'XZR',
    Name: 'Intermediate bulk container, composite, flexible plastic, liquids',
    Description: '',
  },
  { Code: 'XZS', Name: 'Intermediate bulk container, composite', Description: '' },
  { Code: 'XZT', Name: 'Intermediate bulk container, fibreboard', Description: '' },
  { Code: 'XZU', Name: 'Intermediate bulk container, flexible', Description: '' },
  { Code: 'XZV', Name: 'Intermediate bulk container, metal, other than steel', Description: '' },
  { Code: 'XZW', Name: 'Intermediate bulk container, natural wood', Description: '' },
  { Code: 'XZX', Name: 'Intermediate bulk container, plywood', Description: '' },
  { Code: 'XZY', Name: 'Intermediate bulk container, reconstituted wood', Description: '' },
  { Code: 'XZZ', Name: 'Mutually defined', Description: '' },
];

export const country = countries.reduce(
  (acc, country) => {
    acc[country.Code] = country.Country;
    return acc;
  },
  <MasterCode>{},
);

export const currency = currencies.reduce(
  (items, currency) => {
    items[currency.key] = currency.name;
    return items;
  },
  <MasterCode>{},
);

export const state = stateList.reduce(
  (state, item) => {
    state[item.Code] = item.State;
    return state;
  },
  <MasterCode>{},
);

export const msic = msicList.reduce(
  (code, item) => {
    code[item.Code] = {
      Description: item.Description,
      category: item.category,
    };
    return code;
  },
  <MSIC>{},
);

export const classification = classificationList.reduce(
  (code, item) => {
    code[item.Code] = item.Description;
    return code;
  },
  <MasterCode>{},
);

export const invoiceType = invoiceTypeList.reduce(
  (code, item) => {
    code[item.Code] = item.Description;
    return code;
  },
  <MasterCode>{},
);

export const paymentMethod = paymentMethodList.reduce(
  (code, item) => {
    code[item.Code] = item['Payment Method'];
    return code;
  },
  <MasterCode>{},
);

export const taxType = taxTypeList.reduce(
  (code, item) => {
    code[item.Code] = item.Description;
    return code;
  },
  <MasterCode>{},
);

export const unitType = unitTypeList.reduce(
  (code, item) => {
    code[item.Code] = {
      Name: item.Name,
      Description: item.Description,
    };
    return code;
  },
  <UnitType>{},
);
