/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpRequest,
  HttpErrorResponse,
  HttpHandlerFn,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { inject } from '@angular/core';
import { Store } from '@app/config/store';
import { configProvider } from '@app/config/app';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from '@app/types/global.type';

interface ApiConfig {
  url?: string;
  setHeaders?: {
    [name: string]: string | string[];
  };
}

export function HttpInterceptor(
  req: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> {
  const config: AppConfig = inject(configProvider);
  const store = inject(Store);
  const toastr = inject(ToastrService);

  let modifiedRequest = req.clone();

  if (!req.url.startsWith('http')) {
    const apiConfig: ApiConfig = { url: `${config.apiUrl}${req.url}`, setHeaders: {} };

    if (store.authToken()) apiConfig.setHeaders!.Authorization = `Bearer ${store.authToken()}`;

    if (store.activeAccount()?.orgId)
      apiConfig.setHeaders!['x-orgid'] = store.activeAccount()?.orgId.toString() ?? '';

    modifiedRequest = req.clone(apiConfig);
  }

  store.appLoader.set(true);
  return next(modifiedRequest).pipe(
    tap((event) => {
      if (event instanceof HttpResponse) store.appLoader.set(false);
    }),
    catchError((resp: HttpErrorResponse) => {
      console.log('Error: ', resp);
      store.appLoader.set(false);

      let message =
        resp.error?.message ??
        "We couldn't process your request at the moment. Please try again later.";

      if (resp.status === 400 && resp.error?.fieldErrors?.length)
        message = resp.error.fieldErrors[0].message;

      if (resp.status === 401) {
        message = 'Your session has expired. Please login again.';
        store.clearSession();
      }

      toastr.error(message, 'Error');
      throw new HttpErrorResponse({ error: resp.error, status: resp.status });
      // return throwError(() => resp);
      // return of(resp.error);
    }),
  );
}
