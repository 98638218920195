<form [formGroup]="createForm" (submit)="submitForm()" class="card tw-p-7">
  <h1 class="page-header tw-font-semibold mb-4">Create New E-Invoice</h1>

  <div class="tw-flex tw-gap-2">
    <div class="mb-3 tw-w-1/2">
      <label class="required">Tin</label>
      <select formControlName="tin" class="form-select sm" (change)="getPOBs($event)">
        <option value="">- Select -</option>
        @for (tin of tinsList; track tin) {
          <option [value]="tin.tin">[{{ tin.tin }}] {{ tin.name }}</option>
        }
      </select>
    </div>

    <div class="mb-3 tw-w-1/2">
      <label [class.required]="pobRequired">POBs</label>
      <select formControlName="pob" class="form-select sm" [required]="pobRequired">
        <option value="">- Select -</option>
        @for (pob of pobsList; track pob) {
          @if (pob.defaultPob !== true) {
            <option [value]="pob.id">[{{ pob.pobCode }}] {{ pob.pobName }}</option>
          }
        }
      </select>
    </div>
    <div class="input-error">
      @if (createForm.controls['pob'].errors) {
        <p>Enter POB.</p>
      }
    </div>
  </div>

  <div class="flex-apart tw-mb-4">
    <div class="tw-flex-1">
      <label class="required">Supply Type</label>
      <div class="flex-inline">
        <label>
          <input type="radio" class="form-check-input" formControlName="supplyType" value="Sales" />
          Sales
        </label>
        <label>
          <input type="radio" class="form-check-input" formControlName="supplyType" value="Self Billed" />
          Self-Billed
        </label>
      </div>
    </div>

    <div class="tw-flex-1">
      <label class="required">Invoice Type</label>
      <select formControlName="invType" (click)="getInvoiceType()" class="form-select sm">
        @for (invoice of invoiceTypeData; track invoice.name) {
          <option [value]="invoice.key">
            {{ invoice.name }}
          </option>
        }
      </select>
    </div>
  </div>

  <div class="flex-apart tw-mb-4">
    <div class="tw-flex-1">
      <label class="required">Document Type</label>
      <select formControlName="docType" class="form-select sm">
        @for (document of documentTypeData; track document.name) {
          <option [value]="document.key">
            {{ document.name }}
          </option>
        }
      </select>
    </div>

    <div class="tw-flex-1">
      <label class="required" for="DocCurrencyCode">Document Currency Code</label>
      <select
        class="form-select form-select-sm"
        formControlName="currencyCode"
        id="DocCurrencyCode"
        (click)="getCurrencyExchangeRateValidators()"
      >
        @for (currency of currencies; track currency.name) {
          <option [value]="currency.key">
            {{ currency.name }}
          </option>
        }
      </select>
      <div class="input-error">
        @if (createForm.controls['currencyCode'].errors) {
          <p>Enter Currency Code.</p>
        }
      </div>
    </div>
  </div>

  <div class="flex-apart tw-mb-6">
    <div class="transaction-type tw-flex-1">
      <label class="required">Transaction Type</label>
      <select class="form-select sm" formControlName="tranactionType">
        @for (transaction of transactionTypeData; track transaction.name) {
          <option [value]="transaction.key">
            {{ transaction.name }}
          </option>
        }
      </select>

      <div class="input-error">
        @if (createForm.controls['tranactionType'].errors) {
          <p>Enter Transaction Type.</p>
        }
      </div>
    </div>
    @if (createForm.controls['currencyCode'].value !== 'MYR') {
      <div class="tw-flex-1">
        <label class="label">Currency Exchange Rate</label>
        <input type="text" formControlName="currencyExchangeRate" class="form-control" />
        <div class="input-error">
          @if (createForm.controls['currencyExchangeRate'].errors) {
            <p>Enter valid Rate.</p>
          }
        </div>
      </div>
    }
  </div>

  <div class="flex-apart">
    <button class="btn btn-light tw-px-10" (click)="cancelForm($event)">Cancel</button>
    <button class="btn btn-primary tw-flex-1" [disabled]="createForm.invalid">
      <span>Save & Continue</span>
    </button>
  </div>
</form>
