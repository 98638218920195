import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matAppsOutline,
  matBusinessOutline,
  matCheckOutline,
  matDevicesOutline,
  matLocalPoliceOutline,
  matLockOutline,
  matPowerSettingsNewOutline,
  matReceiptLongOutline,
  matSettingsOutline,
  matTransformOutline,
  matUnfoldMoreOutline,
  matCheckCircleOutline,
  matChangeCircleOutline,
} from '@ng-icons/material-icons/outline';

import { Store } from '@app/config/store';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from '@app/pages/auth/change-password/change-password.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AuthService, EnableDisableMfa } from '@app/services/auth.service';
import { TaxPayer } from '@app/types/global.type';
import { ConfigMfaComponent } from '../../pages/auth/config-mfa/config-mfa.component';
import { FSLUG } from '@app/constant/features';

enum Apps {
  Einvois = 'taxpayer',
  Garnet = 'garnet',
  Settings = 'account',
}

@Component({
  selector: 'app-header, [app-header]',
  standalone: true,
  imports: [
    RouterModule,
    NgIconComponent,
    NgbDropdownModule,
    ChangePasswordComponent,
    ReactiveFormsModule,
    ConfigMfaComponent,
  ],
  providers: [
    provideIcons({
      matBusinessOutline,
      matSettingsOutline,
      matAppsOutline,
      matUnfoldMoreOutline,
      matPowerSettingsNewOutline,
      matTransformOutline,
      matReceiptLongOutline,
      matLocalPoliceOutline,
      matDevicesOutline,
      matCheckOutline,
      matLockOutline,
      matCheckCircleOutline,
      matChangeCircleOutline,
    }),
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent implements OnInit {
  @ViewChild('menu') menu!: ElementRef;
  mfaValue: FormControl = new FormControl('no');

  Apps = Apps;
  activeApp: Apps = Apps.Einvois;
  orgName = 'Kevin Motors';
  FSLUG = FSLUG;

  accounts = [
    {
      id: '1',
      name: 'Kevin Motors',
      role: 'Admin',
    },
    {
      name: 'Metalbooks Pvt Ltd',
      id: '2',
      role: 'Manager',
    },
  ];

  ngOnInit(): void {
    if (this.store.taxpayer()?.isMfaEnable) {
      this.mfaValue.setValue('yes');
    }
  }

  constructor(
    protected store: Store,
    private ngbModal: NgbModal,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {
    this.route.url.subscribe((url) => {
      this.activeApp = url[0].path as Apps;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openModal(content: any, size = 'sm') {
    this.ngbModal.open(content, {
      centered: true,
      size,
    });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  switchAccount(accountId: number | string) {
    if (accountId === this.store.activeAccount()?.orgId) return;

    this.ngbModal.dismissAll();
    this.store.switchAccount(accountId);
  }

  saveMfa() {
    const payload: EnableDisableMfa = {
      userEmail: this.store.taxpayer()?.email ?? '',
      mfaEnable: this.mfaValue.value === 'yes',
    };

    this.authService.enableDisableMfa(payload).subscribe((res) => {
      if (res.status) {
        const updatedTaxpayer = {
          ...this.store.taxpayer(),
          isMfaEnable: this.mfaValue.value === 'yes',
        } as TaxPayer;

        this.store.taxpayer.set(updatedTaxpayer);
        localStorage.setItem('taxpayer', JSON.stringify(updatedTaxpayer));

        this.closeModal();
      }
    });
  }
}
