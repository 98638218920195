export interface Feature {
  id: number;
  name: string;
  slug: string;
  group: string;
}

export interface AccessMap {
  [key: string]: boolean;
}

interface GroupFeature {
  [key: string]: Feature[];
}

interface SlugFeature {
  [key: string]: Feature;
}

export const featureList: Feature[] = [
  {
    id: 1,
    name: 'Invite Partner',
    slug: 'invite-partner',
    group: 'Partner',
  },

  {
    id: 2,
    name: 'Add Order Form',
    slug: 'add-order-form',
    group: 'Partner',
  },

  {
    id: 3,
    name: 'View Order Form',
    slug: 'view-order-form',
    group: 'Partner',
  },

  {
    id: 4,
    name: 'Edit/ Manage Order Form',
    slug: 'edit-manage-order-form',
    group: 'Partner',
  },

  {
    id: 5,
    name: 'Dashboard',
    slug: 'dashboard',
    group: 'Partner',
  },

  {
    id: 6,
    name: 'Settings',
    slug: 'settings',
    group: 'Partner',
  },

  {
    id: 7,
    name: 'Add TIN',
    slug: 'add-tin',
    group: 'Organisational',
  },

  {
    id: 8,
    name: 'Update TIN',
    slug: 'update-tin',
    group: 'Organisational',
  },

  {
    id: 9,
    name: 'Delete TIN',
    slug: 'delete-tin',
    group: 'Organisational',
  },

  {
    id: 10,
    name: 'View TIN',
    slug: 'view-tin',
    group: 'Organisational',
  },

  {
    id: 11,
    name: 'Add POB',
    slug: 'add-pob',
    group: 'Organisational',
  },

  {
    id: 12,
    name: 'Update POB',
    slug: 'update-pob',
    group: 'Organisational',
  },

  {
    id: 13,
    name: 'Delete POB',
    slug: 'delete-pob',
    group: 'Organisational',
  },

  {
    id: 14,
    name: 'View POB',
    slug: 'view-pob',
    group: 'Organisational',
  },

  {
    id: 15,
    name: 'Grouping of TIN, Grouping of POB',
    slug: 'manage-tin',
    group: 'Organisational',
  },

  {
    id: 16,
    name: 'Add Logo',
    slug: 'add-logo',
    group: 'Organisational',
  },

  {
    id: 17,
    name: 'Update Logo',
    slug: 'update-logo',
    group: 'Organisational',
  },

  {
    id: 18,
    name: 'View Logo',
    slug: 'view-logo',
    group: 'Organisational',
  },

  {
    id: 19,
    name: 'Update / Revoke feature based on role',
    slug: 'update-revoke-feature',
    group: 'Organisational',
  },

  {
    id: 20,
    name: 'Invite User',
    slug: 'invite-user',
    group: 'Organisational',
  },

  {
    id: 21,
    name: 'Update Role',
    slug: 'update-role',
    group: 'Organisational',
  },

  {
    id: 22,
    name: 'Delete Users',
    slug: 'delete-users',
    group: 'Organisational',
  },

  {
    id: 23,
    name: 'View Users',
    slug: 'view-users',
    group: 'Organisational',
  },

  {
    id: 24,
    name: 'Invite Taxpayer User at Org level',
    slug: 'invite-taxpayer-user',
    group: 'Organisational',
  },

  {
    id: 25,
    name: 'Invite Taxpayer User at TIN/ POB, Grouping level',
    slug: 'invite-taxpayer-group-level',
    group: 'Organisational',
  },

  {
    id: 26,
    name: 'Update/Change Role',
    slug: 'update-change-role-taxpayer',
    group: 'Organisational',
  },

  {
    id: 27,
    name: 'Delete Users',
    slug: 'delete-users-taxpayer',
    group: 'Organisational',
  },

  {
    id: 28,
    name: 'View Users',
    slug: 'view-users-taxpayer',
    group: 'Organisational',
  },

  {
    id: 29,
    name: 'Add Master',
    slug: 'add-master',
    group: 'Organisational',
  },
  {
    id: 30,
    name: 'Update Master',
    slug: 'update-master',
    group: 'Organisational',
  },

  {
    id: 31,
    name: 'Delete Master',
    slug: 'delete-master',
    group: 'Organisational',
  },

  {
    id: 32,
    name: 'View list of Master',
    slug: 'view-master',
    group: 'Organisational',
  },

  {
    id: 33,
    name: 'Add Print Template',
    slug: 'add-print-template',
    group: 'Organisational',
  },

  {
    id: 34,
    name: 'Update Print Template',
    slug: 'update-print-template',
    group: 'Organisational',
  },

  {
    id: 35,
    name: 'Delete Print Template',
    slug: 'delete-print-template',
    group: 'Organisational',
  },

  {
    id: 36,
    name: 'View list of Template',
    slug: 'view-print-template',
    group: 'Organisational',
  },

  {
    id: 37,
    name: 'Add OCR Template',
    slug: 'add-ocr-template',
    group: 'Organisational',
  },

  {
    id: 38,
    name: 'Update OCR Template',
    slug: 'update-ocr-template',
    group: 'Organisational',
  },

  {
    id: 39,
    name: 'Delete OCR Template',
    slug: 'delete-ocr-template',
    group: 'Organisational',
  },

  {
    id: 40,
    name: 'View list of OCR Template',
    slug: 'view-ocr-template',
    group: 'Organisational',
  },

  {
    id: 41,
    name: 'Manage Notifications',
    slug: 'manage-notification',
    group: 'Organisational',
  },

  {
    id: 42,
    name: 'Manage SFTP',
    slug: 'manage-sftp',
    group: 'Organisational',
  },

  {
    id: 43,
    name: 'Invite User at Buyer TIN',
    slug: 'invite-buyer-user',
    group: 'Organisational',
  },

  {
    id: 44,
    name: 'Update/Change Role',
    slug: 'update-change-role-buyer',
    group: 'Organisational',
  },

  {
    id: 45,
    name: 'Delete Users',
    slug: 'delete-users-buyer',
    group: 'Organisational',
  },

  {
    id: 46,
    name: 'View Users',
    slug: 'view-users-buyer',
    group: 'Organisational',
  },

  {
    id: 47,
    name: 'Manage Peppol',
    slug: 'manage-peppol',
    group: 'Organisational',
  },

  {
    id: 48,
    name: 'Dashboard',
    slug: 'dashboard',
    group: 'Einvoice Management',
  },

  {
    id: 49,
    name: 'E-Invoice - Web form',
    slug: 'einvoice-web-form',
    group: 'Einvoice Management',
  },

  {
    id: 50,
    name: 'E-Invoice - OCR send for review',
    slug: 'einvoice-ocr-review',
    group: 'Einvoice Management',
  },

  {
    id: 51,
    name: 'E-Invoice - OCR generate',
    slug: 'einvoice-ocr-generate',
    group: 'Einvoice Management',
  },

  {
    id: 52,
    name: 'Bulk Upload',
    slug: 'bulk-upload',
    group: 'Einvoice Management',
  },

  {
    id: 53,
    name: 'Excel Utility',
    slug: 'excel-utility',
    group: 'Einvoice Management',
  },

  {
    id: 54,
    name: 'View- Einvoice Not Generated',
    slug: 'view-einvoice-not-generated',
    group: 'Einvoice Management',
  },

  {
    id: 55,
    name: 'View- Einvoice Generated',
    slug: 'view-einvoice-generated',
    group: 'Einvoice Management',
  },

  {
    id: 56,
    name: 'View - Quick filters Individual invoice, self invoice, consolidate invoice (with count)',
    slug: 'view-quick-filters',
    group: 'Einvoice Management',
  },

  {
    id: 57,
    name: 'View - Basic filters based on single or multiple parameters',
    slug: 'view-basic-filters',
    group: 'Einvoice Management',
  },

  {
    id: 59,
    name: 'Generate',
    slug: 'generate-einvoice',
    group: 'Einvoice Management',
  },

  {
    id: 60,
    name: 'Edit (not generated) other than submitted - allowed for rejected docs, internal error, invalid status docs',
    slug: 'edit-einvoice',
    group: 'Einvoice Management',
  },

  {
    id: 61,
    name: 'Delete (not generated section) other than submitted - allowed for rejected docs, internal error, invalid status docs',
    slug: 'delete-einvoice',
    group: 'Einvoice Management',
  },

  {
    id: 62,
    name: 'Cancel',
    slug: 'cancel-einvoice',
    group: 'Einvoice Management',
  },

  {
    id: 63,
    name: 'Print',
    slug: 'print-einvoice',
    group: 'Einvoice Management',
  },

  {
    id: 64,
    name: 'Email',
    slug: 'email-einvoice',
    group: 'Einvoice Management',
  },

  {
    id: 65,
    name: 'Download as per format',
    slug: 'download-einvoice',
    group: 'Einvoice Management',
  },

  {
    id: 66,
    name: 'Einvoice view',
    slug: 'view-einvoice',
    group: 'Einvoice Management',
  },

  {
    id: 67,
    name: 'Fetch',
    slug: 'fetch-incoming-einvoice',
    group: 'Einvoice Management',
  },

  {
    id: 68,
    name: 'View',
    slug: 'view-incoming-einvoice',
    group: 'Einvoice Management',
  },

  {
    id: 69,
    name: 'Reject',
    slug: 'reject-incoming-einvoice',
    group: 'Einvoice Management',
  },

  {
    id: 70,
    name: 'Reports',
    slug: 'reports',
    group: 'Einvoice Management',
  },

  {
    id: 71,
    name: 'Invoice Repository',
    slug: 'invoice-repoistory',
    group: 'Einvoice Management',
  },

  {
    id: 72,
    name: 'Verify E-Invoice',
    slug: 'verify-einvoice',
    group: 'Einvoice Management',
  },

  {
    id: 73,
    name: 'Verify TIN',
    slug: 'verify-tin',
    group: 'Einvoice Management',
  },

  {
    id: 75,
    name: 'Master Codes',
    slug: 'master-codes',
    group: 'Einvoice Management',
  },

  {
    id: 76,
    name: 'Input format',
    slug: 'input-format',
    group: 'Einvoice Management',
  },

  {
    id: 77,
    name: 'Excel Tools',
    slug: 'excel-tools',
    group: 'Einvoice Management',
  },

  {
    id: 78,
    name: 'Help Guide',
    slug: 'help-guide',
    group: 'Einvoice Management',
  },

  {
    id: 79,
    name: 'Release Notes',
    slug: 'release-notes',
    group: 'Einvoice Management',
  },

  {
    id: 80,
    name: 'Whats New',
    slug: 'whats-new',
    group: 'Einvoice Management',
  },

  {
    id: 81,
    name: 'Create and Define Workflow',
    slug: 'create-define-workflow',
    group: 'Transformation Module',
  },

  {
    id: 82,
    name: 'Edit Workflow',
    slug: 'edit-workflow',
    group: 'Transformation Module',
  },

  {
    id: 83,
    name: 'Replicate Workflow',
    slug: 'replicate-workflow',
    group: 'Transformation Module',
  },

  {
    id: 84,
    name: 'Generate Output',
    slug: 'generate-output',
    group: 'Transformation Module',
  },

  {
    id: 85,
    name: 'Update Master',
    slug: 'update-master',
    group: 'Transformation Module',
  },

  {
    id: 86,
    name: 'View Invoice Patterns',
    slug: 'view-invoice-patterns',
    group: 'Transformation Module',
  },
];

export const groupAccess = featureList.reduce((acc: { [group: string]: string[] }, feature) => {
  if (!acc[feature.group]) acc[feature.group] = [];
  acc[feature.group].push(feature.slug);

  return acc;
}, {});

export const slugAccessMap = featureList.reduce((acc: AccessMap, feature) => {
  acc[feature.slug] = false;
  return acc;
}, {});

export const slugFeatureMap = featureList.reduce((acc: SlugFeature, feature) => {
  acc[feature.slug] = feature;
  return acc;
}, {});

export const groupFeatureMap = featureList.reduce((acc: GroupFeature, feature) => {
  if (!acc[feature.group]) acc[feature.group] = [];

  acc[feature.group].push(feature);
  return acc;
}, {});

// Revamped User Config Features
export enum FSLUG {
  SETTINGS_ROLES_ACCESS_MODIFY = 'settings-roles-access-modify',
  SETTINGS_TINS_READ = 'settings-tins-read',
  SETTINGS_TINS_MODIFY = 'settings-tins-modify',
  SETTINGS_TINS_DELETE = 'settings-tins-delete',
  SETTINGS_POBS_VIEW = 'settings-pobs-view',
  SETTINGS_POBS_MODIFY = 'settings-pobs-modify',
  SETTINGS_POBS_DELETE = 'settings-pobs-delete',
  SETTINGS_USERS_VIEW = 'settings-users-view',
  SETTINGS_USERS_MODIFY = 'settings-users-modify',
  SETTINGS_USERS_REVOKE = 'settings-users-revoke',
  SETTINGS_MASTERS_VIEW = 'settings-masters-view',
  SETTINGS_MASTERS_MODIFY = 'settings-masters-modify',
  SETTINGS_MASTERS_DELETE = 'settings-masters-delete',
  SETTINGS_PRINT_TEMPLATES_VIEW = 'settings-print-templates-view',
  SETTINGS_PRINT_TEMPLATES_MODIFY = 'settings-print-templates-modify',
  SETTINGS_PRINT_TEMPLATES_DELETE = 'settings-print-templates-delete',
  SETTINGS_SFTP_MODIFY = 'settings-sftp-modify',
  SETTINGS_VIEW_EMAIL_NOTIFICATIONS = 'settings-view-email-notifications',
  SETTINGS_MODIFY_EMAIL_NOTIFICATIONS = 'settings-modify-email-notifications',

  EINVOICE_BULKGENERATE_VIEW = 'einvoice-bulkgenerate-view',
  EINVOICE_BULKGENERATE_MODIFY = 'einvoice-bulkgenerate-modify',
  EINVOICE_INVOICES_VIEW = 'einvoice-invoices-view',
  EINVOICE_INVOICES_ISSUED_SALES_VIEW = 'einvoice-invoices-issued-sales-view',
  EINVOICE_INVOICES_ISSUED_PURCHASE_VIEW = 'einvoice-invoices-issued-purchase-view',
  EINVOICE_INVOICES_RECEIVED_SALES_VIEW = 'einvoice-invoices-received-sales-view',
  EINVOICE_INVOICES_RECEIVED_PURCHASE_VIEW = 'einvoice-invoices-received-purchase-view',
  EINVOICE_LHDN_SUBMIT = 'einvoice-lhdn-submit',
  EINVOICE_LHDN_CANCEL = 'einvoice-lhdn-cancel',
  EINVOICE_INVOICES_MODIFY = 'einvoice-invoices-modify',
  EINVOICE_INVOICES_DELETE = 'einvoice-invoices-delete',
  EINVOICE_LHDN_REJECT = 'einvoice-lhdn-reject',
  EINVOICE_INVOICES_DOWNLOAD = 'einvoice-invoices-download',
  EINVOICE_INVOICES_PRINT = 'einvoice-invoices-print',
  EINVOICE_INVOICES_EMAIL = 'einvoice-invoices-email',
  EINVOICE_OCR_TEMPLATES_VIEW = 'einvoice-ocr-templates-view',
  EINVOICE_OCR_TEMPLATES_MODIFY = 'einvoice-ocr-templates-modify',
  EINVOICE_OCR_TEMPLATES_DELETE = 'einvoice-ocr-templates-delete',
  EINVOICE_OCR_REVIEW_REQUEST = 'einvoice-ocr-review-request',
  EINVOICE_EMAIL_LOGS_VIEW = 'einvoice-email-logs-view',
  EINVOICE_PRINT_LOGS_VIEW = 'einvoice-print-logs-view',
  EINVOICE_SCHEDULER_LOGS_VIEW = 'einvoice-scheduler-logs-view',
  EINVOICE_INVOICES_ARCHIVED_VIEW = 'einvoice-invoices-archived-view',
  EINVOICE_TOOLS_VIEW = 'einvoice-tools-view',
  EINVOICE_MASTER_CODES_VIEW = 'einvoice-master-codes-view',
  EINVOICE_TOOLS_EXCEL_TOOLS = 'einvoice-tools-excel-tools',

  GARNET_WORKFLOWS_VIEW = 'garnet-workflows-view',
  GARNET_WORKFLOWS_MODIFY = 'garnet-workflows-modify',
  GARNET_WORKFLOWS_DELETE = 'garnet-workflows-delete',
  GARNET_TRANSFORM_VIEW = 'garnet-transform-view',
  GARNET_TRANSFORM_MODIFY = 'garnet-transform-modify',
  GARNET_MASTERS_MODIFY = 'garnet-masters-modify',
  GARNET_WORKFLOWS_VIEW_INVOICE_PATTERN = 'garnet-workflows-view-invoice-pattern',

  MISC_HELPGUIDE = 'misc-helpguide',
  MISC_RELEASE_NOTES = 'misc-release-notes',
  MISC_WHATS_NEW = 'misc-whats-new',

  SETTINGS_USERS_MFA_MODIFY = 'settings-users-mfa-modify',
}

export interface SlugAccess {
  [slug: string]: boolean;
}

export enum MSLUG {
  EINVOICE_LIST = 'einvoiceList',
  EINVOICE_APP = 'einvoiceApp',
  SETTINGS_APP = 'settingsApp',
  GARNET_APP = 'garnetApp',
}

export type ModuleAccess = { [key in MSLUG]: boolean };
export const moduleAccess: { [key in MSLUG]: boolean } = {
  einvoiceList: false,
  einvoiceApp: false,
  garnetApp: false,
  settingsApp: false,
};

export function getModuleAccess(access: SlugAccess) {
  if (!access) return moduleAccess;

  moduleAccess.einvoiceList =
    access[FSLUG.EINVOICE_INVOICES_VIEW] &&
    (access[FSLUG.EINVOICE_INVOICES_ISSUED_SALES_VIEW] ||
      access[FSLUG.EINVOICE_INVOICES_ISSUED_PURCHASE_VIEW] ||
      access[FSLUG.EINVOICE_INVOICES_RECEIVED_SALES_VIEW] ||
      access[FSLUG.EINVOICE_INVOICES_RECEIVED_PURCHASE_VIEW]);

  moduleAccess.einvoiceApp =
    moduleAccess.einvoiceList ||
    access[FSLUG.EINVOICE_BULKGENERATE_VIEW] ||
    access[FSLUG.EINVOICE_OCR_TEMPLATES_VIEW] ||
    access[FSLUG.EINVOICE_EMAIL_LOGS_VIEW] ||
    access[FSLUG.EINVOICE_PRINT_LOGS_VIEW] ||
    access[FSLUG.EINVOICE_TOOLS_VIEW] ||
    access[FSLUG.EINVOICE_MASTER_CODES_VIEW] ||
    access[FSLUG.EINVOICE_TOOLS_EXCEL_TOOLS];

  moduleAccess.settingsApp =
    access[FSLUG.SETTINGS_ROLES_ACCESS_MODIFY] ||
    access[FSLUG.SETTINGS_USERS_VIEW] ||
    access[FSLUG.SETTINGS_TINS_READ] ||
    access[FSLUG.SETTINGS_MASTERS_VIEW] ||
    access[FSLUG.SETTINGS_PRINT_TEMPLATES_VIEW] ||
    access[FSLUG.SETTINGS_SFTP_MODIFY];

  moduleAccess.garnetApp =
    access[FSLUG.GARNET_WORKFLOWS_VIEW] || access[FSLUG.GARNET_TRANSFORM_VIEW];

  return moduleAccess;
}

export type Group = 'settings' | 'einvoice' | 'garnet';

type GroupRoute = { [key in Group]: { [route: string]: string[] } };
const groupRoutes: GroupRoute = {
  settings: {
    '/account/tins': [FSLUG.SETTINGS_TINS_READ],
    '/account/users': [FSLUG.SETTINGS_USERS_VIEW],
    '/account/master-vendors': [FSLUG.SETTINGS_MASTERS_VIEW],
    '/account/product-masters': [FSLUG.SETTINGS_MASTERS_VIEW],
    '/account/roles/matrix': [FSLUG.SETTINGS_ROLES_ACCESS_MODIFY],
    '/account/sftp': [FSLUG.SETTINGS_SFTP_MODIFY],
  },
  einvoice: {
    '/taxpayer/einvoices/list': [
      FSLUG.EINVOICE_INVOICES_ISSUED_SALES_VIEW,
      FSLUG.EINVOICE_INVOICES_ISSUED_PURCHASE_VIEW,
      FSLUG.EINVOICE_INVOICES_RECEIVED_SALES_VIEW,
      FSLUG.EINVOICE_INVOICES_RECEIVED_PURCHASE_VIEW,
    ],
    '/taxpayer/einvoices/bulk-upload': [FSLUG.EINVOICE_BULKGENERATE_VIEW],
    '/taxpayer/ocr/templates': [FSLUG.EINVOICE_OCR_TEMPLATES_VIEW],
    '/taxpayer/ocr/jobs': [FSLUG.EINVOICE_OCR_TEMPLATES_VIEW],
    '/taxpayer/reports/email-logs': [FSLUG.EINVOICE_EMAIL_LOGS_VIEW],
    '/taxpayer/reports/print-logs': [FSLUG.EINVOICE_PRINT_LOGS_VIEW],
    '/taxpayer/tools/verify-tin': [FSLUG.EINVOICE_TOOLS_VIEW],
    '/taxpayer/codes': [FSLUG.EINVOICE_MASTER_CODES_VIEW],
  },
  garnet: {
    '/garnet/workflows': [FSLUG.GARNET_WORKFLOWS_VIEW],
    '/garnet/output/history': [FSLUG.GARNET_TRANSFORM_VIEW],
  },
};

export type DefaultRoute = { [key in Group]: string | undefined };
export const defaultRoutes: DefaultRoute = {
  settings: '/account/tins',
  einvoice: '/taxpayer/einvoices/list',
  garnet: '/garnet/workflows',
};

export function getDefaultRoutes(access: SlugAccess) {
  const routes: DefaultRoute = {
    settings: '/account/tins',
    einvoice: '/taxpayer/einvoices/list',
    garnet: '/garnet/workflows',
  };

  if (!access) return routes;

  routes.settings = Object.keys(groupRoutes.settings).find((route) =>
    groupRoutes.settings[route].some((slug) => access[slug]),
  );

  routes.einvoice = Object.keys(groupRoutes.einvoice).find((route) => {
    if (route === '/taxpayer/einvoices/list' && !access[FSLUG.EINVOICE_INVOICES_VIEW]) return false;
    else return groupRoutes.einvoice[route].some((slug) => access[slug]);
  });

  routes.garnet = Object.keys(groupRoutes.garnet).find((route) =>
    groupRoutes.garnet[route].some((slug) => access[slug]),
  );

  return routes;
}
