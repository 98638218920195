<div class="app-body tw-relative">
  <app-header class="header" />
  <div class="tw-flex tw-relative">
    <app-sidenav [app]="{ name: 'Transform', icon: 'matTransformOutline' }" [menu]="sidenavMenu" [search]="false" />
    <div class="tw-flex-1 tw-min-w-0 page-wrapper">
      @if (loading) {
        <div class="tw-h-3/4 tw-flex tw-justify-center tw-items-center text-muted">Loading...</div>
      } @else if (!sftpInfo || !sftpInfo.sftpOptin) {
        <div class="tw-h-3/4 tw-flex tw-justify-center tw-items-center">
          <div class="tw-m-auto">
            <p class="tw-text-2xl tw-font-semibold">SFTP Setup Required!</p>
            <p class="mb-3 tw-text-gray-500">Please setup your sftp account to access this page.</p>
            <a routerLink="/account/sftp" class="text-link text-primary">SFTP Settings</a>
          </div>
        </div>
      } @else {
        <router-outlet></router-outlet>
      }
    </div>
  </div>
</div>

<ng-template #updateMaster>
  <app-master-upload />
</ng-template>
