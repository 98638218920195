import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { config } from '@app/config/app';
import { Store } from '@app/config/store';
import { FSLUG } from '@app/constant/features';
import { AuthService } from '@app/services/auth.service';
import { TinsService } from '@app/services/tins.service';

@Component({
  selector: 'app-redirect',
  standalone: true,
  imports: [],
  templateUrl: './redirect.component.html',
  styleUrl: './redirect.component.css',
})
export class RedirectComponent {
  pathName: string = '';
  redirectURL: string | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private store: Store,
    private authSvc: AuthService,
    private tinService: TinsService,
  ) {
    this.pathName = this.document.location.pathname;
    this.redirectURL = this.router.parseUrl(this.router.url).queryParams['redirectUrl'];
    this.redirect();
  }

  async redirect() {
    if (config.env === 'prod' && (this.pathName === '/' || this.pathName === '')) {
      const baseURL = `${this.document.location.protocol}//${this.document.location.host}`;
      this.document.location.href = `${baseURL}/content/`;
      return;
    }

    if (!this.store.authToken()) {
      this.router.navigate(['/login']);
      return;
    }

    const accessList = await this.authSvc.accessList();
    this.store.setAccessList(accessList?.features ?? []);

    if (this.redirectURL?.includes('redirect')) this.redirectURL = null;

    this.tinService.getTins(true).subscribe((tins) => {
      this.store.miniSidenav.set(false);
      const defaultRoute = this.getDefaultRoute();

      if (this.store.userAccess()[FSLUG.SETTINGS_TINS_MODIFY] && tins.length < 1)
        this.router.navigate(['/account/onboarding']);
      else if (this.redirectURL) this.router.navigateByUrl(this.redirectURL);
      else this.router.navigate([defaultRoute]);
    });
  }

  getDefaultRoute() {
    if (this.store.defaultRoute().einvoice) return this.store.defaultRoute().einvoice;
    else if (this.store.defaultRoute().garnet) return this.store.defaultRoute().garnet;
    else if (this.store.defaultRoute().settings) return this.store.defaultRoute().settings;

    return '/taxpayer/einvoice/list';
  }
}
