{
  "name": "malaysia-ui",
  "version": "7.0.0",
  "config": {
    "app": "dev-malaysia"
  },
  "engines": {
    "npm": ">=10.2.4 <=10.5.1",
    "node": ">=20.11.1 <=22.0.0"
  },
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --aot && npm run sentry:sourcemaps:prod",
    "build:local": "ng build --aot --configuration=development && npm run sentry:sourcemaps:local",
    "build:stage": "ng build --aot --configuration=staging && npm run sentry:sourcemaps:stage",
    "build:sandbox": "ng build --aot --configuration=sandbox && npm run sentry:sourcemaps:sandbox",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "format:fix": "pretty-quick --staged",
    "precommit": "run-s format:fix lint",
    "format:check": "prettier --config ./.prettierrc --list-different \"src/{app,environments,assets}/**/*{.ts,.js,.json,.css,.scss}\"",
    "prepare": "husky",
    "git:prune": "sh ./scripts/prune.sh",
    "sentry:sourcemaps:local": "sentry-cli sourcemaps inject --org iris-ha --project local-malaysia ./dist/malaysia_ui/ && sentry-cli sourcemaps upload --org iris-ha --project local-malaysia ./dist/malaysia_ui/browser",
    "sentry:sourcemaps:stage": "sentry-cli sourcemaps inject --org iris-ha --project dev-malaysia ./dist/malaysia_ui/ && sentry-cli sourcemaps upload --org iris-ha --project dev-malaysia ./dist/malaysia_ui/browser",
    "sentry:sourcemaps:sandbox": "sentry-cli sourcemaps inject --org iris-ha --project sandbox-malaysia ./dist/malaysia_ui/ && sentry-cli sourcemaps upload --org iris-ha --project sandbox-malaysia ./dist/malaysia_ui/browser",
    "sentry:sourcemaps:prod": "sentry-cli sourcemaps inject --org iris-ha --project prod-malaysia ./dist/malaysia_ui/ && sentry-cli sourcemaps upload --org iris-ha --project prod-malaysia ./dist/malaysia_ui/browser"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.3",
    "@angular/common": "^18.2.3",
    "@angular/compiler": "^18.2.3",
    "@angular/core": "^18.2.3",
    "@angular/forms": "^18.2.3",
    "@angular/platform-browser": "^18.2.3",
    "@angular/platform-browser-dynamic": "^18.2.3",
    "@angular/router": "^18.2.3",
    "@ng-bootstrap/ng-bootstrap": "^17.0.1",
    "@ng-icons/core": "^29.5.0",
    "@ng-icons/material-icons": "^27.2.0",
    "@popperjs/core": "^2.11.8",
    "@sentry/angular": "^8.13.0",
    "@sentry/cli": "^2.36.1",
    "bootstrap": "^5.3.3",
    "dayjs": "^1.11.13",
    "dragselect": "^3.0.5",
    "esprima": "^4.0.1",
    "lodash-es": "^4.17.21",
    "ng2-pdf-viewer": "^10.3.0",
    "ngx-toastr": "^19.0.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.7.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.3",
    "@angular-eslint/builder": "^18.3.0",
    "@angular-eslint/eslint-plugin": "^18.3.0",
    "@angular-eslint/eslint-plugin-template": "^18.3.0",
    "@angular-eslint/schematics": "^18.3.0",
    "@angular-eslint/template-parser": "^18.3.0",
    "@angular/cli": "^18.2.3",
    "@angular/compiler-cli": "^18.2.3",
    "@angular/localize": "^18.2.3",
    "@eslint/eslintrc": "^3.1.0",
    "@eslint/js": "^9.11.1",
    "@sentry/wizard": "^3.0.0",
    "@types/esprima": "^4.0.6",
    "@types/jasmine": "^5.1.4",
    "@types/lodash-es": "^4.17.12",
    "@typescript-eslint/eslint-plugin": "^8.5.0",
    "@typescript-eslint/parser": "^8.5.0",
    "@typescript-eslint/utils": "^8.5.0",
    "autoprefixer": "^10.4.20",
    "eslint": "^9.10.0",
    "express": "^4.20.0",
    "globals": "^15.9.0",
    "husky": "^9.1.5",
    "jasmine-core": "^5.3.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "npm-run-all": "^4.1.5",
    "postcss": "^8.4.45",
    "prettier": "^3.3.3",
    "pretty-quick": "^4.0.0",
    "tailwindcss": "^3.4.10",
    "typescript": "^5.5.4"
  }
}
