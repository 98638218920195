import { InjectionToken } from '@angular/core';
import { AppConfig, Environment } from '@app/types/global.type';
import { environment } from '@env/environment';
import packageJson from '../../../package.json';

export const config: AppConfig = {
  version: packageJson.version,
  env: <Environment>String(environment.ENV),
  apiUrl: String(environment.API_URL),
  filesUrl: String(environment.FILES_URL),
  sentryDSN: String(environment.SENTRY_DSN),
  captchaKey: String(environment.CAPTCHA_KEY),
  gTagId: environment.GTAG_ID ?? null,

  excludeBranding: [
    'myeinvois.my',
    'dev.app.myeinvois.my',
    'sandbox.myeinvois.my',
    'prod.myeinvois.my',
    'localhost:3000',
    'localhost:4200',
  ],
};

export const configProvider = new InjectionToken<AppConfig>('app.config');
