import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { password, passwordMatchValidator } from '@app/dto/common.dto';
import { AuthService, ChangePassword } from '@app/services/auth.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { matRemoveRedEye, matVisibilityOff } from '@ng-icons/material-icons/baseline';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgIconComponent],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css',
  providers: [
    provideIcons({
      matRemoveRedEye,
      matVisibilityOff,
    }),
  ],
})
export class ChangePasswordComponent {
  @Output() closeEvent = new EventEmitter<void>();

  viewPassword = false;
  passwordForm = new FormGroup(
    {
      currentPassword: new FormControl(''),
      newPassword: new FormControl('', [password]),
      confirmNewPassword: new FormControl('', [password]),
    },
    { validators: passwordMatchValidator() },
  );

  constructor(private authService: AuthService) {}

  savePassword() {
    const changePassword: ChangePassword = {
      currentPassword: this.passwordForm.value.currentPassword as string,
      newPassword: this.passwordForm.value.newPassword as string,
    };
    this.authService.changePassword(changePassword).subscribe((resp) => {
      if (resp.status) this.closeEvent.emit();
    });
  }

  cancel(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.passwordForm.reset();
    this.closeEvent.emit();
  }
}
