import { Component, OnInit } from '@angular/core';
import {
  invoiceType,
  documentType,
  transactionType,
  frequencyBilling,
} from '@app/constant/taxpayer';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { currencyRate } from '@app/dto/common.dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TinsService } from '@app/services/tins.service';
import { EinvoiceService } from '@app/services/einvoice.service';
import { currencies } from '@app/constant/codes';
import { TIN } from '@app/types/taxpayer.type';
import { POBList } from '@app/pages/account/tin-pobs/tin-pobs.component';

@Component({
  selector: 'app-einvoice-create',
  standalone: true,
  imports: [RouterOutlet, RouterModule, ReactiveFormsModule],
  templateUrl: './einvoice-create.component.html',
  styleUrl: './einvoice-create.component.css',
})
export class EinvoiceCreateComponent implements OnInit {
  createForm: FormGroup = new FormGroup({
    tin: new FormControl('', [Validators.required]),
    pob: new FormControl(''),
    supplyType: new FormControl('Sales', [Validators.required]),
    invType: new FormControl('B2B', [Validators.required]),
    docType: new FormControl('Invoice', [Validators.required]),
    currencyCode: new FormControl('MYR', [Validators.required]),
    tranactionType: new FormControl('REGULAR', [Validators.required]),
    currencyExchangeRate: new FormControl('', [currencyRate]),
  });

  invoiceTypeData = invoiceType;
  documentTypeData = documentType;
  transactionTypeData = transactionType;
  frequencyData = frequencyBilling;
  currencies = currencies;
  tinsList: TIN[] = [];
  pobsList: POBList[] = [];
  pobRequired: boolean = false;

  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    private tinService: TinsService,
    private einvoiceService: EinvoiceService,
  ) {}

  ngOnInit(): void {
    this.getTins();
    this.getInvoiceType();
  }

  async getTins() {
    this.tinsList = await this.tinService.getTinsList();
  }

  submitForm() {
    const payload = {
      currencyCode: this.createForm.get('currencyCode')?.value,
      currencyExchangeRate: this.createForm.get('currencyExchangeRate')?.value,
      docType: this.createForm.get('docType')?.value,
      invType: this.createForm.get('invType')?.value,
      supplyType: this.createForm.get('supplyType')?.value,
      tin: this.createForm.get('tin')?.value,
      tranactionType: this.createForm.get('tranactionType')?.value,
    };
    const tin = payload.tin ?? '';
    const pob = this.createForm.get('pob')?.value ?? '';

    this.einvoiceService.createEinvoice(tin, pob, payload).subscribe((response) => {
      if (response) {
        this.ngbModal.dismissAll();
        const draftId = response.response.id;
        this.router.navigate(['taxpayer/einvoices/update', draftId, 'transaction']);
      }
    });
  }

  getInvoiceType() {
    this.invoiceTypeData = invoiceType;
    if (this.createForm.controls.supplyType.value === 'Sales') {
      this.invoiceTypeData = this.invoiceTypeData.filter((item) => item.key !== 'IMPORT');
    } else {
      this.invoiceTypeData = this.invoiceTypeData.filter((item) => item.key !== 'EXPORT');
    }
  }

  cancelForm(event: Event) {
    event.preventDefault();
    this.ngbModal.dismissAll();
  }

  getCurrencyExchangeRateValidators() {
    if (this.createForm) {
      const currencyCodeControl = this.createForm.get('currencyCode');
      const exchangeRateControl = this.createForm.get('currencyExchangeRate');

      if (currencyCodeControl && exchangeRateControl) {
        const originalExchangeRate = exchangeRateControl.value;
        const currencyCode = currencyCodeControl.value;

        if (currencyCode !== 'MYR') {
          exchangeRateControl.setValidators([currencyRate]);
        } else {
          exchangeRateControl.setValidators([currencyRate]);
        }

        exchangeRateControl.setValue(originalExchangeRate);
        exchangeRateControl.updateValueAndValidity();
      }
    }
  }

  getPOBs(event: Event) {
    const selectedTin = (event.target as HTMLSelectElement).value;
    const tin = this.tinsList.find((map) => map.tin === selectedTin);
    this.tinService.getPOBs(false).subscribe((pobs) => {
      this.pobsList = pobs.filter((pob) => pob.tinId === tin?.id);
      this.pobRequired = !this.pobsList.some((pob) => pob.defaultPob);
    });
  }
}
