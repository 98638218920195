import { Component, Inject, OnInit } from '@angular/core';
import { config } from './config/app';
import { environment } from '@env/environment';
import { Store } from './config/store';
import { DOCUMENT } from '@angular/common';
import { AppLoader } from './shared/app-loader/app-loader.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AppLoader],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  title = 'Welcome';
  loading: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected store: Store,
  ) {
    console.log('AppComponent constructor', config.env, config.apiUrl, environment);
  }

  ngOnInit() {
    this.initAssets();
  }

  async initAssets() {
    const hostname = this.document.location.host;
    this.store.setBrandMeta(this.irisBrandMeta());
    if (config.env !== 'prod' || config.excludeBranding.includes(hostname)) return;

    this.loading = true;

    try {
      const metaFile = `${config.filesUrl}/${hostname}/meta.json`;
      const data = await (await fetch(metaFile)).json();
      let logo = data.logo ?? 'logo.png';
      let logoInverted = data.logoInverted ?? 'logo.png';
      let favicon = data.favicon ?? 'favicon.png';

      logo = `${config.filesUrl}/${hostname}/${logo}`;
      logoInverted = `${config.filesUrl}/${hostname}/${logoInverted}`;
      favicon = `${config.filesUrl}/${hostname}/${favicon}`;

      // setting brand favicon
      this.document.getElementById('brandFavicon')?.setAttribute('href', favicon);

      this.store.setBrandMeta({ ...data, logo, logoInverted, favicon });
    } catch (error) {
      console.error('Meta file not found.', error);
    }

    this.loading = false;
  }

  irisBrandMeta() {
    const protocol = this.document.location.protocol;
    const hostname = this.document.location.host;

    return {
      logo: `${protocol}//${hostname}/logos/iris.colored.svg`,
      logoInverted: `${protocol}//${hostname}/logos/iris.inverted.png`,
      favicon: `${protocol}//${hostname}/favicon.webp`,
      brandName: 'IRIS Business Services Limited',
      shortName: 'IRIS',
      theme: 'default',
      privacyLink: '',
      termsLink: '',
    };
  }
}
