import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@app/config/store';
import { FSLUG } from '@app/constant/features';
import { HeaderComponent } from '@app/shared/header/header.component';
import { Menu, SidenavComponent } from '@app/shared/sidenav/sidenav.component';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, SidenavComponent],
  templateUrl: './account.component.html',
  styleUrl: './account.component.css',
})
export class AccountComponent {
  baseURL = '/account';

  sidenavMenu: Menu[] = [
    {
      title: 'Manage',
      route: '',
      icon: '',
      menu: [
        {
          title: 'TINs',
          route: `${this.baseURL}/tins`,
          icon: 'matBusinessOutline',
          access: this.store.userAccess()[FSLUG.SETTINGS_TINS_READ],
        },
        {
          title: 'Users',
          route: `${this.baseURL}/users`,
          icon: 'matPersonAdd',
          access: this.store.userAccess()[FSLUG.SETTINGS_USERS_VIEW],
        },
        // {
        //   title: 'User Roles',
        //   route: `${this.baseURL}/user-roles`,
        //   icon: 'matGroupOutline',
        //   access: this.store.userAccess()[FSLUG.SETTINGS_ROLES_ACCESS_MODIFY],
        // },
        {
          title: 'Config Roles',
          route: `${this.baseURL}/roles/matrix`,
          icon: 'matGroupOutline',
          access: this.store.userAccess()[FSLUG.SETTINGS_ROLES_ACCESS_MODIFY],
        },
        {
          title: 'Notifications',
          route: `${this.baseURL}/notification`,
          icon: 'matNotificationsActive',
          access: this.store.userAccess()[FSLUG.SETTINGS_VIEW_EMAIL_NOTIFICATIONS],
        },
      ],
    },
    {
      title: 'Masters',
      icon: 'matArticleOutline',
      menu: [
        {
          title: 'Counterparty',
          icon: 'matBusinessOutline',
          route: `${this.baseURL}/master-vendors`,
          access: this.store.userAccess()[FSLUG.SETTINGS_MASTERS_VIEW],
        },
        {
          title: 'Products',
          route: `${this.baseURL}/product-masters`,
          icon: 'matDocumentScannerOutline',
          access: this.store.userAccess()[FSLUG.SETTINGS_MASTERS_VIEW],
        },
      ],
    },
    // {
    //   title: '',
    //   route: '',
    //   icon: '',
    //   menu: [
    //     {
    //       title: 'Print Templates',
    //       route: `${this.baseURL}/print-templates`,
    //       icon: 'matPrintOutline',
    //       class: '!tw-mt-2',
    //     },
    //     {
    //       title: 'OCR Templates',
    //       route: `${this.baseURL}/ocr-templates`,
    //       icon: 'matDocumentScannerOutline',
    //     },
    //   ],
    // },
    {
      title: 'Templates',
      route: '',
      icon: '',
      menu: [
        {
          title: 'Print Templates',
          route: `${this.baseURL}/print-templates`,
          icon: 'matPrintOutline',
          access: this.store.userAccess()[FSLUG.SETTINGS_SFTP_MODIFY],
        },
      ],
    },
    {
      title: 'Integrations',
      route: '',
      icon: '',
      menu: [
        {
          title: 'SFTP',
          route: `${this.baseURL}/sftp`,
          icon: 'matHubOutline',
          access: this.store.userAccess()[FSLUG.SETTINGS_SFTP_MODIFY],
        },
      ],
    },
    // {
    //   title: '',
    //   route: '',
    //   icon: '',
    //   menu: [
    //     {
    //       title: 'Peppol',
    //       route: `${this.baseURL}/peppol`,
    //       icon: 'matShare',
    //       class: '!tw-mt-2',
    //     },
    //     {
    //       title: 'Counterparty',
    //       route: `${this.baseURL}/counterparty`,
    //       icon: 'matHandshakeOutline',
    //     },
    //   ],
    // },
  ];

  constructor(protected store: Store) {}
}
